
import { createStore, StoreCore } from "@priolo/jon";
import tasksApi from "api/tasks"
import taskSo from "stores/task";
import draftSo from "stores/draft";
import { clone } from "utils/func";
import { EditTask } from "./utils/edit";
import { Task } from "types/Task";
import { EDIT_TYPE } from "types/Entity";

// used when dialog closed
let resolveClose = null

type PayloadOpen = {
	task: Task,
	readOnly?: boolean
}

/**
 * Gestisce la dialog per visualizzazione dettaglio TASK
 */
const setup = {

	state: {
		/** se la dialog è aperta */
		isOpen: false,
		/** il TASK visualizzato */
		select: <Task>null,
		/** indica se la dialog è aperta in sola lattura o no */
		readOnly: true,
		/** il tab selezioantao in questo momento */
		tab: 0,
	},

	getters: {
	},

	actions: {
		/** apro la dialog del dettaglio e carico anche i dati aggiuntivi della TASK relativa */
		async open({ task, readOnly }: PayloadOpen, store?: TaskDetailStore) {
			if (!task) return
			let taskLocal = EditTask.current(task)
			// se non ha ancora caricato i subtask o i metadata allora preleva il dettaglio dal BE
			if ((!task.subtasks || !task.metadata) && task.id && task.farmId) {
				const { data: taskRemote } = await tasksApi.getByFarm(taskLocal.farmId, taskLocal.id)
				taskLocal = { ...taskRemote, ...taskLocal }
			}
			store.setTab(0)
			store.setReadOnly(!!readOnly)
			store.setSelect(taskLocal)
			store.setIsOpen(true)

			return new Promise((resolve, reject) => resolveClose = resolve)
		},
		async close(save = false, store?: TaskDetailStore) {
			store.setIsOpen(false)
			if (save) {
				const tasks = [...taskSo.state.all]
				const edited = store.state.select
				const indexPrev = tasks.findIndex(task => task.taskUuid == edited.taskUuid)
				if (indexPrev == -1) return
				const target = tasks[indexPrev]
				EditTask.modify(target, edited)
				await draftSo.saveTasks([target])
				taskSo.setAll(tasks)
			}
			if (resolveClose) resolveClose(save)
			resolveClose = null
		},
	},

	mutators: {
		setIsOpen: (isOpen: boolean) => ({ isOpen }),
		setSelect: (select: Task) => ({ select }),
		setReadOnly: (readOnly: boolean) => ({ readOnly }),
		setTab: (tab: number) => ({ tab }),
	},
}


export type TaskDetailState = typeof setup.state
export type TaskDetailGetters = typeof setup.getters
export type TaskDetailActions = typeof setup.actions
export type TaskDetailMutators = typeof setup.mutators
export interface TaskDetailStore extends StoreCore<TaskDetailState>, TaskDetailGetters, TaskDetailActions, TaskDetailMutators {
	state: TaskDetailState
}
const store = createStore(setup) as TaskDetailStore
export default store