import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Card, Collapse, SxProps, Typography } from '@mui/material';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';



interface Props extends HTMLAttributes<HTMLElement> {
	icon?: React.ReactNode
	title?: any
	titleVar?: any
	endRender?: React.ReactNode
	compact?: boolean

	collapsed?: boolean
	onCollapsedChange?: (collapsed: boolean) => void
	onTitleClick?: (e: React.MouseEvent) => void

	children?: ReactNode
	sx?: SxProps
	id?: any
}

const FormParagraph: FunctionComponent<Props> = ({
	id,
	icon,
	title,
	titleVar = "h6",
	endRender,
	compact,

	collapsed,
	onCollapsedChange,
	onTitleClick,

	children,
	sx,
	...props
}) => {

	// HOOKs

	// HANDLER
	const handleIconClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		onCollapsedChange?.(!collapsed)
	}
	const handleTitleClick = (e) => {
		if (!onTitleClick) {
			onCollapsedChange?.(!collapsed)
			return
		}
		if (collapsed) onCollapsedChange?.(false)
		onTitleClick?.(e)
	}

	// RENDER
	const isCollapsable = !!onCollapsedChange
	if (isCollapsable) icon = collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />
	const mh = compact ? "5px" : "15px"
	const styTitle: SxProps = {
		display: "flex",
		alignItems: "center",
		cursor: isCollapsable || !!props.onClick || !!onTitleClick ? "pointer" : "default",
		mb: isCollapsable ? 0 : mh
	}

	return <Card
		sx={[sxCard, sx as any]}
		id={id}
		{...props}
	>

		{/* TITLE */}
		{title && <Box
			sx={styTitle}
			onClick={handleTitleClick}
		>

			{icon && <Box
				sx={{ display: "flex", mr: "10px", mt: "-3px" }}
				onClick={handleIconClick}
			>{icon}</Box>}

			<Typography variant={titleVar}>
				{title}
			</Typography>

			<Box sx={{ flex: 1 }} />

			{endRender}

		</Box>}

		{!isCollapsable ? children :
			<Collapse in={!collapsed} timeout="auto">
				<Box sx={{ mb: mh }} />
				{children}
			</Collapse>
		}
	</Card>
}

export default FormParagraph

const sxCard: SxProps = {
	display: "flex", 
	flexDirection: "column",
	padding: "16px 20px 16px 20px",
	borderRadius: "10px",
}
