const Icon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor" stroke="none"
		{...props}
	>
		<path d="M2.625 18.175L1 17L6 9L9 12.5L13 6L15.725 10.075C15.3417 10.0917 14.9792 10.1375 14.6375 10.2125C14.2958 10.2875 13.9583 10.3917 13.625 10.525L13.075 9.7L9.275 15.875L6.25 12.35L2.625 18.175ZM18.3 10.575C17.9833 10.4417 17.6542 10.3333 17.3125 10.25C16.9708 10.1667 16.6167 10.1167 16.25 10.1L21.375 2L23 3.175L18.3 10.575Z" />
		<path d="M15.3743 22V20.6861C14.8075 20.5639 14.3182 20.3296 13.9064 19.9833C13.4947 19.637 13.1925 19.1481 13 18.5167L14.1872 18.0583C14.3476 18.5472 14.5856 18.919 14.9011 19.1736C15.2166 19.4282 15.631 19.5556 16.1444 19.5556C16.5829 19.5556 16.9545 19.4613 17.2594 19.2729C17.5642 19.0845 17.7166 18.7917 17.7166 18.3944C17.7166 18.038 17.5989 17.7553 17.3636 17.5465C17.1283 17.3377 16.5829 17.1009 15.7273 16.8361C14.8075 16.5611 14.1765 16.2326 13.8342 15.8507C13.492 15.4688 13.3209 15.0028 13.3209 14.4528C13.3209 13.7907 13.5455 13.2764 13.9947 12.9097C14.4439 12.5431 14.9037 12.3343 15.3743 12.2833V11H16.6578V12.2833C17.1925 12.3648 17.6337 12.5507 17.9813 12.841C18.3289 13.1313 18.5829 13.4852 18.7433 13.9028L17.5561 14.3917C17.4278 14.0657 17.246 13.8213 17.0107 13.6583C16.7754 13.4954 16.4545 13.4139 16.0481 13.4139C15.5775 13.4139 15.2193 13.5132 14.9733 13.7118C14.7273 13.9104 14.6043 14.1574 14.6043 14.4528C14.6043 14.7889 14.7647 15.0537 15.0856 15.2472C15.4064 15.4407 15.9626 15.6444 16.754 15.8583C17.492 16.062 18.0508 16.3854 18.4305 16.8285C18.8102 17.2715 19 17.7833 19 18.3639C19 19.087 18.7754 19.637 18.3262 20.0139C17.877 20.3907 17.3209 20.625 16.6578 20.7167V22H15.3743Z" />
	</svg>
)

export default Icon