import { GraphGroup } from 'components/planner/types';
import { getActivityFromGroups } from 'components/planner/utils';
import alertsSo from "stores/alert";
import cycleSo from "stores/cycle";
import farmSo from "stores/farm";
import plannerSo from "stores/planner";
import { buildCyclesAggregateGroup } from 'stores/planner/groups/cyclesAggregateGroup';
import { buildTasksGroup } from 'stores/planner/groups/tasksGroup';
import taskSo from "stores/task";
import { GrowUnit } from 'types/GrowUnit';
import { getValidAlerts, getValidCycles, getValidTasks } from '../utils/update';
import { buildAlertsGroup } from './alertsGroup';
import { buildGrowUnitsCyclesGroup } from './growUnitsCyclesGroup';
import { buildYeldGroup } from './yieldGroup';



/** aggiorno tutto il planner con i dati di STORE di CYCLES, TASKS e ALERTS*/
export function buildSessionCyclesTasksAlertsGroups(growUnits: GrowUnit[], growUnitIdSelect:number): GraphGroup[] {

	//#region SETUP HISTOGRAM
	let maxMinutesForDay = farmSo.state.select?.defaultWorkingDayTotalMinutes
	if (!maxMinutesForDay || maxMinutesForDay == 0) maxMinutesForDay = 5 * 8 * 60
	const factor = plannerSo.state.ganttStyle.histogram.height / maxMinutesForDay
	const newHistogramStyle = { ...plannerSo.state.ganttStyle.histogram, factor }
	plannerSo.setGanttStyle({ histogram: newHistogramStyle })
	//#endregion

	//#region CYCLES/TASKS
	const cycles = getValidCycles(cycleSo.state.all, growUnitIdSelect)
	const tasks = getValidTasks(taskSo.state.all, growUnitIdSelect)
	const alerts = getValidAlerts(alertsSo.state.all)
	//#endregion

	// creo GROWING-SUPER-GROUP
	const growingGroup = buildGrowUnitsCyclesGroup(cycles, growUnits)

	// prelevo tutte le ACTIVITY
	let activities = getActivityFromGroups(growingGroup.groups)

	// creo OPERATIONS-SUPER-GROUP
	const { tasks: taskGroup, work: workGroup } = buildTasksGroup(tasks)
	const cyclesGroup = buildCyclesAggregateGroup(activities)
	const alertGroup = buildAlertsGroup(alerts)
	const operationsGroup: GraphGroup = {
		name: "operations",
		title: "OPERATIONS",
		groups: [cyclesGroup, taskGroup, alertGroup]
	}

	//creo RESULTS-SUPER-GROUP
	const yieldGroup = buildYeldGroup(cycles)
	const resultsGroup: GraphGroup = {
		name: "results",
		title: "RESULTS",
		groups: [yieldGroup, workGroup]
	}

	// inserisco tutti i SUPER-GROUPs
	const groups = [growingGroup, operationsGroup, resultsGroup]
	return groups
}
