import { Badge, IconButton, IconButtonProps } from "@mui/material";
import RootIcon from "images/menu/root2";
import React, { FunctionComponent } from "react";
import eventSo from "stores/event";
import palette from "styles/palette";
import { useStore } from "@priolo/jon";


const NotifyButton: FunctionComponent<IconButtonProps> = (props) => {

	// STORE
	const eventSa = useStore(eventSo)

	// HANDLE
	const handleClick = () => {
		eventSo.toggleDrawer()
	}

	// RENDER
	return (
		<Badge color="secondary" overlap="circular" sx={cssBadge}
			badgeContent={eventSa.unread}
		>
			<IconButton onClick={handleClick} {...props}>
				<RootIcon fill={palette.text.primary} />
			</IconButton>
		</Badge>
	)
}

export default NotifyButton

const cssBadge = {
	'& .MuiBadge-badge': {
		right: 18, top: 9,
		border: `2px solid ${palette.background.header}`,
	},
}