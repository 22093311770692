import { MenuItem, Select } from "@mui/material"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { TASK_SCOPE } from "types/Task"
import WrapControl, { WrapControlProps } from "../base/WrapControl"



interface Props extends WrapControlProps {
	value?: TASK_SCOPE,
	onChange?: (scope: TASK_SCOPE) => void
	readOnly?: boolean
	placeholder?: string
}

const TaskScopeSelect:FunctionComponent<Props> = ({
	value,
	onChange,
	readOnly,
	placeholder,
	...props
}) => {

	const { t } = useTranslation()
	const scopes = Object.keys(TASK_SCOPE)

	const handleChange = (e:React.ChangeEvent<any>) => {
		let newValue = e.target.value
		if (newValue == "none") newValue = null
		onChange(newValue)
	}

	const valueShow = value ?? "none"
	placeholder = placeholder ?? t("selector.task-scope.placeholder")

	return (
		<WrapControl {...props}>

			<Select fullWidth sx={{ mr: "-4px" }}
				readOnly={readOnly}
				value={valueShow}
				onChange={handleChange}
			>
				<MenuItem disabled key="none" value="none">
					<em>{placeholder}</em>
				</MenuItem>
				{scopes.map(key =>
					<MenuItem key={key}
						value={TASK_SCOPE[key]}
					>
						{TASK_SCOPE[key]}
					</MenuItem>
				)}
			</Select>
			
		</WrapControl>
	)
}

export default TaskScopeSelect