import { Warning as AlertIcon } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import FarmIcon from "images/menu/farm";
import React, { FunctionComponent } from "react";
import { EVENTS_ICON, getIconType, GroupedEvents } from "stores/event/utils";
import palette from "styles/palette";


interface Props {
	groupEvents: GroupedEvents
}

const MainIcon: FunctionComponent<Props> = ({
	groupEvents
}) => {

	return {
		[EVENTS_ICON.BOT]: <svg width="13" height="13" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 0H35.5V35.5L0 0Z" fill={palette.secondary.main} />
		</svg>,
		[EVENTS_ICON.FARM]: <Avatar sx={{ width: 26, height: 26, bgcolor: "secondary.main", mr: "6px" }}>
			<FarmIcon width="18px" />
		</Avatar>,
		[EVENTS_ICON.ALERT]: <Avatar sx={{ width: 26, height: 26, bgcolor: "error.main", mr: "6px" }}>
			<AlertIcon sx={{ width: "18px", color: "error.contrastText", mb: "1px" }} />
		</Avatar>,
	}[getIconType(groupEvents)]
}


export default MainIcon