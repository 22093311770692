import dayjs from "dayjs";
import theme from "styles/theme";
import { Task } from "types/Task";
import { Color, DateNumber } from "types/global";
import { dateWithoutTime } from "utils";
import { groupBy } from "utils/array";
import { DUETIME_TYPE, getTaskStatus, sortByDueTime } from ".";
import { SPEED_LEVEL } from "images/speed";



/**
 * Raggruppa i TASKS per "due_date" e restituisce i gruppi con gli score
 */
export function groupTasksByDate(tasks: Task[]): TasksScoreByDate[] {
	if (!tasks) return []
	return Object.entries(groupBy<Task>(tasks, task => task.dueDate))
		.map(([key, tasks]) => {
			const date = tasks[0] ? dateWithoutTime(tasks[0].dueDate) : null
			const tasksSort = sortByDueTime(tasks)
			const isPast = dayjs(date).isBefore(dayjs(), "day")
			const tasksScore = getTasksScore(tasksSort)
			return {
				date,
				tasks: tasksSort,
				isPast,
				...tasksScore,
			} as TasksScoreByDate
		})
		.sort((g1, g2) => g2.date - g1.date)
}
export interface TasksScoreByDate extends TasksScore {
	/** data cui fa capo il gruppo */
	date?: DateNumber
	/** i TASKS presenti nel giorno */
	tasks?: Task[]
	/** indica che è una data nel passato */
	isPast?: boolean
}

/** preleva una struttura di valutazione da un array di TASKs */
export function getTasksScore(tasks: Task[]): TasksScore {
	let score = 0
	let isModify = false
	let inPending: number = 0
	for (const task of tasks) {
		const status = getTaskStatus(task)
		if (task._edit) isModify = true
		score += status.score
		if (status.type == DUETIME_TYPE.OPEN_LATE) inPending += 1
	}
	score = Math.round((score / (tasks.length * 10)) * 100)
	return {
		score,
		isModify,
		inPending,
		length: tasks.length
	}
}
export interface TasksScore {
	/** score della giornata */
	score?: number
	/** se ci sono TASKS modificati */
	isModify?: boolean
	/** quanti TASKS sono in PENDING */
	inPending?: SPEED_LEVEL
	/** il numero di TASK esaminati */
	length?: number
}


/** dato uno SCORE restituisce lo SPEED_LEVEL associato */
export function getGroupTasksLevel(score: number): SPEED_LEVEL {
	if (isNaN(score)) return SPEED_LEVEL.UNKNOW
	let scoreLevel = SPEED_LEVEL.HI
	if (score < 15) {
		scoreLevel = SPEED_LEVEL.LOW
	} else if (score < 50) {
		scoreLevel = SPEED_LEVEL.MEDIUM
	}
	return scoreLevel
}
/** dato uno SCORE restituisce il colore associato */
export function getGroupTasksColor(score: number): Color {
	const level = getGroupTasksLevel(score)
	switch (level) {
		case SPEED_LEVEL.HI: return null
		case SPEED_LEVEL.MEDIUM: return theme.palette.warning.main
		case SPEED_LEVEL.LOW: return theme.palette.error.main
		default: return null
	}
}
/** Testo riassunto della giornata che appare nel tooltip del DAY del calendario*/
export function getGroupTasksDescription(group: TasksScoreByDate): string {
	if (!group) return null
	let tooltip = `${group.tasks.length} Tasks`
	if (group.inPending > 0) tooltip += `, ${group.inPending} are waiting to be completed.`
	return tooltip
}
