import { Report as SeverityIcon, ReportOff as SeverityOffIcon } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { ALARM_SEVERITY, getSeverityData } from "types/AlertRule";
import { ALERT_STATUS } from "types/Alert";



interface Props {
	severityId?: ALARM_SEVERITY
	status?: ALERT_STATUS
	count?: number
	sx?: any
}

const AlertIcon: FunctionComponent<Props> = ({
	severityId,
	status = ALERT_STATUS.OPEN,
	count = 0,
	sx,
}) => {

	// STORE

	// HOOKs

	// HANDLE

	// RENDER
	if (!severityId) return null
	const severity = getSeverityData(severityId)
	const color = severity?.color
	const isClosed = status == ALERT_STATUS.CLOSE
	const tooltip = `${severity?.label?.toUpperCase()}${isClosed ? " (CLOSED)" : ""}`
	const countStr = count > 1 ? count : null

	return (
		<Tooltip title={tooltip}>
			{/* <Badge badgeContent={countStr} sx={cssBadge}> */}
				{isClosed ? (
					<SeverityOffIcon sx={[sx, { color }]} />
				) : (
					<SeverityIcon sx={[sx, { color }]} />
				)}
			{/* </Badge> */}
		</Tooltip>
	)
}

export default AlertIcon


const cssBadge = {
	'& .MuiBadge-badge': {
		top: "-2px", 
		right: "2px", 
		fontSize: "10px",
		bgcolor: "common.black",
		padding: 0,
	},
}