import { DeleteForever, Delete as DeleteIcon, Draw as DraftIcon, DriveFileRenameOutline, Edit as EditIcon, FileDownload, Add as NewIcon, Check as SaveIcon } from "@mui/icons-material";
import { Box, SxProps, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cycleSo from "stores/cycle";
import dialogNewCycleSo from "stores/cycle/newDialog";
import draftSo from "stores/draft";
import { SESSION_TYPE } from "stores/draft/utils";
import plannerSo from "stores/planner";
import { LastDateRange } from "stores/planner/utils/fetch";
import { updatePlannerGroups, updatePlannerTasks } from "stores/planner/utils/update";
import locationSo from "stores/route/location";
import querySo from 'stores/route/query';
import { PAGE_ID, URL_PAR } from "stores/route/utils/types";
import taskSo from "stores/task";
import dialogNewSo from "stores/task/taskNewDialog";
import theme from "styles/theme";
import { DRAFT_STATUS } from "types/Draft";
import { delay } from "utils";
import TasksEditBtts from "./TasksEditBtts";
import dayjs from "dayjs";



const PlannerFooter: FunctionComponent = () => {

	// STORE
	const taskSa = useStore(taskSo)
	const querySa = useStore(querySo)
	const locationSa = useStore(locationSo)
	const draftSa = useStore(draftSo)

	// HOOKs
	const { t } = useTranslation()

	useEffect(() => {
		if (locationSa.currentPage == PAGE_ID.FARM_CYCLES) {
			taskSo.setSelected([])
		}
	}, [locationSa.currentPage])

	const [cycle] = useMemo(() => {
		const [cycleUuid] = querySo.getSearchUrl([URL_PAR.CYCLE_SEL])
		const cycle = cycleSo.getCycleByUuid(cycleUuid)
		return [cycle]
	}, [querySa.queryUrl])

	// HANDLERs
	const handleExport = () => cycleSo.setIsCsvExportOpen(true)

	const handleNewDraft = () => {
		taskSo.setSelected([])
		draftSo.createDraftFromProd(dayjs().format("NEW DD/MM/YYYY HH:MM"))
	}
	const handleDelDraft = () => {
		draftSo.deleteDraft()
	}
	const handleRenameDraft = () => {
		draftSo.setDraftEditOpen(true)
	}
	const handlePromotePreview = async () => {
		draftSo.setPromoteOpen(true)
	}

	const handleApplyDraftInProd = async () => {
		const res = await draftSo.applyDraftInProd()
		if (!res) return
		updatePlannerTasks()
		plannerSo.setGroups([...plannerSo.state.groups])
	}

	const handleNewTask = async () => {
		const save = await dialogNewSo.open()
		if (!save) return
		updatePlannerTasks()
		plannerSo.setGroups([...plannerSo.state.groups])
	}
	const handleNewCycle = async () => {
		const save = await dialogNewCycleSo.open()
		if (!save) return
		await delay(500)
		await draftSo.fetchTasks({ range: LastDateRange })
		updatePlannerGroups()
	}
	const handleDelCycle = () => {
		draftSo.deleteCycles([cycle.cycleUuid])
	}

	// RENDER
	const session = draftSo.getSessionSelect()
	const draft = draftSo.getDraftById(session?.draftId)
	const showPlanner = locationSa.currentPage == PAGE_ID.FARM_PLANNER
	const showCycle = locationSa.currentPage == PAGE_ID.FARM_CYCLES
	const showTasks = locationSa.currentPage == PAGE_ID.FARM_TASKS

	// *** PROD
	if (!session || session.type == SESSION_TYPE.PROD) {

		if (showCycle) {
			return <ButtonCmp sx={{ ml: "26px" }}
				startIcon={<FileDownload />}
				onClick={handleExport}
			>EXPORT CSV</ButtonCmp>
		}

		if (showTasks) return null

		return <ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<EditIcon />}
			onClick={handleNewDraft}
		>
			{t("footer.draft.draft")}
		</ButtonCmp>
	}



	// *** PREVIEW
	if (session.type == SESSION_TYPE.PREVIEW) {
		return <ButtonCmp sx={{ ml: "26px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<DraftIcon />}
			onClick={handlePromotePreview}
		>
			{t("footer.tasks.promote")}
		</ButtonCmp>
	}



	// *** DRAFT PROMOTING_FROM_SOLUTION
	if (draft?.status == DRAFT_STATUS.PROMOTING_FROM_SOLUTION) {
		return <Typography variant="caption" sx={{ ml: "26px", opacity: 0.5 }}>
			DRAFT IN CREATION
		</Typography>
	}

	// *** DRAFT APPLYING | APPLIED
	if (draft?.status == DRAFT_STATUS.APPLYING || draft?.status == DRAFT_STATUS.APPLIED) {
		return (
			<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
				color="error"
				startIcon={<DeleteForever />}
				onClick={handleDelDraft}
			>
				{t("footer.draft.delete")}
			</ButtonCmp>
		)
	}

	// *** DRAFT INIT | PROMOTED_FROM_SOLUTION

	// ** TASKS SELECT
	/** indica che è selezionato almeno un TASK */
	const haveTasksSelected = taskSa.selecteds?.length > 0
	if (haveTasksSelected) return <TasksEditBtts />

	// ** TASKS DESELECT
	/** indica che è selezionato un CYCLE REJECTABLE */
	const isCycleRejectable = draftSo.getCycleCanDelete(cycle)
	//!!cycle && !cycle.rejected && !(cycle._edit?.type == EDIT_TYPE.DELETED)

	return (<>
		{showPlanner && (
			<Box sx={cssBttDraftContainer}>
				<Box sx={cssDraftTitle}>DRAFT</Box>
				<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
					shape={BTT_SHAPE.MAIN}
					startIcon={<SaveIcon />}
					onClick={handleApplyDraftInProd}
				>
					{t("footer.draft.apply")}
				</ButtonCmp>

				<ButtonCmp sx={{ mr: "20px" }}
					color="error"
					startIcon={<DeleteForever />}
					onClick={handleDelDraft}
				>
					{t("footer.draft.delete")}
				</ButtonCmp>

				<ButtonCmp sx={{ mr: "20px" }}
					startIcon={<DriveFileRenameOutline />}
					onClick={handleRenameDraft}
				>
					{t("footer.draft.rename")}
				</ButtonCmp>
			</Box>
		)}

		<Box sx={{ height: "65%", borderLeft: `1px solid ${theme.palette.grey.dark}`, mx: "5px" }} />

		<ButtonCmp sx={{ ml: "26px" }}
			startIcon={<NewIcon />}
			disabled={showTasks}
			onClick={handleNewTask}
		>
			{t("footer.tasks.new_task")}
		</ButtonCmp>

		<ButtonCmp sx={{ ml: "20px" }}
			startIcon={<NewIcon />}
			disabled={showCycle}
			onClick={handleNewCycle}
		>
			{t("footer.tasks.new_cycle")}
		</ButtonCmp>

		{isCycleRejectable && <ButtonCmp sx={{ ml: "20px" }} color="error"
			startIcon={<DeleteIcon />}
			disabled={showCycle}
			onClick={handleDelCycle}
		>
			{t("footer.planner.del_cycle")}
		</ButtonCmp>}
	</>)
}

export default PlannerFooter

const cssBttDraftContainer: SxProps = {
	display: "flex",
	position: "relative",
	alignItems: "center"
}

const cssDraftTitle: SxProps = {
	position: "absolute",
	top: "-15px",
	left: "30px",
	fontSize: 12,
	fontWeight: 600,
	opacity: .7
}