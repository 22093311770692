import dayjs from "dayjs"
import theme from "styles/theme"
import { ALARM_SEVERITY } from "types/AlertRule"
import { ALERT_FILTER, ALERT_STATUS, Alert } from "types/Alert"
import { Color, DateNumber, DateRange } from "types/global"
import { dateWithoutTime, haveValues, isBetween } from "utils"
import { groupBy } from "utils/array"
import { SPEED_LEVEL } from "images/speed"
export * from "./edit"



/** restituisce i ALERTS filtrati secondo parametri */
export function getFiltered(alerts: Alert[], growUnitId?: number, range?: DateRange, severityId?: number, status?: ALERT_FILTER, text?: string): Alert[] {
	if (!alerts) return []
	const haveRange = haveValues(range)
	const txt = text?.trim().toLowerCase() ?? ""

	const alertsFiltered = alerts.filter(alert => {
		if (haveRange && !isBetween(dateWithoutTime(alert.firstOccurredAt), range)) return false
		if (growUnitId && alert.growUnitId != growUnitId) return false
		if (severityId!=null && !isNaN(severityId) && alert.severity != severityId) return false
		if (!isFilteredStatus(alert, status)) return false
		if (txt.length > 0 && (
			!alert.behaviourCode?.toLowerCase().includes(txt)
			&& !alert.rawMessage?.toLowerCase().includes(txt)
		)) return false
		return true
	})
	return alertsFiltered
}

/**
 * Raggruppa gli ALERTS per "date"
 */
export function groupByDate(alerts: Alert[]): AlertsByDate[] {
	if (!alerts) return []
	const byDate = groupBy<Alert>(alerts, alert => dateWithoutTime(alert.firstOccurredAt).toString())
	return Object.entries(byDate)
		.map(([dateString, alerts]) => {
			const date = +dateString
			alerts.sort((a1, a2) => dayjs(a2.firstOccurredAt).valueOf() - dayjs(a1.firstOccurredAt).valueOf())
			const score = calcAlertScore(alerts)
			return { date, alerts, score } as AlertsByDate
		})
		.sort((g1, g2) => g2.date - g1.date)
}
export interface AlertsByDate {
	/** data cui fa capo il gruppo */
	date?: DateNumber
	/** gli ALERTS presenti nel giorno */
	alerts?: Alert[]
	score?: number[]
}

/**
 * Raggruppa gli ALERTS per "severity" 
 */
export function groupBySeverity(alerts: Alert[]): AlertsBySeverity[] {
	if (!alerts) return []
	const bySeverity = groupBy<Alert>(alerts, alert => alert.severity.toString())
	return Object.entries(bySeverity)
		.map(([severityStr, alerts]) => {
			const severity = +severityStr
			alerts.sort((a1, a2) => dayjs(a2.firstOccurredAt).valueOf() - dayjs(a1.firstOccurredAt).valueOf())
			return { severity: severity, alerts } as AlertsBySeverity
		})
		.sort((g1, g2) => g1.severity - g2.severity)
}
export interface AlertsBySeverity {
	/** severity cui fa capo il gruppo */
	severity?: ALARM_SEVERITY
	/** gli ALERTS presenti  */
	alerts?: Alert[]
}


function calcAlertScore(alerts: Alert[]): number[] {
	return alerts.reduce((sum, alert) => {
		if (alert.severity == ALARM_SEVERITY.CRITICAL) {
			sum[0]++
		} else if (alert.severity == ALARM_SEVERITY.HIGH) {
			sum[1]++
		} else {
			sum[2]++
		}
		return sum
	}, [0, 0, 0])
}

/** lo score da inserire nella card */
export function getAlertScore(score: number[]): number {
	if (!score) return null
	const [crit, high, med] = score
	return Math.round(crit * 50 + high * 10 + med * 5)
}

/** lo score da inserire nel planner */
export function getAlertScoreLabel(score: number[]): string {
	if (!score) return null
	const [crit, high, med] = score
	if (crit > 0) {
		return crit.toString()
	} else if (high > 0) {
		return high.toString()
	}
	return med.toString()
}

/** dato un gruppo restituisce il LEVEL dello score */
export function getAlertLevel(score: number[]): SPEED_LEVEL {
	if (!score) return SPEED_LEVEL.UNKNOW
	const [crit, high, med] = score
	if (crit > 0) {
		return SPEED_LEVEL.HI
	} else if (high > 0) {
		return SPEED_LEVEL.MEDIUM
	}
	return SPEED_LEVEL.LOW
}

/** il colore del LEVEL */
export function getAlertColor(level: SPEED_LEVEL): Color {
	switch (level) {
		case SPEED_LEVEL.HI: return theme.palette.error.main
		case SPEED_LEVEL.MEDIUM: return theme.palette.warning.main
		case SPEED_LEVEL.LOW: return null
		default: return null
	}
}

/**  riassunto della giornata che appare nel tooltip */
export function getGroupAlertsDescription(group: AlertsByDate): string {
	if (!group) return null
	let tooltip = `${group.alerts.length} Alerts`
	//if (group.inPending > 0) tooltip += `, ${group.inPending} are waiting to be completed.`
	return tooltip
}

/** lo stato dell'ALERT */
export function getStatus(alert: Alert): ALERT_STATUS {
	if (!alert) return ALERT_STATUS.UNKNOW
	if (!!alert.firstClosedAt) return ALERT_STATUS.CLOSE
	if (!!alert.firstAckedAt) return ALERT_STATUS.ACKNOWLAGED
	if (!!alert.firstViewedAt) return ALERT_STATUS.SEEN
	return ALERT_STATUS.OPEN
}

/** restituisce se l'ALERT del parametro corrisponde al filtro del parametro */
function isFilteredStatus(alert: Alert, filter: ALERT_FILTER): boolean {
	if (!alert) return false
	if (!filter) filter = ALERT_FILTER.OPEN
	switch (filter) {
		case ALERT_FILTER.CLOSED:
			return !!alert.firstClosedAt
		case ALERT_FILTER.ACKNOWLAGED:
			return !!alert.firstAckedAt
		case ALERT_FILTER.NOT_ACKNOWLAGED:
			return !alert.firstAckedAt && !alert.firstClosedAt
		case ALERT_FILTER.NOT_SEEN:
			return !alert.firstAckedAt && !alert.firstClosedAt && !alert.firstViewedAt
		case ALERT_FILTER.OPEN:
			return !alert.firstClosedAt
		default: case ALERT_FILTER.ALL:
			return true
	}
}

/** titolo da inserire nella riga della lista degli ALERTs */
export function getTitle(alert: Alert): string {
	return alert.message ?? alert.rawMessage ?? alert.behaviourCode ?? "--"
}