import { Box, Checkbox, Tooltip } from "@mui/material";
import DatePicker from "components/controls/fields/DatePicker";
import dayjs from 'dayjs';
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRange } from "types/global";



interface Props {
	range: DateRange
	onChange?: (range: DateRange) => void

	column?: boolean
	disabledEnd?: boolean
	labelStart?: string
	labelEnd?: string
	optionOneDay?: boolean
	readOnly?: boolean
	sx?: any
}

/**
 * picker per una range di tipo DateRange
 */
const DateRangePicker: FunctionComponent<Props> = ({
	range,
	onChange,

	column,
	disabledEnd,
	labelStart,
	labelEnd,
	optionOneDay,
	readOnly,
	sx,
}) => {

	// HOOKs
	const { t } = useTranslation()
	const [oneDay, setOneDay] = useState(false)

	// HANDLERs
	const handleChangeStart = (value: dayjs.Dayjs) => {
		const startTime = value.valueOf()
		if ( oneDay == true ) {
			onChange({start: startTime, end: startTime })
			return
		}
		const newRange: DateRange = {
			...range,
			start: startTime,
		}
		if (value.isAfter(newRange.end)) newRange.end = startTime
		onChange(newRange)
	}
	const handleChangeEnd = (value: dayjs.Dayjs) => {
		const endTime = value.valueOf()
		const newRange = {
			...range,
			end: endTime,
		}
		onChange(newRange)
	}
	const handleOneDayChange = (e, value) => {
		setOneDay(value)
		if ( value == true ) onChange({start: range.start, end: range.start })
	}

	// RENDER 
	const disableDate = (date: dayjs.Dayjs) => {
		return date.isBefore(dayjs(range.start).subtract(1, "d"))
	}
	const start = range?.start ? dayjs(range.start) : null
	const end = range?.end ? dayjs(range.end) : null
	
	return (
		<Box sx={[{ display: "flex", alignItems: "center" }, column && { flexDirection: "column" }, sx]}>

			{optionOneDay && <Tooltip title="Just one day">
				<Checkbox
					checked={oneDay}
					onChange={handleOneDayChange}
				/>
			</Tooltip>}

			<DatePicker
				label={labelStart}
				value={start}
				readOnly={readOnly}
				onChange={(value: dayjs.Dayjs) => handleChangeStart(value)}
			/>

			<Box sx={{ mx: 1, my: 3 }} />

			<DatePicker
				label={labelEnd}
				value={end}
				readOnly={readOnly}
				onChange={(value: dayjs.Dayjs) => handleChangeEnd(value)}
				shouldDisableDate={disableDate}
				disabled={disabledEnd || oneDay}
				initialFocusedDate={dayjs()}
			/>
		</Box>
	)
}

export default DateRangePicker