import { RECIPE_SCHEMA } from "types/Recipe"
import i18n from "i18next"
import { PhaseString } from "types/global"
import theme from "styles/theme"

const PHASE_SYS = {
	BATCH: "_batch",
	PRE_CYCLE: "_pre-cycle",
	POST_CYCLE: "_post-cycle",
}

const PHASE_BASE = {
	GERMINATION: "germination",
	GROWING: "growing",
	HD_GROWING: "hd-growing",
	LD_GROWING: "ld-growing",
}

const PHASE_GENERIC = Array.from({ length: 20 })
	.reduce<{ [phase: PhaseString]: string }>(
		(acc, v, i) => ({ ...acc, [`GENERIC_${i + 1}`]: `generic-${i + 1}` }),
		{}
	)

/**
 * Tutte le PHASES 
 */
export const PHASE_SLUG = {
	...PHASE_SYS,
	...PHASE_BASE,
	...PHASE_GENERIC,
}

/**
 * lista di PHASE senza quelle di SISTEMA (BATCH, PRE_CYCLE, POST_CYCLE)
 */
export const ListPhases = Object.values({ ...PHASE_BASE, ...PHASE_GENERIC })

/**
 * Lista completa di tute le PHASE
 */
export const ListPhasesComplete = Object.values(PHASE_SLUG)

/** restituisce la piu' probabile PHASE da usare */
export const getNextPhase = ({ used = [], schema = RECIPE_SCHEMA.CONTINUOUS } = {}) => {
	if (schema == RECIPE_SCHEMA.BATCH) return PHASE_SLUG.BATCH
	if (used == null || used.length == 0) return ListPhases[0]
	const phasesFree = ListPhases.filter(phase => used.findIndex(pUsed => pUsed == phase) == -1)
	return phasesFree[0]
}

/** retituisce il nome visualizzabile di una PHASE  */
export const getPhaseLabel = (phase: PhaseString) => {
	if (!phase) return i18n.t("app.phases.null")
	return i18n.t(`app.phases.${phase}`, phase)
}

/** retituisce il colore tipico di una PHASE  */
export const getPhaseColor = (phase: PhaseString) => {
	return {
		[PHASE_SLUG.BATCH]: theme.palette.error.main,
		[PHASE_SLUG.GERMINATION]: theme.palette.primary.main,
		[PHASE_SLUG.GROWING]: theme.palette.success.main,
		[PHASE_SLUG.HD_GROWING]: theme.palette.success.main,
		[PHASE_SLUG.LD_GROWING]: theme.palette.success.main,
	}[phase] ?? theme.palette.secondary.main
}


/** un dictionary di tutte le PHASES (a parte quelle di SISTEMA) */
export function getPhasesDictionary(): { [phase: string]: any[] } {
	return Object.values(PHASE_SLUG)
		.reduce((acc, phase) => {
			if (phase == PHASE_SLUG.PRE_CYCLE || phase == PHASE_SLUG.POST_CYCLE) return acc
			acc[phase] = []
			return acc
		}, {})
}

/** restituisce un array ordinato delle phases mandate come argomento */
export function sortPhasesArray(phases: PhaseString[]) {
	return ListPhasesComplete.filter(phase => phases.includes(phase))
}

/** mette in ordine un array in base alle phases dei suoi item */
export function sortByPhase(arr: { phase: PhaseString }[]) {
	return arr.sort((a, b) =>
		ListPhasesComplete.indexOf(a.phase) - ListPhasesComplete.indexOf(b.phase)
	)
}