import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";
import { GroupedEvents } from "stores/event/utils";
import palette from "styles/palette";
import { Event } from "types/Root";
import { dateTimeToHuman } from "utils";

interface Props {
	groupEvents: GroupedEvents
}

const UserRow: FunctionComponent<Props> = ({
	groupEvents
}) => {
	const event = groupEvents.events[0]
	return <Box sx={{ display: "flex", mb: 2, maxWidth: "500px", alignSelf: "end" }}>

		<Box sx={cssContent}>

			{/* testo messaggio */}
			{groupEvents.events.map(event =>
				<Box key={event.id} sx={cssBodyText}>
					{event.text}
				</Box>
			)}

			{/* footer */}
			<Box sx={cssFooter}>
				<Box sx={cssFarm}></Box>
				<Box sx={cssTime}>{dateTimeToHuman(event.timestamp)}</Box>
			</Box>
		</Box>

		<Box sx={{ display: "flex", width: "40px" }}>
			<svg width="13" height="13" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M35.5 0H0V35.5L35.5 0Z" fill={palette.white.main} />
			</svg>
		</Box>

	</Box>
}

export default UserRow

const cssContent = {
	flex: "1 0 0", display: "flex", flexDirection: "column",
	backgroundColor: "white.main",
	borderRadius: "10px",
	borderTopRightRadius: "0px",
	p: "3px 8px",
	color: "secondary.contrastText",
	boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.25)"
}

const cssBodyText = {
	flex: "1 0 0",
	display: "flex",
	borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
	fontSize: "14px",
	fontWeight: 400,
}

const cssFooter = {
	flex: "0 1 0",
	display: "flex",
	fontSize: "12px",
	opacity: 0.6,
	fontWeight: 500,
}
const cssFarm = {
	flex: "1 0 0",
}
const cssTime = {
	flex: "0 1 auto",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis",
}