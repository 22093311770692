import { getNextPhase, PHASE_SLUG, sortByPhase, sortPhasesArray } from "stores/phase/utils"
import querySo from "stores/route/query"
import { getItemsFiltered } from "stores/tag/utils"
import { PhaseString } from "types/global"
import { Day, Recipe, RECIPE_SCHEMA } from "types/Recipe"


/** restituisce un nuovo RECIPE in base allo schema */
export function getNewRecipe({ schema = RECIPE_SCHEMA.CONTINUOUS } = {}): Recipe {
	if (schema == RECIPE_SCHEMA.CONTINUOUS) {
		return {
			name: "",
			isBatch: false,
			farmIds: [],
			days: [{
				phase: getNextPhase(),
				...getNewDay()
			}]
		}
	}
	return {
		name: "",
		isBatch: true,
		farmIds: [],
		days: [{
			phase: getNextPhase({ schema }),
			...getNewDay()
		}]
	}
}

/** restituisce un nuovo "day" o completa quello del parametro */
export function getNewDay(day: Day = {}): Day {
	return {
		nutrientSolutionId: null, // int-id
		co2: 0,
		ec: 0.0,
		ph: 0.0,
		humDay: 0,
		humNight: 0,
		tempDay: 0,
		tempNight: 0,
		irrigationDuration: 0,
		irrigationPause: 0,
		ledBars: 0,
		dayPhotoPeriod: 0,
		nightPhotoPeriod: 0,
		spectrum: 0,
		...day
	}
}

/** restituisce un nuovo group-phase */
export function getNewGroupPhase(phase = PHASE_SLUG.BATCH): GroupPhase {
	return {
		phase, // slug
		collapsed: false,
		days: [getNewDay()],
		daysSelect: []
	}
}

/**
 * Utilizzato per la visualizzazione di un gruppo-phase di DAYs del recipe
 */
export interface GroupPhase {
	phase: PhaseString
	collapsed: boolean
	days: Day[]
	/** i giorni selezionati usato in recipe */
	daysSelect?: number[]
}
/** trasforma un array di "day" in un array di "group-phase" */
export function daysToPhasesGroup(days: Day[] = []): GroupPhase[] {
	const groups = days
		.sort((dayA, dayB) => dayA.position - dayB.position)
		.reduce<GroupPhase[]>((acc, day) => {
			const phase = day.phase && day.phase.length > 0 ? day.phase : PHASE_SLUG.BATCH
			let group = acc.find(g => g.phase == phase)
			if (!group) {
				group = {
					phase: phase,
					collapsed: false,
					days: [],
					daysSelect: []
				}
				acc.push(group)
			}
			group.days.push({
				nutrientSolutionId: day.nutrientSolutionId ?? null,
				co2: day.co2 ?? 0,
				ec: day.ec ?? 0,
				ph: day.ph ?? 0,
				humDay: day.humDay ?? 0,
				humNight: day.humNight ?? 0,
				irrigationDuration: day.irrigationDuration ?? 0,
				irrigationPause: day.irrigationPause ?? 0,
				ledBars: day.ledBars ?? 0,
				dayPhotoPeriod: day.dayPhotoPeriod ?? 0,
				nightPhotoPeriod: day.nightPhotoPeriod ?? 0,
				spectrum: day.spectrum ?? 0,
				tempDay: day.tempDay ?? 0,
				tempNight: day.tempNight ?? 0,
			})
			return acc
		}, [])
	sortByPhase(groups)
	return groups
}

/** trasforma un array di "group-phase" in un array di "day" */
export function phasesGroupToDays(groups = [], isBatch: boolean = true, common: Day = {}): Day[] {
	const days = groups.reduce((acc, group) => {
		const daysProps = group.days.map((day, index) => {
			const props = isBatch ? day : common
			return {
				position: index + 1,
				phase: group.phase,
				nutrientSolutionId: common.nutrientSolutionId,
				co2: +props.co2,
				ec: +props.ec,
				ph: +props.ph,
				humDay: +props.humDay,
				humNight: +props.humNight,
				tempDay: +props.tempDay,
				tempNight: +props.tempNight,
				irrigationDuration: +day.irrigationDuration,
				irrigationPause: +day.irrigationPause,
				ledBars: +day.ledBars,
				dayPhotoPeriod: +day.dayPhotoPeriod,
				nightPhotoPeriod: +day.nightPhotoPeriod,
				spectrum: +day.spectrum,
			}
		})
		return [...acc, ...daysProps]
	}, [])
	return days
}

// restituisce un array di "phases" usati da un array di "days"
export function phasesFromDays(days = []): PhaseString[] {
	return sortPhasesArray([...days.reduce(
		(acc, day) => acc.add(day.phase),
		new Set()
	)])
}

/** restituisce i RECIPES filtrati e ordinati in base alla querystring */
export function filterAndSortRecipes(recipes: Recipe[]): Recipe[] {
	if (!recipes || recipes.length == 0) return []

	const [txt, farmIdStr, tagsStr] = querySo.getSearchUrl(["search", "farm", "tags"])
	const chk = { "archived": (querySo.getSearchUrl("archived") == "true") }

	let items = getItemsFiltered(
		recipes,
		{ txt, withArchived: chk.archived, onlyArchived:chk.archived, noSort: true, farmId: farmIdStr ? +farmIdStr : null, tags: tagsStr?.split("~") }
	) as Recipe[]
	items = querySo.getSorted({ items })
	return items
}