import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Check as SaveIcon, Close as CancelIcon } from "@mui/icons-material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import alarmSo from "stores/alarm";
import { getUrlByPageId } from "stores/route/utils/pagesId";
import { PAGE_ID } from "stores/route/utils/types";
import { Box } from "@mui/material";


const AlarmDetailFooter: FunctionComponent = () => {

	// STORE

	// HOOKs
	const history = useHistory()
	const { t } = useTranslation()

	// HANDLERs
	const handleClickSave = async () => {
		const saved = await alarmSo.save()
		if (saved) history.push(getUrlByPageId(PAGE_ID.ALARMS))
	}

	// RENDER
	return <Box sx={{ display: "flex", ml: "26px"}}>

		<ButtonCmp sx={{ mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<SaveIcon />}
			onClick={handleClickSave}
		>
			{t("footer.alarm.save")}
		</ButtonCmp>

		<ButtonCmp
			href="/alarms"
			startIcon={<CancelIcon />}
		>
			{t("footer.alarm.cancel")}
		</ButtonCmp>

	</Box>
}

export default AlarmDetailFooter