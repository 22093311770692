import { StoreCore, createStore } from "@priolo/jon";
import { NATSClose, NATSConnect } from "plugins/nats";
import farmSo from "stores/farm";
import growUnitSo from "stores/growUnit";
import { startChannelRuby, stopChannelRuby } from "stores/growUnit/utils/channel";
import layoutSo from "stores/layout";
import parentsSo from "stores/mainMenu/parents";
import sectionsSo from "stores/mainMenu/sections";
import { getByPageId, isChild } from "stores/mainMenu/utils";
import metaSo from "stores/metadata";
import { PAGE_ID } from "./utils/types";



const isPLC = import.meta.env.VITE_TARGET == "plc"

/**
 * gestisce la posizione in cui l'APP si trova nella struttura delle varie pagine
 */
const setup = {

	state: {
		// la PAGE-ID visibile in questo momento
		currentPage: <PAGE_ID>null,
		/** la PAGE-ID precedente */
		previousPage: <PAGE_ID>null,
	},

	getters: {
	},

	actions: {
		/** chiamata dalla pagina quando è renderizzata */
		updatePageId: (pageId: PAGE_ID, store?: LocationStore) => {
			const { parents, sections, index } = getByPageId(pageId) ?? {}

			updateFarm(pageId)
			updateComponent(pageId)

			// se si torna indietro cancello eventuali "overwriting" sul parent
			if (isChild(store.state.currentPage, pageId)) parentsSo.setOverwritePage(null)

			// TODO: è sbagliato devo trovare anche se è una pagina in profondità di un tabs per esempio
			if (!sections) {
				store.setCurrentPage(pageId)
				return
			}

			// MAIN MENU and HEADER
			sectionsSo.select(index)
			sectionsSo.update(sections)
			parentsSo.update(parents)

			layoutSo.setTitle("")
			store.setCurrentPage(pageId)
		},
	},

	mutators: {
		/** permette di settare l'attuale pagina visualizzata */
		setCurrentPage: (currentPage: PAGE_ID, store?: LocationStore) => {
			return { currentPage, previousPage: store.state.currentPage }
		},
	},
}

export type LocationState = typeof setup.state
export type LocationGetters = typeof setup.getters
export type LocationActions = typeof setup.actions
export type LocationMutators = typeof setup.mutators
export interface LocationStore extends StoreCore<LocationState>, LocationGetters, LocationActions, LocationMutators {
	state: LocationState
}
const locationSo = createStore(setup) as LocationStore
export default locationSo



/** su cambio pageId si occupa del cambio di FARM */
function updateFarm(pageId: PAGE_ID) {

	// è all'interno di una contest FARM (oppure nel PLC)
	if (isChild(pageId, PAGE_ID.FARMS) || isPLC) {

		// carico i SERVICES
		const config = farmSo.state.select.edgeServicesConfig
		// utente di debug e ci sono i services allora connetti NATS e carica METADATA
		if (farmSo.getHavePLCAccess()) {
			NATSConnect(
				farmSo.isInCloud() ? config.cloudNats : config.nats,
				() => metaSo.fetchIfVoid(farmSo.state.select?.edgeServicesConfig?.host?.url),
			)
		} else {
			NATSClose()
		}

		// è fuori dall'area dei FARM
	} else {
		farmSo.setSelect(null)
		growUnitSo.state.lastFarmId = null
		NATSClose()
	}
}

/** su cambio pageId si occupa del cambio di COMPONENT */
function updateComponent(pageId: PAGE_ID) {

	// è all'interno di dell'area dei COMPONENTs
	if (isChild(pageId, PAGE_ID.FARM_GROWUNITS)) {
		startChannelRuby(growUnitSo.state.select?.id)

		// è fuori dall'area dei COMPONENTs
	} else {
		stopChannelRuby()
		growUnitSo.setSelect(null)
		farmSo.setComponentSelect(null)
	}

}