import { Close, ChevronLeft as CloseIcon } from "@mui/icons-material"
import { Box, Drawer, IconButton, SxProps } from "@mui/material"
import { useStore } from "@priolo/jon"
import ButtonIcon from "components/controls/buttons/ButtonIcon"
import MainMenu from 'components/layout/menu/MainMenu'
import LogoIcon from 'images/logoIcon'
import LogoComplete from 'images/logo'
import { FunctionComponent } from "react"
import { useHistory } from "react-router"
import deviceSo, { DEVICE_TYPE } from "stores/layout/device"
import drawerSo from "stores/mainMenu/drawer"
import palette from "styles/palette"
import theme from "styles/theme"
import MenuExpandIcon from "images/menuExpand"
import MenuCompactIcon from "images/menuCompact"



interface Props {
	sx?: SxProps
}
const MenuDrawer: FunctionComponent<Props> = ({
	sx
}) => {

	// STORE
	const deviceSa = useStore(deviceSo)
	const drawerSa = useStore(drawerSo)

	// HOOKS
	const history = useHistory()

	// HANDLES
	const handleClickLogo = () => history.push("/")
	const handleClose = () => drawerSo.toggleOpen()
	const handleCompact = () => drawerSo.toggleCompact()

	// RENDER
	const variant = deviceSa.type == DEVICE_TYPE.MOBILE ? "temporary" : "permanent"
	const sxDrawer: SxProps = [
		cssDrawer,
		(!drawerSa.isOpen && variant == "permanent") ? cssClose : drawerSa.isCompact ? cssCompact : null,
		sx
	]
	const isCompact = drawerSa.isCompact

	return <Drawer sx={sxDrawer}
		variant={variant}
		open={drawerSa.isOpen}
		onClose={() => drawerSo.setIsOpen(false)}
	>

		<Box sx={cssMenuHeader}>

			{isCompact ? (
				<LogoIcon style={{ marginLeft: "8px", cursor: "pointer", width: "24px", height: "24px" }} fill={palette.text.primary}
					onClick={handleClickLogo}
				/>
			) : (
				<LogoComplete style={{ marginLeft: "22px", cursor: "pointer", width: "60px", height: "60px" }} fill={palette.text.primary}
					onClick={handleClickLogo}
				/>
			)}

			{!isCompact && <IconButton onClick={handleClose}>
				<CloseIcon />
			</IconButton>}

		</Box>

		<Box sx={cssMenuContainer}>
			<MainMenu sx={{ flex: 1 }} />
			<ButtonIcon onClick={handleCompact}>
				{ isCompact ? <MenuCompactIcon /> : <MenuExpandIcon /> }
			</ButtonIcon>
		</Box>
	</Drawer>
}

export default MenuDrawer

const cssDrawer: SxProps = {
	".MuiDrawer-paper": {
		zIndex: theme.app.menu.zIndex,
		position: "relative",
		width: theme.app.menu.width,
		transition: "width 700ms ease",
		overflowX: "hidden",
	},
}

const cssClose: SxProps = {
	".MuiDrawer-paper": {
		width: "0px",
	},
}

const cssCompact: SxProps = {
	".MuiDrawer-paper": {
		width: "40px",
	},
}

const cssMenuHeader: SxProps = {
	display: "flex", alignItems: "center", justifyContent: "space-between",
	bgcolor: theme.palette.background.header,
	height: theme.app.header.height,
	br: `1px solid ${theme.palette.grey[800]}`,
}

const cssMenuContainer: SxProps = {
	display: "flex", flexDirection: "column", flex: "1 1 auto",
	overflowY: "auto", overflowX: "hidden",
	bgcolor: theme.palette.background.paper,
}
