import { EnergySavingsLeaf as ConsumptionIcon, Spa as CropLotsIcon, Star as MainIcon, AssignmentTurnedIn as TaskIcon } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import { useStore } from "@priolo/jon";
import Modal from "components/controls/Modal";
import { TAGS } from "components/planner/types";
import CycleIcon from "images/cycle";
import LostTrolley from "images/lostTrolley";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import dialogSo, { CYCLE_EDIT_TYPE } from "stores/cycle/editDialog";
import { EditCycle } from "stores/cycle/utils/edit";
import { SESSION_TYPE } from "stores/draft/utils";
import userSo from "stores/user";
import Actions from "./Actions";
import ConsumptionTab from "./tabs/ConsumptionTab";
import CropLotsTab from "./tabs/CropLotsTab";
import DetailTab from "./tabs/DetailTab";
import IncidentsTab from "./tabs/IncidentsTab";
import TasksTab from "./tabs/TasksTab";



const DetailDialog: FunctionComponent = () => {

    // STORE
    const dialogSa = useStore(dialogSo)

    // HOOKs
    const { t } = useTranslation()

    // HANDLEs
    const handleDialogClose = () => dialogSo.close(CYCLE_EDIT_TYPE.CLOSE)

    // RENDER
    const isPreview = EditCycle.getExtra(dialogSa.cycle, TAGS.SESSION) == SESSION_TYPE.PREVIEW
    const title = t(`pag.cycle.edit.title${isPreview ? "-preview" : ""}`)

    return <Modal sx={{ ".MuiDialog-paper": { maxWidth: "725px" } }}
        icon={<CycleIcon />}
        title={title}
        isOpen={dialogSa.isOpen}
        onClose={handleDialogClose}
        actionsRender={<Actions />}
    >

        <Box sx={{ display: "flex", flexDirection: "column" }}>

            <Tabs sx={{ marginBottom: "20px" }}
                value={dialogSa.tab}
                onChange={(e, tab) => dialogSo.setTab(tab)}
            >
                <Tab
                    icon={<MainIcon />}
                    iconPosition="start"
                    label={t("pag.cycle.edit.tab.details")}
                />
                <Tab sx={{ display: isPreview ? "none" : null }}
                    icon={<CropLotsIcon />}
                    iconPosition="start"
                    label={t("pag.cycle.edit.tab.crop-lots")}
                />
                <Tab
                    icon={<TaskIcon />}
                    iconPosition="start"
                    label={t("pag.cycle.edit.tab.tasks")}
                />
                <Tab sx={{ display: isPreview ? "none" : null }}
                    icon={<ConsumptionIcon />}
                    iconPosition="start"
                    label={t("pag.cycle.edit.tab.consumption")}
                />
                {!!userSo.state.current?.debugEnabled && (
                    <Tab sx={{ display: isPreview ? "none" : null }}
                        icon={<LostTrolley />}
                        iconPosition="start"
                        label={t("pag.cycle.edit.tab.incidents")}
                    />
                )}
            </Tabs>

            <Box sx={{ flex: "1 0 530px", overflowY: "auto", paddingRight: "15px", marginRight: "-15px" }}>
                {[
                    <DetailTab />,
                    <CropLotsTab />,
                    <TasksTab />,
                    <ConsumptionTab />,
                    <IncidentsTab cycle={dialogSa.cycle} />
                ][dialogSa.tab]}
            </Box>

        </Box>
    </Modal>
}

export default DetailDialog