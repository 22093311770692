import { Box } from '@mui/material'
import { useStore } from '@priolo/jon'
import SelectorTower from 'components/controls/select/SelectorTower'
import { FunctionComponent, useMemo } from 'react'
import querySo from 'stores/route/query'
import { URL_PAR } from 'stores/route/utils/types'



interface Props {
	sx?: any
}

const IrriFilterHeader: FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const querySa = useStore(querySo)

	// HOOKs

	// HANDLERs
	const handleChangeTowers = (towers: number[]) => {
		const towersUrl = towers?.join("_")
		querySo.setSearchUrl([URL_PAR.GU_TOWER, towersUrl])
	}

	// RENDER
	const towers = useMemo(
		() => querySo.getSearchUrl(URL_PAR.GU_TOWER)?.split("_").sort().map(tower => parseInt(tower)),
		[querySa.queryUrl]
	)

	return (
		<Box sx={[{ display: "flex", alignItems: "center", gap: "10px" }, sx]}>
			<SelectorTower
				towersSelect={towers}
				onChange={handleChangeTowers}
			/>
		</Box >
	)
}

export default IrriFilterHeader

