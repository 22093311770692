import { Done, PowerOff as NatsOffIcon, Power as NatsOnIcon, Warning } from "@mui/icons-material"
import { Box, Tooltip } from "@mui/material"
import { useStore } from "@priolo/jon"
import { FunctionComponent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import natsSo, { NATS_STATUS } from "stores/nats"
import valuesSo from "stores/values"
import { ServiceNATS } from "types/Value"



interface Props {
	sx?: any
}

const NatsCnnIcon: FunctionComponent<Props> = ({
	sx,
}) => {
	// STORE
	const natsSa = useStore(natsSo)
	const valuesSa = useStore(valuesSo)

	// HOOKS
	const { t } = useTranslation()

	// RENDER
	const natsConn = t(`app.nats.cnn.${natsSa.status}`)
	const servicesIsOnline = valuesSo.servicesIsOnline()
	const colorOnline = servicesIsOnline.some( s => !s ) ? "secondary" : "success"
	const services = useMemo (()=> Object.values(valuesSa.services).sort((v1,v2)=>v1.code.localeCompare(v2.code)), [valuesSa.services])

	return <Tooltip 
		placement="top"
		title={<Box>
			{services.map((service, index) =>
				<ServiceRow key={service.code} 
					service={service} 
					isOnline={servicesIsOnline[index]}
				/>
			)}
			<Box sx={{ fontSize: "12px", fontWeight: 700 }}>
				{natsConn}
			</Box>
		</Box>}
	>
		{{
			[NATS_STATUS.UNKNOW]: <NatsOnIcon sx={[{ opacity: 0.5 }, sx]} />,
			[NATS_STATUS.OFFLINE]: <NatsOffIcon sx={sx} color="error" />,
			[NATS_STATUS.RETRY]: <NatsOffIcon sx={sx} color="warning" />,
			[NATS_STATUS.ONLINE]: <NatsOnIcon sx={sx} color={colorOnline as any}/>,
		}[natsSa.status]}
	</Tooltip>
}

export default NatsCnnIcon


interface PropsServiceRow {
	service: ServiceNATS
	isOnline?: boolean
}

const ServiceRow: FunctionComponent<PropsServiceRow> = ({
	service,
	isOnline,
}) => {

	// RENDER
	return (
		<Box sx={cssRoot}>
			<Box sx={cssTitle}>
				{service.code}
			</Box>
			<Box>{isOnline ? <Done /> : <Warning />}</Box>
		</Box>
	)
}

const cssRoot = {
	maxWidth: '133px',
	display: 'flex',
	alignItems: 'center'
}

const cssTitle = {
	flex: '1',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
}