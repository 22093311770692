import { Delete as DeleteIcon, ArrowBack as DeselectIcon, Edit as EditIcon, Restore, Sync as SyncIcon } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import draftSo from "stores/draft";
import plannerSo from "stores/planner";
import { updatePlannerTasks } from "stores/planner/utils/update";
import taskSo from "stores/task";
import multiSo from "stores/task/taskMultiEdit";
import theme from "styles/theme";



const TasksEditBtts: FunctionComponent = () => {

	// STORE
	const taskSa = useStore(taskSo)
	const draftSa = useStore(draftSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleMultiEdit = async () => {
		const save = await multiSo.open()
		if (!save) return
		updatePlannerTasks()
		plannerSo.setGroups([...plannerSo.state.groups])

	}
	const handleDelete = async () => {
		await draftSo.deleteTasks(taskSa.selecteds)
		taskSo.setSelected([])
		updatePlannerTasks()
		plannerSo.setGroups([...plannerSo.state.groups])

	}
	const handleSync = async () => {
		await draftSo.syncTasks(taskSa.selecteds)
		taskSo.setSelected([])
		updatePlannerTasks()
		plannerSo.setGroups([...plannerSo.state.groups])
	}
	const handleRestore = async () => {
		await draftSo.restoreTasks(taskSo.getSelected())
		taskSo.setSelected([])
		updatePlannerTasks()
		plannerSo.setGroups([...plannerSo.state.groups])
	}
	const handleDeselect = () => taskSo.setSelected([])


	// RENDER
	const [haveTasksModify, haveTasksDeleted] = useMemo(() => [taskSo.haveSelectEdited(), taskSo.haveSelectAllDeleted()], [taskSa.selecteds])

	return (<>
		<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			startIcon={<DeselectIcon />}
			onClick={handleDeselect}
		>
			{t("footer.tasks.deselect")}
		</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			startIcon={<EditIcon />}
			onClick={handleMultiEdit}
		>
			{t("footer.tasks.multiedit")}
		</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			startIcon={<SyncIcon />}
			onClick={handleSync}
		>
			{t("footer.tasks.sync")}
		</ButtonCmp>

		{haveTasksModify && (
			<ButtonCmp sx={{ mr: "20px" }}
				startIcon={<Restore />}
				onClick={handleRestore}
			>
				{t("footer.tasks.restore")}
			</ButtonCmp>
		)}

		{!haveTasksDeleted && <ButtonCmp sx={{ mr: "20px" }}
			shape={BTT_SHAPE.OPTION_DELETE}
			startIcon={<DeleteIcon />}
			onClick={handleDelete}
		>
			<p style={{ color: theme.palette.error.main }}>{t("footer.tasks.delete")}</p>
		</ButtonCmp>}

	</>)
}

export default TasksEditBtts