import { StoreCore, createStore, mixStores, validateAll } from "@priolo/jon"
import recipesApi from "api/recipes"
import i18n from "i18next"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import phasesDaySo from "stores/library/recipe/detail"
import querySo from "stores/route/query"
import { RECIPE_SCHEMA, Recipe } from "types/Recipe"
import { rules } from "utils/rules"
import folderSetup from "../folder"
import { daysToPhasesGroup, getNewDay, getNewRecipe, phasesGroupToDays } from "./utils"



const setup = {

	state: {
		/** All RECIPEs (used in index page) */
		all: <Recipe[]>[],
		/** Recipe selected */
		select: <Recipe>null,
		/** la dialog per NEW RECIPE */
		isModalOpen: false,
		/** lo schema selezionato su NEW RECIPE */
		modalSchemaSel: RECIPE_SCHEMA.BATCH,
	},

	getters: {
		getById: (id: number, store?: RecipeStore): Recipe => {
			return store.state.all.find(p => p.id == id)
		},
		isSelected: (id: number, store?: RecipeStore): boolean => {
			if ( id == null ) return false
			return store.state.selectedIds.includes(id)
		},
	},

	actions: {
		/** recupero tutti i RECIPEs */
		fetchAll: async (_: void, store?: RecipeStore) => {
			const { data } = await recipesApi.index()
			store.setAll(data)
		},
		fetchAllIfVoid: async (_: void, store?: RecipeStore) => {
			if (store.state.all?.length > 0) return
			await store.fetchAll()
		},
		/** recupero il RECIPE indicato nel parametro "id" */
		fetchSelect: async (id: number, store?: RecipeStore) => {
			const { data } = await recipesApi.get(id)
			store.setSelect(data)
		},
		/** rimuovo l'id in maniera da clonare l'elemento */
		fetchSelectAndClone: async (id: number, store?: RecipeStore) => {
			const { data } = await recipesApi.get(id)
			delete data.id
			store.setSelect(data)
		},
		/** salva il RECIPE in editazione */
		saveSelect: async (_: void, store?: RecipeStore) => {

			if (validateAll().length > 0) return
			if (rules.obligatory(store.state.select.name) || rules.obligatoryArray(store.state.select.farmIds)) {
				querySo.setSearchUrl(["tab", "0"])
				return false
			}

			const days = phasesGroupToDays(
				phasesDaySo.state.daysGroups,
				store.state.select.isBatch,
				phasesDaySo.state.common
			)
			if (days.some(day => rules.photoperiod([day.dayPhotoPeriod, day.nightPhotoPeriod]) != null)) {
				querySo.setSearchUrl(["tab", "1"])
				return false
			}

			const recipe = { ...store.state.select, days }
			const { data } = await recipesApi.save(recipe.id, recipe)
			store.setSelect(data)

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.recipe.${store.state.select.id ? "update" : "create"}`)
			})
			return true
		},
		/** crea un nuovo RECIPE e lo mette in editazione (select) */
		editNew: async (schema: RECIPE_SCHEMA, store?: RecipeStore) => {
			const newRecipe = getNewRecipe({ schema })
			newRecipe.groupingFolderId = store.state.selectedFolderId
			store.setSelect(newRecipe)
		},
		/** elimina un RECIPE sul server tramite id */
		destroy: async (id: number, store?: RecipeStore) => {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.recipe.delete"),
			})
			if (!res) return
			await recipesApi.destroy(id)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.recipe.delete"),
			})
			const recipes = store.state.all.filter(p => p.id != id)
			store.setAll(recipes)
		},
		archive: async (id: number, store?: RecipeStore) => {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.recipe.archive.confirm"),
			})
			if (res == false) return
			const { data: recipeArchived } = await recipesApi.archive(id)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.recipe.archive"),
			})
			const recipe = store.state.all.find(recipe => recipe.id == recipeArchived.id)
			recipe.archived = true
			store.setAll([...store.state.all])
		},
		bulkArchive: async (_: void, store?: RecipeStore) => {
			if (store.state.selectedIds.length == 0) return
			if (await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.recipe.bulk-archive.confirm"),
			}) == false) return

			await recipesApi.bulkArchive(store.state.selectedIds)
			for (const recipe of store.state.all) if (store.state.selectedIds.includes(recipe.id)) recipe.archived = true
			store.setAll([...store.state.all])
			store.setSelectedIds([])

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.recipe.bulk-archive"),
			})
			
		},
		restore: async (id: number, store?: RecipeStore) => {
			const { data: recipeArchived } = await recipesApi.restore(id)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.recipe.restore"),
			})
			const recipe = store.state.all.find(recipe => recipe.id == recipeArchived.id)
			recipe.archived = false
			store.setAll([...store.state.all])
		},
	},

	mutators: {
		setAll: (all: Recipe[]) => ({ all }),
		setIsModalOpen: (isModalOpen: boolean) => ({ isModalOpen }),
		setModalSchemaSel: (modalSchemaSel: RECIPE_SCHEMA) => ({ modalSchemaSel }),
		/**
		 * seleziono un recipe. Contestualmente creo i day-phases-group
		 */
		setSelect: (select: Recipe) => {
			const daysGroups = daysToPhasesGroup(select?.days)
			const common = getNewDay(select?.days?.[0])
			phasesDaySo.setDaysGroups(daysGroups)
			phasesDaySo.setCommon(common)
			return { select }
		},
		/** setto un porzione di props della Recipe select (usato per le TextBox)*/
		setSelectProp: (prop: Partial<Recipe>, store?: RecipeStore) => ({ select: { ...store.state.select, ...prop } }),
	},
}

export type RecipeState = typeof setup.state & typeof folderSetup.state
export type RecipeGetters = typeof setup.getters & typeof folderSetup.getters
export type RecipeActions = typeof setup.actions & typeof folderSetup.actions
export type RecipeMutators = typeof setup.mutators & typeof folderSetup.mutators
export interface RecipeStore extends StoreCore<RecipeState>, RecipeGetters, RecipeActions, RecipeMutators {
	state: RecipeState
}
const recipeSo = createStore(mixStores(folderSetup, setup)) as RecipeStore
recipeSo.state.repo = "recipes"

export default recipeSo
