const CropIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  
		fill="currentColor"
		{...props}
	>
		<path
			d="M20.9331 6.93655C20.8754 6.85217 20.7821 6.80332 20.6799 6.79888C20.489 6.78556 15.9099 6.57684 13.8047 8.09117C13.0985 8.59743 12.6322 9.32574 12.3124 10.0851C12.2946 9.84088 12.2635 9.52114 12.2058 9.15254C12.0015 7.91354 11.4907 6.24377 10.1938 5.31119C8.08862 3.79685 3.50955 4.00557 3.31413 4.01445C3.21198 4.02333 3.11871 4.07218 3.06097 4.15212C3.00323 4.23649 2.98547 4.34307 3.01211 4.44077C3.06541 4.62729 4.32676 9.03264 6.43198 10.547C7.28917 11.1643 8.34178 11.3508 9.31 11.3508C10.2827 11.3508 10.4937 11.1332 11 11V19C11 19.1821 11 20 12 20C13 20 13 19.1821 13 19V14C13.5063 14.1377 13.7203 14.1308 14.693 14.1308C15.6567 14.1308 16.7138 13.9443 17.571 13.327C19.6762 11.8126 20.9375 7.40728 20.9908 7.22076C21.013 7.12306 20.9953 7.01648 20.9331 6.93655Z"
		/>
	</svg>
)

export default CropIcon