import { Label } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tagSo from "stores/tag";
import MultiSelectorDialogBase2 from "../base/MultiSelectorDialogBase2";
import Chip from "./Chip";



interface Props {
	tags: string[],
	onChange: (tags: string[]) => void,
	sx?: any
}

const SelectorTagsH: FunctionComponent<Props> = ({
	tags,
	onChange,
	sx,
}) => {

	// STORE
	const tagSa = useStore(tagSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	useEffect(() => {
		tagSo.fetchAllIfVoid()
	}, [])

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (tags: string[]) => {
		setIsOpen(false)
		if (!!tags) onChange?.(tags)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.([])
	}

	// RENDER
	const allTags = useMemo(() => tagSo.getFiltered(), [tagSa.all, tagSa.filter])
	if (!tagSa.all) return null
	
	const isActive = tags?.length > 0
	const label = isActive ? tags.length < 3 ? tags?.join(" ") : `${tags[0]} ${tags[1]} +${tags.length-2}` : "ALL TAGS"

	return (<>
		<Chip sx={sx}
			label={label}
			icon={<Label />}
			tooltip={"ONLY THOSE WHO HAVE THESE TAGS"}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<MultiSelectorDialogBase2 noSpace
			maxWidth="xs"
			icon={<Label />}
			title={"SELECT ONE OR MORE TAGS"}

			items={allTags}
			itemsSelect={tags}
			textValue={tagSa.filter}

			isOpen={isOpen}
			onClose={handleClose}
			onChangeTextValue={txt => tagSo.setFilter(txt)}
		/>
	</>)
}

export default SelectorTagsH
