import ajax from "plugins/AjaxService"
import { Production, Request } from "types/Production"



/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33975
 */
async function index(production: Production): Promise<{ data: Request[] }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.get(
		`farms/${farmId}/productions/${prodUuid}/requests`
	)
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-34155
 */
async function show(production: Production, reqUuid: string): Promise<{ data: Request }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.get(
		`farms/${farmId}/productions/${prodUuid}/requests/${reqUuid}`
	)
}

/**
 * CREATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-30575
 */
async function create(production: Production, request: Request): Promise<{ data: Request }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	// elimino dati di troppo
	delete request.uuid
	delete request.solutions
	delete request.status
	return await ajax.post(
		`farms/${farmId}/productions/${prodUuid}/requests`,
		{ productionRequest: request }
	)
}

/**
 * MOVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33995
 */
async function move(production: Production, prodDestUuid: string, requestUuid: string): Promise<{ data: Request }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.post(
		`farms/${farmId}/productions/${prodUuid}/requests/${requestUuid}/move`,
    {
      productionRequest: {
        productionUuid: prodDestUuid
      }
    }
	)
}

/**
 * DELETE
 *  * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33995
 */
async function destroy(production: Production, reqUuid: string): Promise<void> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.delete(
		`farms/${farmId}/productions/${prodUuid}/requests/${reqUuid}`
	)
}



const api = {
	index,
	show,
	create,
	move,
	destroy,
}
export default api
