import { Add } from "@mui/icons-material";
import { Box, Breakpoint, Button, SxProps } from "@mui/material";
import Modal from "components/controls/Modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import deviceSo from "stores/layout/device";
import theme from "styles/theme";
import FindField from "../fields/FindField";
import MultiSelectorBase2 from "./MultiSelectorBase2";



interface Props {
	/** titolo sulla DIALOG */
	title: string
	/** icona significativa */
	icon: React.ReactNode
	/** indica se  la DIALOG è visibile o no */
	isOpen: boolean
	/** indica se eliminare o no l'uso del tasto "space" (usato per i tag) */
	noSpace: boolean
	/** array di ID degli item selezionati */
	itemsSelect: any[]
	/** oggetti da visualizzare */
	items: any[]
	/** testo di ricerca */
	textValue: string
	textPlaceholder?: string

	/** string "xs":0px "sm":600px "md":960px "lg":1280px "xl":1920px */
	maxWidth?: Breakpoint | false
	width?: number | string
	fullWidth?: boolean
	height?: number | string

	/** quando la DIALOG si chiude.  */
	onClose: (itemsSelect: any[]) => void
	/** chiamato se il FIND cambia. Se null allora il FIND non c'e' */
	onChangeTextValue?: (text: string) => void
	onNewItem: (text: string) => boolean

	/** per un "item" restituisce il suo "text" */
	fnTextFromItem: (item: any) => React.ReactNode,
	/** per un "item" restituisce il suo "sottotitolo" */
	fnSecondaryFromItem: (item: any) => React.ReactNode,
	/** a fronte di un item restituisce il suo ID */
	fnIdFromItem: (item: any) => any,

	renderFilter?: React.ReactNode,
	renderFilterColumn?: React.ReactNode,
}

const MultiSelectorDialogBase2: FunctionComponent<Partial<Props>> = ({
	title,
	icon,
	isOpen,
	noSpace = false,

	itemsSelect,
	items,
	textValue,
	maxWidth,
	width,
	fullWidth,
	height = "600px",
	textPlaceholder,

	onClose,
	onChangeTextValue,
	onNewItem,

	fnTextFromItem = (item) => item,
	fnSecondaryFromItem,
	fnIdFromItem = (item) => item,

	renderFilter,
	renderFilterColumn,
}) => {

	// HOOKs
	const { t } = useTranslation()
	const [selected, setSelected] = useState<any[]>()

	useEffect(() => {
		if (!isOpen) return
		onChangeTextValue?.("")
		setSelected([...(itemsSelect ?? [])])
	}, [isOpen])

	// HANDLER
	const handleChangeTextValue = (e: any) => onChangeTextValue?.(e.target.value)
	const handleClickOk = () => {
		onClose(selected)
		setSelected(null)
	}
	const handleClickCancel = () => {
		onClose(null)
		setSelected(null)
	}
	const handleCreateNew = (e: React.MouseEvent) => {
		e.preventDefault()
		addNewItem(textValue)
	}
	const handleKeyDown = (e: React.KeyboardEvent) => {
		switch (e.code) {
			case "Enter":
				addNewItem(textValue)
				break
			case "Space":
				if (!noSpace) return
				e.preventDefault()
				break
			default:
		}
	}

	// RENDER 
	/** cerca un solo ITEM tramite una "text" */
	const findIndexFromText = (text = "") => {
		text = text.trim().toLowerCase()
		if (text.length == 0) return -1
		return items.findIndex(i => fnTextFromItem(i) == text)
	}
	/** indica che si è inserito una text nuova e che quindi potrei suggerire di creare un nuovo elemento */
	const isNew = () => !!onNewItem && textValue.length > 0 && findIndexFromText(textValue) == -1
	const addNewItem = (itemId: any) => {
		if (!isNew()) return
		onChangeTextValue?.("")
		if (!onNewItem(itemId)) return
		setSelected([...selected, itemId])
	}

	return (
		<Modal
			sx={{ ".MuiDialog-paper": { width: width } }}
			icon={icon}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			title={title}
			isOpen={isOpen}
			onClose={handleClickCancel}
			actionsRender={<>
				<Box sx={{ flex: "1 1 auto" }} />

				<Button data-test="dialog-btt-cancel"
					onClick={handleClickCancel}
				>{t("dialog.default.cancel")}</Button>

				<Button data-test="dialog-btt-ok"
					onClick={handleClickOk} color="secondary"
				>{t("dialog.default.ok")}</Button>
			</>}
		>
			<Box sx={sxBody(height)}>

				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>

					{/* TEXT FILTER */}
					{onChangeTextValue && (
						<FindField sx={{ flex: 1 }}
							autoFocus={deviceSo.isDesktop()}
							placeholder={textPlaceholder ?? t("dialog.tag.label")}
							value={textValue}
							onChange={handleChangeTextValue}
							onKeyDown={handleKeyDown}
						/>
					)}

					{renderFilter}

				</Box>

				{renderFilterColumn}


				{/* ADD NEW ITEM */}
				{isNew() && (<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Button startIcon={<Add />} color={"secondary"}
						onClick={handleCreateNew}
					>
						<span>
							{t("dialog.tag.add-tag")}
							<span style={{ textTransform: "none", color: theme.palette.text.main }}> {textValue}</span>
						</span>
					</Button>
				</Box>)}

				<MultiSelectorBase2 sx={{ maxHeight: "600px", marginTop: "10px" }}
					itemIdsSelect={selected}
					items={items}
					onChangeItemsSelect={setSelected}
					fnTextFromItem={fnTextFromItem}
					fnSecondaryFromItem={fnSecondaryFromItem}
					fnIdFromItem={fnIdFromItem}
				/>

			</Box>
		</Modal>
	)
}

export default MultiSelectorDialogBase2

const sxBody = (height: number | string): SxProps => ({
	display: "flex",
	flexDirection: "column",
	height: height ?? "unset",
	gap: "5px",
})
