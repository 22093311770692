import { createStore, StoreCore } from "@priolo/jon";
import { URL_PAR } from 'stores/route/utils/types';
import { queryUpdateUrl } from "./utils/query";



/**
 * Si occupa di gestire lo store dei dati nella query string
 * questi servono per i filtri i sort e alcune selezioni 
 */
const setup = {

	state: {
		/** questo valore mi serve principalmente per notificare quando cambia la queryUrl */
		queryUrl: window.location.search,
	},

	getters: {
		/**
		 * restituisce il valore di un parametro memorizzato nell'url
		 */
		getSearchUrl: ((names: string | string[], store?: any): string | string[] => {
			const searchParams = new URLSearchParams(store.state.queryUrl)
			if (typeof names == "string") return searchParams.get(names)
			if (!names) return []
			return names.map(name => searchParams.get(name))
		}) as {
			(input: string, store?: any): string
			(input: string[], store?: any): string[]
		},
		/**
		 * restituisce sgli "items" messi in ordine in base ai parametri dell'url
		 * - items da mettere in ordine
		 * - (opz) map è un dictionary con dentro le funzioni da usare per l'ordinamento
		 * - (opz) sortNameDefault è il nome proprietà da "sortare" ne caso non sia indicata nell'url
		 * - (opz) orderDefault l'ordine decrescente/crescente da usare nel caso non sia indicato nell'url 
		 */
		getSorted: ({ items, map, sortNameDefault = "name", orderDefault }: PayloadGetSorted, store?: QueryStringStore) => {
			if (!items || items.length < 2) return items
			let sortName = store.getSearchUrl("sortName")
			let isDes = store.getSearchUrl("isDes") == "true"
			if (!sortName || sortName.length == 0) {
				sortName = sortNameDefault
				isDes = orderDefault != null ? orderDefault : isDes
			}
			const extractor = map?.[sortName]
			if (!extractor) {
				const first = items[0][sortName]
				const fnCompare = typeof first == "string"
					? (a: any, b: any) => a[sortName]?.localeCompare(b[sortName])
					: (a: any, b: any) => (a[sortName] < b[sortName] ? -1 : 1)
				return items.sort((a, b) => fnCompare(a, b) * (isDes ? -1 : 1))
			}
			return items.sort(
				(a, b) => (extractor(a) < extractor(b) ? -1 : 1) * (isDes ? -1 : 1)
			)
		},
	},

	actions: {
		/** aggiorno la queryUrl per intero */
		updateQueryUrl: (value?: string, store?: QueryStringStore) => {
			if (value == null) {
				value = store.state.queryUrl
			} else {
				window.history.replaceState(null, null, `?${value}`)
			}
			store.setQueryUrl(value)
			store.setSearchUrl([URL_PAR.UPDATE, (Math.round(Math.random() * 999)).toString()])
		},
	},

	mutators: {
		/** modifico la queryUrl */
		setQueryUrl: (queryUrl: string) => ({ queryUrl }),
		/** inserisco nella queryUrl una coppia name=value 
		 * e aggiorno lo store
		*/
		setSearchUrl: (params: string[], store?: QueryStringStore) => {
			//if (!Array.isArray(params)) params = [params]
			const queryUrl = queryUpdateUrl(params, store.state.queryUrl)
			setTimeout(() => window.history.replaceState(null, null, "?" + queryUrl), 100)
			return { queryUrl }
		},
		/** inserisco nella queryUrl un nome che verra' poi abbinato ad una campo da "sortare" */
		setSort: (sortName: string) => {
			const oldSortName = querySo.getSearchUrl("sortName")
			const oldIsDes = querySo.getSearchUrl("isDes") == "true"
			let sortIsDes = oldSortName == sortName ? !oldIsDes : oldIsDes
			querySo.setSearchUrl(["sortName", sortName])
			querySo.setSearchUrl(["isDes", String(sortIsDes)])
		},
		/** [II] da eliminare e usare l'url vedi filtro CYCLE*/
		setSearchUrlPositive: ({ name, checked }, store?: QueryStringStore) => {
			const value = checked ? "" : "false"
			store.setSearchUrl([name, value])
		},
		/** [II] da eliminare e usare l'url vedi filtro CYCLE*/
		setSearchUrlNegative: ({ name, checked }, store?: QueryStringStore) => {
			const value = checked ? "true" : ""
			store.setSearchUrl([name, value])
		},
	},
}

type PayloadGetSorted = {
	items?: any[]
	map?: { [key: string]: (v: any) => any }
	sortNameDefault?: string
	orderDefault?: boolean
}

export type QueryStringState = typeof setup.state
export type QueryStringGetters = typeof setup.getters
export type QueryStringActions = typeof setup.actions
export type QueryStringMutators = typeof setup.mutators
export interface QueryStringStore extends StoreCore<QueryStringState>, QueryStringGetters, QueryStringActions, QueryStringMutators {
	state: QueryStringState
}
const querySo = createStore(setup) as QueryStringStore
export default querySo
