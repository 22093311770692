import dayjs from "dayjs";
import alertsSo from "stores/alert";
import cycleSo from "stores/cycle";
import draftSo from "stores/draft";
import { SESSION_TYPE } from "stores/draft/utils";
import growUnitSo from "stores/growUnit";
import layoutSo from "stores/layout";
import plannerSo from "stores/planner";
import taskSo from "stores/task";
import taskTemplateSo from "stores/taskTemplateGroup";
import { DateRange } from "types/global";
import { addDateRange, includeDateRange } from "utils";



const DefaultRange: DateRange = {
	start: dayjs().subtract(3, "month").startOf("day").valueOf(),
	end: dayjs().add(3, "month").startOf("day").valueOf(),
}
export let LastDateRange: DateRange = DefaultRange


/** Carica tutti i dati necessari:
 * CYCLES, TASKS, GROW-UNITS, TASK-TEMPLATES
 */
export async function loadAll(farmId: number) {

	if (farmId == growUnitSo.state.lastFarmId) return
	LastDateRange = DefaultRange

	layoutSo.setLoadingMsg("Loading...")

	await Promise.all([
		// recupera tutte le GROW-UNITS di questa FARM
		growUnitSo.fetchByFarm(farmId),
		// recupera tutti i TASK-TEMPLATE
		taskTemplateSo.fetchAllIfVoid(),
		// recupero tutti i DRAFTS creati
		draftSo.fetchDraftsIfVoid(),
		// recupera tutti i CYCLEs della FARM 
		cycleSo.fetch({ farmId, dateRange: LastDateRange }),
		// recupera tutti i TASKs della FARM
		taskSo.fetch({ farmId, dateRange: LastDateRange }),
		// recupera tutti gli ALERTs della FARM
		alertsSo.fetch({ farmId, dateRange: LastDateRange }),
	])
	draftSo.initSessions()
	
	layoutSo.setLoadingMsg()
	plannerSo.setVisibleDateRange(LastDateRange)
	growUnitSo.state.lastFarmId = farmId
}

/** carica un RangeData aggiuntivo che poi attacca agli altri dati
 * questa funzione espande il RangeData "loaded" del PLANNER
 */
export async function loadChunk(dateRange: DateRange) {

	if (includeDateRange(LastDateRange, dateRange)) return

	layoutSo.setLoadingMsg("Loading...")

	await Promise.all([
		// recupera lo "stub" dei CYCLEs e li setta in PROD
		cycleSo.fetch({ add: true, dateRange }),
		// recupera lo "stub" dei TASKs e lisetta in PROD
		taskSo.fetch({ add: true, dateRange }),
		// [II] SERVE DAVVERO?
		//alertsSo.fetch({ add: true, dateRange }),
	])

	// aggiungo a tutte le sessioni il pezzo caricato
	for (const session of draftSo.state.sessions) {
		// se è un DRAFT allora carico anche i drafts di quel range
		if (session.type == SESSION_TYPE.DRAFT) {
			await Promise.all([
				draftSo.fetchCycles({ draftId: session.draftId, range: dateRange }),
				draftSo.fetchTasks({ draftId: session.draftId, range: dateRange }),
			])
		}
	}

	LastDateRange = addDateRange(LastDateRange, dateRange)
	plannerSo.setVisibleDateRange(LastDateRange)
	layoutSo.setLoadingMsg()
}

