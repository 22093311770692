import { Box, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { forDates } from "utils/func";
import { useStore } from "@priolo/jon";
import React from "react";
import dialogSo from "stores/task/taskNewDialog";
import querySo from 'stores/route/query'
import { URL_PAR } from 'stores/route/utils/types'
import { urlToDateRange } from "utils";
import { groupBy } from "components/planner/utils";



interface Props {
	sx?: any
}
const TaskPreviewTab:FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const dialogSa = useStore(dialogSo)
	const querySa = useStore(querySo)

	// HOOKs
	const { t } = useTranslation()
	const dateRangeSelUrl = useMemo(
		()=>querySo.getSearchUrl(URL_PAR.DATE_SEL) as string, 
		[querySa.queryUrl]
	)
	const groups = useMemo(() => {
		const dateRangeSel = urlToDateRange(dateRangeSelUrl)
		const dates = [...forDates(dateRangeSel?.start, dateRangeSel?.end, dialogSa.dateStep)]
			.map(time => dayjs(time))
		const groups = groupBy(dates, (d1, d2) => d1.month() == d2.month() && d1.year() == d2.year())
		return groups
	}, [dateRangeSelUrl, dialogSa.dateStep])


	// HANDLERs

	// RENDER 
	const cnDay = (day: number) => day == 0 || day == 6 ? cssHoliday : cssDayOff

	return (
		<Box sx={[cssContainer, sx]}>
			<List sx={cssList}>
				{groups.map((group, index) => (<div key={index}>

					<ListSubheader sx={cssHeader}>
						<Typography variant="h6">
							{`${group[0].year()} ${t(`app.months.${group[0].month()}`)}`}
						</Typography>
					</ListSubheader>

					{group.map((date) => (
						<ListItem key={date.valueOf()} dense>
							<ListItemAvatar>
								<Typography 
									variant="h6" 
									sx={cnDay(date.day())}
								>{date.date()}</Typography>
							</ListItemAvatar>
							<ListItemText primary={t(`app.week.${date.day()}`)} />
						</ListItem>
					))}

				</div>))}
			</List>
		</Box>
	)
}

export default TaskPreviewTab

const cssContainer = {
	overflowY: "auto",
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	padding: "10px",
    bgcolor: "common.black",
    borderRadius: "10px",
}
const cssList = {
	overflowY: "auto",
	pt: "0px", pr: "10px"
}
const cssHeader = theme => ({
	bgcolor: "common.black",
	height: "50px",
	display: "flex",
	alignItems: "center",
	borderBottom: `1px solid ${theme.palette.grey.dark}`,
	marginBottom: "5px",
})
const cssHoliday = theme => ({
	backgroundColor: "transparent",
	color: theme.palette.secondary.main,
})
const cssDayOff = theme => ({
	backgroundColor: "transparent",
	color: theme.palette.text.primary,
})

