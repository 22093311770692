import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useStore } from '@priolo/jon';
import PlaceholderCmp, { ICON_TYPE } from 'components/controls/PlaceholderCmp';
import TableSortProp from 'components/controls/TableSortProp';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from "react-i18next";
import cycleSo from 'stores/cycle';
import querySo from "stores/route/query";
import { delay } from 'utils';
import CyclesRow from './CyclesRow';
import CycleNewDialog from './new/CycleNewDialog';



const CyclesTable: FunctionComponent = () => {

    // STORE
    const cycleSa = useStore(cycleSo)
    const querySa = useStore(querySo)

    // HOOKs
    const { t } = useTranslation()
    const cycles = useMemo(() => cycleSo.getFiltered(), [cycleSa.all, querySa.queryUrl])

    // serve solo a ricordare che lo scrolling è gia' avvenuto altrimenti lo fa di continuo
    const hasMovedRef = useRef(false)
    // permette lo scrolling al CYCLE selezionato
    const tableRef = useCallback(async (node: Element) => {
        if (hasMovedRef.current || !node || !cycles || cycles.length == 0) return
        hasMovedRef.current = true
        await delay(800)
        node?.querySelector(`.Mui-selected`)?.scrollIntoView({ behavior: "auto", block: "center" })
    }, [cycleSa.all])

    // HANDELEs

    // RENDER
    if (cycles?.length == 0) {
        return <PlaceholderCmp
            title="THERE ARE NO CYCLES"
            subtitle="try changing the filter settings"
            iconType={ICON_TYPE.VOID}
        />
    }

    return (<>
        <Table stickyHeader>

            <TableHead>
                <TableRow>
                    <TableCell sx={{ pr: 0 }}>
                        <TableSortProp name="cycleUuid">
                            {t("pag.cycle.index.cycle-ref")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell sx={{ minWidth: "120px",pr: 0 }}>
                        <TableSortProp name="growunit">
                            {t("pag.cycle.index.gu")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell sx={{ width: "100%", pr: 0 }}>
                        <TableSortProp name="cropName">
                            {t("pag.cycle.index.crop")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell align="center" sx={{ pr: 0 }}>
                        <TableSortProp name="status">
                            {t("pag.cycle.index.status")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell sx={{ pr: 0 }}>
                        <TableSortProp name="seedingDate">
                            {t("pag.cycle.index.seeding-date")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell sx={{ pr: 0 }}>
                        <TableSortProp name="harvestingDate">
                            {t("pag.cycle.index.harvesting-date")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell align="center" sx={{ pr: 0 }}>
                        <TableSortProp name="layers">
                            {t("pag.cycle.index.harvesting-layers")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: "120px", pl: 0 }}>
                        <TableSortProp name="plannedYield">
                            {t("pag.cycle.index.planned-yield")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell align="right" sx={{ pl: 0 }}>
                        <TableSortProp name="yield">
                            {t("pag.cycle.index.yield")}
                        </TableSortProp>
                    </TableCell>
                    <TableCell align="right" sx={{ pl: 0 }}>
                        <TableSortProp name="unitsYield">
                            {t("pag.cycle.index.units-yield")}
                        </TableSortProp>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody ref={tableRef}>
                {cycles.map((cycle) => <CyclesRow key={cycle.cycleUuid} cycle={cycle} />)}
            </TableBody>

        </Table>

        <CycleNewDialog />
    </>)
}

export default CyclesTable
