
const Icon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" 
		width="24" heigth="24" viewBox="0 0 24 24" 
		{...props}
		fill="currentColor"
	>
		<path 
			d="M21.1709 6.82811C21.168 6.82267 21.1657 6.81703 21.1626 6.81159C21.16 6.80715 21.157 6.80316 21.1543 6.79877C21.0209 6.57297 20.8306 6.38612 20.6024 6.25687L12.7274 1.80585C12.502 1.67928 12.2478 1.6128 11.9893 1.61279C11.7308 1.61278 11.4766 1.67925 11.2512 1.8058L3.37607 6.25696C3.14398 6.38831 2.95113 6.5792 2.81741 6.80994C2.81599 6.81241 2.8143 6.81461 2.81293 6.81708C2.8105 6.82143 2.80869 6.82596 2.80636 6.83035C2.68068 7.05402 2.61454 7.30622 2.61426 7.56278V16.4375C2.61452 16.7031 2.68516 16.9639 2.819 17.1933C2.95284 17.4227 3.14509 17.6125 3.37616 17.7434L11.2512 22.1945C11.4609 22.3124 11.696 22.378 11.9364 22.3858C11.9514 22.3869 11.9663 22.3884 11.9815 22.3885C11.9841 22.3886 11.9868 22.3886 11.9894 22.3886C12.0098 22.3886 12.0298 22.3872 12.0498 22.3856C12.2876 22.3766 12.5199 22.3111 12.7274 22.1945L20.6025 17.7433C20.8335 17.6124 21.0257 17.4226 21.1596 17.1932C21.2934 16.9639 21.364 16.7031 21.3643 16.4375V7.56278C21.364 7.30535 21.2974 7.05233 21.1709 6.82811ZM11.9893 3.11167L19.0968 7.12895L16.5914 8.56153L9.42844 4.55911L11.9893 3.11167ZM12.0845 11.1386L4.89509 7.1214L7.89969 5.42314L15.0716 9.43059L12.0845 11.1386ZM12.7515 20.4578L12.8343 12.4378L15.8456 10.7159V13.923C15.8456 14.122 15.9247 14.3127 16.0653 14.4534C16.206 14.594 16.3967 14.673 16.5956 14.673C16.7946 14.673 16.9853 14.594 17.126 14.4534C17.2666 14.3127 17.3456 14.122 17.3456 13.923V9.85814L19.8643 8.41797V16.4375L12.7515 20.4578Z" 
		/>
	</svg>
)

export default Icon