import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'plugins/i18n';
import 'plugins/msw';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { getAppUrl } from 'stores/user/utils';
import AppOS from './AppOS';
import './index.css';



dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(utc);
dayjs.extend(timezone);

function Base() {
	return (
		<Router basename={getAppUrl()}>
			<AppOS />
		</Router>
	)
}

const root = createRoot(document.getElementById('root'))
root.render(<Base />)
