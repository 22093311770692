import { PHASE_SLUG } from "stores/phase/utils"
import querySo from "stores/route/query"
import { getItemsFiltered } from "stores/tag/utils"
import { Crop, Day, Schema } from "types/Crop"
import { DayGroups } from "./detail"



// restituisce un nuovo group-phase
export const getNewGroupPhase = (phase = PHASE_SLUG.BATCH) => ({
    phase, // slug
    ratio: 1,
    shrinkDays: 0,
    expandDays: 0,
    collapsed: false,
    days: [{ tasks: [] }],
})

// trasforma un array di "day" in un array di "group-phase"
export function daysToPhasesGroup(
    { days, rearrangementSchema }: { days?: Day[], rearrangementSchema?: Schema[] }
) {
    if (!Array.isArray(days)) return null
    const groups = days
        .sort((dayA, dayB) => dayA.position - dayB.position)
        .reduce((acc, day) => {
            const phase = day.phase && day.phase.length > 0 ? day.phase : PHASE_SLUG.BATCH
            let group = acc.find(g => g.phase == phase)
            if (!group) {
                const schema = rearrangementSchema.find(schema => schema.phase == phase)
                group = {
                    phase: phase,
                    ratio: +(schema?.ratio ?? 1),
                    shrinkDays: +(schema?.shrinkDays ?? 0),
                    expandDays: +(schema?.expandDays ?? 0),
                    collapsed: false,
                    days: [],
                }
                acc.push(group)
            }
            group.days.push({ tasks: day.taskTemplateGroupIds })
            return acc
        }, [])
    return groups
}

// trasforma un array di "group-phase" in un array di "day"
export function phasesGroupToDays(groups: DayGroups[] = []) {
    // crea i days
    const days: Day[] = groups.reduce((acc, group, groupIndex) => {
        const daysTasks = group.days.map((day, dayIndex) => ({
            phase: group.phase,
            taskTemplateGroupIds: day.tasks,
            position: +calcIndexDay(groups, groupIndex, dayIndex),
        }))
        return [...acc, ...daysTasks]
    }, [])
    // crea l'array di rearrangment
    const rearrangementSchema: Schema[] = groups.reduce((acc, group) => {
        if (group.phase == PHASE_SLUG.BATCH || group.phase == PHASE_SLUG.PRE_CYCLE || group.phase == PHASE_SLUG.POST_CYCLE) return acc
        return [...acc, {
            phase: group.phase,
            ratio: group.ratio,
            shrinkDays: group.shrinkDays,
            expandDays: group.expandDays
        }]
    }, [])
    return { days, rearrangementSchema }
}

// [string] restituisce un indice del giorno del CROP
// tenendo conto dei gruppi e della PHASE PRE-CYCLE e POST-CYCLE
export function calcIndexDay(groups: DayGroups[], groupIndex: number, dayIndex: number) {

    // calcolo dell'indice sui gruppi
    let count = groups
        .reduce((acc, group, index) => acc += index < groupIndex ? group.days.length : 0, 0)
    count = count + dayIndex + 1

    // se c'e' il gruppo PRE_CYCLE allora togli giorni
    let group = groups[0]
    if (group.phase == PHASE_SLUG.PRE_CYCLE) count -= group.days.length

    // se il gruppo che sto analizzando è PRE-CYCLE togli un giorno perche' deve finire con -1 e non con 0
    group = groups[groupIndex]
    if (group.phase == PHASE_SLUG.PRE_CYCLE) count -= 1

    let ret: string = count.toString()
    // se il gruppo che sto analizzando è POST-CYCLE agiungi "+"
    if (group.phase == PHASE_SLUG.POST_CYCLE) count = `+${count}` as any

    return count
}


/** restituisce i CROPS filtrati e ordinati in base alla querystring */
export function filterAndSortCrops(crops: Crop[]): Crop[] {
    if (!crops || crops.length == 0) return []

    const [txt, farmIdStr, tagsStr] = querySo.getSearchUrl(["search", "farm", "tags"])
    const chk = { "archived": (querySo.getSearchUrl("archived") == "true") }

    let items = getItemsFiltered(
        crops,
        { txt, withArchived: chk.archived, onlyArchived:chk.archived, noSort: true, farmId: farmIdStr ? +farmIdStr : null, tags: tagsStr?.split("~") }
    ) as Crop[]
    items = querySo.getSorted({ items })
    return items
}