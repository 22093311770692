import { Timestamp } from "./global"

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-35735
 */
export interface Event {
	farmName?: string,
	id?: number,
	imageUrl?: string,
	predefinedUserMessage?: boolean,
	replyMarkup?: any,
	source?: EVENT_SOURCE,
	subtype?: EVENT_SUBTYPE,
	text?: string,
	timestamp?: Timestamp
}

export enum EVENT_SOURCE {
	USER= "user",
	BOT = "bot",
	SYSTEM = "system",
	FARM = "farm"
}

export enum EVENT_SUBTYPE {
	NULL = "",					// [null] su user e bot
	PAUSE_NOW = "pause_now", 	// sempre su system
	PLAY_NOW = "play_now",		// sempre su system
	STATUS_NOW = "status_now",	// sempre su system
	NORMAL = "normal",			// su farm
	FAULT = "fault",			// su farm
}