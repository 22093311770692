import { Timestamp } from "types/global";
import { Event, EVENT_SOURCE } from "types/Root";
import { dateToHuman, isDayChanged } from "utils";
export * from "./icons";



export interface GroupedEvents {
	id?: string
	dayChanged?: boolean
	farmName?: string;
	source?: EVENT_SOURCE;
	timestamp?: Timestamp
	events?: Event[];
}

export function groupEventsByFarmAndSource(events: Event[]) {
	const summary: LinkItem[] = []
	const groups = events.reduce((acc, curr) => {
		const lastEvent = acc[acc.length - 1];

		// se è entro i 3 minuti e ha i requisiti giusti raggruppa
		if (
			lastEvent &&
			lastEvent.farmName === curr.farmName &&
			isRoot(lastEvent) === isRoot(curr) &&
			(curr.timestamp - lastEvent.timestamp) / (1000 * 60) <= 3
		) {
			lastEvent.timestamp = curr.timestamp
			lastEvent.events.push(curr);

			// altrimenti...
		} else {
			const dayChanged = !lastEvent || isDayChanged(lastEvent?.timestamp, curr.timestamp)
			// creo il summary
			if (dayChanged) {
				summary.push({
					label: dateToHuman(curr.timestamp),
					id: `${curr.id}`
				})
			}
			const group = {
				id: `${curr.id}`,
				// se c'e' un cambio di giorno inserisce un delimitatore
				dayChanged,
				farmName: curr.farmName,
				source: isRoot(curr) ? EVENT_SOURCE.BOT : EVENT_SOURCE.USER,
				timestamp: curr.timestamp,
				events: [curr],
			}
			// inserisce un nuovo gruppo
			acc.push(group);
		}

		return acc;
	}, [] as GroupedEvents[]);

	return {
		groups,
		summary
	}
}

export function isRoot(event: GroupedEvents | Event): boolean {
	return event.source != EVENT_SOURCE.USER
}


export interface LinkItem {
	label: string
	id: string
}