import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectorDialogBase from "../base/SelectorDialogBase";
import { useStore } from "@priolo/jon";
import FarmIcon from "images/menu/farm";
import GrowUnitIcon from "images/menu/growunit";
import farmSo from "stores/farm";
import { getFiltered } from "stores/growUnit/utils";
import { GrowUnit } from "types/GrowUnit";
import Chip from "./Chip";
import { Farm } from "types/Farm";



interface Props {
	farmId?: number,
	onChange: (farm: Farm) => void,
	sx?: any
}

const SelectorFarmH: FunctionComponent<Props> = ({
	farmId,
	onChange,
	sx,
}) => {

	// STORE
	const farmSa = useStore(farmSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [textFilter, setTextFilter] = useState("")
	useEffect(() => {
		farmSo.fetchAllIfVoid()
	}, [])

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (farm: Farm) => {
		setIsOpen(false)
		if (farm) onChange?.(farm)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const farms = useMemo(() => farmSa.all, [farmSa.all, textFilter])
	const farmSelect = useMemo(() => farmSo.getById(farmId), [farmId, farmSa.all])
	const isActive = !!farmSelect
	if (!farms) return null

	return (<>
		<Chip sx={sx}
			label={farmSelect?.name ?? "ALL FARM"}
			icon={farmSelect ? <GrowUnitIcon width={18} height={18} /> : <FarmIcon width={18} height={18} />}
			tooltip={"ONLY THOSE THAT BELONG TO A SPECIFIC FARM"}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto"
			height={null}
			icon={<FarmIcon />}
			items={farms}
			textValue={textFilter}
			idSelect={farmId}
			title={t("selector.growunit.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}
			onChangeTextValue={txt => setTextFilter(txt)}
			fnTextFromItem={item => item?.name}
			fnIdFromItem={item => item.id}
		/>
	</>)
}

export default SelectorFarmH
