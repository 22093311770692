import { SxProps } from '@mui/material';
import TowerIcon from "images/tower";
import React, { FunctionComponent, useState } from 'react';
import MultiSelectorDialogBase2 from '../base/MultiSelectorDialogBase2';
import { WrapControlProps } from '../base/WrapControl';
import Chip from './Chip';
import { getColumnName, getColumnsName, getColumnsVisible } from 'stores/growUnit/utils/columns';



interface Props extends WrapControlProps {
	towersSelect: number[]
	onChange: (towers: number[]) => void
	sx?: SxProps
}

const SelectorTower: FunctionComponent<Partial<Props>> = ({
	towersSelect,
	onChange,
	sx,
}) => {

	// HOOKs
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (selected: number[]) => {
		setIsOpen(false)
		onChange(selected)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const towers = getColumnsVisible()
	const getLabelTower = (tower: number) => `TOWER ${getColumnName(tower)}`
	const getLabelTowers = (towers: number[]) => !towers ? "ALL TOWERS" : `TOWERS ${getColumnsName(towers)}`
	const isActive = towersSelect != null

	return (<>
		<Chip sx={sx}
			label={getLabelTowers(towersSelect) ?? "ALL TOWERS"}
			icon={<TowerIcon style={{ width: "16px" }} />}
			//tooltip={t("selector.service.tooltip")}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<MultiSelectorDialogBase2
			icon={<TowerIcon />}
			title={"TOWERS"}

			width={250}
			height={null}

			items={towers}
			itemsSelect={towersSelect}

			isOpen={isOpen}
			onClose={handleClose}
			fnTextFromItem={getLabelTower}
		/>
	</>)
}

export default SelectorTower
