import { Error as GenericIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";
import { GroupedEvents } from "stores/event/utils";
import { dateTimeToHuman } from "utils";

import MainIcon from "../MainIcon";
import SubIcon from "./SubIcon";



interface Props {
	groupEvents: GroupedEvents
}

const RootRow: FunctionComponent<Props> = ({
	groupEvents
}) => {

	return <Box sx={{ display: "flex", mb: 2, maxWidth: "500px", alignSelf: "start" }}>

		<Box sx={{ display: "flex", width: "40px", mr: "-1px", justifyContent: "end" }}>
			<MainIcon groupEvents={groupEvents} />
		</Box>

		<Box sx={cssContent}>

			{/* testo messaggio */}
			{groupEvents.events.map(event =>
				<Box key={event.id} sx={cssContText}>
					<Box sx={cssBodyText}>
						{event.text}
					</Box>
					{/* eventuale icona */}
					<Box sx={cssIcon}>
						<SubIcon event={event} />
					</Box>
				</Box>
			)}

			{/* footer */}
			<Box sx={cssFooter}>
				<Box sx={cssFarm}>{groupEvents.farmName}</Box>
				<Box sx={cssTime}>{dateTimeToHuman(groupEvents.timestamp)}</Box>
			</Box>
		</Box>

	</Box>
}

export default RootRow

const cssContent = {
	flex: "1 0 0", display: "flex", flexDirection: "column",
	backgroundColor: "secondary.main",
	borderRadius: "10px",
	borderTopLeftRadius: "0px",
	p: "3px 8px",
	color: "secondary.contrastText",
	boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.25)"
}


const cssContText = {
	flex: "1 0 0",
	fontSize: "14px",
	display: "flex",
	fontWeight: 400,
	borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
}
const cssBodyText = {
	flex: "1 0 0",

}
const cssIcon = {
	ml: 1,
	flex: "0 1 0",
}


const cssFooter = {
	flex: "0 1 0",
	display: "flex",
	fontSize: "12px",
	opacity: 0.6,
	whiteSpace: "nowrap",
	textOverflow: "ellipsis",
	fontWeight: 500,
}
const cssFarm = {
	flex: "1 0 0",
	mr: "10px",
	fontWeight: 900,
}
const cssTime = {
	flex: "0 1 auto",
}
