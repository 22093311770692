import { Cycle } from "types/Cycle"
import { EDIT_TYPE } from "types/Entity"
import { Layer } from "types/Layer"
import { Edit } from "utils/entity"



export class EditCycle extends Edit {
	static PropIdName = "cycleUuid"
	static COMMON = [
		"layers",
		"cropLots",
		"cropCycleId",
		"cropName",
		"growUnitId",
		"color",
		"seedingDate",
		"harvestingDate",
		"rejected",
		"rejectingDate",
		"yield",
		"yieldDate",
		"plannedYield",
	]
	/** per i CYCLE setto solo alcune proprietà */
	static set(target: Cycle, template: Partial<Cycle>, deep?: boolean) {
		target.seedingDate = template.seedingDate
		target.harvestingDate = template.harvestingDate
		for (let propLayer of template.layers) {
			const targetLayer = target.layers?.find(targetLayer => targetLayer.layerNumber == propLayer.layerNumber)
			EditCycle.setLayer(targetLayer, propLayer)
		}
	}
	private static setLayer(target: Layer, props: Partial<Layer>) {
		if (!target || !props) return
		for (const key in props) target[key] = props[key]
	}

	static draftsToTemplate(drafts: Cycle[], entities: Cycle[]): Cycle[] {
		return drafts.reduce((acc: Cycle[], draft: Cycle) => {
			
			// cerco il DRAFT
			const index = entities.findIndex(cycle => cycle.cycleUuid == draft.refToCycleUuid)

			// è la modifica di un TASK gia' esistente in PRODUZIONE				
			if (index != -1) {
				// è una MODIFICA "cancelled"
				if (draft.rejected) {
					draft._edit = { type: EDIT_TYPE.DELETED}
				}

				// si tratta di un nuovo DRAFT
			} else {
				// se è un "cancelled" è una situazione "non corretta"
				if (draft.rejected) return acc
				draft._edit = { type: EDIT_TYPE.NEW }
			}

			// unifico gli uuid
			draft.cycleUuid = draft.refToCycleUuid
			acc.push(draft)
			return acc
		}, [] as Cycle[])
	}
}
