import { Box, SxProps } from '@mui/material'
import { useStore } from '@priolo/jon'
import SelectorFarmH from 'components/controls/select/SelectorFarmH'
import SelectorStateH from 'components/controls/select/SelectorStateH'
import SelectorTagsH from 'components/controls/select/SelectorTagsH'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { LIBRARY_STATE } from "stores/library/utils"
import querySo from 'stores/route/query'
import { Farm } from 'types/Farm'



interface Props {
	noFarms?: boolean,
	sx?: SxProps,
}
const FilterHeader: FunctionComponent<Props> = ({
	noFarms = false,
	sx,
}) => {

	// STORE
	const querySa = useStore(querySo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleStateClose = (state: LIBRARY_STATE) => querySo.setSearchUrl(["archived", state == LIBRARY_STATE.ARCHIVED ? "true" : null])
	const handleFarmClose = (farm: Farm) => querySo.setSearchUrl(["farm", farm?.id?.toString()])
	const handleTagsClose = (tags: string[]) => querySo.setSearchUrl(["tags", tags.join("~")])

	// RENDER
	const [stateStr, farmIdStr, tagsStr] = querySo.getSearchUrl(["archived", "farm", "tags"])
	const state = stateStr == "true" ? LIBRARY_STATE.ARCHIVED : LIBRARY_STATE.ACTIVE
	const farmId = Number.parseInt(farmIdStr)
	const tags = tagsStr?.split("~") ?? []
	const states = Object.values(LIBRARY_STATE)

	return (
		<Box sx={[sxRoot as any, sx]}>
			<SelectorStateH
				states={states}
				state={state}
				stateDefault={LIBRARY_STATE.ACTIVE}
				fnGetLabel={(s) => s?.toUpperCase()}
				onClose={handleStateClose}
			/>
			{!noFarms && <SelectorFarmH
				farmId={farmId}
				onChange={handleFarmClose}
			/>}
			<SelectorTagsH
				tags={tags}
				onChange={handleTagsClose}
			/>
		</Box >
	)
}

export default FilterHeader

const sxRoot: SxProps = {
	display: "flex", 
	alignItems: "center", 
	gap: 1,
}