import { Activity, ACTIVITY_TYPE, GraphGroup, TAGS } from "../types";
import { DateRange } from "types/global"
import { DAY_MILLISEC } from "utils/time";



/**
 * raggruppa un array in base ad un callback 
 * che restituisce (true/false) se due elementi fanno parte dello stesso gruppo o no
 * @param activities 	le ACTIVITIES da raggruppare (monodimensionale)
 * @param callback 		funzione di confronto
 */
export function groupBy<T = Activity>(
	activities: T[],
	callback: (firstElem: T, current: T) => boolean
): T[][] {
	if (!activities) return []
	return activities.reduce((groups, activity) => {
		let groupEq = groups.find(group => callback(group[0], activity))
		if (!groupEq) {
			groupEq = []
			groups.push(groupEq)
		}
		groupEq.push(activity)
		return groups
	}, [])
}

/**
 * Da un array di Activity le unisce e ricava un Activity della lunghezza totale
 */
export function mergeActivities(activities: Activity[]): Activity {
	if (!activities) return null
	const act = activities.reduce((acc, act) => {
		// merge dei TAGS
		if (acc == null) {
			acc = { ...act, tags: { ...act.tags } }
			if (!acc.tags[TAGS.JOINT]) acc.tags[TAGS.JOINT] = []
			return acc
		}
		const start = Math.min(acc.start, act.start)
		const accEnd = acc.start + (acc.length * DAY_MILLISEC)
		const actEnd = act.start + (act.length * DAY_MILLISEC)
		let end: number
		if (accEnd < actEnd) {
			end = actEnd
			acc.tags[TAGS.JOINT].push(accEnd)
		} else {
			end = accEnd
		}
		acc.start = start
		acc.length = Math.round((end - start) / DAY_MILLISEC)
		acc.overlaps = [...acc.overlaps ?? [], ...act.overlaps ?? []]
		return acc
	}, null)
	if (!act) return null
	// converto la data ad indice di lunghezza della joint
	act.tags[TAGS.JOINT] = act.tags[TAGS.JOINT].map((j: number) => Math.round((j - act.start) / DAY_MILLISEC))
	act.row = 0
	act.type = ACTIVITY_TYPE.FULL
	return act
}

/** preleva da della activities solo quelle con i TAGS in comune */
export function filterActivitiesByTags(activities: Activity[], tags: { [key: string]: any }) {
	return activities.filter((activity: Activity) => {
		if (!activity.tags) return false
		for (const [key, value] of Object.entries(tags)) {
			const actTags = activity.tags[key]
			if (Array.isArray(actTags) && actTags.some(tag => value.includes(tag))) {
				return true
			} else if (actTags == value) {
				return true
			}
		}
		return false
	})
}

/** 
 * setto il type di estensione che deve avere un gruppo di activity 
  * */
export function setType(activities: Activity[]): void {
	if (!activities || activities.length == 0) return
	if (activities.length == 1) {
		activities[0].type = ACTIVITY_TYPE.FULL
		return
	}

	const { min, max } = activities.reduce((acc, a) => {
		if (!a.length) return acc
		const testMin = a.start
		const testMax = a.start + (a.length * DAY_MILLISEC)
		if (acc.min == null || testMin < acc.min) acc.min = testMin
		if (acc.max == null || testMax > acc.max) acc.max = testMax
		return acc
	}, { min: null, max: null })

	activities.forEach(a => {
		if (a.start == min) {
			a.type = ACTIVITY_TYPE.START
		} else if (a.start + (a.length * DAY_MILLISEC) == max) {
			a.type = ACTIVITY_TYPE.END
		} else {
			a.type = ACTIVITY_TYPE.MIDDLE
		}
	})
}



// GROUPS

/**
 * Dato un "group" calcola i totali di ogni sua "row" 
 * in un intervallo di tempo "dateStart" -> "dateEnd"
 */
export function calcGroupTots(group: GraphGroup, range: DateRange): number[] {
	if (!group || range.end == null || range.start == null) return []
	return group.activities
		.filter(activity => activity.start >= range.start && activity.start <= range.end)
		.reduce((tots, activity) => {
			tots[activity.row] += isNaN(activity.value) ? 0 : activity.value
			return tots
		}, Array(group.labels.length).fill(0))
}

/** Restituisce tutte le activity presenti in un GROUP comprese quelle dei suoi children */
export function getActivityFromGroups(groups: GraphGroup[] | GraphGroup): Activity[] {
	if (groups && !Array.isArray(groups)) groups = [groups]
	return (groups as GraphGroup[]).reduce((acc, group) => {
		if (group.activities?.length > 0) acc = acc.concat(group.activities)
		if (group.groups?.length > 0) {
			acc = acc.concat(getActivityFromGroups(group.groups))
		}
		return acc
	}, [] as Activity[])
}
