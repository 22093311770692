import React from 'react';
import { Tooltip } from "@mui/material"
import { Warning as WarningIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next';


function WarnLayerIcon({
	layer,
	...props
}) {

	// HOOKs
	const { t } = useTranslation()


	// RENDER
	if ( !layer.errors || layer.errors.length == 0  ) return null
	const tooltip = layer.errors.reduce( (acc,err) => acc += t(`app.cycle.warn.${err.code}`)+". ","")

	return <Tooltip title={tooltip} placement="top">
		<WarningIcon {...props} />
	</Tooltip>
}

export default WarnLayerIcon