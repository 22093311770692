import { createStore, StoreCore } from "@priolo/jon"
import tasksApi from "api/tasks"
import draftSo from "stores/draft"
import farmSo from "stores/farm"
import { LastDateRange } from "stores/planner/utils/fetch"
import { EDIT_TYPE } from "types/Entity"
import { DateRange, Uuid } from "types/global"
import { Task } from "types/Task"
import { haveValues, normalizeDateRange } from "utils"
import { updateTasksStatus } from "./utils"
import { EditTask } from "./utils/edit"
import guSo from "stores/growUnit"



const setup = {

	state: {
		/** all TASKs  */
		all: <Task[]>null,
		/** sono gli uuid TASKS selezionati per l'editazione */
		selecteds: <Uuid[]>[],
	},

	getters: {
		/** indeica se l'uuid passato è selezionato in questo momento */
		isSelected: (taskUuid: Uuid, store?: TaskStore) => store.state.selecteds.includes(taskUuid),
		/** restituisce l'array di oggetti TASKS selezionati (ricordati che state.selecteds sono gli uuid) */
		getSelected: (_: void, store?: TaskStore) => store.state.selecteds
			?.map(uuid => store.state.all?.find(t => t.taskUuid == uuid)).filter(t => t) ?? [],
		/** restituisce true se ci sono TASK cancellati nel selected */
		haveSelectEdited: (_: void, store?: TaskStore) => store.getSelected().some(task => EditTask.isEdited(task)),
		haveSelectAllDeleted: (_: void, store?: TaskStore) => store.getSelected().every(task => task._edit?.type == EDIT_TYPE.DELETED),
		/** Recupero da "all" il task con l'uuid specificato */
		getByUuid: (uuid: Uuid, store?: TaskStore): Task => store.state.all?.find(task => task.taskUuid == uuid),
		getIndexByUuid: (uuid: Uuid, store?: TaskStore) => store.state.all?.findIndex(task => task.taskUuid == uuid),
	},

	actions: {
		/** recupera dal BE tutti i TASKs */
		fetch: async (payload?: FetchPayload, store?: TaskStore) => {
			// controllo ci siano tutti i dati
			const farmId = payload?.farmId ?? guSo.state.lastFarmId
			const dateRange = normalizeDateRange(payload?.dateRange, LastDateRange)
			if (farmId == null || !haveValues(dateRange)) return

			// a sto punto carico di brutto...
			const { data: tasksLoad } = await tasksApi.indexByFarm(farmId, dateRange)
			// se devo aggiungere... lo faccio sempre in PROD
			if (payload.add) {
				draftSo.addTasksInSessions(tasksLoad)
				// ...altrimenti sostituisco tutto
			} else {
				draftSo.setTasksInSessions(tasksLoad)
			}

			return tasksLoad
		},
		/** seleziona o deseleziona un TASK */
		toggleSelected(taskUuid: Uuid, store?: TaskStore) {
			const selecteds = [...store.state.selecteds]
			const index = store.state.selecteds.findIndex(uuid => uuid == taskUuid)
			if (index != -1) {
				selecteds.splice(index, 1)
			} else {
				selecteds.push(taskUuid)
			}
			store.setSelected(selecteds)
		},
	},

	mutators: {
		setAll: (all: Task[]) => ({ all: updateTasksStatus(all, farmSo.state.select?.timezone) }),
		setSelected: (selecteds: Uuid[]) => ({ selecteds }),
	},
}

type FetchPayload = {
	farmId?: number
	dateRange?: DateRange
	force?: boolean
	add?: boolean
}

export type TaskState = typeof setup.state
export type TaskGetters = typeof setup.getters
export type TaskActions = typeof setup.actions
export type TaskMutators = typeof setup.mutators
export interface TaskStore extends StoreCore<TaskState>, TaskGetters, TaskActions, TaskMutators {
	state: TaskState
}
const taskSo = createStore(setup) as TaskStore

export default taskSo
