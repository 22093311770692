import i18n from "i18next"

// regole da applicare ai "validator"
export const rules = {

	/** se è una stringa null o vuota da errore */
	obligatory: (v:string) => {
		if (v != null && v.trim().length > 0) return
		return i18n.t("text-err.required")
	},

	/** se non è un ARRAY oppure è un ARRAY vuoto da errore */
	obligatoryArray: (v:any[]) => {
		if (Array.isArray(v) && v.length > 0) return
		return i18n.t("text-err.required-array")
	},

	photoperiod: ([day, night]:[number,number]) => {
		if ((day + night) == 0) return i18n.t("text-err.photo-zero")
		if (day < 0 || night < 0) return i18n.t("text-err.photo-nagative")
		const sum = (+day) + (+night)
		if (sum == 0 || (sum <= 24 && 24 % sum == 0)) return
		return i18n.t("text-err.photo-ivalid")
	},

	samePassword: ([val1, val2]:[string,string]) => {
		let res = rules.obligatory(val1)
		if ( res ) return res
		if (val1 != val2) return i18n.t("text-err.same-passwords")
	},

	/**
	 * da errore se il valore è uguale ad un valore esposto nell'array
	 */
	notEqualAt: (illegals: string[]) => (value:string) => {
		if ( illegals.includes(value)) return i18n.t("text-err.illegal")
	}

}
