import { useStore } from "@priolo/jon";
import SuspenseMsgCmp from "components/layout/SuspenseMsgCmp";
import DatePlaceholder from "pages/farms/alert/list/date/DatePlaceholder";
import React, { FunctionComponent, useEffect } from "react";
import draftSo from "stores/draft";
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import taskSo from "stores/task";
import { TasksScoreByDate } from "stores/task/utils/group";
import { Cycle } from "types/Cycle";
import { Task } from "types/Task";
import { Uuid } from "types/global";
import { dateToString } from "utils";
import TasksByDateCard from "./TasksByDateCard";



interface Props {
	groups?: TasksScoreByDate[]
	cycleUuidHighlight?: Uuid
	onClickTask?: (task: Task) => void
	onCycleMouseOver?: (cycle: Cycle, e: React.MouseEvent) => void
}
const TasksList: FunctionComponent<Props> = ({
	groups,
	cycleUuidHighlight,
	onClickTask,
	onCycleMouseOver,
}) => {

	// STORE
	const taskSa = useStore(taskSo)
	const querySa = useStore(querySo)
	const draftSa = useStore(draftSo)

	// HOOKs
	useEffect(() => {
		const dateSelStr = querySo.getSearchUrl(URL_PAR.DATE_SEL)
		const elm = document.getElementById(dateSelStr)
		elm?.scrollIntoView({ behavior: "auto" /*"smooth"*/, block: "start" })
	}, [querySa])

	// HANDLERs

	// RENDER
	if (!groups) return <SuspenseMsgCmp />
	let date: number
	const inEdit = draftSo.isEditable()
	
	return (<>
		{groups.map((group) => {
			let prevDate = date
			date = group.date
			return <React.Fragment key={date}>
				<DatePlaceholder key="placeholder" prevDate={prevDate} currDate={date} desc/>
				<TasksByDateCard key={date} id={dateToString(date)}
					group={group}
					onClick={onClickTask}
					editMode={inEdit}
					cycleUuidHighlight={cycleUuidHighlight}
					onCycleMouseOver={onCycleMouseOver}
				/>
			</React.Fragment>
		})}
		<DatePlaceholder prevDate={date} desc/>
	</>
	)
}

export default TasksList
