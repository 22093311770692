import { Box } from '@mui/material'
import { useStore } from '@priolo/jon'
import SelectorGroup from 'components/controls/select/SelectorGroup'
import SelectorService from 'components/controls/select/SelectorService'
import { FunctionComponent, useMemo } from 'react'
import querySo from 'stores/route/query'
import { URL_PAR } from 'stores/route/utils/types'


interface Props {
	sx?:any
}

const MetaFilterHeader: FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const querySa = useStore(querySo)

	// HOOKs

	// HANDLERs
	const handleChangeGroup = (group: string) => {
		querySo.setSearchUrl([URL_PAR.META_GROUP, group])
	}
	const handleChangeService = (service: string) => {
		querySo.setSearchUrl([URL_PAR.META_SERVICE, service])
	}


	// RENDER
	const [group, service] = useMemo(() => querySo.getSearchUrl([URL_PAR.META_GROUP, URL_PAR.META_SERVICE]), [querySa.queryUrl])

	return (
		<Box sx={[{ display: "flex", alignItems: "center", gap: "10px" }, sx]}>
			<SelectorService
				service={service}
				onChange={handleChangeService}
			/>
			<SelectorGroup
				group={group}
				onChange={handleChangeGroup}
			/>
		</Box >
	)
}

export default MetaFilterHeader

