import React, { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectorDialogBase from "../base/SelectorDialogBase";

import { useStore } from "@priolo/jon";
import FarmIcon from "images/menu/farm";
import GrowUnitIcon from "images/menu/growunit";
import farmSo from "stores/farm";
import { getFiltered } from "stores/growUnit/utils";
import { GrowUnit } from "types/GrowUnit";
import Chip from "./Chip";



interface Props {
	/** la farm da cui prendere le GrowUnit, se non è inserita la prendo da farmSo.select*/
	farmId?: number,
	/** id delle GROW-UNIT selezionata */
	growUnitId: number,
	/** quando si seleziona una GROW-UNIT  */
	onChange: (growUnit: GrowUnit) => void,
	sx?: any
}

const SelectorGrowUnitH: FunctionComponent<Props> = ({
	farmId,
	growUnitId,
	onChange,
	sx,
}) => {

	// STORE
	const farmSa = useStore(farmSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [textFilter, setTextFilter] = useState("")

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (growUnit: any) => {
		setIsOpen(false)
		if (growUnit) onChange?.(growUnit)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const farm = useMemo(() => !farmId ? farmSa.select : farmSo.getById(farmId), [farmId, farmSa.all, farmSa.select])
	const growUnits = useMemo(() => getFiltered(farm?.growUnit, textFilter), [farm?.growUnit, textFilter])
	const growUnitSelect = useMemo(() => growUnits?.find(gu => gu.id == growUnitId), [growUnits, growUnitId])
	if (!growUnits) return null

	return (<>
		<Chip sx={sx}
			label={growUnitSelect?.name ?? "FARM"}
			icon={growUnitSelect ? <GrowUnitIcon width={18} height={18} /> : <FarmIcon width={18} height={18} />}
			tooltip={t("selector.growunit.tooltip")}
			active={!!growUnitSelect}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto"
			height={null}
			icon={<GrowUnitIcon />}
			items={growUnits}
			textValue={textFilter}
			idSelect={growUnitId}
			title={t("selector.growunit.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}

			onChangeTextValue={txt => setTextFilter(txt)}
			fnTextFromItem={item => item?.name}
			fnIdFromItem={item => item.id}
		/>
	</>)
}

export default SelectorGrowUnitH
