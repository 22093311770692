import { Box, Typography } from '@mui/material';
import { useStore } from '@priolo/jon';
import Paragraph from 'components/controls/form/Paragraph';
import React, { FunctionComponent, useMemo } from 'react';

import dialogSo from 'stores/cycle/editDialog';
import farmSo from 'stores/farm';
import { getGrowUnitLayerName, getLayerByNumber } from 'stores/growUnit/utils/layer';
import { groupLayersByPhases } from "stores/phase/layers";
import { PHASE_SLUG, getPhaseLabel } from 'stores/phase/utils';
import theme from 'styles/theme';
import { Cycle } from 'types/Cycle';
import { Layer } from 'types/Layer';
import { PhaseString } from 'types/global';



interface Props {
	cycle?: Cycle
}

/**
 * Visualizza i LAYER raggruppati per PHASEs di un CYCLE
 */
const CycleLayersShow2: FunctionComponent<Props> = ({
	cycle,
}) => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs
	const growUnit = useMemo(
		() => farmSo.getGrowUnit(dialogSa.cycle.growUnitId),
		[dialogSa.cycle?.growUnitId]
	)

	// RENDER
	if (!cycle) return null

	// i LAYERs raggruppati per PHASE
	const phasesGroups = groupLayersByPhases<Layer>(dialogSa.cycle.layers)
	// i nomi dei layer utilizzati da questo CYCLE
	const getNameLayer = (layerNumber: number) => getGrowUnitLayerName(growUnit, getLayerByNumber(growUnit, layerNumber))
	const getConsumption = (layerNumber: number) => {
		return cycle.powerConsumption?.layersConsumption?.find(layer => layer?.layerNumber == layerNumber)
	}
	const getLabelPower = (layerNumber: number): string => {
		return getConsumption(layerNumber)?.consumedKwh?.toFixed(2) ?? "--"
	}
	const getLabelPowerByPhase = (phase: PhaseString) => {
		return phasesGroups[phase].reduce(
			(sum, layer) => sum + (getConsumption(layer.layerNumber)?.consumedKwh ?? 0),
			0
		).toFixed(2)
	}
	return <>{
		Object.keys(phasesGroups).map(phase => (
			<Paragraph key={phase} sx={{ alignItems: "start" }}
				title={phase != PHASE_SLUG.BATCH ? getPhaseLabel(phase)?.toUpperCase() : ""}
				subtitle={`${getLabelPowerByPhase(phase)} kwh`}
			>
				<Box sx={cssRow}>
					{phasesGroups[phase].map((layer) => (
						<ChipLayer key={layer.layerNumber}
							label={getNameLayer(layer.layerNumber)}
							body={getLabelPower(layer.layerNumber)}
						/>
					))}
				</Box>
			</Paragraph>
		))
	}</>

}

export default CycleLayersShow2


const cssRow = {
	display: "flex",
	margin: "5px 0px",
	flexWrap: "wrap",
	gap: 2,
}

interface ChipLayerProps {
	label?: string
	body?: string
	disabled?: boolean
}
const ChipLayer: FunctionComponent<ChipLayerProps> = ({
	label,
	body,
	disabled
}) => {
	return (
		<Box sx={cssContainer}>
			<Box sx={[cssTitle, { bgcolor: disabled ? "text.disabled" : "secondary.main" }]}>
				<Typography variant="caption" color="secondary.contrastText">
					{label}
				</Typography>
			</Box>
			<Typography variant="caption" sx={{ padding: "4px 6px", textAlign: "center" }}>
				{body} <span style={{ fontSize: "10px" }}>kwh</span>
			</Typography>
		</Box>
	)
}

const cssContainer = {
	minWidth: "80px",
	display: "flex", flexDirection: "column",
	borderRadius: "4px",
	bgcolor: theme.palette.background.header,

}

const cssTitle = {
	borderRadius: "4px 4px 0px 0px",
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
}