import { Activity, GraphGroup, GROUP_SHOW_TYPE, TAGS } from "components/planner/types"
import { groupsHeightForEach } from "components/planner/utils"
import { getGrowUnitLayerName, getLayersDesired } from "stores/growUnit/utils/layer"
import { getGrowUnitName } from "stores/growUnit/utils"
import { groupLayersByPhases } from "stores/phase/layers"
import { getPhaseLabel } from "stores/phase/utils"
import { GrowUnit, GrowUnitLayer } from "types/GrowUnit"



/** Crea un generico GROWINGS-GROUP: rappresentazione sul PLANNER di piu' GROWINGS co i loro LAYERS*/
export function buildGrowUnitsGroup(
	growUnits: GrowUnit[],
	getActivities: (gu: GrowUnit, phasesLayers: { [phase: string]: GrowUnitLayer[] }) => Activity[]
): GraphGroup {

	const growingGroup: GraphGroup = {
		name: "root_growing",
		title: "GROW UNITS",
		collapsed: true,
		groups: []
	}

	// ciclo tutte le GROW-UNIT e creo i relativi GROUP
	for (const growUnit of growUnits) {

		// i GROWUNIT-LAYER desiderati
		const desPhasesLayer = groupLayersByPhases(getLayersDesired(growUnit))
		const phasesLayer = Object.keys(desPhasesLayer)
			.reduce((acc, phase) => {
				const layers = desPhasesLayer[phase]
				if (layers?.length > 0) acc[phase] = layers
				return acc
			}, {} as { [phase: string]: GrowUnitLayer[] })

		// ricavo le ACTVITIES da inserire nella GROW-UNIT
		const acts = getActivities(growUnit, desPhasesLayer)

		// trasforma le ACTIVITIES in Groups
		const grps = createPhasesGroup(
			acts,
			phasesLayer,
			layer => getGrowUnitLayerName(growUnit, layer)
		)
		// creo il GROUP GROW-UNIT e lo inserisco nel SUPER-GROUP GROWING
		const groups: GraphGroup = {
			name: growUnit.growUnitCode ?? "unknow",
			title: getGrowUnitName(growUnit),
			groups: grps,
		}
		growingGroup.groups.push(groups)

	}

	// tutto compatto e bello
	groupsHeightForEach([growingGroup], null, (g) => {
		g.show = GROUP_SHOW_TYPE.COMPACT
	})

	// messo in ordine alfebetico
	growingGroup.groups = growingGroup.groups.sort((g1, g2) => g1.title.localeCompare(g2.title))

	return growingGroup
}


/**
 * Da una serie di ACTIVITY  
 * e specificando i LAYER per ogni PHASES  
 * restituisco le rappresentazioni GRAPH-GOUP 
 */
function createPhasesGroup(
	/** le ACTIVITY */
	activities: Activity[] = [],
	/** per ogni PHASE tutti i LAYER da prendere in considerazione*/
	phasesLayer: { [phase: string]: GrowUnitLayer[] },
	/** funzione utile per ricavare il nome */
	fnGetName: (gul: GrowUnitLayer) => string
): GraphGroup[] {

	const phases = Object.keys(phasesLayer)
	const ret = phases.map(phase => {
		const layers = phasesLayer[phase]
		if (!phase || !layers || layers.length == 0) return null
		const labels = layers
			.sort((l1, l2) => l2.layerNumber - l1.layerNumber) // ordine inverso rispetto al "layerNumber"
			.map(layer => fnGetName(layer))
		const activitiesPhase = activities
			.filter(activity => activity?.tags?.[TAGS.PHASE] == phase)
		return {
			name: phase,
			title: getPhaseLabel(phase),
			collapsed: false,
			noCollap: true,
			noCompact: true,
			labels,
			activities: activitiesPhase,
		} as GraphGroup
	}).filter(group => group)
	return ret
}
