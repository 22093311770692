
export const croplot = [
	{
		"cycle_uuid": "4479571b-8db0-48f7-86dc-0c23b53c0d2d",
		"grow_unit_id": 19,
		"seeding_date": "2024-05-20",
		"harvesting_date": "2024-06-04",
		"id": 3507,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "1a0ecfbc8bdbbe9f14a0b5b3851a51c9",
		"yield": null,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "0cfc811a-e119-480a-b43a-22d152dd1f60",
		"grow_unit_id": 18,
		"seeding_date": "2024-05-20",
		"harvesting_date": "2024-06-04",
		"id": 3506,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "8ba3b0691300bd7cde0de96d67323248",
		"yield": 100,
		"trolleys_to_harvest": 60,
		"planned_yield": 107.9298
	},
	{
		"cycle_uuid": "1d0f0e31-5894-41e9-b9aa-61a9efa0af44",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-17",
		"harvesting_date": "2024-06-02",
		"id": 3505,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "77745418818e404483cfb41a912d2803",
		"yield": 80,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "cd06608e-b343-4042-91f1-2761b92743ed",
		"grow_unit_id": 18,
		"seeding_date": "2024-05-17",
		"harvesting_date": "2024-06-02",
		"id": 3504,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "7abfbaec72ffbd90fe06bd4525305451",
		"yield": 67,
		"trolleys_to_harvest": 60,
		"planned_yield": 107.9298
	},
	{
		"cycle_uuid": "0b95d8a2-4c96-4351-887f-84f1dc53364b",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-15",
		"harvesting_date": "2024-05-30",
		"id": 3503,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "7a251bd9b3db0d0756bc321bf451a765",
		"yield": 34,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "9e381a10-b0a7-4551-88c9-34df0ef09b00",
		"grow_unit_id": 18,
		"seeding_date": "2024-05-15",
		"harvesting_date": "2024-05-30",
		"id": 3502,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "96d1e2cf6fbd846dc23ecb76081cfaee",
		"yield": 89,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "d5ef4117-05bf-4f24-ab1d-1893a6cfaa71",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-13",
		"harvesting_date": "2024-05-28",
		"id": 3527,
		"crop_id": 146,
		"crop_name": "Basilico rosso",
		"lot_code": "62257eb473962eacb6e798265586916a",
		"yield": 25,
		"trolleys_to_harvest": 16,
		"planned_yield": 11.19272
	},
	{
		"cycle_uuid": "d5ef4117-05bf-4f24-ab1d-1893a6cfaa71",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-13",
		"harvesting_date": "2024-05-28",
		"id": 3528,
		"crop_id": 147,
		"crop_name": "Mix Spicy",
		"lot_code": "8e464b07444dffbd0034bfb4d1cc29e5",
		"yield": 77,
		"trolleys_to_harvest": 14,
		"planned_yield": 18.18817
	},
	{
		"cycle_uuid": "2b487314-f41c-4411-ba8c-fff27cf20d95",
		"grow_unit_id": 18,
		"seeding_date": "2024-05-13",
		"harvesting_date": "2024-05-28",
		"id": 3501,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "67f5ad0212441e20e9ff0f451346cf7e",
		"yield": 84,
		"trolleys_to_harvest": 60,
		"planned_yield": 107.9298
	},
	{
		"cycle_uuid": "69b21e9c-8430-40f5-a8bf-cf5b124fc0af",
		"grow_unit_id": 19,
		"seeding_date": "2024-05-13",
		"harvesting_date": "2024-05-28",
		"id": 3500,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "0a6b6c18a0ce850cd778752f43f2e60f",
		"yield": 33,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "a7bec464-7812-47bf-acbd-7a52b951b364",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-10",
		"harvesting_date": "2024-05-26",
		"id": 3532,
		"crop_id": 146,
		"crop_name": "Basilico rosso",
		"lot_code": "b4f4f797a864dc505cc4dc4bfeb18c0e",
		"yield": 74,
		"trolleys_to_harvest": 16,
		"planned_yield": 11.19272
	},
	{
		"cycle_uuid": "a7bec464-7812-47bf-acbd-7a52b951b364",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-10",
		"harvesting_date": "2024-05-26",
		"id": 3533,
		"crop_id": 147,
		"crop_name": "Mix Spicy",
		"lot_code": "91dfe553cae7bbb2655e63435e514f7d",
		"yield": 56,
		"trolleys_to_harvest": 14,
		"planned_yield": 18.18817
	},
	{
		"cycle_uuid": "fd9e071f-83db-44db-8a4e-1a20c8bfa81b",
		"grow_unit_id": 19,
		"seeding_date": "2024-05-10",
		"harvesting_date": "2024-05-26",
		"id": 3499,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "4867a1517966e299a8d6c1e9f84044fb",
		"yield": 23,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "16b27922-ad04-45c1-8595-96186044e008",
		"grow_unit_id": 17,
		"seeding_date": "2024-05-10",
		"harvesting_date": "2024-05-26",
		"id": 3497,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "9861e2d5ced43bc2d86a3f7937a24990",
		"yield": 67,
		"trolleys_to_harvest": 60,
		"planned_yield": 107.9298
	},
	{
		"cycle_uuid": "75787c06-371c-48dc-925e-fd6bd78d7381",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-08",
		"harvesting_date": "2024-05-23",
		"id": 3530,
		"crop_id": 146,
		"crop_name": "Basilico rosso",
		"lot_code": "869c3193307988544c3cc633ab5b8ed3",
		"yield": 27,
		"trolleys_to_harvest": 16,
		"planned_yield": 11.19272
	},
	{
		"cycle_uuid": "75787c06-371c-48dc-925e-fd6bd78d7381",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-08",
		"harvesting_date": "2024-05-23",
		"id": 3531,
		"crop_id": 147,
		"crop_name": "Mix Spicy",
		"lot_code": "e8c984f52edd077bab9abf3a05775062",
		"yield": 99,
		"trolleys_to_harvest": 14,
		"planned_yield": 18.18817
	},
	{
		"cycle_uuid": "7a19f552-bde2-4c30-ab1b-b817bd24a8f6",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-06",
		"harvesting_date": "2024-05-21",
		"id": 3525,
		"crop_id": 146,
		"crop_name": "Basilico rosso",
		"lot_code": "ff90e04a93f864fce801448ede7865cb",
		"yield": 56,
		"trolleys_to_harvest": 16,
		"planned_yield": 11.19272
	},
	{
		"cycle_uuid": "7a19f552-bde2-4c30-ab1b-b817bd24a8f6",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-06",
		"harvesting_date": "2024-05-21",
		"id": 3526,
		"crop_id": 147,
		"crop_name": "Mix Spicy",
		"lot_code": "e0097831772b827ebdddf5b8b347e348",
		"yield": 98,
		"trolleys_to_harvest": 14,
		"planned_yield": 18.18817
	},
	{
		"cycle_uuid": "6ea9eb60-01c8-44e2-ab2c-68e4ae1a8572",
		"grow_unit_id": 19,
		"seeding_date": "2024-05-06",
		"harvesting_date": "2024-05-21",
		"id": 3495,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "2e928f8ad3b8af120afda81c240f790b",
		"yield": 23,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},
	{
		"cycle_uuid": "926f20f9-9ed0-4b99-bb9d-52c13fb51f47",
		"grow_unit_id": 18,
		"seeding_date": "2024-05-06",
		"harvesting_date": "2024-05-21",
		"id": 3494,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "fb2ab8bc86533fe85d2b0c9de3d539ac",
		"yield": null,
		"trolleys_to_harvest": 60,
		"planned_yield": 107.9298
	},
	{
		"cycle_uuid": "9b3265d0-1b92-4014-b06b-5f13ac074dd1",
		"grow_unit_id": 20,
		"seeding_date": "2024-05-03",
		"harvesting_date": "2024-05-19",
		"id": 3529,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "204a96d10c9dd8eb6af32dbbad7bec79",
		"yield": null,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	},




	{
		"cycle_uuid": "926f20f9-9ed0-4b99-bb9d-II-1",
		"grow_unit_id": 18,
		"seeding_date": "2024-06-06",
		"harvesting_date": "2024-06-21",
		"id": 111,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "fb2ab8bc86533fe85d2b0c9de3d-II-0",
		"yield": 13,
		"trolleys_to_harvest": 60,
		"planned_yield": 107.9298
	},
	{
		"cycle_uuid": "9b3265d0-1b92-4014-b06b-II-1",
		"grow_unit_id": 18,
		"seeding_date": "2024-06-03",
		"harvesting_date": "2024-06-19",
		"id": 112,
		"crop_id": 138,
		"crop_name": "Capriolo_Lattuga_GEL_14",
		"lot_code": "204a96d10c9dd8eb6af32dbbad7be-II-1",
		"yield": 15,
		"trolleys_to_harvest": 90,
		"planned_yield": 161.8947
	}
]