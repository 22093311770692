const MenuExpandIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		{...props}
	>
		<rect x="4" y="5" width="3" height="3" />
		<rect x="4" y="10" width="3" height="3" />
		<rect x="4" y="15" width="3" height="3" />
		<rect x="9" y="6" width="8" height="2" />
		<rect x="9" y="11" width="5" height="2" />
		<rect x="9" y="16" width="8" height="2" />
		<path d="M20 8L16 12L20 16" fill="none" stroke="currentColor" strokeWidth="2" />
	</svg>
)

export default MenuExpandIcon