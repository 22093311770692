import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TASK_SCOPE } from "types/Task";
import SelectorDialogBase from "../base/SelectorDialogBase";
import WrapControl, { ICON_VARIANT, WrapControlProps } from "../base/WrapControl";



interface Props extends WrapControlProps {
	/** lo scope selezionato */
	scope?: TASK_SCOPE,
	/** quando si seleziona una GROW-UNIT  */
	onChange: (scope: TASK_SCOPE) => void,
}

const SelectorScope: FunctionComponent<Props> = ({
	scope,
	onChange,
	...props
}) => {

	// STORE

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (scope:string) => {
		setIsOpen(false)
		if (scope) onChange?.(scope.toLowerCase() as TASK_SCOPE)
	}
	const handleClear = () => {
		onChange?.(null)
	}

	// RENDER
	const scopes = Object.values(TASK_SCOPE).map( s => s?.toUpperCase())
	return (<>
		<WrapControl
			variantIcon={ICON_VARIANT.PAGE}
			placeholder="Click to select"
			textValue={scope?.toUpperCase() ?? ""}
			onClick={handleClick}
			onClickClear={handleClear}
			{...props}
		/>
		<SelectorDialogBase
			width={"auto"}
			height={null}
			items={scopes}
			idSelect={scope}
			title={t("selector.scope.title")}
			isOpen={isOpen}
			onClose={handleClose}
		/>
	</>)
}

export default SelectorScope

