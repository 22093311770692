import { ALARM_SEVERITY, ALARM_TEAM } from "./AlertRule";
import { Entity } from "./Entity";
import { DateString } from "./global";

/**
 * POCO per la gestione degli ALERTs
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-306/ALERT
 */
export interface Alert extends Entity<Alert> {
	opsgenieId: string
	// eventualmente la GROW-UNIT a cui fa riferimento
	growUnitId?: number
	farmId: number

	rawMessage: string
	message?:string
	severity?: ALARM_SEVERITY
	link?: string
	team?: ALARM_TEAM
	count?: number

	firstViewedAt?: DateString
	firstAckedAt?: DateString
	firstClosedAt?: DateString
  
	firstOccurredAt: DateString, 	// la prima istanza dell'errore se c'e' un count
	lastOccuredAt: DateString,    	// l ultima istanza dell'errore se c'e' un count
	
	behaviourCode?: string, 		// codice allerma che metti anche su ALERT RULE
	alertname?: string, 			// codice allarme interno PLC
}

export enum ALERT_STATUS {
	UNKNOW = "unknow",
	OPEN = "open", 					// l'ALERT è stato generato e ancora nessuno l'ha visto
	SEEN = "seen",					// l'ALERT è stato generato ed è stato visto su Opsgenie (graficamente sara' il label OPEN ma "not bold" )
	ACKNOWLAGED = "acknowlaged",	// l'ALERT è stato generato ed è stato "preso in considerazione"
	CLOSE = "close",				// l'ALERT è stato gestito
}

export enum ALERT_FILTER {
	ALL = "all",	
	OPEN = "open",
	NOT_SEEN = "not_seen",
	NOT_ACKNOWLAGED = "not_acknowlaged",
	ACKNOWLAGED = "acknowlaged",
	CLOSED = "closed",
}
