import React, { FunctionComponent } from "react";

import { Box } from "@mui/material";
import { getCropNames } from "stores/cycle/utils/cycle";
import { Cycle } from "types/Cycle";
import { getRef } from "utils/humanize";
import Chip from "./Chip";



interface Props {
	/** il CYCLE selezionato */
	cycle: Cycle,
	/** quando si seleziona un CYCLE  */
	onChange: (cycle: Cycle) => void,
	sx?: any
}

const SelectorCycleH: FunctionComponent<Props> = ({
	cycle,
	onChange,
	sx,
}) => {

	// STORE

	// HOOKs

	// HANDLER
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		onChange?.(null)
	}

	// RENDER
	if (!cycle) return null
	const cycleLabel = getRef(cycle.cycleUuid)
	const tooltip = getCropNames(cycle)

	return <Chip sx={sx}
		label={cycleLabel}
		icon={<Box sx={[cssCircle, { bgcolor: cycle.color }]} />}
		tooltip={tooltip}
		active={true}
		onClear={handleClear}
	/>
}

export default SelectorCycleH

const cssCircle = {
	height: "12px",
	width: "12px",
	mx: "2px",
	borderRadius: "25%",
	boxShadow: 2,
	border: 1
}