import theme from "styles/theme"
import { EDIT_TYPE, Entity } from "types/Entity"
import { Color } from "types/global"
import { clone } from "./func"



export class Edit {

	/** il nome della proprietà che funge da ID */
	static PropIdName = "id"

	/** una lista di nomi di proprietà comuni a tutte le entities */
	static COMMON = []

	/** Restituise TRUE se l'ENTITY è stata modificata/cancellata o è nuova */
	static isEdited<T extends Entity<T>>(entity: T): boolean {
		return !!entity._edit
	}

	/** 
	 * dato un ENTITY "target" applico le modifiche "props"
	 * eventualmente creo ORIGINAL per poter ripristinare lo stato alla prima modifica
	 */
	static modify<T extends Entity<T>>(target: T, template: Partial<T>): boolean {

		// se il target non è stato editato allora lo setto in modificato
		if (!target._edit) {
			target._edit = {
				type: EDIT_TYPE.MODIFIED,
				original: clone(target)
			}

			// se il target è NEW cmq metto da parte l'originale 
		} else if (target._edit.type == EDIT_TYPE.NEW && !target._edit.original) {
			target._edit.original = clone(target)

			// se il target è DELETE è una situazione "strana" e la segnalo
		} else if (target._edit.type == EDIT_TYPE.DELETED) {
			console.warn("EDIT:ENTITY:modify on delete")

			// nel caso di modifica ad un target gia' modificato eseguo semplicemente il set
		} else if (target._edit.type == EDIT_TYPE.MODIFIED) {
		}

		this.set(target, template)
		// if (this.equal(target, target._edit?.original)) {
		// 	target._edit = null
		// }
		return true
	}

	/** 
	 * permette di impostare i valori del "target" con quelli di "template"
	 * se deep è false "template" non copia il prorpio _edit
	 */
	static set<T>(target: T, template: Partial<T>, deep?: boolean) {
		for (const key in template) {
			if (!deep && key == "_edit") continue
			target[key] = template[key]
		}
	}

	/** 
	 * TRUE se le ENTITY hanno stesse COMMON properties  
	 */
	// static equal<T extends Entity<T>>(entity1: T, entity2: T): boolean {
	// 	if (entity1 == entity2) return true
	// 	if (entity1 == null || entity2 == null) return false
	// 	return !this.COMMON.some(prop => entity1[prop] != entity2[prop])
	// }

	/** 
	 * CLONE della versione ORIGINALE dell'ENTITY 
	 * nel caso ci sia withNew allora restituirà l'original (cosa possibile anche se è NEW)
	 */
	static original<T>(entity: Entity<T>, withNew: boolean = false): T {

		// se è new e non si vogliono i NEW allora restituisco null
		if (!withNew && entity._edit?.type == EDIT_TYPE.NEW) return null

		// quindi clono l'original
		if (!!entity._edit?.original) return { ...entity._edit.original }

		// nel caso non ci sia l'original retituisco l'oggetto stesso clonato
		const clone = { ...entity }
		delete clone._edit
		delete clone._extra
		return clone as T
	}

	/**
	 * CLONE del valore CURRENT "pulito" da edit e ORIGINAL
	 */
	static current<T>(entity: Entity<T>, deleted: boolean = false, withExtra: boolean = false): T {
		if (entity._edit?.type == EDIT_TYPE.DELETED && !deleted) return null
		const clone = { ...entity }
		delete clone._edit
		if (!withExtra) delete clone._extra
		return clone as T
	}

	/** 
	 * CLONE del'entity solo delle proprietà comuni CORRENTI 
	 */
	static common<T>(entity: T): Partial<T> {
		const src = this.COMMON.reduce((src, key) => {
			if (entity[key] != null) src[key] = entity[key]
			return src
		}, {})
		return src
	}

	/**
	 * Se è un elemento cancellato lo ripristino
	 * Diversamente da "setup": lascia l'ORIGINAL e eventuali modifiche
	 */
	// static restoreDelete<T extends Entity<T>>(entity: T): boolean {
	// 	if (entity._edit?.type == EDIT_TYPE.DELETED) {
	// 		if (entity._edit.original) {
	// 			entity._edit.type = EDIT_TYPE.MODIFIED
	// 		} else {
	// 			delete entity._edit
	// 		}
	// 		return true
	// 	}
	// 	return false
	// }


	//#region COLLECTIONS

	static diff<T extends Entity<T>>(entities: T[]): T[] {
		return entities.reduce((acc: T[], entity: T) => {
			if (!Edit.isEdited(entity)) return acc
			acc.push(clone(entity))
			return acc
		}, [])
	}

	/** 
	 * Applica a tutti i "entities" i "templates"
	 * se "modify=true" viene eseguito come "modify" altrimenti come "set"
	 * MODIFICA IN PLACE "entities"
	 * */
	static merge<T extends Entity<T>>(entities: T[], templates: T[]) {
		if (!entities && !templates) return []
		if (!entities) return templates
		if (!templates) return entities
		for (const template of templates) {

			// lo cerco nella libreria delle entities
			const target = entities.find(item => item[this.PropIdName] == template[this.PropIdName])

			// se lo trovo ed è da CANCELLARE 
			if (target && template._edit?.type == EDIT_TYPE.DELETED) {
				target._edit = { type: EDIT_TYPE.DELETED }

				// se lo trovo 
			} else if (target) {

				// se la sorgente è NEW e la destinazione NEW allora sostituisco brutalmente il NEW della sorgente nella destinazione
				// se la sorgente è NEW e la destinazione non è NEW è una situazione "sbagliata": non faccio null
				if (template._edit?.type == EDIT_TYPE.NEW) {
					if (target._edit?.type == EDIT_TYPE.NEW) {
						this.set(target, template, true)
					}

					// quindi se trovo il TEMPLATE come TARGET gia' esistente applico le MODIFICHE
				} else {
					this.modify(target, template)
				}

				// se non lo trovo... allora sarà NUOVO
			} else {
				template._edit = { type: EDIT_TYPE.NEW }
				entities.push(template)
			}
		}
		return entities
	}

	/**
	 * Ripristina l ORIGINAL di prima della modifica
	 * RESTITUISCE COPIA
	 */
	static rollback<T extends Entity<T>>(entities: T[]) {
		const ret = entities.reduce((acc, entity) => {
			const clone = this.original(entity)
			if (clone) acc.push(clone)
			return acc
		}, [])
		return ret
	}

	/** 
	 * Conferma le modifiche ed elimina l'ORIGINAL
	 * RESTITUISCE COPIA 
	 * */
	static setup<T extends Entity<T>>(entities: T[]) {
		const ret = entities
			.filter(entity => !entity._edit || entity._edit.type != EDIT_TYPE.DELETED)
			.map(entity => this.current(entity))
		return ret
	}

	/**
	 * imposta a DELETE un elemento di un array tramite il suo id
	 * restituisce l'elemento eliminato
	 * MODIFICA IN PLACE
	 */
	static deleteById<T extends Entity<T>>(entities: T[], id: string): T {
		const index = entities.findIndex(t => t[this.PropIdName] == id)
		const entity = entities[index]
		if (entity._edit?.type == EDIT_TYPE.DELETED) return null
		if (entity._edit?.type == EDIT_TYPE.NEW) {
			entities.splice(index, 1)
			return entity
		}
		if (!entity._edit) {
			entity._edit = { type: EDIT_TYPE.DELETED }
		} else {
			entity._edit.type = EDIT_TYPE.DELETED
		}
		return entity
	}

	//#endregion

	/** setta un valore "extra" */
	static setExtra<T extends Entity<T>>(entity: T, name: string, value: any): T {
		if (!entity._extra) entity._extra = {}
		entity._extra[name] = value
		return entity
	}
	/** setta un valore "extra" solo se non è gia' settato e restituisce il valore extra */
	static setExtraIfNull<T extends Entity<T>>(entity: T, name: string, value: any): any {
		const v = this.getExtra(entity, name)
		if (v != null) return v
		this.setExtra(entity, name, value)
		return value
	}
	/** restituisce un valore "extra" */
	static getExtra<T extends Entity<T>>(entity: T, name: string): any {
		return entity?._extra?.[name]
	}

}

export function getSxRow(type: EDIT_TYPE): any {
	const sx = {
		[EDIT_TYPE.MODIFIED]: {
			color: theme.palette.entity.modify,
		},
		[EDIT_TYPE.DELETED]: {
			color: theme.palette.entity.delete,
			textDecoration: "line-through",
		},
		[EDIT_TYPE.NEW]: {
			color: theme.palette.entity.new,
		},
	}[type] ?? {}

	return sx
}

export function getEditTypeColor(type: EDIT_TYPE): Color {
	return {
		[EDIT_TYPE.MODIFIED]: theme.palette.entity.modify,
		[EDIT_TYPE.DELETED]: theme.palette.entity.delete,
		[EDIT_TYPE.NEW]: theme.palette.entity.new,
	}[type]
}


