import { dateWithoutTime, DAY_MILLISEC, diffDay } from "utils/time";
import { Activity, GraphStyle } from "../types";

/**
 * dato un "syle" e una "data di riferimento" 
 * restituisce una funzione che da la posizione x rispetta ad una data
 */
export const xFromTimeStyled = (style: GraphStyle, dateRef: number) =>
	(time: number) => xFromTime(time, dateRef, style)
export const xFromTime = (date: number, dateRef: number, style: GraphStyle) : number =>
	Math.round((date - dateRef) / DAY_MILLISEC) * style.space.x

/**
 * dato uno "style" e una "data di riferimento" 
 * restituisce una funzione che da il tempo rispetto ad una posizione x
 */
export const timeFromXStyled = (style: GraphStyle, dateCurrent: number) =>
	(x: number) => timeFromX(x, dateCurrent, style)
export const timeFromX = (x: number, dateCurrent: number, style: GraphStyle): number => {
	const date = dateCurrent + ((Math.floor((x - style.stageMarginLeft) / style.space.x)) * DAY_MILLISEC)
	return dateWithoutTime(date)
}

/**
 * setta la data ad un gruppo di ACTIVITY 
 * usando la propr "offset"
 */
// export function setDate(start: number, activities: Activity[]) {
// 	activities
// 		.forEach((a: Activity) => a.start = start + (DAY_MILLISEC * a.offset))
// }

/**
 * restituisce true se l'ACTIVITY è visibile altrimanti false
 * @param activity 	l'attività da valutare
 * @param start 	data di inizio visualizzazione
 * @param columns 	colonne (o giorni) visualizzati
 * @returns 
 */
export function isActivityVisible(activity: Activity, start: number, columns: number): boolean {
	if (!activity || !activity.start == null) return false
	const col = diffDay(activity.start, start)
	// se non c'e' il "length" allora suppongo sia un DataItem
	const length = activity.length ?? 1
	if (col + length <= 0 || col > columns || length == 0) return false
	return true
}

/**
 * arrotonda il valore con fattori 
 * @param {number} value 
 * @returns 
 */
export function roundValue(value: number): string {
	if (value > 999999) return `${Math.round(value / 100000) / 10}M`
	if (value > 99999) return `${Math.round(value / 1000)}k`
	if (value > 9999) return `${Math.round(value / 1000)}k`
	if (value > 999) return `${Math.round(value / 100) / 10}k`
	return Math.round(value).toString()
}
