import { useStore } from "@priolo/jon";
import { FunctionComponent, useMemo, useState } from "react";
import cropSo from "stores/library/crop";
import { getItemsFiltered } from "stores/tag/utils";
import { Crop } from "types/Crop";
import ListTags from "../ListTags";
import SelectorDialogBase from "../base/SelectorDialogBase";
import WrapControl, { ICON_VARIANT, WrapControlProps } from "../base/WrapControl";



interface Props extends WrapControlProps {
	/** filtro sulla GROW-UNIT da utilizzare */
	farmId?: number,
	/** il CROP ID selezionato */
	cropId?: number,
	/** quando si seleziona un CROP  */
	onChange: (crop: Crop) => void,
}
const SelectorCrop: FunctionComponent<Props> = ({
	farmId,
	cropId,
	onChange,
	...props
}) => {

	// STORE
	const cropSa = useStore(cropSo)

	// HOOKs
	const [isOpen, setIsOpen] = useState(false)
	const [textFilter, setTextFilter] = useState("")

	// HANDLER
	const handleClick = () => setIsOpen(true)
	const handleClose = (cropSelect: Crop) => {
		setIsOpen(false)
		if (cropSelect) onChange?.(cropSelect)
	}

	// RENDER
	const crops = useMemo(() => {
		return getItemsFiltered(cropSa.all, { txt: textFilter, farmId })
	}, [cropSa.all, textFilter, farmId])
	const cropSelect = useMemo(() => cropSo.getById(cropId), [cropId])

	return (<>
		<WrapControl
			variantIcon={ICON_VARIANT.PAGE}
			textValue={cropSelect?.name ?? ""}
			onClick={handleClick}
			{...props}
		/>
		<SelectorDialogBase
			maxWidth="sm"
			items={crops}
			textValue={textFilter}
			idSelect={cropId}
			isOpen={isOpen}
			onClose={handleClose}
			onChangeTextValue={txt => setTextFilter(txt)}
			fnTextFromItem={item => (
				<span style={{ fontSize: "15px", fontWeight: "500" }}>{item.name}</span>
			)}
			fnSecondaryFromItem={item => <ListTags tags={item.tagList} autotags={item.autotagList} />}
			fnIdFromItem={item => item.id}
		/>
	</>)
}

export default SelectorCrop

