const LostTrolley = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		{...props}
	>
		<path d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12Z" fill="currentColor" />
		<path d="M9 13L16 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
		<path d="M16 13L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export default LostTrolley