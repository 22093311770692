import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import dialogSo from "stores/task/taskNewDialog";
import taskSo from "stores/taskTemplateGroup";
import { TaskTemplate } from "types/TaskTemplate";

interface Props {
	sx?: any
}
const TaskTemplateList:FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const dialogSa = useStore(dialogSo)
	useStore(taskSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleToggle = (taskTemplate:TaskTemplate) => {
		const tasks = [...dialogSa.taskTemplates]
		const index = tasks.findIndex(t => t.id == taskTemplate.id)
		if (index == -1) {
			tasks.push(taskTemplate)
		} else {
			tasks.splice(index, 1)
		}
		dialogSo.setTasks(tasks)
	}

	// RENDER 
	const group = dialogSa.taskTemplateGroupId ? taskSo.getGroupTaskTemplatesById(dialogSa.taskTemplateGroupId) : null

	if (!group?.taskTemplates) return (
		<Typography sx={{ color: "text.sub", mx: 1}}>
			{t("dialog.task.new.detail.templates-void")}
		</Typography>
	)

	const isSelect = (taskTemplate:TaskTemplate) => dialogSa.taskTemplates.some(t => t.id == taskTemplate.id)

	return (
		<List sx={sx}>
			{group.taskTemplates.map((taskTemplate) => (
				<ListItemButton key={taskTemplate.id} dense 
					onClick={() => handleToggle(taskTemplate)}
				>
					<ListItemIcon>
						<Checkbox disableRipple tabIndex={-1} edge="start"
							checked={isSelect(taskTemplate)}
						/>
					</ListItemIcon>
					<ListItemText primary={taskTemplate.subject} />
				</ListItemButton>
			))}
		</List>
	)
}

export default TaskTemplateList
