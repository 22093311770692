import { Box, BoxProps, SxProps } from "@mui/material";
import React, { FunctionComponent } from "react";



interface Props extends BoxProps {
	width?: any
	marginTop?: number

	renderLeft?: React.ReactNode
	renderRight?: React.ReactNode
	children?: React.ReactNode

	sx?: SxProps
	centerSx?: SxProps
	leftSx?: SxProps
	rightSx?: SxProps
}

const VerticalPage: FunctionComponent<Props> = ({
	width = "970px",
	marginTop = 0,

	renderLeft,
	renderRight,
	children,

	sx,
	centerSx,
	leftSx,
	rightSx,

	...props
}) => {

	const mt = `${marginTop}px`
	return (
		<Box sx={{ ...cssContainer as any, ...sx }} {...props}>

			{/* LEFT */}
			<Box sx={[cssLeft as any, { mt }, leftSx,]}>
				{renderLeft && (
					<Box sx={cssStiky(marginTop)}>
						{renderLeft}
					</Box>
				)}
			</Box>

			{/* CENTER */}
			<Box sx={{ ...cssCenter, maxWidth: width, mt, ...centerSx }}>
				{children}
			</Box>

			{/* RIGHT */}
			<Box sx={[cssRight as any, { mt }, rightSx]} >
				{renderRight && (
					<Box sx={cssStiky(marginTop)}>
						{renderRight}
					</Box>
				)}
			</Box>

		</Box>
	)
}


export default VerticalPage


const cssContainer: SxProps = {
	display: "flex",
	justifyContent: "space-between",
	width: "100%"
}
const cssLeft: SxProps = {
	display: "flex",
	flexDirection: "column",
	flex: "0 1",
	p: "0px 0px 0px 16px",
}
const cssCenter: any = {
	display: "flex",
	flexDirection: "column",
	flex: `1 0`,
	pt: "0px",
}
const cssRight: SxProps = {
	display: "flex",
	flexDirection: "column",
	flex: "0 1",
	p: "0px 0px 0px 16px",
}
const cssStiky = (mt: number): SxProps => ({
	position: "sticky",
	top: `${mt}px`,
})