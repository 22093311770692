import { Assignment as TaskIcon } from "@mui/icons-material";
import { Box, Button, Checkbox } from "@mui/material";
import { useStore } from "@priolo/jon";
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

import Modal from "components/controls/Modal";
import DatePicker from "components/controls/fields/DatePicker";
import NumberField from "components/controls/fields/NumberField";
import TimeField from "components/controls/fields/TimeField";
import Paragraph from "components/controls/form/Paragraph";
import TaskScopeSelect from "components/controls/select/TaskScopeSelect";
import React, { FunctionComponent } from "react";

import TextField from "components/controls/fields/TextField";
import multiSo from "stores/task/taskMultiEdit";




const TaskMultiEditDialog: FunctionComponent = () => {

	// STORE
	const multiSa = useStore(multiSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleClose = () => {
		multiSo.close(false)
	}
	const handleConfirm = async () => {
		multiSo.close(true)
	}
	const handleChangeField = (value) => {
		multiSo.setSelect({ ...multiSa.select, ...value })
	}

	// RENDER 
	if (!multiSa.select) return null
	let dueDate = dayjs(multiSa.select.dueDate)
	return (
		<Modal onClick={(e) => e.stopPropagation()}
			icon={<TaskIcon />}
			maxWidth="sm"
			title={t("dialog.task.title")}
			isOpen={multiSa.isOpen}
			onClose={handleClose}
			actionsRender={<>
				<Box sx={{ flex: 1 }} />
				<Button
					onClick={handleClose}
				>{t("dialog.task.btt-cancel")}</Button>
				<Button color="secondary"
					onClick={handleConfirm}
				>{t("dialog.task.btt-confirm")}</Button>
			</>}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>

				<Paragraph title={t("dialog.task.details.subject")}>
					<TextField sx={{ flex: 1 }}
						placeholder={t("dialog.task.multiedit.indeterminate")}
						value={multiSa.select.subject ?? ""}
						onChange={e => handleChangeField({ subject: e.target.value })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.description")}>
					<TextField sx={{ flex: 1 }}
						placeholder={t("dialog.task.multiedit.indeterminate")}
						multiline rows={3}
						value={multiSa.select.description ?? ""}
						onChange={e => handleChangeField({ description: e.target.value })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.scope")}>
					<TaskScopeSelect  sx={{ flex: 1 }}
						placeholder={t("dialog.task.multiedit.indeterminate-select")}
						value={multiSa.select.scope}
						onChange={scope => handleChangeField({ scope })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.points")}>
					<NumberField sx={{ width: "150px" }}
						placeholder={t("dialog.task.multiedit.indeterminate")}
						value={multiSa.select.points ?? ""}
						onChangeNumber={value => handleChangeField({ points: +value, totalPoints: (+value + multiSa.select.trolleysPoints) })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.points-trolley")}>
					<NumberField sx={{ width: "150px" }}
						placeholder={t("dialog.task.multiedit.indeterminate")}
						value={multiSa.select.trolleysPoints ?? ""}
						onChangeNumber={value => handleChangeField({ trolleysPoints: +value, totalPoints: (+value + multiSa.select.points) })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.date-time")}>
					<DatePicker
						value={dueDate}
						onChange={value => handleChangeField({ dueDate: dayjs(value).format("YYYY-MM-DD") })}
					/>
					<TimeField sx={{ marginLeft: "5px", width: "100px" }}
						value={multiSa.select.dueTime}
						onChange={dueTime => handleChangeField({ dueTime })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.updates-yield")}>
					<Checkbox color="secondary"
						indeterminate={multiSa.select.updatesYield == null}
						checked={multiSa.select.updatesYield}
						onChange={e => handleChangeField({ updatesYield: e.target.checked })}
					/>
				</Paragraph>

				<Paragraph title={t("dialog.task.details.cancel-if-cycle-rejected")}>
					<Checkbox color="secondary"
						indeterminate={multiSa.select.cancelIfCycleRejected == null}
						checked={multiSa.select.cancelIfCycleRejected}
						onChange={e => handleChangeField({ cancelIfCycleRejected: e.target.checked })}
					/>
				</Paragraph>

			</Box>
		</Modal>
	)
}

export default TaskMultiEditDialog
