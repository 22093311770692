import { Box, Button, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import Modal, { ModalProps } from "components/controls/Modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import deviceSo from "stores/layout/device";
import FindField from "../fields/FindField";



interface Props extends ModalProps {
	/** l'id dell'item selezionato */
	idSelect: string | number,
	/** gli oggetti che andranno renderizzati */
	items: any[]
	textValue: string

	width?: number | string
	height?: number | string

	/** chiamata quando si clicca sul btt colose o fuori dalla dialog */
	onClose: (item: any) => void
	/** chiamata se si preme il bottone "Clear" */
	onClear?: (e: React.MouseEvent) => void

	/** quando si digita sulla textbox di ricerca */
	onChangeTextValue: (text: string) => void

	/** per un "item" restituisce il suo "text" */
	fnTextFromItem: (item: any) => React.ReactNode,
	/** per un "item" restituisce il suo "sottotitolo" */
	fnSecondaryFromItem?: (item: any) => React.ReactNode,
	/** a fronte di un item restituisce il suo ID */
	fnIdFromItem: (item: any) => number | string,

	renderFilterColumn?: React.ReactNode,
}

const SelectorDialogBase: FunctionComponent<Partial<Props>> = ({
	isOpen,

	idSelect,
	items,
	textValue,

	width,
	height = "600px",

	onClose,
	onClear,
	onChangeTextValue,

	fnTextFromItem = (item) => item,
	fnSecondaryFromItem,
	fnIdFromItem = (item) => item,

	renderFilterColumn,
	...props
}) => {

	const getIndexFromId = (id: string | number) => {
		if (!items) return -1
		return items.findIndex(item => fnIdFromItem(item) == id)
	}

	const normalizeIndex = (index: number) => {
		if (index >= items.length) return items.length - 1
		if (index < 0) return 0
		return index
	}


	// HOOKs
	const { t } = useTranslation()
	const [indexHighlight, setIndexHighlight] = useState<number>()

	useEffect(() => {
		if (!isOpen) return
		onChangeTextValue?.("")
		setIndexHighlight(getIndexFromId(idSelect))
	}, [isOpen])


	// HANDLER
	const handleClickItem = (item: any) => {
		onClose(item)
	}
	const handleChangeTextValue = (e: any) => {
		onChangeTextValue?.(e.target.value)
	}
	const handleCancel = () => {
		onClose(null)
	}

	const handleKeyDown = (e) => {
		switch (e.code) {
			case "ArrowDown":
				e.preventDefault()
				setIndexHighlight(normalizeIndex(indexHighlight + 1))
				break
			case "ArrowUp":
				e.preventDefault()
				setIndexHighlight(normalizeIndex(indexHighlight - 1))
				break
			case "Enter":
				e.preventDefault()
				onClose(items[indexHighlight])
				break
			case "Esc":
				e.preventDefault()
				onClose(null)
				break
			default:
		}
	}

	// RENDER 
	return (
		<Modal
			sx={{ ".MuiDialog-paper": { width: width } }}
			maxWidth="xs"
			isOpen={isOpen}
			onClose={handleCancel}
			actionsRender={<>
				<Box sx={{ flex: "1 1 auto" }} />
				<Button onClick={handleCancel}>
					{t("dialog.default.cancel")}
				</Button>
				{onClear && (
					<Button onClick={onClear} color="secondary">
						{t("dialog.default.clear")}
					</Button>
				)}
			</>}
			{...props}
		>
			<Box sx={{ display: "flex", flexDirection: "column", height: height ?? "unset", gap: 1 }}>

				{onChangeTextValue && (
					<FindField autoFocus={deviceSo.isDesktop()}
						placeholder="Search..."
						value={textValue}
						onChange={handleChangeTextValue}
						onKeyDown={handleKeyDown}
					/>
				)}

				{renderFilterColumn}

				<Box sx={{
					flex: "1 0 0",
					maxHeight: "600px",
					overflowY: height ? "auto" : "visible",
					marginTop: "10px",
				}}>
					{items ? (
						<List sx={{ width: "100%" }}>
							{items.map((item, index) => (

								<ListItemButton data-test={`dialog-item-${index}`} key={fnIdFromItem(item)} dense
									selected={indexHighlight == index}
									onClick={() => handleClickItem(item)}
								>
									<ListItemText
										primary={fnTextFromItem(item)}
										secondary={fnSecondaryFromItem?.(item)}
									/>
								</ListItemButton>

							))}
						</List>
					) : (
						<Typography>No data...</Typography>
					)}
				</Box>

			</Box>
		</Modal>
	)
}

export default SelectorDialogBase



