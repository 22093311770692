const Icon = (props) => (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none" 
		{...props} 
	>
		<path d="M9.9999 4H8.0749C7.89157 4 7.72074 4.04583 7.5624 4.1375C7.40407 4.22917 7.28324 4.35833 7.1999 4.525L6 6.00011L5.2499 8H6.6999L7.6999 6H9.9999V7H8.2999L7.2999 9H4.6999L3 10.0001V11.0001L4.5 12.0001L3 13.0001V14.0001L4 15H7.2999L8.2999 17H9.9999V18H7.6999L6.6999 16H4.9999V18L6.5 20C7 20 7.59213 20 8.0749 20H10C10.5575 20 11 19.5575 11 19L10.8874 15H8.9999V14H11V11.0001L9.2999 11L8.2999 13H5.9999V12H7.6999L8.6999 10L11 10.0001C11 8.05749 11 5 11 5C11 4.5 10.5 4 9.9999 4Z" />
		<path d="M14.0001 4H15.9251C16.1084 4 16.2793 4.04583 16.4376 4.1375C16.5959 4.22917 16.7168 4.35833 16.8001 4.525L18 6.00011L18.7501 8H17.3001L16.3001 6H14.0001V7H15.7001L16.7001 9H19.3001L21 10.0001V11.0001L19.5 12.0001L21 13.0001V14.0001L20 15H16.7001L15.7001 17H14.0001V18H16.3001L17.3001 16H19.0001V18L17.5 20C17 20 16.4079 20 15.9251 20H14C13.4425 20 13 19.5575 13 19L13.1126 15H15.0001V14H13V11.0001L14.7001 11L15.7001 13H18.0001V12H16.3001L15.3001 10L13 10.0001C13 8.05749 13 5 13 5C13 4.5 13.5 4 14.0001 4Z" />

	</svg>
)

export default Icon