import { createStore, StoreCore } from "@priolo/jon"
import tagsApi from "api/tags"



const isPLC = import.meta.env.VITE_TARGET == "plc"

const setup = {

	state: {
		all: <string[]>[],
		autotags: <string[]>[],
		filter: "",
	},

	getters: {

		// get filtered TAGs
		getFiltered: (
			{ noAutotags }: { noAutotags: boolean } = { noAutotags: false },
			store?: TagStore
		): string[] => {
			const filter = store.state.filter.toLowerCase().trim()
			const allTags = !noAutotags ? store.state.autotags.concat(store.state.all) : store.state.all
			if (filter.length == 0) return allTags
			const tags = allTags.filter(tag => tag.startsWith(filter))
			return tags
		},

		existTag: (tag: string, store?: TagStore): boolean => store.state.all.concat(store.state.autotags).includes(tag),

	},

	actions: {

		// recupera tutti i TAGS 
		fetchAll: async (_: void, store?: TagStore) => {
			const { data: { tags, autotags } } = await tagsApi.index()
			store.setAll(tags.map((t: string) => t.toLowerCase()))
			store.setAutotags(autotags.map((t: string) => t.toLowerCase()))
		},

		fetchAllIfVoid: async (_: void, store?: TagStore) => {
			// nel PLC non ci sono TAGS
			if ( isPLC || store.state.all?.length > 0) return
			await store.fetchAll()
		},

	},

	mutators: {

		setAll: (all: string[]) => ({ all: all?.sort() ?? [] }),
		setAutotags: (autotags: string[]) => ({ autotags }),
		setFilter: (filter: string) => ({ filter }),

	},

}


export type TagState = typeof setup.state
export type TagGetters = typeof setup.getters
export type TagActions = typeof setup.actions
export type TagMutators = typeof setup.mutators
export interface TagStore extends StoreCore<TagState>, TagGetters, TagActions, TagMutators {
	state: TagState
}
const tagSo = createStore(setup) as TagStore
export default tagSo


