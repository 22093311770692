import { Close as CloseIcon } from "@mui/icons-material"
import { Box, Tooltip } from "@mui/material"
import React, { FunctionComponent, ReactNode } from "react"
import theme from "styles/theme"
import IconType from "./IconType"



export enum TAB_TYPE {
	NONE,
	PRODUCTION,
	ROOT,
	DRAFT,
	DRAFT_APPLY,
	PREVIEW,
	CLENDAR,
	ALERT,
	LOADING,
}

interface Props {
	type?: TAB_TYPE
	index?: any
	select?: any
	tooltip?: React.ReactNode
	tooltipIcon?: string
	onClick?: (index: any) => void
	onIconClick?: (index: any) => void
	onDblClick?: (index: any) => void
	onClose?: (index: any) => void
	canClose?: boolean
	children?: ReactNode
}

/**
 * Identificato con un "index" è la barra superiore del componente TABS
 */
const Tab: FunctionComponent<Props> = ({
	type,
	index = 0,
	select,
	tooltip,
	tooltipIcon,
	onClick,
	onIconClick,
	onDblClick,
	onClose,
	canClose = false,
	children
}) => {

	// HANDLERs
	const handleClick = (e: React.MouseEvent) => {
		onClick?.(index)
	}
	const handleDblClick = (e: React.MouseEvent) => {
		onDblClick?.(index)
	}
	const handleClose = (e: React.MouseEvent) => {
		if (!onClose) return
		e.preventDefault()
		e.stopPropagation()
		onClose(index)
	}
	const handleIconClick = (e: React.MouseEvent) => {
		if (!select || !onIconClick) return
		e.preventDefault()
		e.stopPropagation()
		onIconClick(index)
	}


	// RENDER
	const isSelect = index == select
	const cnContainer = [cssContainer, isSelect && cssSelect]

	return (

		<Box
			sx={cnContainer}
			onClick={handleClick}
			onDoubleClick={handleDblClick}
		>
			<IconType sx={cssIcon}
				type={type}
				tooltip={tooltipIcon}
				onClick={handleIconClick}
			/>

			<Tooltip title={tooltip}><div>
				{children}
			</div></Tooltip>

			{canClose && <CloseIcon fontSize="small" onClick={handleClose} sx={cssCloseIcon} />}
		</Box>

	)
}

export default Tab

const cssIcon = {
	marginLeft: "-5px",
	marginRight: "5px",
	width: "20px", height: "20px",
}

const cssCloseIcon = {
	marginLeft: "5px",
	marginRight: "-5px",
}

const cssContainer = {
	display: "flex",
	fontSize: "14px",
	padding: "7px 16px",
	backgroundColor: theme.palette.background.card,
	borderRadius: "5px 5px 0px 0px",
	ml: "7px",
	borderBottom: "1px solid #393939",
	cursor: "pointer",
	alignItems: 'center',

	transition: "padding-bottom 300ms cubic-bezier(0.8, 0.28, 0.25, 1.85), background-color 300ms linear",
}

const cssSelect = {
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
	fontWeight: 700,
	paddingBottom: "10px",
}
