/**
 * Identificativi delle pagine
 */
export const PAGE_ID = {

    //#region FARMS

    UNKNOW: "unknow",
    LANDINGPAGE: "landing-page",

    FARMS: "farms",
    FARM_GROWUNITS: "growunits",
    FARM_PLANNER: "farm-gantt",
    FARM_CYCLES: "farm-cycles",
    FARM_TASKS: "farm-tasks",
    FARM_AI_PLANNING: "farm-ai-planning",
    FARM_ALERTS: "farm-alerts",
    FARM_PLC_ALERTS: "farm-plc-alert",
    FARM_CO2: "farm-co2",
    FARM_BI: "farm-bi",
    FARM_MAP: "farm-map",
    
    FARM_CHARTS: "farm-main",
    FARM_CONSOLES: "farm-consoles",
    FARM_TECHNICAL_DASHBOARDS: "farm-technical-dashboards",
    FARM_BUSINESS_DASHBOARDS: "farm-business-dashboards",
    FARM_EXTERNAL_LINK: "farm-external-link",

    GROWUNIT_DETAIL: "growunits-detail",
    GROWUNIT_RECIPE: "growunits-recipe",
    GROWUNIT_TOWERS: "growunits-towers",
    GROWUNIT_PROPERTIES: "growunits-properties",

    //#endregion



    //#region PLC

    FARM_COMPONENTS: "farm-components",

    GROWUNIT_HOME: "growunit-home",
	GROWUNIT_MAIN: "growunit-main",
	GROWUNIT_LAYERS: "growunits-layers",
	GROWUNIT_FERTI: "growunits-ferti",
	GROWUNIT_ADJ: "growunits-adj",
	GROWUNIT_LIGHTING: "growunits-lighting",
	GROWUNIT_LIGHTING_PS: "growunits-lighting-ps",
	GROWUNIT_IRRIGATION: "growunits-irrigation",
    GROWUNIT_PLC_ALERTS: "growunit-alerts",

    COMPONENT_HVAC: "component-hvac",
    COMPONENT_HVAC_ALERTS: "component-hvac-alerts",
    COMPONENT_OSMOTIC_WATER: "component-ow",
    COMPONENT_OSMOTIC_WATER_ALERTS: "component-ow-alerts",
	COMPONENT_GERMINATION: "component-germinator",
    COMPONENT_GERMINATION_ALERTS: "component-germinator-alerts",
	COMPONENT_POWER_MONITOR: "component-power-monitor",

    FARM_MODULES: "modules",
    FARM_METADATA: "metadata",
    
    //#endregion



    //#region LIBRARY

    LIBRARY: "library",
    
    RECIPES: "recipes",
    RECIPES_DETAIL: "recipes-edit",

    CROPS: "crops",
    CROPS_DETAIL: "crops-edit",

    PRODUCTS: "products",
    PRODUCTS_DETAIL: "products-edit",

    //#endregion


    
    //#region SETTINGS

    SETTINGS: "settings",

    ALARMS: "alarms",
    ALARMS_DETAIL: "alarms-edit",
    
    ROOT: "dashboard",
    ZW: "zw",
    PREFERENCES: "preferences",

    //#endregion
    
}
export type PAGE_ID = typeof PAGE_ID | string

export enum URL_PAR {
	/** testo scritto */
	TEXT = "search",
    ALERT_TEXT = "alert.txt",
	/** selezione di un CYCLE */
	CYCLE_SEL = "cycle.sel",
    TASK_SEL = "task.sel",
    REQUEST_SEL = "req.sel",
	/** selezione di una DATE */
	DATE_SEL = "date.sel",
	/** lo SCOPE attualmente filtrato */
	SCOPE_FIL = "scope.fil",
	/** la GROWUNIT attualmente filtrata */
	GROWUNIT_FIL = "gu.fil",
	/** la SEVERITY attualmente filtrata */
	ALARM_SEVERITY = "svr",
	/** lo STATUS ALARMattualmente filtrata */
	ALERT_STATUS = "sts",
	/** la modalità di visualizzazione */
	ALERT_MODE = "mde",

    /** GROW-UNIT SINOTTICI */
	GU_TOWER = "twr",
    /** GROUP di METADATA */
	META_GROUP = "grp",
	/** CODE SERVICE di METADATA */
	META_SERVICE = "srv",
	ROLE = "r",

    PRODUCTION_STATE = "prod.sts",
    PRODUCTION_SEARCH = "prod.search",

	/** indica un numero random per poter aggiornare la stringa URL */
	UPDATE = "t",
}