import { AccessTime as TimeIcon } from "@mui/icons-material";
import { WrapControlProps } from "../base/WrapControl";
import TextField from "./TextField";
import { FunctionComponent } from "react";
import { SxProps } from "@mui/material";



interface Props extends WrapControlProps {
	value: string
	onChange: (time: string) => void
}

const TimeField: FunctionComponent<Props> = ({
	value,
	onChange,
	...props
}) => {

	const sxIcon:SxProps = { marginLeft: "-22px", opacity: props.readOnly ? 0.5 : 1 }

	return (
		<TextField
			type="time"
			value={value ?? ""}
			onChange={e => onChange(e.target.value)}
			endAdornment={<TimeIcon sx={sxIcon} />}
			{...props}
		/>
	)
}

export default TimeField