
import Cookies from "js-cookie"

export const Auth = {
	get: () => Cookies.get("zeroAuth"),
	remove: () => Cookies.remove("zeroAuth"),
	set: (auth: string) => Cookies.set("zeroAuth", auth)
}

export const getAppUrl = (url = "") => {
	return `/app/${url}`
}

export enum LOGIN_RESPONSE {
	LOGGED = "logged",
	NEEDS_OTP = "needs_otp",
	ERROR = "error",
	FORCE_2FA = "force-2fa",
}