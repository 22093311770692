
const Icon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"  
		fill="currentColor" 
		{...props}
	>
		<path fillRule="evenodd" clipRule="evenodd"
			d="M14.8375 3.07947L20.8812 8.58463C20.9549 8.65176 21 8.75726 21 8.86756V20.6403C21 20.837 20.8607 21 20.6927 21H3.30731C3.13931 21 3 20.837 3 20.6403V3.3624C3 3.22333 3.06966 3.09385 3.17619 3.03631C3.28682 2.97397 3.41384 2.99315 3.50398 3.08426L8.67084 8.10029V3.3624C8.67084 3.22333 8.7405 3.09385 8.84703 3.03631C8.95766 2.97397 9.08468 2.99315 9.17482 3.08426L14.3417 8.10029V3.3624C14.3417 3.22813 14.4072 3.10345 14.5138 3.0411C14.6203 2.97876 14.7432 2.99315 14.8375 3.07947ZM12.4043 20.095V15.5386C12.7683 15.613 13.2822 15.6921 13.8497 15.6921C14.5349 15.6921 15.2844 15.5758 15.8947 15.1942C17.357 14.2775 18.2321 11.6605 18.2863 11.4984L18.2877 11.4941C18.3145 11.4057 18.2984 11.308 18.2342 11.2335C18.1699 11.159 18.0736 11.1125 17.9665 11.1078L17.9604 11.1076C17.7573 11.1003 14.6351 10.9885 13.1698 11.899C12.249 12.4761 11.885 13.5 11.7404 14.2587C11.7297 14.3285 11.719 14.3936 11.7083 14.4541C11.4942 14.1144 11.2105 13.7979 10.8304 13.5606C9.37432 12.6479 6.25986 12.7611 6.04145 12.7691C6.03823 12.7692 6.03563 12.7693 6.03369 12.7693C5.92662 12.774 5.83026 12.8205 5.76602 12.895C5.70177 12.9695 5.68571 13.0672 5.71248 13.1556C5.71297 13.1571 5.71361 13.159 5.71438 13.1613C5.77293 13.3368 6.642 15.9416 8.10547 16.8557C8.71576 17.2373 9.46524 17.3537 10.1505 17.3537C10.7179 17.3537 11.2319 17.2746 11.5959 17.2001V20.0996L12.4043 20.095Z"
		/>
	</svg>
)

export default Icon