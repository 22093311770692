import { Box, Card, CircularProgress, Typography } from "@mui/material";
import React, { FunctionComponent } from 'react';


type Props = {
	message?: string
}

const SuspenseMsgCmp: FunctionComponent<Props> = ({
	message,
}) => {

	// HOOKs

	// RENDER
	if (!message) message = "Please wait ..."
	return <Box sx={{ display: "flex", justifyContent: "space-around" }}>
		<Card sx={cssCard} elevation={0}>
			<CircularProgress />
			{message && <Typography sx={{ mt: 3 }}>
				{message}
			</Typography>}
		</Card>
	</Box>
}

export default SuspenseMsgCmp

const cssCard = {
	alignSelf: "center",
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
	padding: "50px",
	marginTop: "150px",
}
