import { TAGS } from "components/planner/types"
import dayjs from "dayjs"
import cropSo from "stores/library/crop"
import { SESSION_TYPE } from "stores/draft/utils"
import { getLayersFromGrowUnits } from "stores/growUnit/utils/layer"
import { Cycle } from "types/Cycle"
import { GrowUnit } from "types/GrowUnit"
import { Layer } from "types/Layer"
import { SolutionCycle } from "types/Production"
import { dateToString } from "utils"
import { generateColor } from "utils/humanize"



/**
 * da un array i SOLUTION-CYCLES restituisce un array di CYCLES
 */
export function cyclesFromSolutionCycles(
	solutionsCycles: SolutionCycle[],
	growUnits: GrowUnit[],
): Cycle[] {

	const cycles = []
	const allGrowUnitsLayers = getLayersFromGrowUnits(growUnits)

	for (const solutionCycle of solutionsCycles) {

		// creo un CYCLE nuovo
		const cycle: Cycle = {
			cycleUuid: solutionCycle.uuid,
			layers: [],
			growUnitId: null,
			_extra: { [TAGS.SESSION]: SESSION_TYPE.PREVIEW }
		}
		let minDate: number = null
		let maxDate: number = null

		// creo i LAYERS del CYCLE
		for (const scp of solutionCycle.phases) {
			const guLayers = allGrowUnitsLayers.filter(gul => scp.layers.includes(gul.id))
			if (guLayers.length == 0) break

			const busyFrom = dayjs(scp.busyFrom).valueOf()
			const busyTo = dayjs(scp.busyTo).valueOf()
			if (minDate == null || busyFrom < minDate) minDate = busyFrom
			if (maxDate == null || busyTo > maxDate) maxDate = busyTo

			const cyclePhaseLayers = guLayers.map(guLayer => {
				return {
					id: guLayer.id,
					layerNumber: guLayer.layerNumber,
					busyFrom: scp.busyFrom,
					busyTo: scp.busyTo,
					phase: scp.name,
					growUnitId: guLayer.growUnitId,
				} as Layer
			})

			cycle.layers.push(...cyclePhaseLayers)
		}
		
		// se non ci sono LAYERS allora non se na fa nulla!
		if (cycle.layers.length == 0) continue
		if (minDate) cycle.seedingDate = dateToString(minDate)
		if (maxDate) cycle.yieldDate = cycle.harvestingDate = dateToString(maxDate)
		cycle.color = generateColor(cycle.cycleUuid)
		cycle.plannedYield = solutionCycle.cycleYield
		const crop = cropSo.getByUuid(solutionCycle.cropUuid)
		cycle.cropCycleId = crop.id
		cycle.cropName = crop.name
		cycles.push(cycle)
	}

	return cycles
}

