import { Uuid } from "types/global"
import farmSo from "stores/farm"



const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625]
const avg = 0.5279276315789471

/**
 * Data una stringa calcola APPROSSIMATIVAMENTE quale puo' essere il suo "width"
 */
export function measureText(text: string, fontSize: number = 16): number {
	return Array.from(text).reduce(
		(acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0
	) * fontSize
}

/**
 * serve a trovare la posizione di inizio e fine di una parola in una stringa di testo. 
 * Prende come parametri il testo e la posizione corrente del cursore e restituisce un oggetto con le proprietà start e end che indicano rispettivamente la posizione di inizio e fine della parola.
 */
export function getWordPos(text: string, pos: number): { start: number, end: number } {
	let end = text.indexOf(" ", pos)
	if (end == -1) end = text.length
	let start = text.lastIndexOf(" ", pos - 1)
	start = start == -1 ? 0 : start + 1
	return { start, end }
}

/**
 * restituisce di un "text" in una "pos" la parola e i suoi limiti
 */
export function getWord(text: string, pos: number) {
	const { start, end } = getWordPos(text, pos)
	const word = text.slice(start, end)
	return { word, start, end }
}

/**
 * restituisce tutti i tag utilizzati all'interno di una "text"
 */
export function getTagsFromText(text: string) {
	if (!text || text.length == 0) return { tags: [], texts: [] }
	const words = text.split(" ")
	const response = words.reduce((acc, word) => {
		word = word.trim().toLowerCase()
		if (word.startsWith("#")) {
			if (word.length < 2) return acc
			acc.tags.push(word.slice(1))
		} else {
			if (word.length == 0) return acc
			acc.texts.push(word)
		}
		return acc
	}, { tags: [], texts: [] })
	return response
}

/**
 * 
 */
export function matchTags(tags: string[], tagsFilter: string[]): boolean {
	if (!tagsFilter || tagsFilter.length == 0) return true
	if (!tags || tags.length == 0) return false
	return tags.some(tag => tagsFilter.some(tagF => tag.startsWith(tagF)))
}

/**
 * Dato un array di oggetti (tipicamente Recipe o Crop)
 * li filtra in base all'oggetto "option"
 * @param {any[]} items 
 * @param {{
 * txt:string,
 * noSort:boolean,
 * withArchived:boolean,
 * ignore:string[],
 * farmId:number
 * }} param1 
 * @returns 
 */
export function getItemsFiltered(
	items: Partial<Item>[],
	{ txt, noSort, withArchived, onlyArchived, ignore = [], farmId, tags: tagsUrl }: Option
): Partial<Item>[] {
	const { tags, texts } = getTagsFromText(txt)
	txt = texts.join(" ")
	items = items
		.filter(item => {
			if (!withArchived && item.archived) return false
			if ( onlyArchived && !item.archived) return false
			if (ignore.includes(item.uuid)) return false
			const text = !txt || txt.length == 0
				|| item.name?.toLowerCase().includes(txt)
				|| farmSo.getNames(item.farmIds).toLowerCase().includes(txt)
			if (!text) return false
			if (farmId && !item.farmIds.some((id: number) => id == farmId)) return false
			const itemTags = (item.tagList ?? []).concat(item.autotagList ?? [])
			if (!matchTags(itemTags, tags)) return false
			if (!!tagsUrl && !itemTags.some(t => tagsUrl.includes(t))) return false
			return true
		})
	if (!noSort) items = items.sort((a, b) => a.name.localeCompare(b.name))
	return items
}

interface Item {
	uuid: Uuid
	archived: boolean
	name: string
	farmIds: number[]
	tagList: string[]
	autotagList: string[]
}

type Option = Partial<{
	txt: string,
	noSort: boolean,
	withArchived: boolean,
	onlyArchived: boolean,
	ignore: any[],
	farmId: number,
	tags: string[],
}>

