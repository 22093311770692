import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useStore } from '@priolo/jon';
import MainOS from 'components/layout/MainOS';
import MsgBox from 'components/layout/MsgBox';
import SuspenseMsgCmp from 'components/layout/SuspenseMsgCmp';
import "devextreme/dist/css/dx.material.teal.dark.compact.css";
import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";
import layoutStore from 'stores/layout';
import userStore from 'stores/user';

const LoginPag = lazy(() => import("pages/login/LoginPag"));
const RequestPag = lazy(() => import('pages/login/RequestPag'));
const ResetPag = lazy(() => import('pages/login/ResetPag'));
const OtpCodePag = lazy(() => import('pages/login/OtpCodePag'));

const LandingPage = lazy(() => import("pages/landingpage/Pag"));


export default function AppOS() {

    // HOOKs
    useStore(userStore)
    const { fetch, isLogged } = userStore
    const layout = useStore(layoutStore)
    const [ready, setReady] = useState(false)


    useEffect(() => {
        const load = async () => {
            await fetch()
            setReady(true)
        }
        load()
    }, [])

    // RENDER
    if (!ready) return null

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={layout.theme}>
                <CssBaseline />

                <MsgBox />

                <Suspense fallback={<SuspenseMsgCmp />}>
                    {!isLogged() ? (
                        <Switch>
                            <Route exact path="/password/request" component={RequestPag} />
                            <Route exact path="/password/reset" component={ResetPag} />
                            <Route exact path="/login/otp-code" component={OtpCodePag} />
                            <Route path={["/", "/login"]} component={LoginPag} />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route path="/landingpage" component={LandingPage} />
                            <Route path="*">
                                <MainOS />
                            </Route>
                        </Switch>
                    )}
                </Suspense>

            </ThemeProvider>
        </StyledEngineProvider>
    );

}

