import { Box, Divider, Typography } from "@mui/material"
import { useStore } from "@priolo/jon"
import Paragraph from "components/controls/form/Paragraph"
import { TAGS } from "components/planner/types"
import dayjs from "dayjs"
import { FunctionComponent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import dialogSo from "stores/cycle/editDialog"
import { getCropNames, getCycleStatus, getCycleStatusLabel, getLabelCycleDayStatus } from "stores/cycle/utils/cycle"
import { EditCycle } from "stores/cycle/utils/edit"
import { CYCLE_STATUS } from "stores/cycle/utils/enums"
import { SESSION_TYPE } from "stores/draft/utils"
import guSo from "stores/growUnit"
import { getGrowUnitName } from "stores/growUnit/utils"
import { dateFormat, getRef } from "utils/humanize"
import CycleLayersShow from "../CycleLayersShow"



const DetailTab: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs
	const { t } = useTranslation()

	// RENDER
	if (!dialogSa.cycle) return null
	const guName = useMemo(() => getGrowUnitName(guSo.getByID(dialogSa.cycle.growUnitId)), [dialogSa.cycle])
	const isPreview = EditCycle.getExtra(dialogSa.cycle, TAGS.SESSION) == SESSION_TYPE.PREVIEW
	const cycleStatus = getCycleStatus(dialogSa.cycle)
	const statusLabel = isPreview ? "PREVIEW" : getCycleStatusLabel(cycleStatus)
	const harvestingDate = dialogSa.cycle.harvestingDate ? dayjs(dialogSa.cycle.harvestingDate).format("DD/MM/YYYY") : "--"
	const unitsYieldTot = dialogSa.cycle.cropLots?.reduce((acc, cl) => acc + (cl.unitsYield ?? 0), 0)

	return <Box sx={{ display: "flex", flexDirection: "column" }}>

		<Paragraph title={t("pag.cycle.edit.ref")} >
			<Typography variant="subtitle2">{getRef(dialogSa.cycle.cycleUuid)}</Typography>
		</Paragraph>

		<Paragraph title={t("pag.cycle.edit.gu")} >
			<Typography variant="subtitle2">{guName}</Typography>
		</Paragraph>

		<Paragraph title={t("pag.cycle.edit.crop-name")} >
			<Typography>{getCropNames(dialogSa.cycle)}</Typography>
		</Paragraph>

		<Paragraph title={t("pag.cycle.edit.status")} >
			<Typography>{statusLabel}</Typography>
		</Paragraph>

		{!isPreview && cycleStatus == CYCLE_STATUS.HARVESTED && <>
			<Paragraph title={t("pag.cycle.edit.yield")} >
				<Typography>{Math.round(dialogSa.cycle.yield)}</Typography>
			</Paragraph>
			<Paragraph title="UNITs YIELD" >
				<Typography>{unitsYieldTot ?? "--"}</Typography>
			</Paragraph>
		</>}

		{!isPreview && (
			<Paragraph title={t("pag.cycle.edit.seeding-date")} >
				<Typography>{dateFormat(dialogSa.cycle.seedingDate)}</Typography>
			</Paragraph>
		)}

		{!isPreview && (
			<Paragraph title={t("pag.cycle.edit.day")} >
				<Typography>{getLabelCycleDayStatus(dialogSa.cycle)}</Typography>
			</Paragraph>
		)}

		<Paragraph title={t("pag.cycle.edit.harvesting-date")} >
			<Typography>{harvestingDate}</Typography>
		</Paragraph>

		<Box sx={{ display: "flex", flexDirection: "column", margin: "12px 0px" }}>
			<Typography sx={theme => ({ color: theme.palette.text.disabled })} variant="caption">PHASES</Typography>
			<Divider />
		</Box>
		<CycleLayersShow />
	</Box>

}

export default DetailTab
