import { Assignment as TaskIcon, Visibility } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import Modal from "components/controls/Modal";
import Paragraph from "components/controls/form/Paragraph";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import detailSo from "stores/task/taskDetail";
import { TASK_SCOPE } from "types/Task";
import { getRef } from "utils/humanize";



const TaskPreviewForm: FunctionComponent = () => {

	// STORE
	const detailSa = useStore(detailSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleClose = () => detailSo.close()

	// RENDER 
	if (!detailSa.select) return null
	const dueDate = dayjs(detailSa.select.dueDate).format("DD/MM/YYYY")
	const ref = getRef(detailSa.select?.taskUuid) ?? "--"
	const isPreview = detailSa.select?.scope == TASK_SCOPE.PREVIEW
	const title = t(`dialog.task.title${isPreview ? "-preview" : ""}`)
	const icon = isPreview ? <Visibility /> : <TaskIcon />

	return (
		<Modal
			icon={icon}
			maxWidth="sm"
			title={title}
			isOpen={detailSa.isOpen}
			onClose={handleClose}
			actionsRender={<>
				<Box style={{ flex: 1 }} />
				<Button
					onClick={handleClose}
				>{t("dialog.task.btt-close")}</Button>
			</>}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Paragraph flex={30} title={t("dialog.task.details.ref")}>
					<Typography variant="subtitle1">{ref}</Typography>
				</Paragraph>
				<Paragraph flex={30} title={t("dialog.task.details.subject")}>
					<Typography>{detailSa.select.subject ?? ""}</Typography>
				</Paragraph>
				<Paragraph flex={30} title={t("dialog.task.details.points")}>
					<Typography>{detailSa.select.points ?? ""}</Typography>
				</Paragraph>
				<Paragraph flex={30} title={t("dialog.task.details.date")}>
					<Typography>{dueDate}</Typography>
				</Paragraph>
			</Box>
		</Modal>
	)
}

export default TaskPreviewForm
