
/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51875
 */
export interface Incident {
	id?: number
	cycleId?: number
	incidentType: INCIDENT_TYPE
	dateOfOccurrence: string // YYYY-MM-DD
	resolved: boolean
	reverted: boolean
	notes: string
	//totalLostTrolleys: number
	lostTrolleysPerCropLot: {
		cropLotId: number
		numberOfLostTrolleys: number
	}[],
	positionOfTrolleys: PositionTrolley[]
}

export enum INCIDENT_TYPE {
	LOST_TROLLEY= "lost_trolley", 
	IRRIGATION_MALFUNCTION = "irrigation_malfunction", 
	OTHER = "other"
}

export interface PositionTrolley {
	layerId: number
	layerNumber?: number
	positionOfTrolleys: number[]
}