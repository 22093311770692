import React, { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectorDialogBase from "../base/SelectorDialogBase";

import { Report as SeverityIcon } from "@mui/icons-material";
import { ALARM_SEVERITY, AlarmSeverity, AlarmSeverityData, getSeverityData } from "types/AlertRule";
import Chip from "./Chip";
import { Box } from "@mui/material";
import AlertIcon from "pages/farms/alert/list/AlertIcon";



interface Props {
	/** id delle SEVERITY selezionata */
	severityId?: ALARM_SEVERITY,
	/** quando si seleziona una SEVERITY  */
	onChange: (severity: ALARM_SEVERITY) => void,
	sx?: any
}

const SelectorSeverityH: FunctionComponent<Props> = ({
	severityId,
	onChange,
	sx,
}) => {

	// STORE

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (severity: AlarmSeverity) => {
		setIsOpen(false)
		if (severity) onChange?.(severity.id)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const severitySelect = useMemo(() => getSeverityData(severityId), [severityId])
	const getLabel = (severity: AlarmSeverity) => severity?.label.toUpperCase() ?? "ALL"

	const SeverityRow = ({ severity }) => <Box sx={{ display: "flex" }}>
		<AlertIcon severityId={severity.id} sx={{ mr: 1 }} />
		{severity.label.toUpperCase()}
	</Box>

	return (<>
		<Chip sx={sx}
			label={getLabel(severitySelect)}
			icon={<SeverityIcon />}
			tooltip={t("selector.severity.tooltip")}
			active={!!severitySelect}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto"
			height={null}
			icon={<SeverityIcon />}
			items={AlarmSeverityData}
			idSelect={severityId}
			title={t("selector.severity.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}

			fnTextFromItem={item => <SeverityRow severity={item} />}
			fnIdFromItem={item => item.id}
		/>
	</>)
}

export default SelectorSeverityH
