import { MenuItem, Select } from "@mui/material";
import { FunctionComponent } from "react";
import { RELATED_TO } from "types/Task";
import WrapControl, { WrapControlProps } from "../base/WrapControl";



interface Props extends WrapControlProps {
	value?: RELATED_TO
	onChange?: (value: RELATED_TO) => void
}

const SelectorTaskRelated: FunctionComponent<Partial<Props>> = ({
	value,
	onChange,
	...props
}) => {

	// HOOKs

	// RENDER
	return (
		<WrapControl {...props}>
			<Select fullWidth
				value={value}
				onChange={e => onChange(e.target.value as RELATED_TO)}
			>
				{Object.entries(RELATED_TO).map(([key, val]) => (
					<MenuItem
						key={val}
						value={val}
					>{val.replace("_", " ")}</MenuItem>
				))}
			</Select>
		</WrapControl>
	)
}

export default SelectorTaskRelated
