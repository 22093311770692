import ajax from "plugins/AjaxService"
import { TaskTemplateGroup } from "types/TaskTemplate"


// restituisce tutti i TASK-TEMPLATE-GROUPs presenti nel server!
// https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-159/Task-templates-group
function index():Promise<{ data: TaskTemplateGroup[] }> {
	return ajax.get("task_template_groups")
}


const api = {
	index,
}

export default api