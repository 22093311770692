import { Close, Delete, DeleteForever, Done, Edit, Warning } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";
import { useStore } from "@priolo/jon";
import ActionsMenu from "components/controls/buttons/ActionsMenu";
import FormParagraph from "components/controls/form/FormParagraph";
import Paragraph from "components/controls/form/Paragraph";
import { FunctionComponent, useState } from "react";
import incidentSo from "stores/incidents";
import { CropLot } from "types/CropLot";
import { Cycle } from "types/Cycle";
import { Incident } from "types/Incident";
import LayersPositionSelector from "./LayersPositionSelector";
import theme from "styles/theme";
import dayjs from "dayjs";



interface Props {
    incident: Incident
    cycle: Cycle
}

const IncidentCard: FunctionComponent<Props> = ({
    incident,
    cycle,
}) => {

    // STORE
    useStore(incidentSo)

    // HOOKs
    const [collapsed, setCollapsed] = useState(true)

    // HANDLERS
    const handleModify = () => incidentSo.edit(incident)
    const handleRevert = () => incidentSo.revert(incident)
    const handleProceeded = () => incidentSo.proceeded(incident)
    const handleDelete = () => incidentSo.delete(incident)
    const handleCollapse = () => setCollapsed(!collapsed)

    // RENDER
    const lostTrolleys = incident.lostTrolleysPerCropLot?.[0]
    const cropLot: CropLot = cycle?.cropLots?.find(cl => cl.id == lostTrolleys?.cropLotId)
    const cropLotName = cropLot?.cropName ?? "--"
    const type = incident.incidentType?.toUpperCase() ?? "--"
    const total = lostTrolleys?.numberOfLostTrolleys ?? 0
    const date = dayjs(incident.dateOfOccurrence).format("DD/MM/YYYY")
    const icon = incident.reverted ? <Done /> : <Warning />
    const flex = 20

    return (
        <FormParagraph compact key={incident.id}
            onTitleClick={handleModify}
            icon={icon}
            title={cropLotName}
            endRender={
                <ActionsMenu sx={{ color: "inherit" }}
                    actions={[
                        {
                            label: "MODIFY",
                            onClick: handleModify,
                            icon: <Edit />,
                        },
                        (!incident.reverted ?
                            {
                                label: "REVERT",
                                onClick: handleRevert,
                                icon: <Done />,
                            } : {
                                label: "CLEAR REVERTED", color: "secondary",
                                onClick: handleProceeded,
                                icon: <Warning color="secondary" />,
                            }
                        ),
                        { label: "---" },
                        {
                            label: "DELETE", color: "error",
                            onClick: handleDelete,
                            icon: <DeleteForever color="error" />,
                        },
                    ]}
                />
            }
        >
            <Box sx={sxInfoRow}>
                {incident.reverted && <Box sx={sxInfoReverted}>REVERTED</Box>}
                <Box sx={sxInfoType}>{type}</Box>
                <Box sx={sxInfoChip}><span>
                    <span style={sxInfoLabel}>LOST: </span>
                    <span style={sxInfoValue}>{total}</span>
                </span></Box>
                <Box sx={{ flex: 1 }} />
                <Box sx={{ ...sxInfoLabel, opacity: 0.7 }}>{date}</Box>
            </Box>

            <Paragraph title={"LAYERS"} flex={flex}>
                <LayersPositionSelector sx={{ flex: 1 }}
                    cycle={cycle}
                    layersSelect={incident.positionOfTrolleys}
                />
            </Paragraph>

            {(incident.notes?.length > 0) &&
                <Paragraph title={"NOTES"} flex={flex} sxLabel={{ alignSelf: 'start' }}>
                    <Box sx={collapsed ? sxNotesCol : sxNotes}
                        onClick={handleCollapse}
                    >
                        {incident.notes}
                    </Box>
                </Paragraph>
            }

        </FormParagraph>
    )
}

export default IncidentCard

const sxNotes: SxProps = {
    cursor: "pointer",
    m: "0px 8px"
}

const sxNotesCol: SxProps = {
    ...sxNotes,
    cursor: "pointer",
    flex: 100,
    width: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const sxInfoRow: SxProps = {
    display: "flex",
    gap: "10px",
    alignItems: 'center',
    mb: "12px",
}
const sxInfoChip: SxProps = {
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '5px',
    padding: '0px 7px',
    minHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.secondary.contrastText,
}
const sxInfoType: SxProps = {
    ...sxInfoChip,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
}
const sxInfoReverted: SxProps = {
    ...sxInfoChip,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
}

const sxInfoLabel: React.CSSProperties = {
    fontSize: '12px',
    fontWeight: 400,
}
const sxInfoValue: React.CSSProperties = {
    fontSize: '14px',
    fontWeight: 600,
}