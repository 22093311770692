import ajax from "plugins/AjaxService"


/**
 * Send an email with instructions to change the password
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28755
 */
function getEmailInstructions(email: string) {
	return ajax.post("password/reset_password_instructions",
		{ user: { email } }
	)
}

/**
 * Retrieves the current logged in user
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28775
 */
function getUserByToken(token: string) {
	return ajax.post("password/reset_password",
		{ password: { reset_password_token: token } }
	)
}

/**
 * Update password with recovery token
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28795
 */
function resetPassword(password: string, repassword: string, token: string) {
	return ajax.put("password", {
		password: {
			password: password,
			password_confirmation: repassword,
			reset_password_token: token
		}
	})
}

const passwordApi = {
	getEmailInstructions,
	getUserByToken,
	resetPassword,
}

export default passwordApi

