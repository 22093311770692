import { Flight as AirportIcon, Schedule as ClockIcon, Flag, LocationOn as LocationIcon } from "@mui/icons-material";
import { Box, BoxProps, SxProps, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { Farm } from "types/Farm";
import { applyTimezone, timeToHuman } from "utils";



interface Props {
	farm: Farm
	sx?: SxProps
}

const FarmSubtitleCmp: FunctionComponent<Props> = ({
	farm,
	sx,
}) => {

	// HOOKS
	const timeStr = timeToHuman(applyTimezone(dayjs(), farm?.timezone))

	// RENDER
	if (!farm) return null
	return (
		<Box sx={[{ display: "flex", alignItems: "center" }, sx as any]}>

			<LocationIcon sx={sxIcon} />
			<Box sx={sxTextCountry}>{farm.country}</Box>

			{farm.address && (
				<Box sx={sxText}>{farm.address}</Box>
			)}

			<Tooltip title="Local time"><ClockIcon sx={sxIcon} /></Tooltip>
			<Box sx={sxText}>{timeStr}</Box>

			{farm.airportCode && <>
				<Tooltip title="Airport code"><AirportIcon sx={sxIcon} /></Tooltip>
				<Box sx={sxText}>{farm.airportCode}</Box>
			</>}

			{farm.zipCode && <>
				<Tooltip title="ZIP code"><Flag sx={sxIcon} /></Tooltip>
				<Box sx={sxText}>{farm.zipCode}</Box>
			</>}
		</Box>
	)
}


export default FarmSubtitleCmp

const sxIcon: SxProps = {
	width: "18px",
	height: "18px",
	mr: "3px",
}

const sxText: SxProps = {
	fontSize: 12,
	fontWeight: 400,
	mr: 1,
	whiteSpace: 'nowrap',
}

const sxTextCountry: SxProps = {
	...sxText,
	fontSize: 14,
	fontWeight: 600,
}

