
import { createStore, StoreCore, StoreSetup } from "@priolo/jon";
import taskSo from "stores/task";
import { EDIT_TYPE } from "types/Entity";
import { minCommonProps } from "utils/func";
import { EditTask, multiModifyByTemplate } from "./utils/edit";
import { Task } from "types/Task";
import draftSo from "stores/draft"



// used when dialog closed
let resolveClose = null

/**
 * Gestisce la dialog per visualizzazione dettaglio TASK
 */
const setup = {

	state: {
		/** se la dialog è aperta */
		isOpen: false,
		/** il TASK visualizzato */
		select: <Task>null,
	},

	getters: {
	},

	actions: {
		open(_: void, store?: TaskMultiEditStore) {
			const tasks = taskSo.getSelected().filter(task => task._edit?.type != EDIT_TYPE.DELETED)
			const taskCommon = minCommonProps(tasks)
			store.setSelect(taskCommon)
			store.setIsOpen(true)
			return new Promise((resolve, reject) => {
				resolveClose = resolve
			})
		},
		async close(save = false, store?: TaskMultiEditStore) {
			store.setIsOpen(false)
			if (save) {
				const tasks = [...taskSo.state.all]
				const edited = store.state.select
				const src = EditTask.common(edited)
				const tasksSelected = taskSo.getSelected().filter(task => task._edit?.type != EDIT_TYPE.DELETED)
				multiModifyByTemplate(tasksSelected, src)
				await draftSo.saveTasks(tasksSelected)
				taskSo.setAll(tasks)
				taskSo.setSelected([])
			}
			if (resolveClose) resolveClose(save)
			resolveClose = null
		},
	},

	mutators: {
		setIsOpen: (isOpen: boolean) => ({ isOpen }),
		setSelect: (select: Task) => ({ select }),
	},
}

export type TaskMultiEditState = typeof setup.state
export type TaskMultiEditGetters = typeof setup.getters
export type TaskMultiEditActions = typeof setup.actions
export type TaskMultiEditMutators = typeof setup.mutators
export interface TaskMultiEditStore extends StoreCore<TaskMultiEditState>, TaskMultiEditGetters, TaskMultiEditActions, TaskMultiEditMutators {
	state: TaskMultiEditState
}
const store = createStore(setup) as TaskMultiEditStore
export default store