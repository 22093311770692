import { Cycle } from "types/Cycle";
import { Layer } from "types/Layer";
import { dateRangeFromStrings, includes } from "utils";
import { LayersNumberByPhase } from "./layer";
import { forObject } from "utils/array";
import { groupLayersByPhases } from "stores/phase/layers";
import { getLayersDesired } from "stores/growUnit/utils/layer";
import { GrowUnit } from "types/GrowUnit";
import { Crop } from "types/Crop";
import { buildCycleFromCrop } from "./cycle";
import { DateNumber } from "types/global";
import { EDIT_TYPE } from "types/Entity";



/** confronto un "cycleTarget" con un array di "cycles" 
 * e restituisco eventuali LAYERs in overlap*/
export function layersInOverlap(cycles: Cycle[], cycleTarget: Cycle): Layer[] {
	if (!cycles || !cycleTarget) return []
	const layers: Layer[] = []
	for (const cycle of cycles) {
		if (cycle.rejected || cycle._edit?.type == EDIT_TYPE.DELETED || cycle.growUnitId != cycleTarget.growUnitId) continue
		for (const layer of cycle.layers) {
			const layerTarget = cycleTarget.layers.find(l => l && l.layerNumber == layer.layerNumber)
			if (!layerTarget) continue
			const overlap = includes(
				dateRangeFromStrings(layer.busyFrom, layer.busyTo),
				dateRangeFromStrings(layerTarget.busyFrom, layerTarget.busyTo),
			)
			if (overlap) layers.push(layer)
		}
	}
	return layers
}

/** verifica l'OVERLAP di un "cycleTarget" su un array di "cycles" 
 * e restituiece tutti i CYCLES in overlap*/
export function cycleInOverlap(cycles: Cycle[], cycleTarget: Cycle): Cycle[] {
	const cycleOverlap = cycles.filter(cycle => {
		if (cycle.rejected || cycle._edit?.type == EDIT_TYPE.DELETED || cycle.growUnitId != cycleTarget.growUnitId) return false

		const haveOverlap = cycle.layers.some(layer => {
			const layerTarget = cycleTarget.layers.find(l => l.layerNumber == layer.layerNumber)
			if (!layerTarget) return false
			return includes(
				dateRangeFromStrings(layer.busyFrom, layer.busyTo),
				dateRangeFromStrings(layerTarget.busyFrom, layerTarget.busyTo),
			)
		})
		return haveOverlap
	})
	return cycleOverlap
}

/** trasformop un CROP in un CYCLE e verifico che non sia in overlap con gli altri CYCLES */
export function cropInOverlap(cycles:Cycle[], growUnit:GrowUnit, crop:Crop, seedingDate:DateNumber, layerByPhase?:LayersNumberByPhase ): Layer[] {
	if ( !cycles || !crop || !growUnit || !seedingDate ) return []
	// se non specificato prendo tutti i layer della growunit
	if ( !layerByPhase ) {
		layerByPhase = forObject(
			groupLayersByPhases(getLayersDesired(growUnit)),
			(phase, layers) => layers.map(l => l.layerNumber)
		)
	}
	const cycle = buildCycleFromCrop(
		crop,
		seedingDate,
		growUnit.id,
		layerByPhase, //store.state.layersSelected
	)
	const layersOver = layersInOverlap(cycles, cycle)
	return layersOver
}