import { StoreCore, createStore } from "@priolo/jon"
import dialogSo from "stores/layout/dialogStore"
import { Day } from "types/Recipe"
import { moveTo } from "utils/array"
import { ListPhases, getNextPhase } from "../../phase/utils"
import { GroupPhase, getNewDay, getNewGroupPhase } from "./utils"



const setup = {

	state: {
		daysGroups: <GroupPhase[]>[],
		common: <Partial<Day>>{
			nutrientSolutionId: 0,
			co2: 0,
			ec: 0,
			ph: 0,
			humDay: 0,
			humNight: 0,
			tempDay: 0,
			tempNight: 0,
		},
		clipboard: <Day[]>[],
		readOnly: false,
	},

	getters: {
		getAllSelect: (_: void, store?: PhasesDayStore): Day[] => store.state.daysGroups
			.reduce<Day[]>((acc, dg) => {
				return acc.concat(dg.days.filter((d, dIndex) => dg.daysSelect.includes(dIndex)))
			}, []),
	},

	actions: {
		clearSelection: (groupIndex?: number, store?: PhasesDayStore) => {
			if (groupIndex != null) {
				store.state.daysGroups[groupIndex].daysSelect = []
			} else {
				store.state.daysGroups.forEach(g => g.daysSelect = [])
			}
			store.setDaysGroups([...store.state.daysGroups])
		},
		selectAll: (groupIndex?: number, store?: PhasesDayStore) => {
			store.state.daysGroups.forEach((g, index) => {
				if (groupIndex != null && groupIndex != index) return
				g.daysSelect = Array.from({ length: g.days.length }, (x, i) => i)
			})
			store.setDaysGroups([...store.state.daysGroups])
		},

		addPhase: (groupIndex, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			const newPhase = getNextPhase({ used: store.state.daysGroups.map(g => g.phase) })
			const newGroup = getNewGroupPhase(newPhase)
			groups.splice(groupIndex + 1, 0, newGroup)
			store.setDaysGroups(groups)
		},
		movePhase: ({ from, to }, store?: PhasesDayStore) => {
			const daysGroups: GroupPhase[] = [...store.state.daysGroups]
			store.setDaysGroups(moveTo(daysGroups, from, to))
		},

		//#region  DAY
		addDay: (groupIndex, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			const days = groups[groupIndex].days
			days.push(getNewDay(days[days.length - 1]))
			store.setDaysGroups(groups)
		},
		deleteDay: ({ groupIndex, dayIndex }, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			const group = groups[groupIndex]
			if (group.days.length == 1) {
				dialogSo.dialogOpen({
					title: "Operation not allowed",
					text: "A phase must have at least one day",
				})
				return
			}
			group.days.splice(dayIndex, 1)
			store.setDaysGroups(groups)
		},
		changeDay: ({ propDay, groupIndex, dayIndex }, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			const day = store.state.daysGroups[groupIndex].days[dayIndex]
			groups[groupIndex].days[dayIndex] = { ...day, ...propDay }
			store.setDaysGroups(groups)
		},
		cloneDay: ({ groupIndex, dayIndex }, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			const dayClone = { ...groups[groupIndex].days[dayIndex] }
			groups[groupIndex].days.splice(dayIndex, 0, dayClone)
			store.setDaysGroups(groups)
		},
		dragEndDay: (result, store?: PhasesDayStore) => {
			if (!result.destination) return

			const groups = [...store.state.daysGroups]
			const groupIndex = +result.destination.droppableId
			const group = groups[groupIndex]

			const [del] = group.days.splice(result.source.index, 1)
			group.days.splice(result.destination.index, 0, del)
			store.setDaysGroups(groups)
		},
		//#endregion

		//#region GROUP PHASE
		changeGroup: ({ newGroup, groupIndex }, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			groups[groupIndex] = newGroup
			store.setDaysGroups(groups)
		},
		deleteGroup: (groupIndex, store?: PhasesDayStore) => {
			const groups = store.state.daysGroups.filter((g, i) => i != groupIndex)
			if (groups.length == 0) {
				dialogSo.dialogOpen({
					title: "Operation not allowed",
					text: "There must be at least one phase",
				})
				return
			}
			store.setDaysGroups(groups)
		},
		cloneGroup: (groupIndex, store?: PhasesDayStore) => {
			const groups = [...store.state.daysGroups]
			const cloneGroup = JSON.parse(JSON.stringify(groups[groupIndex]))
			groups.splice(groupIndex, 0, cloneGroup)
			store.setDaysGroups(groups)
		},
		//#endregion

	},

	mutators: {
		setDaysGroups: (daysGroups: GroupPhase[]) => ({ daysGroups }),
		setNewDaysGroups: () => ({
			daysGroups: [
				{
					phase: ListPhases[0],
					collapsed: false,
					days: [getNewDay()],
					daysSelect: []
				} as GroupPhase
			]
		}),
		setCommon: (common: Day) => ({ common }),
		setCommonProp: (prop: Day, store?: PhasesDayStore) => ({ common: { ...store.state.common, ...prop } }),
		setClipboard: (clipboard: Day[]) => ({ clipboard }),
		setReadOnly: (readOnly: boolean) => ({ readOnly }),
	},
}



export type PhasesDayState = typeof setup.state
export type PhasesDayGetters = typeof setup.getters
export type PhasesDayActions = typeof setup.actions
export type PhasesDayMutators = typeof setup.mutators
export interface PhasesDayStore extends StoreCore<PhasesDayState>, PhasesDayGetters, PhasesDayActions, PhasesDayMutators {
	state: PhasesDayState
}
const recipeDetailSo = createStore(setup) as PhasesDayStore
export default recipeDetailSo
