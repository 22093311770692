import { Report as AlertIcon, CalendarMonth as DateIcon, Draw as DraftIcon, Gesture, Star as StarIcon, Visibility } from "@mui/icons-material"
import { Box, CircularProgress, Tooltip } from "@mui/material"
import RootIcon from "images/menu/root2"
import { FunctionComponent } from "react"
import { TAB_TYPE } from "./Tab"
import ProductionIcon from "images/ai"



type IconTypeProps = {
	type: TAB_TYPE
	tooltip?: string
	onClick?: (e: React.MouseEvent) => void
	sx: any
}
const IconType: FunctionComponent<Partial<IconTypeProps>> = ({
	type,
	tooltip,
	onClick,
	sx,
}) => {
	return (
		<Tooltip title={tooltip}>
			<div
				style={{ display: "flex" }}
				onClick={onClick}
			>
				{{
					[TAB_TYPE.NONE]: null,
					[TAB_TYPE.PRODUCTION]: <StarIcon sx={sx} />,
					[TAB_TYPE.ROOT]: <Box sx={sx}>
						<RootIcon style={cssIconRoot} />
					</Box>,
					[TAB_TYPE.DRAFT]: <DraftIcon sx={sx} />,
					[TAB_TYPE.DRAFT_APPLY]: <Gesture sx={sx} />,
					[TAB_TYPE.PREVIEW]: <ProductionIcon sx={sx} />,
					[TAB_TYPE.CLENDAR]: <DateIcon sx={sx} />,
					[TAB_TYPE.ALERT]: <AlertIcon sx={sx} />,
					[TAB_TYPE.LOADING]: <CircularProgress sx={sx} size={16} color="inherit" />,
				}[type]}
			</div>
		</Tooltip>
	)
}

export default IconType

const cssIconRoot = {
	width: "20px",
	height: "20px",
}