import { FilterList as FilterIcon, RestartAlt as ResetIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useStore } from '@priolo/jon';

import DrawerCmp from 'components/controls/drawer/DrawerCmp';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import layoutSo from 'stores/layout';


interface Props {
	title?: string
	width?: number
	onReset?: () => void
	children?: React.ReactNode

}

const FilterDrawer: FunctionComponent<Props> = ({
	title,
	width=200,
	onReset,
	children,
}) => {

	// STORE
	const layoutSa = useStore(layoutSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleClose = () => {
		layoutSo.setDrawerIsOpen(false)
	}

	return (
		<DrawerCmp width={width}
			icon={<FilterIcon />}
			title={title}
			open={layoutSa.drawerIsOpen}
			onClose={handleClose}
		>
			{children}

			{onReset && <Button color="secondary" sx={{ mt: 3, ml: "-4px", alignSelf: "start" }}
				startIcon={<ResetIcon />}
				onClick={onReset}
			>
				{t("drawer.gantt.reset")}
			</Button>}

		</DrawerCmp>
	);
}

export default FilterDrawer
