import cycleApi, { CycleDraftData, DefineDataDraft } from "api/cycles"
import cycleSo from "stores/cycle"
import { EditCycle } from "stores/cycle/utils/edit"
import { LastDateRange } from "stores/planner/utils/fetch"
import { updatePlannerGroups } from "stores/planner/utils/update"
import querySo from 'stores/route/query'
import taskSo from "stores/task"
import { Cycle } from "types/Cycle"
import { EDIT_TYPE } from "types/Entity"
import { DateRange } from "types/global"
import { delay } from "utils"
import { clone } from "utils/func"
import { DraftStore } from "."
import { SESSION_TYPE, sessionVoid } from "./utils"
import { URL_PAR } from "stores/route/utils/types"
import { DRAFT_STATUS } from "types/Draft"



const cyclesSetup = {

	state: {
	},

	getters: {
		getCycleCanDelete(cycle: Cycle, store?: DraftStore) {
			const session = store.getSessionSelect()
			const draft = store.getDraftById(session?.draftId)
			if (!session || !draft || session.type != SESSION_TYPE.DRAFT
				|| (draft.status != DRAFT_STATUS.INIT && draft.status != DRAFT_STATUS.PROMOTED_FROM_SOLUTION)
			) return false
			return !!cycle && !cycle.rejected && !(cycle._edit?.type == EDIT_TYPE.DELETED)
		}
	},

	actions: {

		/**
		 * Carico i CYCLES del DRAFT ID
		 * e aggiorno (merge) la SESSION corrispodente al DRAFT ID
		 */
		async fetchCycles({ draftId, range }: { draftId?: number, range?: DateRange }, store?: DraftStore) {
			if (!draftId) draftId = store.getSessionSelect()?.draftId

			const session = store.getSessionByDraftId(draftId)
			if (!session || session.type == SESSION_TYPE.PROD) return

			// loading DRAFT
			const drafts = (await cycleApi.indexDraft(draftId, range))?.data ?? []

			// modifico le entities.. se la SESSION da modificare è selezionata in questo momento
			if (session == store.getSessionSelect()) {
				const templates = EditCycle.draftsToTemplate(drafts, cycleSo.state.all)
				EditCycle.merge(cycleSo.state.all, templates)
				store.updateSessionWithStores()
			} else if (session.type == SESSION_TYPE.DRAFT && !sessionVoid(session)) {
				const templates = EditCycle.draftsToTemplate(drafts, session.cycle.all)
				EditCycle.merge(session.cycle.all, templates)
			}
		},

		/**
		 * Sincronizza nel DRAFT un array di CYCLES
		 * CURRENT SESSION, IN PLACE
		 */
		async saveCycles(cycles: Cycle[], store?: DraftStore) {
			if (!cycles || cycles.length == 0) return
			const draftId = store.state.sessions[store.state.select]?.draftId
			const data: CycleDraftData = cycles.reduce<CycleDraftData>((acc, c) => {
				if (!c) return acc
				// se invece c'e' una modifica e non è DELETED allora lo modifico
				if (c._edit) {
					const data = {
						refToCycleUuid: c.refToCycleUuid,
						layers: c.layers,
					}
					acc.scheduled.push(data)
				}
				return acc
			}, { undo: [], rejected: [], scheduled: [] })

			await cycleApi.bulkScheduleDraft(draftId, data)
		},

		/**
		 * Elimina tutti gli UUID di "uuidsDel"
		 * CURRENT SESSION, IN PLACE
		 */
		async deleteCycles(uuidsDel: string[], store?: DraftStore) {
			const draftId = store.state.sessions[store.state.select]?.draftId
			if (!uuidsDel || uuidsDel.length == 0 || !draftId) return
			const data: CycleDraftData = { undo: [], rejected: [], scheduled: [] }
			const cyclesAll = [...cycleSo.state.all]
			let tasksAll = [...taskSo.state.all]

			// preparo lo store e i dati
			for (const uuid of uuidsDel) {
				const index = cyclesAll.findIndex(cycle => cycle.cycleUuid == uuid)
				if (index == -1) continue
				const cycle = cyclesAll[index]

				// se si tratta di un NEW allora ...
				if (cycle._edit && cycle._edit.type == EDIT_TYPE.NEW) {
					data.undo.push({ refToCycleUuid: cycle.refToCycleUuid })
					cyclesAll.splice(index, 1)
					// elimino tutti i TASKS che fanno riferimento ad un CYCLE NEW
					tasksAll = tasksAll.filter(t => t.cycleUuid != uuid)

					// altrimenti ...
				} else {
					data.rejected.push({ refToCycleUuid: cycle.refToCycleUuid })
					if (!cycle._edit) cycle._edit = { type: EDIT_TYPE.DELETED }
					cycle._edit.type = EDIT_TYPE.DELETED
				}
			}

			// effettuo la cancellazione sul BE
			await cycleApi.bulkScheduleDraft(draftId, data)
			cycleSo.setAll(cyclesAll)
			taskSo.setAll(tasksAll)

			// ricarico dopo un po' di tempo tutti i TASKS perche' il BE fa cose
			// se ci sono solo UNDO allora non è necessario ricaricare i CYCLES
			if (data.rejected.length > 0 || data.scheduled.length > 0) {
				await delay(500)
				await store.fetchTasks({ range: LastDateRange })
			}

			// aggiorno il planner
			updatePlannerGroups()
			querySo.setSearchUrl([URL_PAR.CYCLE_SEL, null])
		},

		/** 
		 * Creo il CYCLE nel DRAFT e lo restituisco 
		 * */
		async createCycle(template: DefineDataDraft, store?: DraftStore) {
			const draftId = store.state.sessions[store.state.select]?.draftId
			const { data: newCycle } = await cycleApi.defineDraft(template, draftId)
			if (!newCycle) return null
			newCycle._edit = { type: EDIT_TYPE.NEW }
			if (!newCycle.cycleUuid) newCycle.cycleUuid = newCycle.refToCycleUuid

			cycleSo.setAll([...cycleSo.state.all, newCycle])
			return newCycle
		},

		/** [II] ATTENZIONE AL MOMENTO NONFUNZIONA NON USARE
		 * ripristina lo stato di un CYCLE
		 * DA VERIFICARE per via del fatto che un CYCLE modifica anche i TASKS
		 * */
		async restoreCycle(cycle: Cycle, store?: DraftStore) {
			const draftId = store.state.sessions[store.state.select]?.draftId
			const index = cycleSo.getIndexByUuid(cycle?.cycleUuid)
			if (!draftId || index == -1) return

			const undo = [{ refToCycleUuid: cycle.cycleUuid }]
			const data: CycleDraftData = { scheduled: [], undo, rejected: [] }
			await cycleApi.bulkScheduleDraft(draftId, data)

			const allCycle = [...cycleSo.state.all]
			allCycle[index] = EditCycle.original(cycle)
			cycleSo.setAll(allCycle)
		},

		/** AGGIUNGE a tutte le SESSIONS i CYCLE passati*/
		addCyclesInSessions: (cycles: Cycle[], store?: DraftStore) => {
			for (const session of store.state.sessions) {
				if (sessionVoid(session)) continue
				session.cycle.all = clone(cycles).reduce((acc: Cycle[], cycle: Cycle) => {
					if (session.cycle.all.some(c => c.cycleUuid == cycle.cycleUuid)) return acc
					acc.push(cycle)
					return acc
				}, [...session.cycle.all])
				if (session == store.getSessionSelect()) cycleSo.state.all = session.cycle.all
			}
		},

		/** SETTA TUTTI i "cycles" */
		setCyclesInSessions: (cycles: Cycle[], store?: DraftStore) => {
			// [II] ATTENZIONE per il momento lo faccio solo per la PROD
			const sessionProd = store.getSessionProd()
			if (!sessionProd || sessionProd == store.getSessionSelect()) {
				cycleSo.setAll(cycles)
			} else {
				sessionProd.cycle.all = cycles
			}
			//[II] TO DO aggiornare tutte le session di conseguenza 
			// for (let index = 0; index < store.state.sessions.length; index++) {
			// 	const session = store.state.sessions[index]
			// }
		},

	},

	mutators: {
	},
}

export default cyclesSetup