import dayjs, { Dayjs } from "dayjs"
import i18n from "i18next"
import { Task } from "types/Task"
import { applyTimezone, roundMinute } from "utils"
import { EditTask } from "."



export type TaskStatus = {
	/** tipo rispetto alla sua chiusura */
	type?: DUETIME_TYPE
	/** minuti di ritardo */
	delay?: number
	/** punteggio */
	score?: number
}

/** stati possibili di un TASK */
export enum DUETIME_TYPE {
	CANCELLED,
	CLOSED,
	CLOSED_LATE,
	OPEN_LATE,
	OPEN,
}

/** aggiorno un array di TASK sul loro STATUS */
export function updateTasksStatus(tasks: Task[], timezone?: string): Task[] {
	tasks?.forEach(task => EditTask.setExtra(task, "status", buildTaskStatus(task, timezone)))
	return tasks
}

/** restituisce lo STATUS di un TASK (attingendo dalla cache)*/
export function getTaskStatus(task: Task, timezone?: string): TaskStatus {
	if (!task) return null
	let status = EditTask.getExtra(task, "status") as TaskStatus
	if (!status) {
		status = buildTaskStatus(task, timezone)
		EditTask.setExtra(task, "status", status)
	}
	return status
}

/** restituisce un oggetto che indica il tipo di TASK in relazione con la sua chiusura */
function buildTaskStatus(task: Task, timezone?: string): TaskStatus {
	if (!task) return

	if (task.isCancelled) {
		return {
			type: DUETIME_TYPE.CANCELLED,
			score: 10,
		}

	} else if (task.isClosed) {
		const min = minutesLate(task, timezone)
		if (min > 60) {
			return {
				type: DUETIME_TYPE.CLOSED_LATE,
				delay: min,
				score: 5,
			}
		}
		return {
			type: DUETIME_TYPE.CLOSED,
			delay: min,
			score: 10
		}

	} else if (isPending(task, timezone)) {
		return {
			type: DUETIME_TYPE.OPEN_LATE,
			score: 0,
		}
	}
	return {
		type: DUETIME_TYPE.OPEN,
		score: 10,
	}
}

/** restituisce un testo descrittivo dello stato del TASKS */
export function getTaskStatusDescription(status: TaskStatus): string {
	switch (status.type) {
		case DUETIME_TYPE.CANCELLED:
			return i18n.t("app.task.status.cancelld")
		case DUETIME_TYPE.CLOSED:
			return i18n.t("app.task.status.close")
		case DUETIME_TYPE.CLOSED_LATE:
			return i18n.t("app.task.status.close_late", { min: roundMinute(status.delay) })
		case DUETIME_TYPE.OPEN:
			return i18n.t("app.task.status.open")
		case DUETIME_TYPE.OPEN_LATE:
			return i18n.t("app.task.status.open_late")
		default:
			return ""
	}
}
/** in relazione con la sua chiusura indica se ci sono minuti di ritardo */
function minutesLate(task: Task, timezone?: string) {
	if (!task.isClosed || task.isCancelled || !task.dueTime || !task.dueDate || !task.lastClosedAt) return 0
	const minutes = dayjs(task.lastClosedAt).diff(dueDateTime(task, timezone), "minutes")
	return minutes
}
/** indica se rispetto ad oggi e al suo orario di chiusura non è ancora stato chiuso come dovrebbe */
function isPending(task: Task, timezone?: string) {
	if (task.isClosed || task.isCancelled || !task.dueDate) return false
	return dueDateTime(task, timezone)?.isBefore(dayjs())
}
/** restituisce la data del task tenendo conto del dueTime e dueDate */
function dueDateTime(task: Task, timezone?: string): Dayjs {
	if (!task.dueDate) return null
	let date: Dayjs = null
	if (!task.dueTime) {
		date = dayjs(task.dueDate).endOf('day')
	} else {
		date = applyTimezone(dayjs(`${task.dueDate}T${task.dueTime}`), timezone)
	}
	return date
}

