
export function groupBy<T>(items: T[], cb: (item: T) => string): { [key: string]: T[] } {
	if (!items) return {}
	return items.reduce((acc, item) => {
		const key = cb(item)
		if (acc[key] == null) acc[key] = []
		acc[key].push(item)
		return acc
	}, {})
}

export function forObject<T, K>(object: { [key: string]: K }, cb: (key:string, value: K) => T): { [key: string]: T } {
	const newObject = {}
	for ( const [key, value] of Object.entries(object)) {
		newObject[key] = cb(key, value)
	}
	return newObject
}

export function moveTo<T>(items: T[], from: number, to: number): T[] {
	if (items.length < 2) return items
	if (to < 0) to = 0
	if (from < 0) from = 0
	if (to > items.length - 1) to = items.length - 1
	if (from > items.length - 1) from = items.length - 1
	if (from == to) return items
	let tmp = items[from]
	items[from] = items[to]
	items[to] = tmp
	return items
}
