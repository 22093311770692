import dayjs from "dayjs"
import { Cycle } from "types/Cycle"
import { EDIT_TYPE } from "types/Entity"
import { Task } from "types/Task"
import { dateToString } from "utils"
import { groupBy } from "utils/array"
import { Edit } from "utils/entity"



/** 
 * in base alle modifiche ad un CYCLE aggiorna le date dei TASKS a lui collegati 
 * */
export function updateDateFromCycle(cycle: Cycle, cycleNext: Cycle, tasks: Task[]): void {

	// layer in posizione prima del drag
	const layerPhaseNow = groupBy(cycle.layers, layer => layer.phase)
	// layer nella posizione DOPO il drag
	const layerPhaseNext = groupBy(cycleNext.layers, layer => layer.phase)

	// la differenza in giorni tra due date string
	const getOffset = (date1: string, date2: string) => dayjs(date1).diff(date2, "days")
	const setOffset = (task: Task, offset: number) => EditTask.modify(task, { dueDate: dateToString(dayjs(task.dueDate).add(offset, "days")) })
	const offsetSeeding = getOffset(cycleNext.seedingDate, cycle.seedingDate)
	const offsetHarvesting = getOffset(cycleNext.harvestingDate, cycle.harvestingDate)

	outerLoop: for (const task of tasks) {

		// per tutte le PHASES dei CYCLES ...
		for (const [phase, layers] of Object.entries(layerPhaseNow)) {
			// prendo in considerazione solo il primo LAYER
			const layerNow = layers[0]
			const layerNext = layerPhaseNext[phase][0]
			const offsetFrom = getOffset(layerNext.busyFrom, layerNow.busyFrom)
			const offsetTo = getOffset(layerNext.busyTo, layerNow.busyTo)

			if (dayjs(task.dueDate).isSame(layerNow.busyFrom, "day")) {
				const node = EditTask.setExtraIfNull(task, "node", "busyFrom")
				if (node == "busyFrom") {
					setOffset(task, offsetFrom)
					continue outerLoop
				}
			}
			if (dayjs(task.dueDate).isSame(layerNow.busyTo, "day")) {
				const node = EditTask.setExtraIfNull(task, "node", "busyTo")
				if (node == "busyTo") {
					setOffset(task, offsetTo)
					continue outerLoop
				}
			}
		}

		if (dayjs(task.dueDate).isBefore(cycle.seedingDate, "day")) {
			const node = EditTask.setExtraIfNull(task, "node", "seeding")
			if (node == "seeding") {
				setOffset(task, offsetSeeding)
				continue
			}
		}

		if (dayjs(task.dueDate).isAfter(cycle.harvestingDate, "day")) {
			const node = EditTask.setExtraIfNull(task, "node", "harvesting")
			if (node == "harvesting") {
				setOffset(task, offsetHarvesting)
				continue
			}
		}

		EditTask.setExtraIfNull(task, "node", "block")
	}

}

/** in base ad un "template" modifico un array di TASKS (mi serve per il MULTI EDIT)*/
export function multiModifyByTemplate(tasks: Task[], template: Partial<Task>): Task[] {
	for (const task of tasks) {
		EditTask.modify(task, template)
		// mi assicuro che il "tottalPoints" sia corretto
		task.totalPoints = task.points + task.trolleysPoints
	}
	return tasks
}

export class EditTask extends Edit {

	static PropIdName = "taskUuid"

	/** per i TASK è usato per il MULTIEDIT */
	static COMMON = [
		"scope",
		"subject",
		"description",
		"points",
		"trolleysPoints",
		"updatesYield",
		"cancelIfCycleRejected",
		"dueDate",
		"dueTime",
	]

	// static equal<T extends Entity<T>>(t1: T & Task, t2: T & Task): boolean {
	// 	if (t1 == t2) return true
	// 	if (t1 == null || t2 == null) return false
	// 	return t1.relatedTo == t2.relatedTo
	// 		&& t1.farmId == t2.farmId
	// 		&& t1.growUnitId == t2.growUnitId
	// 		&& t1.cycleUuid == t2.cycleUuid
	// 		&& t1.subject == t2.subject
	// 		&& t1.scope == t2.scope
	// 		&& t1.description == t2.description
	// 		&& t1.dueDate == t2.dueDate
	// 		&& t1.dueTime == t2.dueTime
	// 		&& t1.trolleysPoints == t2.trolleysPoints
	// 		&& t1.points == t2.points
	// 		&& t1.updatesYield == t2.updatesYield
	// 		&& t1.cancelIfCycleRejected == t2.cancelIfCycleRejected
	// 		&& t1.subtasks == t2.subtasks
	// }

	/** per i CYCLE setto solo alcune proprietà */
	// static set<T>(target: T, template: Partial<T>, deep?: boolean) {
	// 	for (const key in template) {
	// 		if (!deep && key == "_edit") continue
	// 		if ( key == "subtasks" && template["subtasks"] == null ) continue
	// 		target[key] = template[key]
	// 	}
	// }
	
	/** trasforma DRAFT-TASKS in TASKS per essere inseriti nello store "entities" */
	static draftsToTemplate(drafts: Task[], entities: Task[]): Task[] {
		return drafts.reduce((acc: Task[], draft: Task) => {

			// cerco il DRAFT
			const index = entities.findIndex(task => task.taskUuid == draft.refToTaskUuid)

			// è la modifica di un TASK gia' esistente in PRODUZIONE				
			if (index != -1) {
				// è una MODIFICA "cancelled"
				if (draft.isCancelled) {
					draft._edit = { type: EDIT_TYPE.DELETED }
				}

				// si tratta di un nuovo DRAFT
			} else {
				// se è un "cancelled" è una situazione "non corretta"
				if (draft.isCancelled) return acc
				draft._edit = { type: EDIT_TYPE.NEW }
			}

			// unifico gli uuid
			draft.taskUuid = draft.refToTaskUuid
			acc.push(draft)
			return acc
		}, [] as Task[])
	}

}