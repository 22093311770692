
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import Paragraph from 'components/controls/form/Paragraph';
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import dialogSo from "stores/cycle/newDialog";
import { getGrowUnitLayerName, getLayerByNumber } from "stores/growUnit/utils/layer";
import { groupLayersByPhases } from "stores/phase/layers";
import { PHASE_SLUG, getPhaseLabel } from "stores/phase/utils";
import { Crop } from "types/Crop";
import { GrowUnit } from "types/GrowUnit";
import { Layer } from "types/Layer";
import { PhaseString } from "types/global";
import { ChipLayer } from "./ChipLayer";



interface Props {
	growUnit?: GrowUnit
	crop?: Crop
	layerOvelap?: Layer[]
	flex?: number
}
/**
 * Permette di selezionare i layer da utilizzare nel NUOVO CYCLE
 * i layer sono presi dalla FARM tramite le PHASEs del CROP
 */
const CycleLayersSelector: FunctionComponent<Props> = ({
	growUnit,
	crop,
	layerOvelap,
	flex = 20,
}) => {

	// STORE
	useStore(dialogSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLEs
	// selezione dei LAYERS per una PHASE
	const handleLayerClick = (e: React.MouseEvent, phase: string, layerNumber: number) => {
		if (e.buttons == 0) return
		const select = !dialogSo.getSelected({ phase, layerNumber })
		dialogSo.select({ phase, layerNumber, select })
	}
	const handlePhaseClick = (phase: string, layerNumber?: number) => {
		const select = layerNumber != null
			? !dialogSo.getSelected({ phase, layerNumber })
			: !(dialogSo.state.layersSelected[phase]?.length > 0)
		dialogSo.setLayersSelected({
			...dialogSo.state.layersSelected,
			[phase]: select ? layersGroup[phase].map(l => l.layerNumber) : []
		})
	}

	// RENDER


	// prelevo tutte le PHASES name del CROP evitando PRE e POST cycle
	const cropPhases = useMemo(() => crop?.phases
		?.map(phaseObj => phaseObj.phase)
		.filter(phase => phase != PHASE_SLUG.PRE_CYCLE && phase != PHASE_SLUG.POST_CYCLE)
		, [crop])
	const layersGroup = useMemo(() => groupLayersByPhases(growUnit?.desiredLayersState?.layers), [growUnit?.desiredLayersState?.layers])

	if (!growUnit || !crop) return null
	if (!cropPhases || cropPhases.length == 0) return null

	const getRatio = (phase: string) => crop.rearrangementSchema.find(r => r.phase == phase)?.ratio ?? ""
	const getNameLayer = (layerNumber: number) => getGrowUnitLayerName(growUnit, getLayerByNumber(growUnit, layerNumber))
	const isSelected = (phase: PhaseString, layerNumber: number) => dialogSo.getSelected({ phase, layerNumber })
	const isOverlap = (layerNumber: number) => layerOvelap.some(layer => layer.layerNumber == layerNumber)

	return <>
		<Box sx={{ display: "flex", alignItems: "center", mt: 2 }} >
			<Typography variant="caption" sx={{ flex: flex}}>
				{t("pag.cycle.selector.phases-ratio")}
			</Typography>
			<Typography variant="caption" sx={{ flex: 100}}>
				{t("pag.cycle.selector.layers")}
			</Typography>
		</Box>
		<Divider sx={{ my: 1 }} />

		{cropPhases.map(phase => (
			<Paragraph key={phase} flex={flex}
				title={<Box sx={{ cursor: "pointer" }} onClick={() => handlePhaseClick(phase)}>
					<span style={{ textTransform: "uppercase", fontWeight: "900" }}>{getPhaseLabel(phase)}</span>
					<span style={{ marginLeft: "5px", opacity: .7 }}>{getRatio(phase)}</span>
				</Box>}
			>
				<Box key={phase} sx={cssRow}>
					{layersGroup[phase]?.map((layer) => (

						<ChipLayer key={layer.layerNumber}
							label={getNameLayer(layer.layerNumber)}
							selected={isSelected(phase, layer.layerNumber)}
							disabled={layer.disabled}
							overlap={isOverlap(layer.layerNumber)}
							onClick={(e) => handleLayerClick(e, phase, layer.layerNumber)}
							onDClick={() => handlePhaseClick(phase, layer.layerNumber)}
						/>

					))}
				</Box>
			</Paragraph>
		))}

	</>
}

export default CycleLayersSelector


const cssRow = {
	display: "flex",
	margin: "5px 0px",
	flexWrap: "wrap",
	gap: "6px",
}

