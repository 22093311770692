import { InputBase, InputBaseProps } from "@mui/material";
import React, { ForwardRefRenderFunction, forwardRef } from "react";
import WrapControl, { ICON_VARIANT } from "../base/WrapControl";



interface Props extends InputBaseProps {
	value?: any
	helperText?: string
	sx?: any

	sxInput?: any

	min?: number
	max?: number
	decimals?: number
	defaultIfNaN?: number

	placeholder?: string
	onChangeNumber?: (value: any) => void
}

const NumberField: ForwardRefRenderFunction<any, Props> = ({
	value,
	helperText,
	sx,
	sxInput,

	min,
	max,
	decimals,
	defaultIfNaN,

	placeholder,
	onChangeNumber,
	...props
}, ref) => {

	// HANDLERS
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let newValue = format(e.target.value, decimals, min, max)
		onChangeNumber(newValue)
	}
	const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		const target = e.target
		setTimeout(() => target.select(), 0)
	}
	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if ( defaultIfNaN == null ) return
		const valueNum = Number.parseFloat(value)
		if (isNaN(valueNum)) {
			onChangeNumber(defaultIfNaN)
		} else {
			onChangeNumber(valueNum)
		}
	}

	// RENDER
	return (
		<WrapControl sx={sx}
			placeholder={placeholder}
			helperText={helperText}
			variantIcon={ICON_VARIANT.NONE}
		>
			<InputBase fullWidth
				value={value}
				inputProps={{ sx: sxInput }}
				autoComplete='off'
				ref={ref}
				type="number"
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				//endAdornment="lt"
				{...props}
			/>
		</WrapControl>
	)
}

export default forwardRef(NumberField)

function format(value: string, decimals?: number, min?: number, max?: number): any {
	if (value == null) return null
	let valueNum = parseFloat(value)

	if (decimals != null && (value.includes(".") || value.includes(","))) {
		//value = parseFloat(value).toString()//.toFixed(decimals)
		value = value.replace(/,/g, ".");
		const index = value.indexOf(".")
		if (index != -1 && (value.length - index - 1) > decimals) {
			value = value.slice(0, index + decimals + 1)
		}
	}

	if (min != null && valueNum < min) value = min.toString()
	if (max != null && valueNum > max) value = max.toString()
	return value
}
