import { ACTIVITY_TYPE, Activity, GraphGroup } from "components/planner/types"
import { getAlertScoreLabel, getAlertColor, getAlertLevel, groupByDate } from "stores/alert/utils"
import { Alert } from "types/Alert"


/**
 * trasforma "tasks" in ACTIVITIES, quindi crea un GROUP-ACTIVITIES e lo restituisce
 * usato per la visualizzazione sul GANTT
 */
export function buildAlertsGroup(alerts: Alert[] = []): GraphGroup {
	// raggruppo i task per "data"
	const groups = groupByDate(alerts)
	
	// le "activities" del GROUP
	const activities = groups.reduce((acts, alertsByDate, index) => {
		const activity = {
			type: ACTIVITY_TYPE.LABEL,
			start: alertsByDate.date,
			label: getAlertScoreLabel(alertsByDate.score),
			color: getAlertColor(getAlertLevel(alertsByDate.score)),
			value: alertsByDate.score,
			row: 0,
		} as Activity
		acts.push(activity)
		return acts
	}, [] as Activity[])

	return {
		name: "alerts",
		title: "ALERTS",
		collapsed: false,
		labels: ["SCORE"],
		activities: activities,
		unselectable: true,
		noCompact: true,
	}
}


