import { Cycle } from "types/Cycle"
import { DateRange } from "types/global"
import { dateWithoutTime, haveValues, includes } from "utils"
import { getCycleStatus } from "./cycle"
import { CYCLE_STATUS } from "./enums"



/** restituisce i CYCLES filtrati secondo parametri */
export function getCycleFiltered(cycles: Cycle[], txt?: string, dateRange?: DateRange, growUnitId?: number, noPlanned?: boolean, noRunning?: boolean, noHarvested?: boolean, rejected?: boolean): Cycle[] {
	cycles = cycles.filter(cycle => {

		if (haveValues(dateRange)) {
			const cycleRange = { start: dateWithoutTime(cycle.seedingDate), end: dateWithoutTime(cycle.harvestingDate) }
			if (!includes(cycleRange, dateRange)) return false
		}

		if (!!growUnitId && cycle.growUnitId != growUnitId) return false

		const text = !txt || txt.length == 0
			// se txt c'e' nel nomde del CROP-LOT o nel sui codice
			|| cycle.cropLots?.some(cl => cl.cropName?.toLowerCase().includes(txt) || cl.lotCode?.includes(txt))
			// se txt c'e' nel uuid del CYCLE
			|| cycle.cycleUuid?.toLowerCase().includes(txt)
		if (!text) return false

		const status = getCycleStatus(cycle)
		if (
			(status == CYCLE_STATUS.PLANNED && noPlanned)
			|| (status == CYCLE_STATUS.RUNNING && noRunning)
			|| (status == CYCLE_STATUS.HARVESTED && noHarvested)
			|| (status == CYCLE_STATUS.REJECTED && !rejected)
		) return false

		return true
	})
	return cycles
}
export type CheckOptions = {
	planned: boolean,
	running: boolean,
	harvested: boolean,
	rejected: boolean
}
