import { createStore, StoreCore } from "@priolo/jon"


/**
 * gestisce il drower del MAIN-MENU
 */
const setup = {

	state: {
		isOpen: true,
		isCompact: false,
	},

	getters: {
	},

	actions: {
	},

	mutators: {
		setIsOpen: (isOpen: boolean) => ({ isOpen }),
		toggleOpen: (_: void, store?: MenuDrawerStore) => ({ isOpen: !store.state.isOpen }),
		setIsCompact: (isCompact: boolean) => ({ isCompact }),
		toggleCompact: (_: void, store?: MenuDrawerStore) => ({ isCompact: !store.state.isCompact }),
	},
}

export type MenuDrawerState = typeof setup.state
export type MenuDrawerGetters = typeof setup.getters
export type MenuDrawerActions = typeof setup.actions
export type MenuDrawerMutators = typeof setup.mutators
export interface MenuDrawerStore extends StoreCore<MenuDrawerState>, MenuDrawerGetters, MenuDrawerActions, MenuDrawerMutators {
	state: MenuDrawerState
}
const drawerSo = createStore(setup) as MenuDrawerStore
export default drawerSo
