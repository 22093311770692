import { Close, Replay, Timeline } from '@mui/icons-material'
import { Box, Card, CircularProgress, Drawer, IconButton, SxProps, Typography } from '@mui/material'
import { useStore } from '@priolo/jon'
import ResizerCmp from 'components/ResizerCmp'
import ButtonIcon from 'components/controls/buttons/ButtonIcon'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import grafanaSo from 'stores/grafana'
import theme from 'styles/theme'



const GrafanaDrawer: FunctionComponent = () => {

	// STORE
	const grafanaSa = useStore(grafanaSo)

	// HOOKs
	const [loading, setLoading] = useState(true)
	const [hide, setHide] = useState(true)
	const [disabled, setDisabled] = useState(false)
	const iframeRef = useRef<HTMLIFrameElement>(null)


	useEffect(() => {
		if (!grafanaSa.open) {
			setTimeout(() => setHide(true), 400)
		} else {
			setHide(false)
		}
	}, [grafanaSa.open])

	const ref = useCallback((node: HTMLIFrameElement) => {
		iframeRef.current = node
		if (!node) return
		setLoading(true)
		node.onload = () => setLoading(false)
	}, [])

	// HANDLERs
	const handleClose = () => {
		grafanaSo.close()
	}
	const handleReload = () => {
		iframeRef.current.src = grafanaSa.url
	}
	const handleDragStart = (pos: number) => {
		setDisabled(true)
		return grafanaSa.heigth
	}
	const handleDragMove = (pos: number, diff: number) => {
		grafanaSo.setHeigth(pos + diff)
	}
	const handleDragStop = () => {
		setDisabled(false)
	}

	// RENDER
	return (
		<Drawer
			sx={sxDrawer}
			variant="persistent"
			anchor="bottom"
			open={grafanaSa.open}
			onClose={handleClose}
		>
			<ResizerCmp
				sx={{ display: "flex", alignItems: "center", mx: 1, gap: 1, cursor: "row-resize" }}
				onStart={handleDragStart}
				onMove={handleDragMove}
				onStop={handleDragStop}
			>

				<Timeline />

				<Typography sx={{ flex: 1 }}
					variant="h6"
				>{grafanaSa.title}</Typography>

				<ButtonIcon
					tooltip='RELOAD'
					onClick={handleReload}
				><Replay /></ButtonIcon>

				<ButtonIcon sx={{ ml: -2 }}
					onClick={handleClose}
				><Close /></ButtonIcon>

			</ResizerCmp>


			<Box sx={{ position: "relative", height: grafanaSa.heigth }}>

				<Box sx={sxLoaderBox(loading)}>
					<Card sx={sxLoader} elevation={0}>
						<CircularProgress />
						<Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>LOADING TIME SERIES</Typography>
					</Card>
				</Box>

				{!hide && (
					//<Box sx ={{ height: "100%", width: "100%", backgroundColor: "#FF00FF"}}/>
					<iframe
						draggable={false}
						contentEditable={false}
						tabIndex={-1}
						ref={ref}
						title="dialog-link"
						style={cssIFrame(loading, disabled)}
						src={grafanaSa.url}
					/>
				)}

			</Box>

		</Drawer>
	)
}

export default GrafanaDrawer

const sxDrawer: SxProps = {
	zIndex: theme.app.menu.zIndex + 1,
	".MuiDrawer-paper": {
		bgcolor: theme.palette.background.header,
		overflowY: "hidden"
	}
}

const cssIFrame = (hide: boolean, disabled: boolean): React.CSSProperties => ({
	height: "100%",
	width: disabled ? "calc( 100% - 1px)" : "100%",
	border: "none",

	pointerEvents: disabled ? "none" : null,
	userSelect: disabled ? "none" : null,

	opacity: hide ? 0 : 1
})



const sxLoaderBox = (hide: boolean): SxProps => ({
	position: "absolute",
	width: "100%",
	height: "100%",
	top: 0,
	left: 0,
	display: hide ? "flex" : "none",
	alignItems: 'center',
	justifyContent: 'center',
})

const sxLoader: SxProps = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: 200,
	p: 4,
	outline: 0,
}
