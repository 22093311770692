import TextField from '@mui/material/TextField';
import { DesktopDatePicker /*MobileDatePicker*/ } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { FunctionComponent } from 'react';
import palette from 'styles/palette';



const DatePicker:FunctionComponent<any> = ({
	sx,
	...props
}) => {
	return <LocalizationProvider dateAdapter={AdapterDayjs}>
		<DesktopDatePicker
			inputFormat="DD/MM/YYYY"
			renderInput={(params) => <TextField {...params} 
				sx={[cssInput,sx]}
				InputLabelProps={{ sx: { top: '-9px' } }}
			/>}
			{...props}
		/>
	</LocalizationProvider>
}

export default DatePicker

const cssInput = {
	backgroundColor: palette.background.default,
	borderRadius: "10px",
	maxWidth: "146px",
}