import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import farmSo from "stores/farm"
import layoutSo from "stores/layout"
import locationSo from "stores/route/location"
import { PAGE_ID } from "stores/route/utils/types"
import { Farm } from "types/Farm"



/**
 * carico i dati comuni sotto la sezione FARM
 * @param idPage l'ID_PAGE attuale
 * @param farmIdDefault da usare se non c'e' nell'URL
 * @param callback funzione chiamata quando i dati della FARM sono disponibili
 * @returns l'id della farm che si sta caricando
 */
export function useInitFarmPage(idPage: PAGE_ID, farmIdDefault?: number, callback?: (farm: Farm) => void): number {

	// STOREs

	// HOOKs
	const { t } = useTranslation()
	const { id: farmIdStr } = useParams<{ id?: string }>()
	const farmId = farmIdStr != null ? Number.parseInt(farmIdStr) : farmIdDefault

	useEffect(() => {
		if (!farmId) return

		const load = async () => {
			await farmSo.fetchSelectIfVoid(farmId)
			if (!farmSo.state.select) return
			locationSo.updatePageId(idPage)
			layoutSo.setTitle(t(`pag.titles.${idPage}`, { name: farmSo.state.select.name }))
			callback?.(farmSo.state.select)
		}
		load()
	}, [farmId, idPage])

	return farmId
}
