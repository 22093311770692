import { GraphGroup } from "components/planner/types"
import { Cycle } from "types/Cycle"
import { GrowUnit } from "types/GrowUnit"
import { activitiesFromCycles } from "../utils/cycle"
import { buildGrowUnitsGroup } from "./growUnitsGroup"
import { activitiesSetOverlap } from "components/planner/utils"



/** crea il GROWING-GROUP per dei CYCLES */
export function buildGrowUnitsCyclesGroup(cycles: Cycle[], growUnits: GrowUnit[]): GraphGroup {
	const group = buildGrowUnitsGroup(
		growUnits,
		(gu, phasesLayers) => {
			// trasformo i CYCLES in ACTIVITIES
			const activities = activitiesFromCycles(cycles, phasesLayers, gu.id)
			activitiesSetOverlap(activities)
			return activities
		}
	)
	return group
}
