import { StoreCore, createStore, resetAll } from "@priolo/jon";
import reqApi from "api/productions_request";
import prodSo from "stores/productions";
import { Production, REQUEST_STATUS, Request, RequestConstraints, RequestLayer } from "types/Production";
import { Uuid } from "types/global";
import { insertIndex } from "utils/humanize";
import { PROD_SHOW, buildRequest } from "./utils";
import querySo from "stores/route/query";
import { URL_PAR } from "stores/route/utils/types";



/**
 * Gestione lista di REQUEST
 */
const setup = {

	state: {
		all: <Request[]>null,
		/**la REQUEST selezionata */
		selectUuid: <string>querySo.getSearchUrl(URL_PAR.REQUEST_SEL),

		/** REQUEST in editazione */
		inEdit: <Request>null,
		/** se la dialog di edit è aperta */
		editOpen: false,
		/** se la REQUEST "in edit" è in sola lattura */
		readOnly: false,


	},

	getters: {
		getByUuid: (uuid: string, store?: RequestStore) => store.state.all.find(r => r.uuid == uuid),
		getIndexByUuid: (uuid: string, store?: RequestStore) => store.state.all.findIndex(r => r.uuid == uuid),
	},

	actions: {
		fetchByProd: async (production: Production, store?: RequestStore) => {
			if (!production) return
			const { data } = await reqApi.index(production)
			store.setAll(data)
		},
		show: (request: Request, store?: RequestStore) => {
			store.setInEdit(request)
			store.setReadOnly(true)
			store.setEditOpen(true)
		},
		/** 
		 * seleziona una REQUEST ed eventualmente la sua PRODUCTION
		 * se uuid non è specificato 
		 */
		select: (uuid?: Uuid, store?: RequestStore) => {
			prodSo.setShow(PROD_SHOW.PRODUCTIONS)
			// eventualmente seleziono anche PRODUCTION
			if (!!uuid) {
				const production = prodSo.getByReqUuid(uuid)
				prodSo.setSelectUuid(production.uuid)
			}
			store.setSelectUuid(uuid)
		},
		/** apre la dialog per creare una nuova REQUEST */
		create: (production?: Production, store?: RequestStore) => {
			if (!production) production = prodSo.getSelect()
			prodSo.select(production.uuid)
			prodSo.expand(production.uuid)
			const request: Request = buildRequest(production)
			store.setReadOnly(false)
			store.setInEdit(request)
			store.setEditOpen(true)
		},
		/** apre la dialog edit con il clone di un altra request */
		clone: async (request: Request, store?: RequestStore) => {
			const prodClone: Request = {
				...request,
				name: insertIndex(request.name),
				constraints: { ...request.constraints },
				uuid: null,
				status: REQUEST_STATUS.PENDING,
				solutions: [],
			}
			store.setReadOnly(false)
			store.setInEdit(prodClone)
			store.setEditOpen(true)
		},
		/** chiudo ed eventualmente salvo la NUOVA REQUEST */
		closeEdit: async (save: boolean, store?: RequestStore) => {
			store.setEditOpen(false)
			if (save) {
				const production = prodSo.getByUuid(store.state.inEdit.productionUuid) ?? prodSo.getSelect()
				if (!production) return
				// sistemo la request in edit
				if (store.state.inEdit.products?.length > 0) store.state.inEdit.crops = []
				else store.state.inEdit.products = []
				const { data: requestNew } = await reqApi.create(production, store.state.inEdit)
				production.requests = [...production.requests, requestNew]
				prodSo.setAll([...prodSo.state.all])
				store.setAll([...store.state.all, requestNew])
				store.setSelectUuid(requestNew.uuid)
			}
			resetAll()
		},

		delete: async (reqUuid: string, store?: RequestStore) => {
			const production = prodSo.getSelect()
			await reqApi.destroy(production, reqUuid)
			store.remove(reqUuid)
		},
		remove: async (reqUuid: string, store?: RequestStore) => {
			const production = prodSo.getSelect()
			production.requests = production.requests.filter(r => r.uuid != reqUuid)
			prodSo.setAll([...prodSo.state.all])
			store.setAll(store.state.all.filter(r => r.uuid != reqUuid))
		},
		move: async ({ from, to, reqUuid }: { from: string, to: string, reqUuid: string }, store?: RequestStore) => {
			const request = store.getByUuid(reqUuid)
			const prodTo = prodSo.getByUuid(to)
			await reqApi.move(prodSo.getByUuid(from), to, reqUuid)
			store.remove(reqUuid)
			prodTo.requests.push(request)
			prodSo.setAll([...prodSo.state.all])
			store.setSelectUuid(null)
		},
		update: async (request: Request, store?: RequestStore) => {
			if (!request) return
			const index = store.getIndexByUuid(request.uuid)
			const all = [...store.state.all]
			if (index == -1) return
			all[index] = { ...all[index], ...request }
			store.setAll(all)
			// aggiorno pure le PRODUCTION
			prodSo.updateRequest(request)
		},
	},
	mutators: {
		setAll: (all: Request[]) => ({ all }),
		setSelectUuid: (selectUuid: string) => {
			querySo.setSearchUrl([URL_PAR.REQUEST_SEL, selectUuid])
			return { selectUuid }
		},

		setInEdit: (inEdit: Request) => ({ inEdit }),
		setInEditProp: (prop: Partial<Request>, store?: RequestStore) => ({ inEdit: { ...store.state.inEdit, ...prop } }),
		setInEditConstraints: (prop: Partial<RequestConstraints>, store?: RequestStore) => ({
			inEdit: {
				...store.state.inEdit,
				constraints: { ...store.state.inEdit.constraints, ...prop },
			}
		}),
		setInEditLeyers: (layers: RequestLayer[], store?: RequestStore) => {
			const inEdit = { ...store.state.inEdit }
			inEdit.constraints.layers = layers
			return { inEdit }
		},

		setEditOpen: (editOpen: boolean) => ({ editOpen }),
		setReadOnly: (readOnly: boolean) => ({ readOnly }),
	},
}



export type RequestState = typeof setup.state
export type RequestGetters = typeof setup.getters
export type RequestActions = typeof setup.actions
export type RequestMutators = typeof setup.mutators
export interface RequestStore extends StoreCore<RequestState>, RequestGetters, RequestActions, RequestMutators {
	state: RequestState
}
const requestSo = createStore(setup) as RequestStore
export default requestSo
