import { Subscription, createConsumer } from "@rails/actioncable"
import humps from "humps"



const isPLC = import.meta.env.VITE_TARGET == "plc"
const cableUrl = import.meta.env.VITE_API_CABLE_HOST ?? "/cable"
const consumer = createConsumer(cableUrl)

export enum CHANNELS {
	EVENTS = "EventChannel",
	FARMS = "FarmStatusChannel",
	GROWUNITS = "GrowUnitStatusChannel",
	/** PRODUCTION REQUEST */
	REQUESTS = "PlannerCyclesRequestStatusChannel",
	DRAFT = "PlannerCyclesDraftStatusChannel",
}

export class Channel {
	name: string
	options: {}
	listeners: Listener[]
	channel: Subscription

	constructor(name: string, options: any = {}) {
		this.name = name
		this.options = options
		this.listeners = []
		this.channel = null
	}

	createChannel() {
		if ( isPLC ) return
		this.channel = consumer.subscriptions.create(
			{
				channel: this.name,
				...this.options
			},
			{
				received: this.onReceiveData.bind(this)
			}
		)
	}

	destroyChannel() {
		if (!this.channel || isPLC) return
		this.channel.unsubscribe()
		this.listeners = []
		this.channel = null
	}

	onReceiveData(data: any) {
		data = humps.camelizeKeys(data)
		this.listeners.forEach(listener => listener(data))
	}

	subscribe(listener: Listener) {
		if ( isPLC ) return
		this.listeners.push(listener)
		if (this.channel == null) this.createChannel()
	}

	unsubscribe(listener: Listener) {
		if ( isPLC ) return
		this.listeners = this.listeners.filter(l => l != listener)
		if (this.listeners.length == 0) this.destroyChannel()
	}

}

export const eventChannel = new Channel(CHANNELS.EVENTS)

type Listener = (data: any) => void
