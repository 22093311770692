import React from 'react';
import { Menu, MenuItem } from '@mui/material';

import contextSo from 'stores/layout/contextMenu';
import { useStore } from '@priolo/jon';


function ContextMenu() {

	// STORE
	const contextSa = useStore(contextSo)

	// HANDLERs
	const handleClickItem = item => {
		contextSo.setMenuPos(null)
		item.onClick()
	}
	const handleClose = e => {
		contextSo.setMenuPos(null)
	}
	const handleContextMenu = e => {
		e.preventDefault()
	}


	// RENDER
	const isOpen = contextSa.menuPos != null

	return (
		<Menu
			keepMounted
			open={isOpen}
			onClose={handleClose}
			anchorReference="anchorPosition"
			anchorPosition={contextSa.menuPos !== null ? { top: contextSa.menuPos.y, left: contextSa.menuPos.x } : undefined}
			onContextMenu={handleContextMenu}
		>
			{contextSa.menuItems.map((item, index) => (
				<MenuItem key={index}
					disabled={item.disabled}
					onClick={() => handleClickItem(item)}
				>{item.label}</MenuItem>
			))}

		</Menu>
	)
}

export default ContextMenu
