import dayjs from "dayjs"
import i18n from "i18next"
import { Cycle } from "types/Cycle"
import { Layer } from "types/Layer"
import { DateNumber, DateRange, DateString } from "types/global"
import { DAY_MILLISEC, dateRangeStringToDateRange, dateToString, isBetween } from "utils"
import { CYCLE_STATUS } from "./enums"
import { Crop } from "types/Crop"
import { LayersNumberByPhase } from "./layer"
import { PHASE_SLUG } from "stores/phase/utils"
import { EDIT_TYPE } from "types/Entity"



/** CYCLE_STATUS: return the status of CYCLE of fn parameter. If is null the CYCLE in edit  */
export function getCycleStatus(cycle: Cycle): CYCLE_STATUS {
	if (cycle == null) return CYCLE_STATUS.UNKNOWN
	if (cycle.id == null) return CYCLE_STATUS.NEW
	if (cycle.yield != null) return CYCLE_STATUS.HARVESTED
	if (cycle.rejected == true || cycle._edit?.type == EDIT_TYPE.DELETED) return CYCLE_STATUS.REJECTED
	if (dayjs(cycle?.seedingDate) < dayjs()) return CYCLE_STATUS.RUNNING
	return CYCLE_STATUS.PLANNED
}


// string: return the status label of CYCLE in editing
export function getCycleStatusLabel(cycleStatus: CYCLE_STATUS): string {
	return {
		[CYCLE_STATUS.UNKNOWN]: "Unknown state",
		[CYCLE_STATUS.PLANNED]: "Planned",
		[CYCLE_STATUS.RUNNING]: "Running",
		[CYCLE_STATUS.HARVESTED]: "Harvest",
		[CYCLE_STATUS.REJECTED]: "Rejected",
	}[cycleStatus]
}


// [TABLE] Restituisce il numero di LAYERs coinvolti nell'harvest
export function getLayersInHarvest(cycle: Cycle): number {
	return cycle.layers
		?.filter(layer => layer.busyTo == cycle.harvestingDate).length
}

// [TABLE] quello che deve essere visualizzato nella colonna "PLANNED YIELD" della tabella
export function getPlannedYield(cycle: Cycle): string {
	return cycle.plannedYield ? (+cycle.plannedYield).toFixed(2) : "--"
}

// [TABLE] quello che deve essere visualizzato nella colonna "YIELD" della tabella
export function getYield(cycle: Cycle): string {
	if (cycle.rejected) return i18n.t("pag.cycle.index.rejected")
	if (cycle.yield) return (+cycle.yield).toFixed(2)
	return "--"
}

/** restituisce il nome composto di tutti i CROP dei "crop_lots" */
export function getCropNames(cycle: Cycle): string {
	return cycle?.cropLots?.map(c => c.cropName).join(", ") ?? cycle?.cropName ?? "--"
}

/** 
 * cerco il CYCLE di uno specifico `LayerNumber` che ha al suo interno `date` 
 * non considera i CYCLES REYECTED
*/
export function getCycleByLayerNumberAndDate(cycles: Cycle[], date: DateNumber, layerNumber: number): CycleLayerResponse {
	if (!cycles || date == null || layerNumber == null) return { cycle: null, layer: null }

	let layer: Layer = null
	const cycle = cycles.find(cycle => cycle.layers.some(l => {
		if (cycle.rejected) return false
		const range = dateRangeStringToDateRange({ start: l.busyFrom, end: l.busyTo })
		if (l.layerNumber == layerNumber && isBetween(date, range)) layer = l
		return layer
	})) ?? null
	return { cycle, layer }
}
type CycleLayerResponse = {
	cycle: Cycle,
	layer: Layer
}


/** 
 * Indica il giorno in cui ci si trova nel CYCLE e i giorni totali da fare
 * eventualmente indica i giorni che mancano all'inizio del CYCLE
 * */
export function getLabelCycleDayStatus(cycle: Cycle) {
	const status = getCycleStatus(cycle)
	if (status == CYCLE_STATUS.UNKNOWN || status == CYCLE_STATUS.HARVESTED || status == CYCLE_STATUS.REJECTED) {
		return "--"
	}
	const firstDate = new Date(cycle.seedingDate);
	const today = new Date();
	// aggiungo 1 cosi' non mi da 0 nel caso di date uguali
	const currentDay = Math.ceil(Math.abs((today.getTime() - firstDate.getTime() + 1) / DAY_MILLISEC));
	if (status == CYCLE_STATUS.PLANNED) {
		return i18n.t('pag.cycle.edit.day-to-start', { days: currentDay });
	}
	const secondDate = new Date(cycle.harvestingDate);
	const daysCount = Math.ceil(Math.abs((secondDate.getTime() - firstDate.getTime()) / DAY_MILLISEC)) + 1;
	return i18n.t('pag.cycle.edit.day-of', { current: currentDay, total: daysCount });
	//return `${currentDay}/${daysCount}`
}

/**
 * Restituisce il "range" di presenza del CYCLE
 */
export function getCycleRange(cycle: Cycle): DateRange {
	return dateRangeStringToDateRange({
		start: cycle.seedingDate,
		end: cycle.harvestingDate,
	})
}

/**
 * Restituisce un CYCLE creato localmento in base ai parametri inseriti
 */
export function buildCycleFromCrop(crop: Crop, seedingDate: DateNumber, growUnitId: number, layersPerPhase: LayersNumberByPhase): Cycle {
	if (!crop || !seedingDate || !layersPerPhase) return null
	const phases = crop.phases.sort((cp1, cp2) => cp1.position - cp2.position)
		.filter(cropPhase => cropPhase.phase != PHASE_SLUG.PRE_CYCLE && cropPhase.phase != PHASE_SLUG.POST_CYCLE)
	let dateCount = seedingDate
	const CycleLayers: Layer[] = phases.map(layer => {
		const layersNum = layersPerPhase[layer.phase]
		if (!layersNum) return null

		const busyFrom: DateString = dateToString(dateCount)
		dateCount = dayjs(dateCount).add(layer.daysCount - 1, "days").valueOf()
		const busyTo: DateString = dateToString(dateCount)
		dateCount = dayjs(dateCount).add(1, "day").valueOf()
		const layers = layersNum.map<Layer>(layerNumber => ({
			layerNumber,
			phase: layer.phase,
			busyFrom,
			busyTo,
		}))
		return layers
	}).flat()
	dateCount = dayjs(dateCount).subtract(1, "day").valueOf()

	const cycle: Cycle = {
		growUnitId,
		layers: CycleLayers,
		seedingDate: dateToString(seedingDate),
		harvestingDate: dateToString(dateCount),
	}

	return cycle
}