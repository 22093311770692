import { createStore, StoreCore } from "@priolo/jon"
import taskTemplateGroupsApi from "api/taskTemplateGroups"
import { Uuid } from "types/global"
import { Task } from "types/Task"
import { TaskTemplateGroup } from "types/TaskTemplate"



const setup = {

	state: {
		/** all TYPE TASKs-TEMPLATE-GROUPS */
		all: <TaskTemplateGroup[]>[],
		/** sono i TASKS selezionati in questo momento in relazione con un CYCLE oppure un DAY 
		 * usato per la visualizzazione */
		select: <Task[]>[],
	},

	getters: {

		/** semplicemente il paramentro all ordinato */
		getTaskGroupSorted: (_: void, store?: TaskTemplateGroupStore) => {
			return store.state.all.sort((a, b) => a.name.localeCompare(b.name))
		},
		/** Restituisce un TASKs-TEMPLATE-GROUPS tramite il suo ID */
		getGroupTaskTemplatesById: (id: number, store?: TaskTemplateGroupStore) => {
			return store.state.all.find(tt => tt.id == id)
		},
		/** Restituisce un TASK-TEMPLATE tramite il suo ID */
		getTaskTemplatesById: (id: number, store?: TaskTemplateGroupStore) => {
			for (const group of store.state.all) {
				const template = group.taskTemplates.find(tt => tt.id == id)
				if (template) return template
			}
			return null
		},
		getById: (id: number, store?: TaskTemplateGroupStore) => store.state.all?.find(task => task?.id == id),
		getByUuid: (uuid: Uuid, store?: TaskTemplateGroupStore) => store.state.all?.find(task => task?.uuid == uuid),
		getByIds: (ids: number[], store?: TaskTemplateGroupStore) => ids.map(id => store.getById(id) ?? { id }),
	},

	actions: {
		/** recupera dal BE tutti i TASKs-TEMPLATE-GROUPS */
		fetchAll: async (_: void, store?: TaskTemplateGroupStore) => {
			const { data } = await taskTemplateGroupsApi.index()
			store.setAll(data)
		},
		/** Recupera dal BE tutti i TASKs-TEMPLATE-GROUPS solo se non è stato fatto prima */
		fetchAllIfVoid: async (_: void, store?: TaskTemplateGroupStore) => {
			if (store.state.all?.length > 0) return
			return store.fetchAll()
		},
		/** [II] DA CANCELLARE recupera i tasks di uno specifico CYCLE
		 * se non specificato uso i correnti FARM-CYCLE **/
		// fetchByCycleId: async (
		// 	{ growUnitId, cycleUuid }: { growUnitId?: number, cycleUuid?: Uuid } = {},
		// 	store?: TaskTemplateGroupStore
		// ) => {
		// 	if (!growUnitId) growUnitId = growUnitSo.state.select?.id
		// 	if (!cycleUuid) cycleUuid = cycleSo.state.select?.cycleUuid
		// 	if (!growUnitId || !cycleUuid) return
		// 	const { data } = await tasksApi.byGrowUnitAndCycle(growUnitId, cycleUuid)
		// 	store.setSelect(data)
		// },
		/** comando di SYNC dei TASK .. usato solo per debug */
		// syncTasks: async (
		// 	{ growUnitId, cycleUuid }: { growUnitId?: number, cycleUuid?: Uuid } = {},
		// 	store?: TaskTemplateGroupStore
		// ) => {
		// 	if (!growUnitId) growUnitId = growUnitSo.state.select.id
		// 	if (!cycleUuid) cycleUuid = cycleSo.state.select.cycleUuid
		// 	await tasksApi.sync(growUnitId, cycleUuid)
		// 	dialogSo.dialogOpen({ type: DIALOG_TYPE.INFO, modal: false, text: i18n.t("snackbar.task.sync") })
		// },
	},

	mutators: {
		setAll: (all: TaskTemplateGroup[]) => ({ all }),
		setSelect: (select: Task[]) => ({ select }),
	},
}

export type TaskTemplateGroupState = typeof setup.state
export type TaskTemplateGroupGetters = typeof setup.getters
export type TaskTemplateGroupActions = typeof setup.actions
export type TaskTemplateGroupMutators = typeof setup.mutators
export interface TaskTemplateGroupStore extends StoreCore<TaskTemplateGroupState>, TaskTemplateGroupGetters, TaskTemplateGroupActions, TaskTemplateGroupMutators {
	state: TaskTemplateGroupState
}
const store = createStore(setup) as TaskTemplateGroupStore
export default store