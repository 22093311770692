import { Box } from "@mui/material";
import { useStore } from "@priolo/jon";
import PlaceholderCmp, { ICON_TYPE } from "components/controls/PlaceholderCmp";
import { FunctionComponent } from "react";
import incidentSo from "stores/incidents";
import { getIncidentsFiltered } from "stores/incidents/utils";
import { Cycle } from "types/Cycle";
import IncidentCard from "../../../incident/IncidentCard";
import IncidentDialog from "../../../incident/IncidentDialog";


interface Props {
    cycle: Cycle
}
const IncidentsTab: FunctionComponent<Props> = ({
    cycle
}) => {

    // STORE
    useStore(incidentSo)

    // HOOKs

    // RENDER
    const incidents = getIncidentsFiltered(incidentSo.state.all, cycle.id)

    return <>
        {!incidents?.length ? (
            <PlaceholderCmp
                title="THERE ARE NO INCIDENTS"
                subtitle="Click on 'NEW INCIDENT' to create one"
                iconType={ICON_TYPE.VOID}
            />
        ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {incidents.map(incident =>
                    <IncidentCard key={incident.id}
                        incident={incident}
                        cycle={cycle}
                    />
                )}
            </Box>
        )}
        <IncidentDialog
            cycle={cycle}
        />
    </>
}

export default IncidentsTab
