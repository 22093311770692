import dayjs, { Dayjs } from "dayjs"
import { DateNumber, DateString } from "types/global"


/**
 * trasforma una data rappresentata come numero  
 * in una string formattata "YYYY-MM-DD"
 */
export function dateToString(date: DateNumber | string | Date | Dayjs): DateString {
	if (!date) return null
	return dayjs(date).format("YYYY-MM-DD")
}

/**
 * da una DATE restituisce una stringa leggibile
 */
export function dateToHuman(date: DateNumber | Date | string | Dayjs, noYear: boolean = false): DateString {
	if (!date) return null
	const delta = dayjs().startOf("day").diff(dayjs(date).startOf("day"), "days")
	//const delta = diffDay(nowWithoutTime(), (new Date(date)).getTime())
	if (delta == 0) return "TODAY"
	if (delta == 1) return "YESTERDAY"
	return dayjs(date).format(noYear ? "DD/MM" : "DD/MM/YYYY")
}
/**
 * da una DATE restituisce una stringa leggibile
 */
export function dateTimeToHuman(date: DateNumber | Date | string | Dayjs): DateString {
	if (!date) return null
	return `${dateToHuman(date)} ${dayjs(date).format("HH:mm")}`
}
/**
 * da una DATE restituisce una stringa TIME 
 */
export function timeToHuman(date: DateNumber | Date | string | Dayjs): DateString {
	return dayjs(date).format("HH:mm")
}


export function secondsToString(value: any): string {
	const seconds = Number.parseInt(value)
	if (value == null || isNaN(seconds)) return ""
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const secondsRest = seconds % 60;
	return `${hours}:${minutes.toString().padStart(2, '0')}:${secondsRest.toString().padStart(2, '0')}`;
}

export function secondsToHours(second: number): number {
	if (isNaN(second)) return null
	return Math.round(second / 3600)
}

export function stringToSeconds(value: string): number {
	const values = value.split(':').map(Number);
	if (values?.length == 0) return 0
	if (values.length == 1) return values[0]
	if (values.length == 2) return (values[0] * 60) + values[1]
	return (values[0] * 60 * 60) + (values[1] * 60) + values[2]
}
