import { Box, Card, InputBaseProps, List, ListItemButton, ListItemText } from "@mui/material";
import { useStore } from "@priolo/jon";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import deviceSo, { DEVICE_TYPE } from "stores/layout/device";
import locationSo from "stores/route/location";
import tagSo from "stores/tag";
import { getWord, getWordPos, measureText } from "stores/tag/utils";
import FindField from "./FindField";



interface Props extends InputBaseProps {
	value?: string,
	onChange?: (value: any) => void,
	label?: string,
	minSize?: number,
	sx?: any,
}

/**
 * TextBox con menu' contestuale sui TAG
 */
const FindTagsField: FunctionComponent<Props> = ({
	value,
	onChange,
	label = "Search",
	sx,
	...props
}) => {

	// STORE
	const locationSa = useStore(locationSo)
	const tagSa = useStore(tagSo)

	// HOOKs
	const ref = useRef(null)
	const [pos, setPos] = useState(0)
	const [haveFocus, setHaveFocus] = useState(false)
	const [haveTag, setHaveTag] = useState(false)
	const [selectIndex, setSelectIndex] = useState(0)

	useEffect(() => {
		tagSo.fetchAllIfVoid()
		if (deviceSo.state.type == DEVICE_TYPE.DESKTOP) ref.current?.focus()
	}, [locationSa.currentPage])

	const tags = useMemo(()=>tagSo.getFiltered(), [tagSa.all, value])


	// HANDLER
	const handleTextChange = (e: any) => {
		const text = e?.target?.value
		setSelectIndex(0)
		onChange?.(text)
	}

	const handleTagSelect = (e: any) => {
		const text = e.target.value
		const cursorPos = ref.current?.selectionStart
		const { word, start } = getWord(text, cursorPos)
		const isTag = word.startsWith("#")
		setHaveTag(isTag)
		if (!isTag) return

		const tag = word.slice(1)
		const subText = text.slice(0, start)
		const posX = measureText(subText, 16)
		tagSo.setFilter(tag)
		setPos(posX)
	}

	const handleBlur = _ => {
		setHaveFocus(false)
	}

	const handleFocus = _ => {
		setHaveFocus(true)
	}

	const handleTagClick = (tag: string, e?: React.MouseEvent) => {
		e?.preventDefault()
		const cursorPos = ref.current?.selectionStart
		const { start, end } = getWordPos(value, cursorPos)
		ref.current.setRangeText(`#${tag}`, start, end, "end");
		const text = ref.current.value
		onChange?.(text)
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		switch (e.code) {
			case "Enter":
				if (!haveTag) return
				const tag = tags[selectIndex]
				handleTagClick(tag)
				break
			case "ArrowDown": {
				e.preventDefault()
				const index = selectIndex + 1
				if (index < tags.length) setSelectIndex(index)
				break
			}
			case "ArrowUp": {
				e.preventDefault()
				const index = selectIndex - 1
				if (index >= 0) setSelectIndex(index)
				break
			}
			case "Escape":
				onChange?.("")
				break

			default:
		}
	}

	// RENDER
	
	return <Box sx={[{ position: "relative" }, sx]}>

		<FindField
			inputRef={ref}
			value={value ?? ""}
			onChange={handleTextChange}
			onSelect={handleTagSelect}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onKeyDown={handleKeyDown}
			{...props}
		/>

		{(haveFocus && haveTag) && (
			<Card sx={cssCard} elevation={6} style={{ left: `${pos}px` }}>

				<List>
					{tags.map((tag, index) => (
						<ListItemButton key={tag} dense
							onMouseDown={e => handleTagClick(tag, e)}
							selected={selectIndex == index}
						>
							<ListItemText
								primary={tag}
							/>
						</ListItemButton>
					))}
				</List>

			</Card>
		)}
	</Box>
}

export default FindTagsField

const cssCard = {
	position: "absolute",
	top: "42px",
	maxHeight: "637px",
	overflowY: "auto",
	boxShadow: "4px 4px 4px 0px rgb(0 0 0 / 64%)",
}
