import { Add as AddIcon, ArrowBack, CreateNewFolder, Delete, MoveUp } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import recipeSo from "stores/library/recipe";



const RecipeFooter: FunctionComponent = () => {

	// STORE
	const recipeSa = useStore(recipeSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleNewRecipe = () => recipeSo.setIsModalOpen(true)
	const handleNewFolder = () => recipeSo.openFolderInEdit(null)
	const handleMoveFolder = () => recipeSo.setFolderSelectorOpen(true)
	const handleDeselect = () => recipeSo.setSelectedIds([])
	const handleMoveInNewFolder = () => recipeSo.openFolderInEdit(null)
	const handleBulkArchive = () => recipeSo.bulkArchive()

	// RENDER
	const haveSelected = recipeSa.selectedIds?.length > 0
	const haveNoArchived = recipeSa.selectedIds.some(id => !recipeSo.getById(id)?.archived)

	return haveSelected ? <>

		<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<ArrowBack />}
			onClick={handleDeselect}
		>DESELECT</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			startIcon={<MoveUp />}
			onClick={handleMoveFolder}
		>MOVE</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			startIcon={<CreateNewFolder />}
			onClick={handleMoveInNewFolder}
		>IN NEW FOLDER</ButtonCmp>

		{haveNoArchived && (
			<ButtonCmp sx={{ mr: "20px" }}
				startIcon={<Delete color="secondary" />}
				onClick={handleBulkArchive}
			>ARCHIVE ALL</ButtonCmp>
		)}

	</> : <>

		<ButtonCmp sx={{ ml: "26px", mr: 1 }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<CreateNewFolder />}
			onClick={handleNewFolder}
		>NEW FOLDER</ButtonCmp>

		<ButtonCmp
			shape={BTT_SHAPE.MAIN}
			startIcon={<AddIcon />}
			onClick={handleNewRecipe}
		>{t("footer.recipes.new")}</ButtonCmp>

	</>
}

export default RecipeFooter