import { Visibility as StatusIcon } from "@mui/icons-material";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ALERT_FILTER } from "types/Alert";
import SelectorDialogBase from "../base/SelectorDialogBase";
import Chip from "./Chip";



interface Props {
	/** id dello STATUS selezionata */
	filter?: ALERT_FILTER,
	/** quando si seleziona uno STATUS  */
	onChange?: (filter: ALERT_FILTER) => void,
	sx?: any
}

const SelectorAlertStatusH: FunctionComponent<Props> = ({
	filter,
	onChange,
	sx,
}) => {
	if (filter as string == "") filter = null
	// STORE

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (filter: ALERT_FILTER) => {
		setIsOpen(false)
		if (filter) onChange?.(filter)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(ALERT_FILTER.ALL)
	}

	// RENDER
	const statuses = Object.values(ALERT_FILTER)
	if ( !filter ) filter = ALERT_FILTER.OPEN
	const getLabel = filter => t(`app.alert.filter.${filter}`)
	const isActive = filter != ALERT_FILTER.ALL

	return (<>
		<Chip sx={sx}
			label={getLabel(filter)}
			icon={<StatusIcon />}
			tooltip={t("selector.status.tooltip")}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto"
			height={null}
			icon={<StatusIcon />}
			items={statuses}
			idSelect={filter}
			title={t("selector.status.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}

			fnTextFromItem={item => getLabel(item)}
			fnIdFromItem={item => item}
		/>
	</>)
}

export default SelectorAlertStatusH
