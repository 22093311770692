import { Close as CloseIcon } from '@mui/icons-material';
import { SxProps, Drawer, DrawerProps, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";


interface Props extends DrawerProps {
	title?: string
	width?: number
	icon?: React.ReactNode
	/** è la parte fissa superiore */
	renderHeader?: React.ReactNode
	anchor?: "right" | "top" | "bottom" | "left"
	children?: React.ReactNode
	onClose?: () => void
}

const DrawerCmp: FunctionComponent<Props> = ({
	title = "",
	width = 500,
	icon,
	renderHeader,
	anchor = "right",
	children,
	onClose,
	...props
}) => {

	return <Drawer 
		anchor={anchor} 
		variant="persistent"
		{...props}
	>

		<Box sx={[sxRoot as any, { width }]}>

			<Box sx={{ display: "flex", alignItems: "center", margin: "10px 0px" }}>
				{icon && <Box sx={{ display: "flex", mr: 1 }}>
					{icon}
				</Box>}

				<Typography sx={{ flex: 1, }}
					align="left"
					variant="h5"
				>{title}</Typography>

				<IconButton sx={{ marginRight: "-15px" }}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			</Box>

			{renderHeader && <Box sx={{ display: "flex", flexDirection: "column", margin: "10px 0px" }}>
				{renderHeader}
			</Box>}

			<Box sx={cssChildren}>
				{children}
			</Box>

		</Box>

	</Drawer>
}

export default DrawerCmp

const sxRoot: SxProps = {
	display: "flex",
	flexDirection: "column",
	height: "100%",
	padding: "0px 20px 20px 20px",
}

const cssChildren = {
	flex: "1 0",
	overflow: "auto",
	paddingRight: "20px",
	marginRight: "-20px",
}