import { Assignment as TaskIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useStore } from "@priolo/jon";
import Modal from "components/controls/Modal";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import dialogSo from "stores/task/taskNewDialog";
import TaskDetailTab from "./TaskDetailTab";
import TaskPreviewTab from "./TaskPreviewTab";



const TaskNewDialog: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleClose = () => {
		dialogSo.close(false)
	}

	const handleCreate = async () => {
		dialogSo.close(true)
	}

	// RENDER
	return (
		<Modal
			maxWidth="md"
			title={t("dialog.task.new.title")}
			icon={<TaskIcon />}
			isOpen={dialogSa.isOpen}
			onClose={handleClose}
			actionsRender={<>
				<Box sx={{ flex: 1 }} />
				<Button
					onClick={handleClose}
				>{t("dialog.task.new.btt-cancel")}</Button>
				<Button color="secondary"
					disabled={!dialogSo.canCreate()}
					onClick={handleCreate}
				>{t("dialog.task.new.btt-create")}</Button>
			</>}
		>
			<Box sx={{ display: "flex", gap: "30px", alignItems: "stretch" }}>
				<TaskDetailTab sx={{ flex: 3 }} />
				<TaskPreviewTab sx={{ flex: 1, height: "500px", }} />
			</Box>
		</Modal>
	)
}

export default TaskNewDialog

