import { InputBase, InputBaseProps, SxProps } from "@mui/material";
import { FunctionComponent } from "react";
import WrapControl, { ICON_VARIANT } from "../base/WrapControl";
import theme from "styles/theme";



/**
 * TextBox con il logo della lente e la x per cancellare
 */
const FindField: FunctionComponent<InputBaseProps> = ({
	sx,
	...props
}) => {

	// HANDLERs
	const handleClear = (e: any) => {
		e?.preventDefault()
		e.target.value = ""
		props.onChange(e)
	}

	// RENDER
	const value = props.value as string
	const haveValue = value && value.trim().length > 0

	return (
		<WrapControl sx={{ ...cssRoot(haveValue), ...sx }}
			variantIcon={ICON_VARIANT.FIND}
			onClickClear={handleClear}
		>

			<InputBase fullWidth
				autoComplete='abc'
				{...props}
			/>

		</WrapControl>
	)
}

export default FindField

const cssRoot = (haveValue:boolean):SxProps => ({
	border: haveValue ? `2px solid ${theme.palette.secondary.main}` : null
})