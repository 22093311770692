import { Biotech as AgroIcon, PrecisionManufacturing as MachineryIcon, Build as MaintenanceIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ProductionIcon from "images/production";
import { FunctionComponent } from "react";
import { TASK_SCOPE } from "types/Task";



interface Props {
	scope: string
	sx?: any
}

const ScopeIcon: FunctionComponent<Props> = ({
	scope: scope,
	sx
}) => {

	// HANDLER

	// RENDER
	if ( !scope ) return null
	const label = scope.toUpperCase()
	const Icon = {
		[TASK_SCOPE.AGRO]: <AgroIcon />,
		[TASK_SCOPE.MACHINERY]: <MachineryIcon />,
		[TASK_SCOPE.MAINTENANCE]: <MaintenanceIcon />,
		[TASK_SCOPE.PRODUCTION]: <ProductionIcon width="20px" height="20px" />,
	}[scope]
	return (
		<Tooltip title={label}>
			<div style={{ display: "flex"}}>
				{Icon}
			</div>
		</Tooltip>)
}

export default ScopeIcon

