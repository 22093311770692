import { StoreCore, createStore } from "@priolo/jon"
import farmApi from "api/farms"
import growUnitApi from "api/growUnits"
import { groupLayersByPhases } from "stores/phase/layers"
import { GrowUnit, GrowUnitLayer, PlanLayer } from "types/GrowUnit"
import { PhaseString } from "types/global"
import { getColumnsName, isBatch, isBatchDesired } from "./utils"
import { getGrowUnitLayerName, getLayerByNumber, getLayerDesiredByNumber, getLayersDesired, getLayerIndexName } from "./utils/layer"
import guSo from "stores/growUnit"



/** id dell'ultima FARM utilizzata per il caricamento delle GROW-UNIT in all */
//let LastFarmId: number = null

const setup = {

	state: {
		/** tutte le GROWUNITs di tutte le FARMs */
		all: <GrowUnit[]>[],
		/** la GROWUNIT selezionata */
		select: <GrowUnit>null,

		/** è l'ultima farm utilizzata per caricare le GROW-UNIt. 
		 * Se null allora ho caricato tutte le GROW-UNIT
		 * */
		lastFarmId: <number>null,
	},

	getters: {
		//getFiltered: (farmId, store) => getFiltered(store.state.all, farmId),
		/** restituisce tutte le GROW-UNIT di una determinata FARM */
		getByFarm(farmId: number, store?: GrowUnitStore): GrowUnit[] {
			if (!farmId) return []
			return store.state.all.filter(growUnit => growUnit.farmId == farmId)
		},
		/** restituisce una GROW-UNIT tramite il suo ID */
		getByID(growUnitId: number, store?: GrowUnitStore): GrowUnit {
			return store.state.all.find(growUnit => growUnit.id == growUnitId)
		},


		/** bool: restituisce true se lo schema della farm select è "continuous" */
		getInBatch: (_: void, store?: GrowUnitStore): boolean => isBatch(store.state.select),
		getInBatchDesired: (_: void, store?: GrowUnitStore): boolean => isBatchDesired(store.state.select),

		getLayerById: (id: number, store?: GrowUnitStore): GrowUnitLayer => store.state.select.layers.find(layer => layer.id == id),
		// restituisce un layer tramite il suo "layer_number"
		// { <layer> }
		getLayerByNumber: (number: number, store?: GrowUnitStore): GrowUnitLayer => getLayerByNumber(store.state.select, number),
		getLayerDesiredByNumber: (number: number, store?: GrowUnitStore): PlanLayer => getLayerDesiredByNumber(store.state.select, number),
		// trasforma aggiunge i dati mancanti nei layer desired (column e row)
		getLayersDesired: (_: void, store?: GrowUnitStore): GrowUnitLayer[] => getLayersDesired(store.state.select),
		// Dictionary[phasename:string] = <layer>: restituisce i LAYERS raggruppati per PHASE
		// select.layers
		// { "phase1": [{<layer>},{<layer>},...], "phase2": [{<layer>},{<layer>},...], ...}
		getLayersGroupByPhase: (_: void, store?: GrowUnitStore): { [phase: string]: GrowUnitLayer[] } => groupLayersByPhases(store.state.select.layers),
		/**
		 * i LAYER raggruppati per PHASEs della GROW-UNIT  
		 * Questi sono quelli "desiderati" select.desiredLayersState?.layers 
		 */
		getLayersDesiredGroupByPhase: (_: void, store?: GrowUnitStore): { [phase: string]: GrowUnitLayer[] } => groupLayersByPhases(store.getLayersDesired()),
		// array<string>: restituisce i nomi delle colonne della FARM
		// select
		// ["A-B-C","D-F-G","H-I-L"]
		getColumnsName: (_: void, store?: GrowUnitStore): string[] => getColumnsName(store.state.select),
		// string: restituisce il nome calcolato del layer passato come payload
		// layer: LAYER della FARM
		// index: indice della lettera
		// index=0 = "A3"; index=1 = "B3"; index=2 = "C3" 
		getLayerIndexName: (
			{ layer, index }: { layer: GrowUnitLayer, index: number, farm: any },
			store?: GrowUnitStore
		): string => getLayerIndexName(store.state.select, layer, index),
		// string: restituisce il nome calcolato del layer passato come payload
		getLayerName: (layer: GrowUnitLayer, store?: GrowUnitStore): string => getGrowUnitLayerName(store.state.select, layer),
		// Array<string>: get all PHASES (senza ripetizioni) of select FARM
		// ["germination", "hd", "ld"]
		getPhases: (_: void, store?: GrowUnitStore): string[] => Object.keys(store.getLayersGroupByPhase()),
		/** la lista della PHASEs presenti */
		getDesiredPhases: (_: void, store?: GrowUnitStore): PhaseString[] => Object.keys(store.getLayersDesiredGroupByPhase()),
		//  Array<layer>: the IDs-name of LAYERS in the PHASE(string) of the parameter
		getLayersPhases: (phase: PhaseString, store?: GrowUnitStore): GrowUnitLayer[] => store.getLayersGroupByPhase()?.[phase] ?? [],
		getLayersPhasesDesired: (phase: PhaseString, store?: GrowUnitStore): GrowUnitLayer[] => store.getLayersDesiredGroupByPhase()?.[phase] ?? [],

	},

	actions: {
		/** Carico tutte le GROWUNIT di tutte la FARMs */
		fetchAll: async (_: void, store?: GrowUnitStore) => {
			guSo.state.lastFarmId = null
			const { data: growUnits } = await growUnitApi.index()
			store.setAll(growUnits)
			guSo.state.lastFarmId = null
		},
		/** Carico il DETAIL della GROWUNIT tramite "id" e la "seleziono"  
		  * TODO: cambiare nome in fetchAndSelect */
		fetchSelect: async (id: number, store?: GrowUnitStore) => {
			const { data: growUnit } = await growUnitApi.get(id)
			store.setSelect(growUnit)
			return growUnit as GrowUnit
		},
		/** Se NON è cambiato il GrowUnit.ID dall'ultimo "fetch" allora uso i dati gia' caricati */
		fetchSelectIfVoid: async (id: number, store?: GrowUnitStore) => {
			if (id == null) return
			const growUnit = store.state.select
			if (growUnit && growUnit.id == id) return growUnit;
			return await store.fetchSelect(id)
		},
		/** Prelevo tutte le GROW-UNIT di una specifica FARM */
		fetchByFarm: async (idFarm: number, store?: GrowUnitStore) => {
			if (guSo.state.lastFarmId == idFarm && store.state.all?.length > 0) return
			const { data: growUnits } = await farmApi.getGrowUnits(idFarm)
			store.setAll(growUnits)
			guSo.state.lastFarmId = idFarm
		},
	},

	mutators: {
		/** set tutte la GROWUNIT di tutte le FARMs (tipicamente lo fa il "fetch") */
		setAll: (all: GrowUnit[]) => ({ all }),
		/** set della GROWUNIT attualmente selezionata (tipicamente per poi caricare il dettagio o editarla */
		setSelect: (select: GrowUnit) => ({ select }),
	},
}

export type GrowUnitState = typeof setup.state
export type GrowUnitGetters = typeof setup.getters
export type GrowUnitActions = typeof setup.actions
export type GrowUnitMutators = typeof setup.mutators
export interface GrowUnitStore extends StoreCore<GrowUnitState>, GrowUnitGetters, GrowUnitActions, GrowUnitMutators {
	state: GrowUnitState
}
const growUnitSo = createStore(setup) as GrowUnitStore
export default growUnitSo

