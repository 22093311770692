import { Add as AddIcon, CreateNewFolder } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import prodSo from "stores/productions";
import reqSo from "stores/productions/request";



const ProductionsFooter: FunctionComponent = () => {

	// STORES
	const prodSa = useStore(prodSo)

	// HOOKS
	const { t } = useTranslation()

	// HANDLERS
	const handleProductionNew = () => {
		prodSo.openEdit()
	}
	const handleRequestNew = () => {
		reqSo.create()
	}
	// const handleDeselect = () => {
	// 	prodSo.setSolutionsSelIds([])
	// }

	// RENDER
	//const solutionsSelected = prodSa.solutionsSelIds.length > 0
	const productionSelected = !!prodSa.selectUuid

	return <>
		<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<CreateNewFolder />}
			onClick={handleProductionNew}
		>
			{t("footer.productions.new_prod")}
		</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<AddIcon />}
			disabled={!productionSelected}
			onClick={handleRequestNew}
		>
			{t("footer.productions.new_req")}
		</ButtonCmp>

		{/* {solutionsSelected && (
			<ButtonCmp sx={{ mr: "20px" }}
				startIcon={<DeselectIcon />}
				onClick={handleDeselect}
			>
				{t("footer.productions.deselect")}
			</ButtonCmp>
		)} */}
	</>
}

export default ProductionsFooter