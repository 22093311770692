import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRange } from "types/global";
import { getDateRangeForHuman, getDateRangeToday, haveValues, isSingleDate } from "utils";
import Modal from "../Modal";
import Chip from "./Chip";
import MultiDaysCalendar from "./MultiDaysCalendar";



interface Props {
	dateRange: DateRange
	onChange?: (dateRange: DateRange) => void
	sx?: any
}

/**
 * picker per una range di tipo DateRange
 */
const DateRangePickerH: FunctionComponent<Props> = ({
	dateRange,
	onChange,
	sx,
}) => {

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [dateRangeSel, setDateRangeSel] = useState<DateRange>({})

	// HANDLERs
	const handleCancel = () => {
		setIsOpen(false)
	}
	const handleSelect = () => {
		setIsOpen(false)
		onChange?.(dateRangeSel)
	}
	const handleToday = () => {
		setDateRangeSel(getDateRangeToday())
	}

	const handleOpen = () => {
		setDateRangeSel(dateRange)
		setIsOpen(true)
	}
	const handleChange = (range: DateRange) => {
		setDateRangeSel(range)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}


	// RENDER 
	const dateRangeSelStr = getDateRangeForHuman(dateRangeSel) ?? "--"
	const dateRangeStr = getDateRangeForHuman(dateRange) ?? "--"
	const haveValue = haveValues(dateRange) && !isSingleDate(dateRange)

	return (<>
		<Chip sx={sx}
			label={dateRangeStr}
			icon={<DateRangeIcon sx={{ width: "18px", height: "18px" }} />}
			tooltip={t("selector.range-date.tooltip")}
			active={haveValue}
			onClick={handleOpen}
			onClear={handleClear}
		/>
		<Modal
			sx={{ ".MuiDialog-paper": { width: "400px" } }}
			title={dateRangeSelStr}
			icon={<DateRangeIcon />}
			maxWidth="xs"

			isOpen={isOpen}
			onClose={handleCancel}
			actionsRender={<>
				<Button
					onClick={handleToday}
				>TODAY</Button>
				<Box sx={{ flex: "1 1 auto" }} />
				<Button onClick={handleCancel}>
					{t("dialog.default.cancel")}
				</Button>
				<Button onClick={handleSelect} color="secondary">
					Select
				</Button>
			</>}
		>
			<MultiDaysCalendar
				dateRange={dateRangeSel}
				onChange={handleChange}
			/>
		</Modal>
	</>)
}

export default DateRangePickerH
