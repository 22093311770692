
const Icon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24" 
		fill="currentColor" 
		{...props}
	>
		<path d="M6.05001 8.01245C3.32001 10.7424 3.32001 15.1624 6.03001 17.8924C7.50001 14.4924 10.12 11.6524 13.39 9.96245C10.62 12.3024 8.68001 15.5724 8.00001 19.2824C10.6 20.5124 13.8 20.0624 15.95 17.9124C18.94 14.9224 19.78 6.77245 19.96 4.53245C19.98 4.22245 19.73 3.97245 19.43 4.00245C17.19 4.18245 9.04001 5.02245 6.05001 8.01245Z" fill="inherit" />
	</svg>
)

export default Icon