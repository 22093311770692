import { Box, Chip, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import LabelTag from "./LabelTag";

interface Props {
	tags?: string[]
	autotags?: string[]
	placeholder?: string
	sx?: any
}

const ListTags: FunctionComponent<Props> = ({
	tags = [],
	autotags = [],
	placeholder,
	sx,
}) => {

	// HOOKs

	// RENDER
	const notHaveTasks = !tags && !autotags && (tags.length + autotags.length) == 0
	return (
		<Box sx={[{ display: "flex", flexWrap: "wrap" }, sx]}>

			{notHaveTasks ? (
				placeholder && <Typography sx={cssPlaceholder}>
					{placeholder}
				</Typography>

			) : (
				autotags.map(tag => (

					<Chip key={tag} sx={cssChip} size="small"
						label={<LabelTag label={tag} />}
					/>

				)).concat(tags.map(tag => (

					<Chip key={tag} sx={cssChip} size="small"
						label={<LabelTag label={tag} />}
					/>

				)))
			)}
		</Box>
	)
}

export default ListTags;


const cssPlaceholder = {
	color: "text.disabled",
	margin: "7px",
}
const cssChip = {
	margin: "3px 3px",
	height: "19px",
}

