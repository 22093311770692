import { Box, BoxProps, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import FarmSubtitleCmp from "components/header/FarmSubtitleCmp";
import { FunctionComponent } from "react";
import farmSo from "stores/farm";
import layoutSo from "stores/layout";
import { isChild } from "stores/mainMenu/utils";
import locationSo from "stores/route/location";
import { PAGE_ID } from "stores/route/utils/types";



const isPLC = import.meta.env.VITE_TARGET == "plc"

/**
 * Permette di visualizzare una stringa formattata secondo lo schema:
 * "| testo normale|*GRASSETTO|_piccolo"
 */
const SubtitleCmp: FunctionComponent<BoxProps> = ({
	sx,
	...props
}) => {

	// STORE
	const farmSa = useStore(farmSo)
	const locationSa = useStore(locationSo)
	const layoutSa = useStore(layoutSo)

	// HOOKs

	// RENDER
	if (isPLC || isChild(locationSa.currentPage, PAGE_ID.FARMS)) {
		return <FarmSubtitleCmp
			sx={{ opacity: 0.8, ...sx }}
			farm={farmSa.select}
		/>
	}

	if (!layoutSa.title || layoutSa.title.length == 0) return null

	const title = !layoutSa.title.startsWith("|") ? `| ${layoutSa.title}` : layoutSa.title

	return <Box sx={[{ display: "flex", alignItems: "center" }, sx as any]} {...props}>
		{title.split("|").map((str, i) => {
			let type = str.slice(0, 1)
			str = str.slice(1)
			return {
				"*": <Typography key={i} variant="body2" color="text.primary" sx={{ fontWeight: 500, mr: "5px" }} >
					{str}
				</Typography>,

				"_": <Box key={i} sx={{ fontSize: "10px", mr: "5px", opacity: 0.8 }} >
					{str}
				</Box>,

				" ": <Typography key={i} variant="body2" sx={{ mr: "5px", opacity: 0.8 }} >
					{str}
				</Typography>
			}[type]

		})}
	</Box>
}

export default SubtitleCmp
