import { StoreCore, createStore } from "@priolo/jon";
import prodsApi from "api/productions";
import dayjs from "dayjs";
import farmSo from "stores/farm";
import { default as reqSo, default as requestSo } from "stores/productions/request";
import { PRODUCTION_STATUS, Production, Request, Solution } from "types/Production";
import { Uuid } from "types/global";
import { PROD_SHOW, getProductionsView } from "./utils";



/**
 * Gestione lista di PRODUCTIONS
 */
const setup = {

	state: {
		all: <Production[]>[],
		/** la modalità da visualizzare */
		show: PROD_SHOW.PRODUCTIONS,
		/** la PRODUCTION selezionata */
		selectUuid: <Uuid>null,
		/** la PRODUCTION attualmente in editazione */
		inEdit: <Production>null,
		/** indica se la dialog per l'editazione della PRODUCTION è aperta */
		editOpen: false,
	},

	getters: {
		/** restituisce l'oggetto PRODUCTION selezionato in questo momento  */
		getSelect: (_: void, store?: ProductionStore) => store.state.all.find(p => p.uuid == store.state.selectUuid),
		/** la SOLUTION tramite il suo uuid */
		getByUuid: (uuid: string, store?: ProductionStore) => store.state.all.find(p => p.uuid == uuid),
		/** restituisce la PRODUCTION che ha una specifico REQUEST */
		getByReqUuid(requestId: string, store?: ProductionStore) {
			return store.state.all.find(p => p.requests.some(r => r.uuid == requestId))
		},
		/** le REQUESTS che contengono una determinata SOLUTION  */
		getRequestFromSolution(solutionId: string, store?: ProductionStore) {
			return store.getAllRequest().find(r => r.solutions.some(s => s.uuid == solutionId))
		},
		/** tutte le request contenute in tutte le production */
		getAllRequest(_: void, store?: ProductionStore) {
			return store.state.all.reduce((acc, prod) => acc.concat(prod.requests ?? []), [] as Request[])
		},
		/** tutte le SOLUTION */
		getAllSolution(_: void, store?: ProductionStore) {
			return store.getAllRequest().reduce((acc, request) => acc.concat(request.solutions ?? []), [] as Solution[])
		}
	},

	actions: {
		/** carico tutti i PRODUCTIONS */
		fetch: async (farmId: number, store?: ProductionStore) => {
			const { data } = await prodsApi.index(farmId)
			store.setAll(data)
			store.selectInit()
		},
		save: async (production: Production, store?: ProductionStore) => {
			const { data: prodNew } = await prodsApi.save(production)
			const productions = [...store.state.all]
			const index = productions.findIndex(p => p.uuid == production.uuid)
			if (index == -1) {
				productions.push(prodNew)
			} else {
				productions[index] = prodNew
			}
			store.setAll(productions)
		},
		archive: async (production: Production, store?: ProductionStore) => {
			if (!production) return
			await prodsApi.archive(production)
			production.status = PRODUCTION_STATUS.ARCHIVED
			if (store.state.selectUuid == production.uuid) store.setSelectUuid(null)
			store.setAll([...store.state.all])
		},
		restore: async (production: Production, store?: ProductionStore) => {
			await prodsApi.restore(production)
			production.status = PRODUCTION_STATUS.ACTIVE
			store.setAll([...store.state.all])
		},
		delete: async (production: Production, store?: ProductionStore) => {
			if (!production?.uuid) return
			await prodsApi.destroy(production)
			const all = store.state.all.filter(p => p.uuid != production.uuid)
			store.setAll(all)
		},
		updateRequest: (request: Request, store?: ProductionStore) => {
			if (!request) return null
			const production = store.getByReqUuid(request.uuid)
			const index = production.requests.findIndex(r => r.uuid == request.uuid)
			production.requests[index] = { ...production.requests[index], ...request }
			store._update()
		},


		/** seleziona una PRODUCTION */
		select: (uuid?: Uuid, store?: ProductionStore) => {
			store.setSelectUuid(uuid)
			reqSo.select(null)
		},
		/** seleziona la prima PRODUCTION */
		selectInit: (_: void, store?: ProductionStore) => {
			if (!!requestSo.state.selectUuid) {
				requestSo.select(requestSo.state.selectUuid)
			} else {
				let selected = store.getByUuid(store.state.selectUuid) ?? getProductionsView(store.state.all)?.[0]
				store.select(selected?.uuid)
			}
			store.expand(store.state.selectUuid)
		},
		/** espande una PRODUCTION */
		expand: (uuid?: Uuid, store?: ProductionStore) => {
			if (!uuid) return
			const production = store.getByUuid(uuid)
			const all = [...store.state.all]
			production["_exp"] = true
			store.setAll(all)
		},


		/** apre la dialog di edit della PRODUCTION */
		openEdit: (production?: Production, store?: ProductionStore) => {
			if (!production) production = {
				name: "",
				status: PRODUCTION_STATUS.ACTIVE,
				startDate: dayjs().format("YYYY-MM-DD"),
				endDate: dayjs().format("YYYY-MM-DD"),
				farmId: farmSo.state.select.id,
				requests: [],
			}
			const prodClone = { ...production }
			store.setInEdit(prodClone)
			store.setEditOpen(true)
		},
		/** chiude la dialog di edit della PRODUCTION e lancia il save eventualmente */
		closeEdit: async (save: boolean, store?: ProductionStore) => {
			if (save) store.save(store.state.inEdit)
			store.setEditOpen(false)
		},
	},

	mutators: {
		setAll: (all: Production[]) => ({ all }),
		/** imposto cosa deve visualizzare la pagina principale */
		setShow: (show: PROD_SHOW) => ({ show }),
		/** la PRODUCTION selezionata */
		setSelectUuid: (selectUuid: Uuid) => ({ selectUuid, show: PROD_SHOW.PRODUCTIONS }),

		setInEdit: (inEdit: Production) => ({ inEdit }),
		setInEditProp: (prop: Partial<Production>, store?: ProductionStore) => ({ inEdit: { ...store.state.inEdit, ...prop } }),
		setEditOpen: (editOpen: boolean) => ({ editOpen }),
	},
}



export type ProductionState = typeof setup.state
export type ProductionGetters = typeof setup.getters
export type ProductionActions = typeof setup.actions
export type ProductionMutators = typeof setup.mutators
export interface ProductionStore extends StoreCore<ProductionState>, ProductionGetters, ProductionActions, ProductionMutators {
	state: ProductionState
}
const prodSo = createStore(setup) as ProductionStore
export default prodSo
