import { createStore, StoreCore, validateAll } from "@priolo/jon"
import productsApi from "api/products"
import i18n from "i18next"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import querySo from "stores/route/query"
import { getItemsFiltered } from "stores/tag/utils"
import { Uuid } from "types/global"
import { CropPerc, Product } from "types/Product"



const setup = {

	state: {
		// all PRODUCTS
		all: <Product[]>[],
		// select PRODUCT 
		select: <Product>null,
		isDialogNewOpen: false,
		indexCropSelect: <number>null,
	},

	getters: {
		// getFiltered: (_: void, store?: ProductStore) => {
		// 	let txt = querySo.getSearchUrl("search")
		// 	const chk = {
		// 		"archived": (querySo.getSearchUrl("archived") == "true"),
		// 	}
		// 	let items = getItemsFiltered(store.state.all, {
		// 		txt,
		// 		withArchived: chk.archived,
		// 		noSort: true,
		// 	})
		// 	items = querySo.getSorted({ items })
		// 	return items
		// },
		getTotal: (_: void, store?: ProductStore) => {
			const crops = store.state.select?.crops ?? []
			return getTot(crops)
		},
		/** recupera un PRODUCT tramite il suo uuid */
		getByUuid: (uuid: Uuid, store?: ProductStore) => {
			return store.state.all.find(p => p.uuid == uuid)
		},
	},

	actions: {
		/** carico tutti i PRODUCT */
		fetchAll: async (_: void, store?: ProductStore) => {
			const { data } = await productsApi.index()
			store.setAll(data)
		},
		fetchSelect: async (uuid: Uuid, store?: ProductStore) => {
			const { data } = await productsApi.get(uuid)
			store.setSelect(data)
		},
		fetchSelectAndClone: async (uuid: Uuid, store?: ProductStore) => {
			const { data } = await productsApi.get(uuid)
			delete data.uuid
			delete data.id
			store.setSelect(data)
		},
		save: async (product: Product, store?: ProductStore) => {
			const { data } = await productsApi.save(product.uuid, product)
			await store.setSelect(data)
		},
		archive: async (uuid: Uuid, store?: ProductStore) => {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.product.archive.confirm"),
			})
			if (res == false) return
			const { data: productArchived } = await productsApi.archive(uuid)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.product.archive"),
			})
			const product = store.state.all.find(product => product.uuid == productArchived.uuid)
			product.archived = true
			store.setAll([...store.state.all])
		},
		restore: async (uuid: Uuid, store?: ProductStore) => {
			const { data: productArchived } = await productsApi.restore(uuid)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.product.restore"),
			})
			const product = store.state.all.find(product => product.uuid == productArchived.uuid)
			product.archived = false
			store.setAll([...store.state.all])
		},
		destroy: async (id: number, store?: ProductStore) => {
			// await productsApi.destroy(id)
			// dialogSo.dialogOpen({
			// 	type: DIALOG_TYPE.INFO, modal: false,
			// 	text: i18n.t("snackbar.crop.restore"),
			// })
			// const products = store.state.all.filter(i => i.id != id)
			// store.setAll(products)
		},
		editNew: async (_: void, store?: ProductStore) => {
			store.setSelect({
				"name": "",
				"description": "",
				"crops": [],
				"archived": false,

			})
		},
		saveSelect: async (_: void, store?: ProductStore) => {

			// validazione dei dati inseriti
			if (validateAll().length > 0) return false

			const total = store.getTotal()
			if (total != 100) {
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.ERROR,
					title: i18n.t("dialog.product.invalid.title"),
					text: i18n.t("dialog.product.invalid.text"),
				})
				return false
			}

			// salvataggio
			await store.save(store.state.select)

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.product.${store.state.select.uuid ? "update" : "create"}`)
			})
			return true
		},
		normalizePercentage: (
			{ index, percentage }: { index: number, percentage: number },
			store?: ProductStore
		) => {
			if (percentage < 0 || percentage > 100) return
			const crops = [...store.state.select.crops]
			crops[index].percentage = percentage
			store.setSelectProp({ crops })
		},
	},

	mutators: {
		setAll: (all: Product[]) => ({ all }),
		setSelect: (select: Product) => ({ select }),
		setSelectProp: (prop: Product, store?: ProductStore) => ({ select: { ...store.state.select, ...prop } }),
		setIsDialogNewOpen: (isDialogNewOpen: boolean) => ({ isDialogNewOpen }),
		setIndexCropSelect: (indexCropSelect: number) => ({ indexCropSelect }),
	},

}

export type ProductState = typeof setup.state
export type ProductGetters = typeof setup.getters
export type ProductActions = typeof setup.actions
export type ProductMutators = typeof setup.mutators
export interface ProductStore extends StoreCore<ProductState>, ProductGetters, ProductActions, ProductMutators {
	state: ProductState
}
const store = createStore(setup) as ProductStore
export default store

function getTot(cropsPerc: CropPerc[]) {
	return cropsPerc.reduce((acc, crop) => {
		return acc + crop.percentage
	}, 0)
}