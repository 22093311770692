import { Close, Delete } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";
import { FunctionComponent } from "react";
import farmSo from "stores/farm";
import { getGrowUnitLayerName, getLayerByNumber } from "stores/growUnit/utils/layer";
import theme from "styles/theme";
import { Cycle } from "types/Cycle";
import { PositionTrolley } from "types/Incident";



interface Props {
    position: PositionTrolley
    cycle: Cycle
    readOnly?: boolean
    onSelect: (pos: PositionTrolley) => void
    onDelete: (pos: PositionTrolley) => void
}
const PositionChip: FunctionComponent<Props> = ({
    position,
    cycle,
    readOnly,
    onSelect,
    onDelete,
}) => {

    // HANDLERS
    const handleClose = (e: any) => {
        e.stopPropagation()
        onDelete(position)
    }

    // RENDER
    const havePosition = position.positionOfTrolleys?.length > 0
    const name = `${getNameLayer(cycle.growUnitId, position.layerNumber)}${havePosition ? ":" : ""}`

    return <Box sx={[sxRoot as any, { gap: havePosition ? "3px" : "0px" }]}
        onClick={() => onSelect(position)}
    >
        <Box sx={sxLayers}>
            {name}
        </Box>

        {havePosition && (
            <Box sx={sxPositions}>
                {position.positionOfTrolleys.join(",")}
            </Box>
        )}

        {!readOnly && (
            < Close sx={sxIcon}
                onClick={handleClose}
            />
        )}
    </Box>
}

// i nomi dei layer utilizzati da questo CYCLE
const getNameLayer = (growUnitId: number, layerNumber: number) => {
    const growUnit = farmSo.getGrowUnit(growUnitId)
    return getGrowUnitLayerName(growUnit, getLayerByNumber(growUnit, layerNumber))
}

export default PositionChip

const sxRoot: SxProps = {
    display: 'flex', alignItems: "center",
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.text.negative,
    //backgroundColor: theme.palette.secondary.main,
    //color: theme.palette.secondary.contrastText,
    borderRadius: '5px',
    padding: '0px 5px',
}
const sxLayers: SxProps = {
    fontSize: '14px',
    fontWeight: 900,
}
const sxPositions: SxProps = {
    fontSize: '12px',
    fontWeight: 500,
    mt: "1px",
    opacity: .8,
}
const sxIcon: SxProps = {
    width: "14px"
}