import { Check as SaveIcon, Close as CancelIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useStore } from "@priolo/jon";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import cropSo from "stores/library/crop";
import { getUrlByPageId } from "stores/route/utils/pagesId";
import { PAGE_ID } from "stores/route/utils/types";

const CropDetailFooter: FunctionComponent = () => {

	// STORE
	const cropSa = useStore(cropSo)

	// HOOKs
	const history = useHistory()
	const { t } = useTranslation()

	// HANDLERs
	const handleClickSaveCrop = async () => {
		const saved = await cropSo.saveSelect()
		if (saved) history.push(getUrlByPageId(PAGE_ID.CROPS))
	}

	// RENDER
	const disabled = cropSa.select?.archived == true
	return <Box sx={{ ml: "26px", display: "flex" }}>

		{!disabled && (
			<ButtonCmp sx={{ mr: "20px" }}
				shape={BTT_SHAPE.MAIN}
				startIcon={<SaveIcon />}
				onClick={handleClickSaveCrop}
			>
				{t("footer.crops.save")}
			</ButtonCmp>
		)}

		<ButtonCmp 
			href="/crops"
			startIcon={<CancelIcon />}
		>
			{t("footer.crops.cancel")}
		</ButtonCmp>

	</Box>
}

export default CropDetailFooter