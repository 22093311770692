import { Box } from "@mui/material";
import { useStore } from "@priolo/jon";
import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import farmSo from "stores/farm";
import growUnitSo from "stores/growUnit";
import layoutSo from "stores/layout";
import parentsSo from "stores/mainMenu/parents";
import sectionSo, { AnimSectionsState } from "stores/mainMenu/sections";
import { MenuItem } from "stores/mainMenu/utils";
import { getUrlByPageId } from "stores/route/utils/pagesId";
import ParentItem from "./ParentItem";
import SelectItem from "./SelectItem";



const TitleCmp: FunctionComponent = () => {

	// STORE
	useStore(parentsSo)
	useStore(sectionSo)
	useStore(layoutSo)
	useStore(farmSo)
	useStore(growUnitSo)

	// HOOKs
	const history = useHistory()

	// HANDLERS
	function handleParentClick(item: MenuItem) {
		const url = getUrlByPageId(item.id)
		if (url) history.push(url)
	}
	
	// RENDER
	const items = parentsSo.state.items
	const select = sectionSo.state.items[sectionSo.state.selected]
	const isRoot = items.length == 0

	return <Box sx={{ display: "flex", alignItems: "center" }}>

		{items.map(item => (
			<ParentItem key={item.id}
				item={item}
				onClickIcon={() => handleParentClick(item)}
			/>
		))}

		{sectionSo.state.animState != AnimSectionsState.HIDE && (
			<SelectItem item={select} isRoot={isRoot} />
		)}

	</Box>
}

export default TitleCmp
