import { Activity, GraphGroup, TAGS } from "components/planner/types"
import { activityOverlap, groupBy, mergeActivities } from "components/planner/utils"

/**
 * restituisco il GROUP delle ACTIVITY AGGREGATE
 */
export function buildCyclesAggregateGroup(activities: Activity[]): GraphGroup {

	// "fondo" tutte le activity con lo stesso CYCLE le metto in ordine
	const actGroupByCycle = groupBy<Activity>(activities, (a1, a2) => a1.tags?.[TAGS.CYCLES]?.[0] == a2.tags?.[TAGS.CYCLES]?.[0])
	activities = actGroupByCycle.reduce((acc, acts) => {
		const act = mergeActivities(acts)
		acc.push(act)
		return acc
	}, [])
	activities = activities.sort((a1, a2) => a1.start - a2.start)

	const buff = [null]
	for (const act of activities) {
		act.row = buff.findIndex(a => !activityOverlap(act, a, true))
		if (act.row == -1) {
			act.row = buff.length
			buff.push(act)
		} else {
			buff[act.row] = act
		}
	}
	const cyclesGroup: GraphGroup = {
		name: "cycles",
		title: "CYCLES",
		labels: Array.from({ length: buff.length }, (x, i) => ""/*`${i}`*/),
		activities: activities,
		collapsed: false,
	}

	return cyclesGroup
}