import { createStore, StoreCore } from "@priolo/jon"
import auth2faApi from "api/auth2fa"



const setup = {

	state: {
		otpCode: "",
		qrcode: <string>null,
		otpSecret: <string>null,
		otpCodesBackup: <string[]>[],
		is2FAEnabled: false,
		is2FAConfigured: false,
		is2FASetupOpen: false,
		is2FABackupCodeOpen: false,
	},

	getters: {
	},

	actions: {
		fetch2faSettings: async (_: void, store?: UserTFAStore) => {
			const { data } = await auth2faApi.get2faSettings()
			const { otpRequiredForLogin, otpSecret, totpQrCodeUri } = data
			store.setIs2FAEnabled(otpRequiredForLogin)
			store.setIs2FAConfigured(!otpSecret && !totpQrCodeUri)
		},
		set2FAActive: async (activate: boolean, store?: UserTFAStore) => {
			const { data } = await auth2faApi.enable2fa(activate)
			const { otpRequiredForLogin, totpQrCodeUri, otpSecret } = data
			if (otpRequiredForLogin) {
				store.setIs2FAEnabled(true)
				store.setQRCode(totpQrCodeUri)
				store.setOtpSecret(otpSecret)
				store.setIs2FASetupOpen(true)
			} else {
				store.setIs2FAEnabled(false)
				store.setIs2FAConfigured(false)
				store.setIs2FASetupOpen(false)
			}
		},
		startup2FA: async (password: string, store?: UserTFAStore) => {
			const { data } = await auth2faApi.startup2fa(store.state.otpCode, password)
			const { otpBackupCodes } = data
			store.setOtpCodesBackup(otpBackupCodes)
			store.setIs2FAConfigured(true)
			store.setIs2FASetupOpen(false)
			store.setIs2FABackupCodeOpen(true)
		},
	},

	mutators: {
		setIs2FASetupOpen: (is2FASetupOpen: boolean) => ({ is2FASetupOpen }),
		setIs2FABackupCodeOpen: (is2FABackupCodeOpen: boolean) => ({ is2FABackupCodeOpen }),

		setIs2FAEnabled: (is2FAEnabled: boolean) => ({ is2FAEnabled }),
		setIs2FAConfigured: (is2FAConfigured: boolean) => ({ is2FAConfigured }),
		setOtpCode: (otpCode: string) => ({ otpCode }),
		setOtpCodesBackup: (otpCodesBackup: string[]) => ({ otpCodesBackup }),
		setQRCode: (qrcode: string) => ({ qrcode }),
		setOtpSecret: (otpSecret: string) => ({ otpSecret }),
	},
}


export type UserTFAState = typeof setup.state
export type UserTFAGetters = typeof setup.getters
export type UserTFAActions = typeof setup.actions
export type UserTFAMutators = typeof setup.mutators
export interface UserTFAStore extends StoreCore<UserTFAState>, UserTFAGetters, UserTFAActions, UserTFAMutators {
	state: UserTFAState
}
const store = createStore(setup) as UserTFAStore
export default store