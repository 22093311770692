import { Add as AddIcon, ArrowBack, CreateNewFolder, Delete, MoveUp } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import cropSo from "stores/library/crop";



const CropTableFooter: FunctionComponent = () => {

	// STORE
	const cropSa = useStore(cropSo)


	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleNewFolder = () => cropSo.openFolderInEdit(null)
	const handleMoveFolder = () => cropSo.setFolderSelectorOpen(true)
	const handleDeselect = () => cropSo.setSelectedIds([])
	const handleMoveInNewFolder = () => cropSo.openFolderInEdit(null)
	const handleBulkArchive = () => cropSo.bulkArchive()

	// RENDER
	const haveSelected = cropSa.selectedIds?.length > 0
	const haveNoArchived = cropSa.selectedIds.some(id => !cropSo.getById(id)?.archived)

	return haveSelected ? <>

		<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<ArrowBack />}
			onClick={handleDeselect}
		>DESELECT</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			startIcon={<MoveUp />}
			onClick={handleMoveFolder}
		>MOVE</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			startIcon={<CreateNewFolder />}
			onClick={handleMoveInNewFolder}
		>IN NEW FOLDER</ButtonCmp>

		{haveNoArchived && (
			<ButtonCmp sx={{ mr: "20px" }}
				startIcon={<Delete color="secondary" />}
				onClick={handleBulkArchive}
			>ARCHIVE ALL</ButtonCmp>
		)}


	</> : <>

		<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<CreateNewFolder />}
			onClick={handleNewFolder}
		>NEW FOLDER</ButtonCmp>

		<ButtonCmp sx={{ mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			href="/crops/new"
			startIcon={<AddIcon />}
		>{t("footer.crops.new")}</ButtonCmp>

	</>
}

export default CropTableFooter