import { Close as CloseIcon, Error as ErrorIcon, Info as InfoIcon, ThumbUp as SuccessIcon, Warning as WarningIcon } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, Snackbar, Typography } from '@mui/material';
import { useStore } from '@priolo/jon';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router';
import deviceSo from "stores/layout/device";
import dialogSo, { DIALOG_TYPE } from 'stores/layout/dialogStore';



const MsgBox: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs
	const { t } = useTranslation()
	const { msg } = useParams<{ msg: string }>()

	useEffect(() => {
		if (!msg) return
		dialogSo.dialogOpen({
			type: DIALOG_TYPE.INFO,
			modal: false,
			text: t(msg),
		})
	}, [msg])

	// HANDLE

	// RENDER
	const data = typeData[dialogSa.dialogType]
	const colorBg = `${data.color}.main`
	const colorFg = `${data.color}.contrastText`

	if (!dialogSa.dialogModal) return (
		<Snackbar data-test="msgbox-title"

			ContentProps={{ sx: { bgcolor: colorBg, color: colorFg } }}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={dialogSa.dialogModal == false && dialogSa.dialogIsOpen}
			autoHideDuration={6000}
			onClose={() => dialogSo.dialogClose()}

			message={<Box sx={{ display: "flex", alignItems: "center" }}>
				<Box sx={{ display: "flex", mr: 1 }}>{data.icon}</Box>
				<Box sx={{ flex: 1, fontWeight: 500 }}>{dialogSa.dialogText}</Box>
			</Box>}

			action={
				<IconButton size="small" aria-label="close" color="inherit"
					onClick={dialogSo.dialogClose}
				>
					<CloseIcon />
				</IconButton>
			}
		></Snackbar>
	)

	// RENDER
	const mustConfirm = dialogSa.dialogLabelConfirm

	return <Dialog
		open={dialogSa.dialogIsOpen}
		onClose={() => dialogSo.dialogClose(false)}
	>

		<Box sx={[{ backgroundColor: colorBg, color: colorFg }, cssTitle]} >
			{data.icon}
			<Typography data-test="msgbox-title" variant="h5" sx={{ color: colorFg, ml: "10px" }}>
				{dialogSa.dialogTitle}
			</Typography>
		</Box>

		<Box sx={cssContent}>
			{dialogSa.dialogText}
		</Box>

		{mustConfirm && <FormControlLabel sx={{ my: 3 }}
			control={<Checkbox
				checked={dialogSa.dialogCheckConfirm}
				onChange={(e, check) => dialogSo.setCheckConfirm(check)}
			/>}
			label={dialogSa.dialogLabelConfirm}
		/>}

		<Box sx={{ display: "flex", mr: "-10px" }}>
			<Box sx={{ flex: "1 1 0" }} />

			{dialogSa.dialogLabelCancel && (
				<Button data-test="msgbox-btt-cancel"
					onClick={() => dialogSo.dialogClose(false)}
				>
					{dialogSa.dialogLabelCancel}
				</Button>
			)}

			<Button data-test="msgbox-btt-ok" sx={{ color: colorBg }} autoFocus={deviceSo.isDesktop()}
				onClick={() => dialogSo.dialogClose(true)}
				disabled={mustConfirm && !dialogSa.dialogCheckConfirm}
			>
				{dialogSa.dialogLabelOk}
			</Button>

		</Box>

	</Dialog>

}

export default MsgBox

const cssTitle = {
	display: "flex", alignItems: "center",
	margin: "-20px -20px 0px -20px",
	p: "16px 20px 12px 20px",
}
const cssContent = {
	p: "16px 0px 10px 0px",
	minWidth: "250px",
	whiteSpace: "pre-wrap",
}

const typeData = {
	[DIALOG_TYPE.INFO]: {
		color: "primary",
		icon: <InfoIcon fontSize='medium' />
	},
	[DIALOG_TYPE.ERROR]: {
		color: "error",
		icon: <ErrorIcon fontSize='medium' />
	},
	[DIALOG_TYPE.SUCCESS]: {
		color: "success",
		icon: <SuccessIcon fontSize='medium' />
	},
	[DIALOG_TYPE.WARNING]: {
		color: "secondary",
		icon: <WarningIcon fontSize='medium' />
	},
}
