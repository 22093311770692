import { Box, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { getCropNames } from "stores/cycle/utils/cycle";
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import { Cycle } from "types/Cycle";
import { GrowUnit } from "types/GrowUnit";
import { RELATED_TO, Task } from "types/Task";
import { getRef } from "utils/humanize";



interface Props {
	task?: Task
	growUnit?: GrowUnit
	cycle?: Cycle
	sx?: any
	onMouseOver?: (cycle: Cycle, e: React.MouseEvent) => void
}

const GrowUnitCycleChip: FunctionComponent<Props> = ({
	task,
	growUnit,
	cycle,
	sx,
	onMouseOver,
}) => {

	// STORE

	// HOOKs

	// HANDLE
	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		querySo.setSearchUrl([URL_PAR.CYCLE_SEL, cycle?.cycleUuid])
	}

	// RENDER
	if (task.relatedTo == RELATED_TO.FARM) {
		return <Typography variant="subtitle2" sx={{ mr: 1 }}>
			ALL FARM
		</Typography>
	}

	if (!growUnit) return null

	const tooltip = cycle ? `[${getRef(cycle.cycleUuid)}] ${getCropNames(cycle)}` : "--"

	return (
		<Tooltip title={tooltip} 
			onMouseOver={e => onMouseOver?.(cycle, e)}
			onMouseOut={e => onMouseOver?.(null, e)}
		>
			<Box sx={[{ display: "flex", flexDirection: "column", alignItems: "center", mr: "5px" }, sx]}
				onClick={handleClick}
			>
				<Typography variant="subtitle2" sx={{ width: "50px", textAlign: "center", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
					{growUnit.name}
				</Typography>
				{cycle && <Box sx={{ bgcolor: cycle.color, marginTop: "-3px", height: "1px", width: "100%" }} />}
			</Box>
		</Tooltip>
	)
}

export default GrowUnitCycleChip

