import { ACTIVITY_TYPE, Activity, GraphGroup, TAGS } from "components/planner/types"
import { groupBy } from "components/planner/utils"
import dayjs from "dayjs"
import { getGroupTasksColor, getTaskStatus, getTasksScore } from "stores/task/utils"
import { Task } from "types/Task"
import { dateWithoutTime, roundMinute } from "utils/time"



/**
 * trasforma "tasks" in ACTIVITIES, quindi crea un GROUP-ACTIVITIES e lo restituisce
 * usato per la visualizzazione sul GANTT
 */
export function buildTasksGroup(allTasks: Task[] = []): { tasks: GraphGroup, work: GraphGroup } {

	// raggruppo i task per "scope"
	const groupsScope = groupBy<Task>(allTasks, (t1, t2) => t1.scope == t2.scope)
	// i "labels" del GROUP
	const labels: string[] = groupsScope.map(groupScope => groupScope[0].scope?.toUpperCase()).filter(l => l)
	// aggiungo anche "Totals"
	labels.push("TOTALS")

	const tasksByDueDate: Map<string, Task[]> = new Map()
	const actsTotals: Map<string, Activity> = new Map()

	// le "activities" del GROUP
	const activities = groupsScope.reduce((acts, groupScope, index) => {
		// raggruppo per "due_date" sul PLANNER viene fuori con un unico valore
		const groupDueDate = groupBy<Task>(groupScope, (t1, t2) => t1.dueDate == t2.dueDate)
		// creo l'ACTIVITY per ogni "due_date"
		const activitiesDuDate = groupDueDate.map(tasks => {
			const dueDate = tasks[0].dueDate
			const start = dateWithoutTime(dueDate)
			let sum = 0
			let score = 0
			const cycles = new Set<string>()

			for (const task of tasks) {
				if (!!task.cycleUuid) cycles.add(task.cycleUuid)
				sum += isNaN(task.totalPoints) ? 0 : task.totalPoints
				score += getTaskStatus(task).score
			}

			let taskByDueDate = tasksByDueDate.get(dueDate)
			let actTotals = actsTotals.get(dueDate)
			if (!taskByDueDate) {
				tasksByDueDate.set(dueDate, tasks)
				actTotals = {
					start,
					label: roundMinute(sum),
					value: sum,
					row: labels.length - 1,
				}
				actsTotals.set(dueDate, actTotals)
			} else {
				tasksByDueDate.set(dueDate, tasks.concat(taskByDueDate))
				actTotals.value += sum
				actTotals.label = roundMinute(actTotals.value)
			}

			// prendo tutti gli uuid CYCLES a cui è collegato questo TASK e lo shiaffo nei TAGS
			return {
				type: ACTIVITY_TYPE.LABEL,
				tags: { [TAGS.CYCLES]: [...cycles] },
				start,
				label: roundMinute(sum),
				value: sum,
				row: index,
			} as Activity
		})
		return acts.concat(activitiesDuDate)
	}, [] as Activity[])

	const actsWork: Activity[] = []
	for (const [dueDate, tasks] of tasksByDueDate.entries()) {
		const start = dayjs(dueDate)
		const isPast = start.isBefore(dayjs(), "day")
		if (!isPast) continue
		const tasksScore = getTasksScore(tasks)
		const activity: Activity = {
			type: ACTIVITY_TYPE.LABEL,
			start: start.valueOf(),
			label: tasksScore.score.toString(),
			value: tasksScore.score,
			color: getGroupTasksColor(tasksScore.score),
			row: 0,
		}
		actsWork.push(activity)
	}

	activities.push(...actsTotals.values())

	return {
		tasks: {
			name: "task",
			title: "TASKS",
			collapsed: false,
			labels,
			activities,
			unselectable: true,
			noCompact: true,
			activitiesHistogram: activities.filter(activity => activity.row == labels.length - 1)
		},
		work: {
			name: "work",
			title: "WORK",
			collapsed: false,
			labels: ["LOAD"],
			activities: actsWork,
			unselectable: true,
			noCompact: true,
		},
	}
}
