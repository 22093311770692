import ajax from "plugins/AjaxService"

/**
 * asks the BE for the data to enable 2fa
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28815
 */
function get2faSettings() {
	return ajax.get("two_fa_settings")
}

/**
 * if called enable/disable 2fa (toggle)
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28835
 */
function enable2fa(enabled: boolean) {
	return ajax.put("two_fa_settings",
		{ two_fa: { otp_required_for_login: enabled } }
	)
}

/**
 * allows you to start up the 2fa
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28855
 */
function startup2fa(otpCode: string, password: string) {
	const twoFa = { otpCode, password }
	return ajax.post("two_fa_settings",
		{ twoFa },
		{
			errorOverride: {
				"422": {
					title: "Errore di autentificazione",
					text: "La Password o OTP Code sono errati, riprova."
				}
			}
		}
	)
}

const apiAuth2fa = {
	get2faSettings,
	enable2fa,
	startup2fa,
}

export default apiAuth2fa
