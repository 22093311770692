
export interface SharedServices {
    /** dove prelevare i METADATA */
    host?: {
        url: string
    }
    /** per la connessione a NATS da edge */
    nats?: Nats
    /** per la connessione a NATS dal web */
    cloudNats?: Nats
    /** tutti i componenti della FARM */
    components: Component[]
}

export interface Nats {
    host?: string | string[]
    port?: number
    username?: string // skip verification if null or empty
    password?: string
    enableTls?: boolean
    responseTimeout?: number
    subscribe: string
    publish: string
}

export interface Component {
    code?: string
    name?: string
    /** il super-type a cui appartiene questo */
    group?: COMPONENT_TYPE,
    /** tags utilizzati per individuare il COMPONENT su description di un METADATA */
    tags?: string[]
    type: COMPONENT_TYPE
    disabled?: boolean
    hidden?: boolean
    subjects?: string[]
    options?: OptionsBase
}

export interface OptionsBase {
    grafana?: GrafanaOption[]
}

type GrafanaOption = {
    code: string,
    title?: string,
    url: string
}

export interface GrowUnitComponent extends Component {
    options: {
        /** GROW-UNIT code */
        code: string
        fans?: {
            /** non visualizzare la box della velocità (non si puo' regolare) */
            hideSpeed?: boolean,
            /** numero di torri che gestisce questo FAN */
            span?: number
        }[]
    } & OptionsBase
}

export interface HVACComponent extends Component {
    options?: {
        /** null=default | "no-chiller" */
        template?: string
    } & OptionsBase
}

export interface PowersComponent extends Component {
}

export interface GerminatorComponent extends Component {
}

export interface OsmoticWaterComponent extends Component {
    options?: {
        /** null=default | "single-row" */
        template?: string
    } & OptionsBase
}


export enum COMPONENT_TYPE {
    GERMINATOR = "germinator",
    GROWUNIT = "grow-unit",
    HVAC = "hvac",
    POWERS = "powers",
    OSMOTIC_WATER = "osmotic-water",
    SERVICES = "services",
    CO2 = "co2",
}


export function componentTypeToString(type: COMPONENT_TYPE): string {
    return {
        [COMPONENT_TYPE.GERMINATOR]: "GERMINATOR",
        [COMPONENT_TYPE.GROWUNIT]: "GROW UNITS",
        [COMPONENT_TYPE.HVAC]: "SERVICES",
        [COMPONENT_TYPE.POWERS]: "POWERS",
        [COMPONENT_TYPE.SERVICES]: "SERVICES",
        [COMPONENT_TYPE.CO2]: "CO2",
    }[type]
}