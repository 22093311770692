import { Activity, GraphGroup } from 'components/planner/types';
import { copyShowSetup, filterActivitiesByTags, getActivityFromGroups, setSubGroup } from 'components/planner/utils';
import { getFiltered } from 'stores/alert/utils';
import draftSo from "stores/draft";
import { SESSION_TYPE } from 'stores/draft/utils';
import growUnitSo from "stores/growUnit";
import plannerSo from "stores/planner";
import { buildTasksGroup } from 'stores/planner/groups/tasksGroup';
import reqSo from "stores/productions/request";
import locationSo from "stores/route/location";
import querySo from "stores/route/query";
import { PAGE_ID, URL_PAR } from 'stores/route/utils/types';
import taskSo from "stores/task";
import { ALERT_FILTER, Alert } from 'types/Alert';
import { Cycle } from "types/Cycle";
import { EDIT_TYPE } from 'types/Entity';
import { Task } from "types/Task";
import { Uuid } from "types/global";
import { buildDateRange } from 'utils';
import { buildSessionCyclesTasksAlertsGroups } from '../groups/sessionCyclesTasksAlertsGroups';



/** restituisce, in base ai filtri settati nell'URL, i TASKS da utilizzare */
export function getValidTasks(tasks: Task[], growUnitId?: number): Task[] {
	tasks = tasks?.filter(task =>
		!(task._edit?.type == EDIT_TYPE.DELETED) && (!growUnitId || task.growUnitId == growUnitId)
	) ?? []
	return tasks
}

/** restituisce, in base ai filtri settati nell'URL, i CYCLES da utilizzare */
export function getValidCycles(cycles: Cycle[], growUnitId?: number): Cycle[] {
	const cyclesFiltered = cycles?.filter(cycle =>
		!cycle.rejected && !(cycle._edit?.type == EDIT_TYPE.DELETED)
		&& (
			!growUnitId 
			|| cycle.growUnitId == growUnitId
			// nel caso della PREVIEW
			|| (cycle.growUnitId == null && cycle.layers.some(l => l.growUnitId == growUnitId))
		)
	) ?? []
	return cyclesFiltered
}

/** restituisce, in base ai filtri settati nell'URL, gli ALERTS da utilizzare */
export function getValidAlerts(alerts: Alert[]): Alert[] {
	if (!alerts) return []
	const [growUnitSel, txtSearch, severityUrl, statusUrl] = querySo.getSearchUrl([URL_PAR.GROWUNIT_FIL, URL_PAR.ALERT_TEXT, URL_PAR.ALARM_SEVERITY, URL_PAR.ALERT_STATUS, URL_PAR.ALERT_MODE])
	return getFiltered(alerts, +growUnitSel, null, Number.parseInt(severityUrl), statusUrl as ALERT_FILTER, txtSearch)
}

/** aggiorna tutto il PLANNER */
export function updatePlannerGroups() {

	// se non stiamo nella pagina del PLANNER allora non fare nulla
	if (locationSo.state.currentPage != PAGE_ID.FARM_PLANNER) return

	// prelevo la SESSION corrente
	const session = draftSo.getSessionSelect()
	if (!session) return

	// prelevo la GROW-UNIT selezionata
	const [growUnitIdUrlSelect, cycleUuid] = querySo.getSearchUrl([URL_PAR.GROWUNIT_FIL, URL_PAR.CYCLE_SEL])
	const growUnitIdSelect = +growUnitIdUrlSelect
	let growUnits = growUnitSo.state.all ?? []
	if (growUnitIdSelect) growUnits = growUnits.filter(gu => gu.id == growUnitIdSelect)

	// creo i groups da renderizzare sul PLANNER
	const groups: GraphGroup[] = buildSessionCyclesTasksAlertsGroups(growUnits, growUnitIdSelect)

	// lascio il setup dei collapse impostati precedentemente
	copyShowSetup(plannerSo.state.groups, groups)
	plannerSo.setGroups(groups)

	// seleziona il range della preview
	if (session.type == SESSION_TYPE.PREVIEW) {
		const request = reqSo.getByUuid(session.solution.requestUuid)
		const range = buildDateRange(request.constraints.startingFrom, request.constraints.timeFrame)
		plannerSo.setGenericDateRange(range)
	} else {
		plannerSo.setGenericDateRange(null)
	}

	// aggiorno l'ACTIVITY eventualmente selezionata
	updateActivitySelect()
}

/** aggiorno SOLO il GROUP dei TASKS */
export function updatePlannerTasks() {
	const growUnitIdSelect = +(querySo.getSearchUrl(URL_PAR.GROWUNIT_FIL) as string)
	const tasks = getValidTasks(taskSo.state.all, growUnitIdSelect)
	const { tasks: tasksGroup, work } = buildTasksGroup(tasks)
	setSubGroup(plannerSo.state.groups, ["operations", "task"], tasksGroup)
}

/**
 * imposta come selezionate le ACTIVITY che fanno riferimento al CYCLE dell'URL 
 */
export function updateActivitySelect(activities?: Activity[], cycleUuid?: Uuid): void {
	if (!plannerSo.state.groups?.length) return
	if (!cycleUuid) cycleUuid = querySo.getSearchUrl(URL_PAR.CYCLE_SEL) as string
	if (!activities) activities = getActivityFromGroups(plannerSo.state.groups)

	// seleziono eventualmente le ACTIVITIES
	if (!cycleUuid || cycleUuid.length == 0) {
		querySo.setSearchUrl([URL_PAR.CYCLE_SEL, null])
		plannerSo.setSelect(null)
		// se c'e' un CYLCE selezionato
	} else {
		const actsSelect = filterActivitiesByTags(activities, { cycles: [cycleUuid] })
		if (actsSelect && actsSelect.length > 0) {
			plannerSo.setSelect(actsSelect)
			//if (!plannerSo.noScroll) plannerSo.showActivities(actsSelect)
		} else {
			//querySo.setSearchUrl2([URL_PAR.CYCLE_SEL, null])
			plannerSo.setSelect(null)
		}
	}
}
