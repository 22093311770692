import { Tab } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import draftSo from "stores/draft";
import { PlannerSession, SESSION_TYPE } from "stores/draft/utils";
import SelectorDialogBase from "../base/SelectorDialogBase";
import Chip from "./Chip";



interface Props {
	sx?: any
}

const SelectorSessionH: FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const draftSa = useStore(draftSo)


	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (session: PlannerSession) => {
		setIsOpen(false)
		draftSo.selectSession(draftSo.getSessionIndex(session))
	}

	// RENDER
	const getLabel = (session: PlannerSession) => {
		if (!session) return "--"
		if (session.type == SESSION_TYPE.PROD) return "PROD"
		if (session.type == SESSION_TYPE.PREVIEW) return "PREVIEW"
		return draftSo.getDraftById(session?.draftId)?.description ?? ""
	}
	const sessions = draftSa.sessions

	return (<>
		<Chip sx={sx}
			tooltip={t("selector.session.tooltip")}
			icon={<Tab />}
			label={getLabel(draftSa.sessions[draftSa.select])}
			onClick={handleClick}
		/>
		<SelectorDialogBase
			icon={<Tab />}
			width={"auto"}
			height={null}
			items={sessions}
			idSelect={draftSa.select}
			title={t("selector.session.title")}
			isOpen={isOpen}

			fnTextFromItem={getLabel}
			fnIdFromItem={draftSo.getSessionIndex}

			onClose={handleClose}
		/>
	</>)
}

export default SelectorSessionH
