// @ts-nocheck
import { colors, PaletteOptions } from "@mui/material";



const palette:PaletteOptions = {
	mode: "dark",
	primary: {
		main: colors.cyan[500], // "#EA21FF",//colors.pink[500],
		light: "#33c9dc",
		dark: "#008394",
		contrastText: "#000",
	},
	secondary: {
		main: colors.amber[500],
		light: "#ffcd38",
		dark: "#b28704",
		contrastText: "#000",
	},

	// usare common.white
	white: {
		main: "#dddddd",
	},
	// usare common.black
	black: {
		main: "#000",
	},
	grey: {
		dark: "#424242",
		light: "#bdbdbd",
	},

	text: {
		main: "#d6d6d6",
		primary: "#d6d6d6",
		secondary: "#c5c5c5",
		sub: "#9e9e9e", // grey[500]
		disabled: "#818181",
		negative: "#161616",
	},
	// https://coolors.co/dcdcdc-3b3b3b-292929-1d1d1d
	background: {
		headerMsgBox: "#dcdcdc",
		bright: "#dcdcdc",
		header: "#3b3b3b", // 363636
		card: "#292929",
		paper: "#1D1D1D",
		down1: "rgb(0 0 0 / 50%)",
		default: "#000",
	},
	components: {
		scrollbarBg: "#282828",
		borderInput: "#c7c7c7",//"#dadada",
		map: {
			label: "#8b8b8b"
		},
	},
	action: {
		selectedOpacity: 0.3,
	},

	entity: {
		modify: "#f9f970",
		delete: colors.red[500],
		new: colors.cyan[500],
	}
}

export default palette as any//PaletteOptions & (typeof palette)