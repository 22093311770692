import { ChevronRight as RightIcon } from '@mui/icons-material';
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import FormParagraph from "components/controls/form/FormParagraph";
import dayjs from "dayjs";
import ScoreIcon from "images/speed";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import taskSo from "stores/task";
import { TasksScoreByDate, getGroupTasksColor, getGroupTasksLevel } from "stores/task/utils/group";
import palette from 'styles/palette';
import { Cycle } from 'types/Cycle';
import { Uuid } from 'types/global';
import { Task } from "types/Task";
import { dateToHuman, dateToString, isToday } from "utils";
import TaskItem from "./TaskItem";



interface Props {
	id?: string
	group: TasksScoreByDate
	editMode?: boolean
	cycleUuidHighlight?: Uuid
	onClick?: (task: Task) => void
	onCycleMouseOver?: (cycle:Cycle, e:React.MouseEvent)=>void
}
const TasksByDateCard: FunctionComponent<Props> = ({
	id,
	group,
	editMode,
	cycleUuidHighlight,
	onClick,
	onCycleMouseOver,
}) => {

	// STORE

	// HOOKs
	const ref = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => setIsVisible(entry.isIntersecting)
		);
		observer.observe(ref.current);
		return () => observer.disconnect();
	}, []);

	// HANDLERs
	const handleDateChange = () => {
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateToString(dateNum)])
		querySo.updateQueryUrl()
	}
	const handleToggleAll = (e: React.MouseEvent) => {
		e.stopPropagation()
		if (isAllSelected) {
			taskSo.setSelected(taskSo.state.selecteds.filter(uuid => tasks.findIndex(task => task.taskUuid == uuid) == -1))
		} else {
			const allTasks = taskSo.state.selecteds.concat(tasks.map(task => task.taskUuid))
			taskSo.setSelected(allTasks)
		}
	}

	// RENDER
	const dateNum = group.date
	const dateFrm = dateToHuman(dateNum)
	const tasks = group.tasks
	const today = isToday(dateNum)
	const isSelect = dayjs(querySo.getSearchUrl(URL_PAR.DATE_SEL)).isSame(dateNum, "day")
	const scoreColor = getGroupTasksColor(group?.score)
	const scoreLevel = getGroupTasksLevel(group?.score)
	const divider = useMemo(() => today && tasks.reduce((acc, task, index) => {
		if (task.isClosed) acc.done += 1
		if (!task.isClosed && !task.isCancelled) {
			if (acc.index == -1) acc.index = index
			acc.toDo += 1
		}
		return acc
	}, { done: 0, toDo: 0, index: -1 }), [tasks])
	const isAllSelected = tasks.every(task => taskSo.isSelected(task.taskUuid))
	// devo calcolare l'altezza dato che questa lista è VIRTUALE
	const height = (tasks.length * 34) + 90 + (today ? 25 : 0)

	return (<div ref={ref} style={{ height: `${height}px` }} id={id}>
		{isVisible && (

			<FormParagraph
				sx={cssContainer(isSelect)}
				icon={editMode && (
					<Checkbox tabIndex={-1} sx={{ p: 0, mr: 1 }}
						checked={isAllSelected}
						onClick={handleToggleAll}
					/>
				)}
				title={<Box
					sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
					onClick={handleDateChange}
				>
					{dateFrm}
				</Box>}
				endRender={group.isPast && <Box sx={{ display: "flex", alignItems: "center", color: scoreColor }}>
					<ScoreIcon level={scoreLevel} width={20} height={20} />
					<Typography variant="subtitle2" sx={{ ml: "5px", color: scoreColor }}>
						{group.score}
					</Typography>
				</Box>}
			>

				{tasks.map((task, index) => (<div key={task.taskUuid ?? index}>

					{divider && divider.index == index && (<>
						<Box sx={{ display: "flex", fontSize: 12, fontWeight: 400 }}>
							<RightIcon sx={{ ml: "-15px" }} />
							<span>
								<span style={{ opacity: 0.7 }}>TO DO:</span> <span style={{ fontWeight: 600 }}>{divider.toDo}</span>
								<span style={{ marginLeft: "10px", opacity: 0.7 }}>DONE:</span> <span style={{ fontWeight: 600 }}>{divider.done}</span>
							</span>
						</Box>
						<Divider />
					</>)}

					<TaskItem sx={{ my: "5px" }}
						task={task}
						editMode={editMode}
						cycleUuidHighlight={cycleUuidHighlight}
						onClick={() => onClick?.(task)}
						onCycleMouseOver={onCycleMouseOver}
					/>

				</div>))}
			</FormParagraph>
		)}
	</div>)
}

export default TasksByDateCard

const cssContainer = (isSelect: boolean) => ({
	border: isSelect ? `2px solid ${palette.secondary.main}` : null,
})

