import { Box, Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { FunctionComponent } from "react";
import PlaceholderCmp, { ICON_TYPE } from "../PlaceholderCmp";



interface Props {
	/** array di ID degli item selezionati */
	itemsSelect: any[]
	/** oggetti da visualizzare */
	items: any[]

	onChangeItemsSelect: (items: any[]) => void
	
	/** per un "item" restituisce il suo "text" */
	fnTextFromItem: (item: any) => React.ReactNode,
	/** per un "item" restituisce il suo "sottotitolo" */
	fnSecondaryFromItem: (item: any) => React.ReactNode,
	/** a fronte di un item restituisce il suo ID */
	fnIdFromItem: (item: any) => any,
}

const MultiSelectorBase: FunctionComponent<Partial<Props>> = ({
	itemsSelect,
	items,
	onChangeItemsSelect,
	fnTextFromItem = (item) => item,
	fnSecondaryFromItem,
	fnIdFromItem = (item) => item,
}) => {

	// HOOKs

	// HANDLER
	const handleClickItem = (item: any) => {
		if (isSelect(item)) {
			deselectItem(item)
		} else {
			selectItem(item)
		}
	}

	// RENDER 
	/** restituisce se due item sono uguali */
	const isEqual = (item1: any, item2: any) => fnIdFromItem(item1) == fnIdFromItem(item2)
	/** restituisce se un ITEM è selezionato (o no) */
	const isSelect = (item: any) => itemsSelect?.some(i => isEqual(i, item)) ?? false

	const selectItem = (item: any) => {
		if (isSelect(item)) return
		const newItemsSelect = [...(itemsSelect ?? []), item]
		onChangeItemsSelect(newItemsSelect)
	}
	const deselectItem = (item: any) => {
		if (!isSelect(item)) return
		const newItemsSelect = itemsSelect?.filter(t => t != item) ?? []
		onChangeItemsSelect(newItemsSelect)
	}


	return (
		<Box sx={cssListCont}>
			{items?.length > 0 ? (
				<List sx={{ width: "100%" }}>
					{items.map((item, index) => (

						<ListItemButton data-test={`dialog-item-${index}`} key={index} dense
							onClick={() => handleClickItem(item)}
						>
							<ListItemIcon>
								<Checkbox
									checked={isSelect(item)}
									tabIndex={-1}
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText
								primary={fnTextFromItem(item)}
								secondary={fnSecondaryFromItem?.(item)}
							/>
						</ListItemButton>

					))}
				</List>
			) : (
				<PlaceholderCmp sx={{ my: 3 }}
					iconType={ICON_TYPE.VOID}
				/>
			)}
		</Box>
	)
}

export default MultiSelectorBase

const cssListCont = {
	flex: 1,
	maxHeight: "600px",
	overflowY: "auto",
	marginTop: "10px",
}
