import cycleApi from "api/cycles"
import taskApi from "api/tasks"
import cycleSo from "stores/cycle"
import { EditCycle } from "stores/cycle/utils/edit"
import farmSo from "stores/farm"
import guSo from "stores/growUnit"
import { LastDateRange } from "stores/planner/utils/fetch"
import { cyclesFromSolutionCycles } from "stores/planner/utils/solutionCycle"
import { tasksFromSolutionTasks } from "stores/planner/utils/solutionTask"
import { updatePlannerGroups } from "stores/planner/utils/update"
import taskSo from "stores/task"
import { EditTask } from "stores/task/utils"
import { DRAFT_STATUS } from "types/Draft"
import { Uuid } from "types/global"
import { clone } from "utils/func"
import { DraftStore } from "."
import { PlannerSession, SESSION_TYPE, sessionFromDraftId, sessionVoid, sessionsDraftsStorageRead } from "./utils"



const sessionsSetup = {

	state: {
		/** la SESSION selezionata in questo momento */
		select: <number>null,
		/** tutte le SESSIONS */
		sessions: <PlannerSession[]>[],

		/** indica che la DIALOG di PROMOTE è aperta */
		promoteOpen: false,
	},

	getters: {
		/** se la sessione corrente è editabile o no */
		isEditable: (_: void, store?: DraftStore) => {
			const session = store.getSessionSelect()
			if (!session || session.type == SESSION_TYPE.PROD) return false
			const draft = store.getDraftById(session.draftId)
			return draft?.status == DRAFT_STATUS.INIT || draft?.status == DRAFT_STATUS.PROMOTED_FROM_SOLUTION
		},
		/** INDEX della SESSION con DRAFT-ID da parametro */
		getSessionIndexByDraftId: (draftId: number, store?: DraftStore) => store.state.sessions?.findIndex(s => s.draftId == draftId),
		/** la SESSION con DRAFT-ID da parametro */
		getSessionByDraftId: (draftId: number, store?: DraftStore) => draftId ? store.state.sessions?.find(s => s.draftId == draftId) : null,
		/** Restituisce l'indice nell'array di una SESSION */
		getSessionIndex: (session: PlannerSession, store?: DraftStore) => store.state.sessions?.findIndex(s => s == session),
		/** la SESSION con SOLUTION-UUID */
		getSessionBySolutionUuid: (uuid: Uuid, store?: DraftStore) => uuid ? store.state.sessions?.find(s => s.solution?.uuid == uuid) : null,
		/** restituisce la SESSION in PROD */
		getSessionProd: (_: void, store?: DraftStore) => {
			const session = store.state.sessions.find(s => s.type == SESSION_TYPE.PROD)
			// caso sia la selezionata la SESSION aggiornata è quella in STORE!
			if (session == store.getSessionSelect()) store.updateSessionWithStores()
			return session
		},
		/** restituisce la SESSION in PROD */
		getSessionSelect: (_: void, store?: DraftStore) => store.state.sessions[store.state.select],
		/** restituisce un clone della SESSION in PROD */
		cloneSessionProd(_: void, store?: DraftStore): PlannerSession {
			// creao la SESSION
			const sessionProd = store.getSessionProd()
			if (!sessionProd) return null
			const session: PlannerSession = {
				task: clone(sessionProd.task),
				cycle: clone(sessionProd.cycle),
			}
			return session
		},
	},

	actions: {

		/** 
		 * inizializza le SESSION
		 * quando entro in una sessione che richiede il load degli STORES TASK, CYCLES
		 */
		initSessions: async (_: void, store?: DraftStore) => {

			// recupero le SESSION-SOLUTION. Queste vanno preservate dato che sono "volatili"
			const sessionSolutions = store.state.sessions.filter(s => s.type == SESSION_TYPE.PREVIEW)
			const oldSessionSelect = store.getSessionSelect()

			// per prima cosa inserisco SESSION-PROD che è poi utilizzato anche per creare i SESSION-DRAFT
			const sessionProd = {
				type: SESSION_TYPE.PROD,
				cycle: cycleSo.state,
				task: taskSo.state,
			}
			store.state.sessions = [sessionProd]

			// ricavo tutti i draft memorizzati per questa FARM
			const draftIds = sessionsDraftsStorageRead(farmSo.state.select?.id) ?? []
			const sessionDrafts = draftIds
				.filter(draftId => store.state.drafts.some(draft => draft.id == draftId))
				.map(draftId => sessionFromDraftId(draftId))
				.filter(draft => !!draft)

			// aggiorno le SESSIONS
			store.setSessions([sessionProd, ...sessionDrafts, ...sessionSolutions].filter(s => !!s))

			// setto la selezione come dovrebbe essere
			store.setSelect(store.getSessionIndex(oldSessionSelect) ?? 0)
		},

		/** se non c'e' gia' aggiungo una SESSION */
		addSession: (session: PlannerSession, store?: DraftStore) => {
			if (store.getSessionByDraftId(session.draftId)) return
			if (store.getSessionBySolutionUuid(session.solution?.uuid)) return
			store.setSessions([...store.state.sessions, session])
		},

		/** clono SESSION-PROD, carico i CYCLES e TASKS del DRAFT e li mergio */
		async fetchSessionDraft(session: PlannerSession, store?: DraftStore) {
			// se la session è gia' carica alllora non fare nulla
			const cloneProd = store.cloneSessionProd()
			if (!session || !session.draftId || !cloneProd) return session
			// carico i draft 
			const tasksDraft = (await taskApi.indexDraft(session.draftId, LastDateRange))?.data ?? []
			const cyclesDraft = (await cycleApi.indexDraft(session.draftId, LastDateRange))?.data ?? []
			// li mergio alla session creata
			session.task = cloneProd.task
			session.cycle = cloneProd.cycle
			session.task.all = EditTask.merge(cloneProd.task.all, EditTask.draftsToTemplate(tasksDraft, cloneProd.task.all))
			session.cycle.all = EditCycle.merge(cloneProd.cycle.all, EditCycle.draftsToTemplate(cyclesDraft, cloneProd.cycle.all))
			return session
		},

		/** aggiorno la SESSION SELECTED 
		 * con lo stato attuale (per esempio STORES TASKS e CYCLES)
		 * */
		updateSessionWithStores: (_: void, store?: DraftStore) => {
			const sessionOld = store.getSessionSelect()
			if (!sessionOld || sessionVoid(sessionOld)) return
			// trasferisco lo STATE dagli STATES alla SESSION
			if (sessionOld.type == SESSION_TYPE.DRAFT) {
				sessionOld.cycle = cycleSo.state
				sessionOld.task = taskSo.state
				store.setSessions([...store.state.sessions])
			}
		},

		/** seleziono una SESSION ad attiva */
		async selectSession(index?: number, store?: DraftStore) {
			if (index == null) index = store.state.select ?? 0
			if (index < 0 || index >= store.state.sessions.length) index = 0
			// se c'e' un cambio di selezione salvo la vecchia sessione
			if (store.state.select != index) store.updateSessionWithStores()
			const session = store.state.sessions[index]
			if (!session) return

			// se è una SESSION-PREVIEW genero CYCLES e TASKS
			if (session.type == SESSION_TYPE.PREVIEW) {
				const sessionProd: PlannerSession = store.cloneSessionProd()
				if (!sessionProd) return
				sessionProd.cycle.all = sessionProd.cycle.all
					.concat(cyclesFromSolutionCycles(session.solution.solutionCycles, guSo.state.all))
				sessionProd.task.all = sessionProd.task.all
					.concat(tasksFromSolutionTasks(session.solution.solutionTaskGroups, farmSo.state.select.id))
				session.cycle = sessionProd.cycle
				session.task = sessionProd.task
			}

			// se è una SESSION-DRAFT vuota allora vado a pescare i dati
			if (session.type == SESSION_TYPE.DRAFT && sessionVoid(session)) {
				const draft = store.getDraftById(session.draftId)
				if (draft.status != DRAFT_STATUS.PROMOTING_FROM_SOLUTION) {
					await store.fetchSessionDraft(session)
				}
			}

			// aggiorno il planner
			store.setSelect(index)
			cycleSo.state = session.cycle;
			taskSo.state = session.task;
			store._update()
			cycleSo._update()
			taskSo._update()
			updatePlannerGroups()
		},

		/** cancella una specifica SESSION
		 * cambia selezione nel caso 
		 * non cancella PROD
		 * */
		delSession: (index: number, store?: DraftStore) => {
			const session = store.state.sessions[index]
			if (!session || session.type == SESSION_TYPE.PROD) return
			const selectIndex = store.state.select
			// se si cancella proprio quella selezionato tocca selezionare un altra SESSION
			if (store.state.select == index) store.selectSession(index - 1)
			// aggiorno
			store.state.sessions.splice(index, 1)
			store.setSessions([...store.state.sessions])
			if (selectIndex > index) store.state.select--
		},

		/** cambia una SESSION da PREVIEW a DRAFT */
		async promote(name: string, store?: DraftStore) {
			const session = store.getSessionSelect()
			if (!session?.solution || session.type != SESSION_TYPE.PREVIEW) return
			const draft = await store.createDraftFromSolution({
				solution: session.solution,
				description: name
			})
			session.draftId = draft.id
			session.type = SESSION_TYPE.DRAFT
			store.setSessions([...store.state.sessions])
			store.setPromoteOpen(false)
		},

		/** resetta tutte le SESSION
		 * chiamata quando esco o cambio FARM
		 */
		clearSessions: (_: void, store?: DraftStore) => {
			taskSo.setAll([])
			cycleSo.setAll([])
			store.setSessions([])
			store.setDrafts(null)
			store.setSelect(null)
		},

	},

	mutators: {
		setSelect: (select: number) => ({ select }),
		setSessions: (sessions: PlannerSession[]) => ({ sessions }),
		setPromoteOpen: (promoteOpen: boolean) => ({ promoteOpen }),
	},
}

export default sessionsSetup


