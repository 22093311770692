import { EVENT_SUBTYPE } from "types/Root"
import { GroupedEvents } from "."

export enum EVENTS_ICON {
	BOT,
	FARM,
	ALERT,
}

export function getIconType(group: GroupedEvents): EVENTS_ICON {
	let iconType = EVENTS_ICON.BOT
	for (const event of group.events) {
		if (event.subtype == EVENT_SUBTYPE.FAULT) return EVENTS_ICON.ALERT
		if ([EVENT_SUBTYPE.PAUSE_NOW, EVENT_SUBTYPE.PLAY_NOW, EVENT_SUBTYPE.STATUS_NOW].includes(event.subtype)) iconType = EVENTS_ICON.FARM
	}
	return iconType
}
