
/** stringa che indica una DATE formattata YYYY-MM-DD */
export type DateString = string

/** stringa che indica un TIMEformattata HH:MM */
export type TimeString = string

/** numbero che indica un time secodno la convenzione di Date.getTime() */
export type DateNumber = number

/** UNIX timestamp */
export type TimestampString = string

/** UNIX timestamp */
export type Timestamp = number


/** Colore esadecimale tipo #FFFFFF */
type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
export type Color = RGB | RGBA | HEX | "transparent"

/** una string che rappresenta un uuid */
export type Uuid = string

/** una string che rappresenta il nome di una PHASE (growing|germination|...)*/
export type PhaseString = string


export type DateRangeString = {
	start: DateString,
	end: DateString,
}

export type ScopeString = string

export type DateRange = {
	start?: number,
	end?: number,
}
export function isDateRange(obj: any): obj is DateRange {
	return obj && typeof obj.start === "number" && typeof obj.end === "number"
}

export type Dimension = {
	width?: number,
	height?: number
}
export type Position = {
	x: number;
	y: number;
};
