import ajax from "plugins/AjaxService"
import { Draft } from "types/Draft"


/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-35375
 */
function index(farmId: number): Promise<{ data: Draft[] }> {
	return ajax.get(`farms/${farmId}/drafts`)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27875
 */
function create(farmId: number, description: string): Promise<{ data: Draft }> {
	return ajax.post(
		`farms/${farmId}/drafts`,
		{ description }
	)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-35815
 */
function update(farmId: number, draftId: number, draft:Draft): Promise<{ data: Draft }> {
	return ajax.put(`farms/${farmId}/drafts/${draftId}`, draft)
}

/**
 * 
 */
function active(farmId: number): Promise<{ data: Draft }> {
	return ajax.get(`farms/${farmId}/drafts/active`)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27915
 */
function apply(farmId: number, draftId:number ): Promise<void> {
	return ajax.post(`farms/${farmId}/drafts/${draftId}/apply`)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-35415
 */
function remove(farmId: number, draftId:number ): Promise<void> {
	return ajax.delete(`farms/${farmId}/drafts/${draftId}`)
}

const api = {
	index,
	create,
	active,
	apply,
	remove,
	update,
}
export default api