const Icon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		{...props}
	>
		<path d="M5 20H19V22H5V20ZM17 2V5H15V2H13V5H11V2H9V5H7V2H5V8H7V18H17V8H19V2H17Z" />
	</svg>
)

export default Icon