const MenuCompactIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		{...props}
	>
		<rect x="5" y="5" width="3" height="3" />
		<rect x="5" y="10" width="3" height="3" />
		<rect x="5" y="15" width="3" height="3" />
		<path d="M13 8L17 12L13 16" fill="none" stroke="currentColor" strokeWidth="2" />
	</svg>
)

export default MenuCompactIcon