import ajax from "plugins/AjaxService"
import { Folder } from "types/Recipe"



/**
 * INDEX 
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46235
 */
function index(base: string): Promise<{ data: Folder[] }> {
	return ajax.get(`${base}/grouping_folders`)
}

/**
 * CREATE UPDATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46275
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46255
 */
function save(base: string, folder: Folder, ids?: number[]): Promise<{ data: Folder }> {

	delete folder.recipes
	delete folder.crops
	if (ids?.length > 0) {
		const propName = {
			"recipes": "recipesIds",
			"crops": "cropsIds"
		}[base]
		folder[propName] = ids
	}

	if (!folder.id) {
		return ajax.post(`${base}/grouping_folders`, { grouping_folder: folder })
	} else {
		return ajax.put(`${base}/grouping_folders/${folder.id}`, { grouping_folder: folder })
	}
}

/**
 * DELETE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46295
 */
function destroy(base: string, id: number): Promise<void> {
	return ajax.delete(`${base}/grouping_folders/${id}`)
}

/**
 * BULK MOVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46655
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46675
 */
function bulkMove(base: string, folderId: number, ids: number[]): Promise<void> {
	const propName = {
		"recipes": "recipesIds",
		"crops": "cropsIds"
	}[base]
	return ajax.post(
		`${base}/grouping_folders/bulk_move`,
		{
			grouping_folder: { 
				id: folderId,
				[propName]: ids 
			},
		}
	)
}

const foldersApi = {
	index,
	save,
	destroy,
	bulkMove,
}
export default foldersApi