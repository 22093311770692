import { createStore, StoreCore } from "@priolo/jon"
import { Activity, GraphGroup, GraphRect, GraphStyle, TAGS } from "components/planner/types"
import { buildGraphStyle, mergeActivities } from "components/planner/utils"
import dayjs from "dayjs"
import cycleSo from "stores/cycle"
import { EditCycle } from "stores/cycle/utils/edit"
import draftSo from "stores/draft"
import taskSo from "stores/task"
import { updateDateFromCycle } from "stores/task/utils"
import { Cycle } from "types/Cycle"
import { DateNumber, DateRange } from "types/global"
import { Task } from "types/Task"
import { buildDateRange, dateRangeCenter, haveValues } from "utils"
import { dateWithoutTime2, DAY_MILLISEC, diffDay, nowWithoutTime } from "utils/time"
import { cycleFromActivities } from "./utils/cycle"
import { updatePlannerGroups } from "./utils/update"



const CurrentDate = nowWithoutTime() - (7 * DAY_MILLISEC)

const setup = {

	state: () => {
		return {
			/**  
			 * i gruppi visualizzati sul gantt. 
			 * Contiene tutte le ACTIVITIES-CYCLE "activities" compresi i TASKs
			 */
			groups: <GraphGroup[]>[],
			/**  l'ACTIVITY selezionata */
			select: <Activity[] | null>null,
			/**  l'ACTIVITIES in highlight */
			highlight: <Activity[] | null>null,
			/** la data in highlight */
			dateHighlight: <DateNumber>null,
			/** [showDateRange] la prima data a sinistra visualizzata sulla timeline. Cioe' determinata dallo scrolling orizzontale*/
			dateCurrent: CurrentDate,
			/** [visibleDateRange] data iniziale dei dati visualizzati (limite scrolling sinistro) */
			dateStart: <number>null,
			/** [visibleDateRange] colonne totali. Definite anche con "setVisibleDateRange" */
			columnsEnd: <number>null,
			/** lo STYLE applicato al PLANNER */
			ganttStyle: buildGraphStyle({}),
			/** indica che il DRAWER dei TASKs è aperto */
			isTaskDrawerOpen: false,
			/** indica che il DRAWER dei ALERTs è aperto */
			isAlertDrawerOpen: false,

			genericDateRange: <DateRange>null,

			// NO REACTIVE
			drag: {
				cycle: <Cycle>null,
				tasks: <Task[]>null,
			}
		}
	},

	getters: {
		/** il range che si puo' scrollare del PLANNER */
		getVisibleDateRange: (_: void, store?: PlannerStore) => {
			const range = buildDateRange(store.state.dateStart, store.state.columnsEnd)
			return range
		},
		/** il range attualmente visibile nel PLANNER */
		getShowDateRange(_: void, store?: PlannerStore) {
			const range = buildDateRange(store.state.dateCurrent, store.state.ganttStyle.columns)
			return range
		},
	},

	actions: {
		/** fa entrare nell'area visibile le activities del parametro */
		showActivities: (activities: Activity[], store?: PlannerStore) => {
			const act = mergeActivities(activities)
			if (!act) return
			store.showDateRange(buildDateRange(act.start, act.length))
		},
		/** fa entrare nell'area visibile il range del parametro */
		showDateRange: (dateRange: DateRange, store?: PlannerStore) => {
			if (!haveValues(dateRange)) return
			const daysDiff = dateRangeCenter(dateRange, store.state.ganttStyle.columns)
			store.setDateCurrent(dayjs(dateRange.start).subtract(daysDiff, "days").valueOf())
		},

		dragStart: (_: void, store?: PlannerStore) => {
		},

		/** chiamato quando c'e' un evento di DRAG */
		drag: (
			{ offset, index, rects }: { offset: number, index: number, rects: GraphRect[] },
			store?: PlannerStore
		) => {
			// const leftRect = index > 0 ? rects[index - 1] : null
			// const rightRect = index < rects.length ? rects[index] : null

			// creo i CYCLES tramite le ACTIVITIES modificate
			const cycleUuid = rects[0].activities[0].tags?.[TAGS.CYCLES][0]
			const cycle = cycleSo.getCycleByUuid(cycleUuid)
			if (!cycle) return
			const allActivities = rects.map(r => r.activities).flat()
			const cycleAct = cycleFromActivities(allActivities, cycle.yieldDate)

			// RICALCOLO TASKS (differenza tra cycles vecchi e nuovi)
			const tasks = taskSo.state.all.filter(task => task.cycleUuid == cycleUuid)
			updateDateFromCycle(cycle, cycleAct, tasks)

			// modifico il CYCLE
			EditCycle.modify(cycle, cycleAct)

			// RENDERING
			updatePlannerGroups()
			store.state.drag.cycle = cycle
			store.state.drag.tasks = tasks
		},
		dragEnd(_: void, store?: PlannerStore) {
			draftSo.saveCycles([store.state.drag.cycle])
			draftSo.saveTasks(store.state.drag.tasks)
			store.state.drag.cycle = null
			store.state.drag.tasks = null
		}
	},

	mutators: {
		/** Setta le ACTIVITIES selezionate */
		setSelect: (select: Activity[]) => ({ select }),
		/** ACTIVITIES in highlight */
		setHighlight: (highlight: Activity[]) => ({ highlight }),
		/** */
		setDateHighlight: (dateHighlight: number) => ({ dateHighlight }),
		/** setta i GRAPH-GROUPS che contengono in pratica i dati dei BLOCCHI da visualizzare */
		setGroups: (groups: GraphGroup[]) => ({ groups }),
		/** setta la prima data a sinistra visibile sul PLANNER */
		setDateCurrent: (dateCurrent: number) => ({ dateCurrent }),
		/** il "range" setta "dateStart" e "columnEnd", cioè il range "scrollabile" */
		setVisibleDateRange: (range: DateRange) => {
			const dateStart = dateWithoutTime2(range.start)
			const dateEnd = dateWithoutTime2(range.end)
			const columnsEnd = diffDay(dateEnd, dateStart)
			return { dateStart, columnsEnd }
		},
		/** setta lo style del PLANNER ... è utile utilizzare "buildGraphStyle" */
		setGanttStyle: (ganttStyle: GraphStyle, store?: PlannerStore) => {
			return { ganttStyle: buildGraphStyle(ganttStyle, store.state.ganttStyle) }
		},
		setIsTaskDrawerOpen: (isTaskDrawerOpen: boolean) => ({ isTaskDrawerOpen }),
		setIsAlertDrawerOpen: (isAlertDrawerOpen: boolean) => ({ isAlertDrawerOpen }),
		setGenericDateRange: (genericDateRange: DateRange) => ({ genericDateRange }),
	},
}

export type PlannerState = ReturnType<typeof setup.state>
export type PlannerGetters = typeof setup.getters
export type PlannerActions = typeof setup.actions
export type PlannerMutators = typeof setup.mutators
export interface PlannerStore extends StoreCore<PlannerState>, PlannerGetters, PlannerActions, PlannerMutators {
	state: PlannerState
	/** indica che non deve centrare l'activity selezionata */
	noScroll: boolean
}
const store = createStore(setup) as PlannerStore
store.noScroll = false
export default store
