import { FilterAlt } from "@mui/icons-material";
import { useStore } from "@priolo/jon";
import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import prodSo from "stores/productions";
import querySo from 'stores/route/query';
import { URL_PAR } from "stores/route/utils/types";
import { PRODUCTION_STATUS } from "types/Production";
import SelectorDialogBase from "../base/SelectorDialogBase";
import Chip from "./Chip";



interface Props {
	sx?: any
}

const SelectorProductionStateH: FunctionComponent<Props> = ({
	sx,
}) => {

	// STORE
	const querySa = useStore(querySo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => setIsOpen(true)
	const handleClose = (newState: PRODUCTION_STATUS) => {
		setIsOpen(false)
		if (newState == null) newState = PRODUCTION_STATUS.ACTIVE
		if ( newState == state ) return
		prodSo.select(null)
		querySo.setSearchUrl([URL_PAR.PRODUCTION_STATE, newState])
	}

	// RENDER
	const states = Object.values(PRODUCTION_STATUS)
	const getLabel = (state: PRODUCTION_STATUS) => t(`app.production.status.${state}`)
	let [state] = useMemo(() => querySo.getSearchUrl([URL_PAR.PRODUCTION_STATE]), [querySa.queryUrl]) as [PRODUCTION_STATUS]
	if (!state) state = PRODUCTION_STATUS.ACTIVE

	return (<>
		<Chip sx={sx}
			tooltip={t("selector.production.filter.tooltip")}
			icon={<FilterAlt />}
			label={getLabel(state)}
			onClick={handleClick}
		/>
		<SelectorDialogBase
			icon={<FilterAlt />}
			width={"auto"}
			height={null}
			title={t("selector.production.filter.title")}

			items={states}
			idSelect={state}

			isOpen={isOpen}
			fnTextFromItem={getLabel}
			onClose={handleClose}
		/>
	</>)
}

export default SelectorProductionStateH
