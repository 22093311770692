import { createStore, StoreCore } from "@priolo/jon"

/**
 * Gestisce le popup che appiono in un determinato punto dello schermo
 * tipo le poup contestuali
 * usato nel gantt
 */
const setup = {
	state: {
		menuPos: <{ x: number, y: number }>null,
		menuItems: <Item[]>[],
	},

	getters: {
	},

	actions: {
	},

	mutators: {
		setMenuPos: (menuPos:{ x: number, y: number }) => ({ menuPos }),
		setMenuItems: (menuItems:Item[]) => ({ menuItems }),
	},
}

export type ContextMenuState = typeof setup.state
export type ContextMenuGetters = typeof setup.getters
export type ContextMenuActions = typeof setup.actions
export type ContextMenuMutators = typeof setup.mutators
export interface ContextMenuStore extends StoreCore<ContextMenuState>, ContextMenuGetters, ContextMenuActions, ContextMenuMutators {
	state: ContextMenuState
}
const store = createStore(setup) as ContextMenuStore
export default store

export interface Item {
	label: string
	disabled: boolean
	onClick: () => void
}