import { Box, SxProps, Typography } from '@mui/material';
import React, { FunctionComponent } from "react";



interface Props {
	/** Titolo che appare a sinistra */
	title?: string | React.ReactNode
	/** sottotitolo, appare minore nella riga in basso al "title" */
	subtitle?: React.ReactNode
	isLast?: boolean

	/** il flex del "title" */
	flex?: number
	sx?: SxProps
	sxLabel?: SxProps
	sxChildren?: SxProps

	children?: React.ReactNode
}

const Paragraph: FunctionComponent<Props> = ({
	title,
	subtitle,
	isLast = false,

	flex = 40,
	sx,
	sxLabel,
	sxChildren,

	children,
}) => {



	return <Box sx={[sxRoot(isLast), sx as any]}>

		<Box sx={[sxLabelRoot(flex), sxLabel as any]}>

			<Typography variant='subtitle2'>{title}</Typography>

			{subtitle && (<Typography variant='subtitle2' sx={sxSubtitle}>
				{subtitle}
			</Typography>)}

		</Box>

		<Box sx={[sxRootChildren(flex), sxChildren as any]}>
			{children}
		</Box>

	</Box>
}

export default Paragraph

const sxRoot = (isLast: boolean): SxProps => ({
	display: "flex",
	alignItems: "center"/*baseline*/,
	mb: isLast ? 0 : "5px"
})

const sxLabelRoot = (flex: number): SxProps => ({
	flex,
	display: "flex",
	flexDirection: "column",
	paddingRight: "15px"
})

const sxSubtitle: SxProps = {
	color: "text.sub",
	mt: "-6px",
	mb: "-10px"
}

const sxRootChildren = (flex: number): SxProps => ({
	display: "flex",
	flex: flex == 1 ? null : 100
})