

/**
 * Aggiorna i parametri della query string di un url
 */
export function queryUpdateUrl(params: string[], queryUrl?: string,) {
	if (!queryUrl) queryUrl = window.location.search
	const queryParams = new URLSearchParams(queryUrl)
	for (let cont = 0; cont < params.length; cont += 2) {
		const value = params[cont + 1]
		const name = params[cont]
		if (value != null && value.toString().length > 0) {
			queryParams.set(name, value)
		} else {
			queryParams.delete(name)
		}
	}
	return queryParams.toString()
}

export function queryFromArray(param: string[]): string {
	return param.join("_")
}

export function queryToArray(queryString: string): string[] {
	return queryString.split("_")
}