import { Close as CloseIcon } from "@mui/icons-material";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import theme from "styles/theme";



interface Props {
	label?: string
	icon?: React.ReactNode
	tooltip?: string
	active?: boolean
	noClear?: boolean
	onClick?: (e: React.MouseEvent) => void
	onClear?: (e: React.MouseEvent) => void
	sx?: SxProps
}

/**
 * picker per una range di tipo DateRange
 */
const Chip: FunctionComponent<Props> = ({
	label,
	icon,
	tooltip,
	active,
	noClear,
	onClick,
	onClear,
	sx,
}) => {

	// HOOKs

	// HANDLERs

	// RENDER 
	return <Box
		sx={[sxChip(active), sx as any]}
		onClick={onClick}
	>
		<Tooltip title={tooltip}>
			<Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
				{icon}
				<Typography variant="caption" color="common.black">
					{label}
				</Typography>
			</Box>
		</Tooltip>
		{active && !noClear && <CloseIcon sx={{ width: "14px" }} onClick={onClear} />}
	</Box>
}

export default Chip

const sxChip = (haveValue: boolean): SxProps => ({
	display: "flex", cursor: "pointer", alignItems: "center", gap: "2px",
	height: "22px",
	backgroundColor: haveValue ? theme.palette.secondary.main : theme.palette.grey.light,
	borderRadius: "10px",
	padding: "2px 8px",
	color: "#000",
})