import ajax from "plugins/AjaxService"
import { Production } from "types/Production"



/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-25555
 */
function index(farmId: number): Promise<{ data: Production[] }> {
	return ajax.get(
		`farms/${farmId}/productions`
	)
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33915
 */
function show(production: Production): Promise<{ data: Production }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return ajax.get(
		`farms/${farmId}/productions/${prodUuid}`
	)
}

/**
 * CREATE / UPDATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33935
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-30275
 */
function save(production: Production): Promise<{ data: Production }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	// UPDATE
	if (!!prodUuid) {
		return ajax.patch(
			`farms/${farmId}/productions/${prodUuid}`,
			{ plannerProduction: production }
		)
		// CREATE
	} else {
		return ajax.post(
			`farms/${farmId}/productions`,
			{ plannerProduction: production }
		)
	}
}

/**
 * ARCHIVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-33955
 */
async function archive(production: Production): Promise<void> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	await ajax.post(
		`farms/${farmId}/productions/${prodUuid}/archive`
	)
}

/**
 * RESTORE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-34195
 */
async function restore(production: Production): Promise<void> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	await ajax.post(
		`farms/${farmId}/productions/${prodUuid}/restore`
	)
}

/**
 * DELETE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-30295
 */
async function destroy(production: Production): Promise<void> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	await ajax.delete(
		`farms/${farmId}/productions/${prodUuid}`
	)
}


const api = {
	index,
	show,
	save,
	archive,
	restore,
	destroy,
}
export default api