import { Share } from "@mui/icons-material";
import { IconButton, SxProps, Tooltip } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import querySo from "stores/route/query";



interface Props {
	sx?: SxProps
	disabled?: boolean
}

const ButtonShare: FunctionComponent<Props> = ({
	sx,
	disabled,
}) => {

	// HOOKs
	const msgWelcome = "CLICK TO COPY LINK"
	const msgCopied = "LINK COPIED"
	const [msg, setMsg] = useState(msgWelcome)

	// HANDLERs
	const handleClick = (e) => {
		e.stopPropagation()
		const url = window.location.href
		navigator.clipboard.writeText(url)
		setMsg(msgCopied)
	}
	const handleEnter = () => setMsg(msgWelcome)

	// RENDER

	return <Tooltip title={msg} placement="top">
		<IconButton sx={sx}
			onClick={handleClick}
			onMouseEnter={handleEnter}
			size="medium"
			disabled={disabled}
		><Share /></IconButton>
	</Tooltip>
}

export default ButtonShare
