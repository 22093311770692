const LogoIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		{...props}
	>
		<svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.3044 7.77246V9.54032H8.69201V11.7501H12.6803V13.3961H8.69201V17.3433H6.59131V7.77246H13.3044Z"/>
			<path d="M14.8571 6.41611C15.1768 6.06559 15.6639 5.89795 16.3337 5.89795C16.562 5.89795 16.7751 5.92843 16.973 5.98939C17.1709 6.05035 17.3536 6.14179 17.5058 6.26371C17.658 6.38563 17.7798 6.53804 17.8712 6.72092C17.9625 6.9038 18.0082 7.11716 18.0082 7.34577C18.0082 7.69629 17.9168 7.97061 17.7494 8.16873C17.5819 8.36686 17.3688 8.54974 17.1253 8.71738C16.8817 8.86978 16.6229 9.03742 16.3794 9.18983C16.1206 9.34223 15.8922 9.54035 15.7096 9.78419H17.9625V10.6529H14.37C14.37 10.3633 14.4157 10.1195 14.4918 9.92135C14.5679 9.72323 14.6744 9.54035 14.8114 9.37271C14.9484 9.20507 15.1007 9.0679 15.2833 8.93074C15.466 8.80882 15.6639 8.67166 15.877 8.54974C15.9836 8.47354 16.0901 8.41258 16.2119 8.33638C16.3337 8.26018 16.4402 8.18397 16.5468 8.09253C16.6534 8.00109 16.7295 7.90965 16.8056 7.80297C16.8665 7.69629 16.9121 7.57437 16.9121 7.43721C16.9121 7.25432 16.8513 7.08668 16.7295 6.96476C16.6077 6.84284 16.4555 6.76664 16.2576 6.76664C16.1054 6.76664 15.9836 6.79712 15.877 6.85808C15.7705 6.91904 15.6943 7.01048 15.6335 7.10192C15.5726 7.2086 15.5269 7.31528 15.5117 7.43721C15.4812 7.55913 15.4812 7.66581 15.4812 7.77249H14.3852C14.37 7.22384 14.5222 6.76664 14.8571 6.41611Z"/>
			<path d="M11.9953 0.579126C5.70842 0.579126 0.578454 5.69982 0.578454 12.0093C0.578454 18.3034 5.6932 23.4394 11.9953 23.4394C18.2974 23.4394 23.4121 18.3187 23.4121 12.0093C23.4121 5.71506 18.2974 0.579126 11.9953 0.579126ZM11.9953 24.0337C5.38875 24.0337 0 18.6387 0 12.0093C0 5.39502 5.38875 0 11.9953 0C18.6018 0 23.9906 5.39502 23.9906 12.0093C23.9906 18.6387 18.6171 24.0337 11.9953 24.0337Z"/>
		</svg>
	</svg>
)

export default LogoIcon