import { Send as SendIcon } from "@mui/icons-material";
import { Box, BoxProps, IconButton } from "@mui/material";
import { useStore } from "@priolo/jon";
import TextField from "components/controls/fields/TextField";
import React, { FunctionComponent } from "react";
import eventSo from "stores/event";
import palette from "styles/palette";



const ChatField: FunctionComponent<BoxProps> = ( props ) => {

	// STORE
	const eventSa = useStore(eventSo)

	// HANDLERs
	const handleSend = () => {
		eventSo.send()
	}
	const handleChangeSend = (e: React.ChangeEvent<any>) => {
		eventSo.setTextSend(e.target.value)
	}
	function handleKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Enter') {
			eventSo.send()
		}
	}

	// RENDER
	return <Box {...props}>
		<TextField sx={cssText}
			value={eventSa.textSend}
			onChange={handleChangeSend}
			onKeyDown={handleKeyDown}
		/>
		<IconButton
			onClick={handleSend}
		>
			<SendIcon />
		</IconButton>
	</Box>
}

export default ChatField

const cssText = {
	flex: 1,
	bgBox: palette.background.card, 
	mr: 1,
}