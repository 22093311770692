const Icon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		{...props}
	>
		<path d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20Z" />
		<path d="M7 11V13H17V11H7Z" />
		<path d="M7 14V16H17V14H7Z" />
		<path d="M7 17V19H17V17H7Z" />
	</svg>
)

export default Icon