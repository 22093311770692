import { createStore, StoreCore } from "@priolo/jon";
import { DefineDataDraft } from "api/cycles";
import dayjs from "dayjs";
import i18n from "i18next";
import cropSo from "stores/library/crop";
import cycleSo from "stores/cycle";
import growUnitSo from "stores/growUnit";
import { isBatchDesired } from "stores/growUnit/utils";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import { DateNumber, PhaseString } from "types/global";
import { dateToString, haveValues, urlToDateRange } from "utils";
import { getLayers, getUngroupSelectedLayers, LayersNumberByPhase } from "./utils/layer";
import { cropInOverlap } from "./utils/overlap";
import draftSo from "stores/draft"


// used when dialog closed
let resolveClose = null

const setup = {

	state: {
		/** indica se la dialog è aperta o no */
		isOpen: false,
		/**  la data per il seeding selezionata */
		seedingDate: <DateNumber>null,
		/** il CROP  selezionato */
		cropId: <number>null,
		/** la GROW-UNIT selezionata */
		growUnitId: <number>null,
		/** LAYER selezionati */
		layersSelected: <LayersNumberByPhase>{},
	},

	getters: {
		/** restituisce se per una determinata PHASE c'e' ul LAYER selezionato */
		getSelected(
			{ phase, layerNumber }: { phase: PhaseString, layerNumber: number },
			store?: CycleNewDialogStore
		) {
			return store.state.layersSelected[phase]?.includes(layerNumber)
		},
		/** restituisce se una data o serie di layer sono in overlap */
		getLayersInOverlap(_: void, store?: CycleNewDialogStore) {
			const growUnit = growUnitSo.getByID(store.state.growUnitId)
			const crop = cropSo.getById(store.state.cropId)
			if (!growUnit || !crop) return null
			return cropInOverlap(cycleSo.state.all, growUnit, crop, store.state.seedingDate)
		},
	},

	actions: {
		/** 
		 * apre la dialog 
		 */
		open: async (_: void, store?: CycleNewDialogStore) => {
			// mi accerto di avere i CROPS
			await cropSo.fetchAllIfVoid()
			// setto i valori di default
			const [dateRangeUrl, growUnitUrl] = querySo.getSearchUrl([URL_PAR.DATE_SEL, URL_PAR.GROWUNIT_FIL])
			const dateRange = urlToDateRange(dateRangeUrl)
			store.setSeedingDate(haveValues(dateRange) ? dateRange.start : dayjs().valueOf())
			if (growUnitUrl) store.setGrowUnitId(+growUnitUrl)
			//store.setVerifyOverlap(null)
			store.setLayersSelected({})
			// apro la dialog
			store.setIsOpen(true)
			return new Promise((resolve, reject) => resolveClose = resolve)
		},
		/** 
		 * chiude la dialog 
		 */
		close: async (save: boolean, store?: CycleNewDialogStore) => {
			store.setIsOpen(false)
			let haveSaved = false
			if (save) {
				haveSaved = await store.save()
				dialogSo.dialogOpen(haveSaved
					? { type: DIALOG_TYPE.INFO, modal: false, text: i18n.t("snackbar.cycle.defined") }
					: { type: DIALOG_TYPE.ERROR, modal: false, text: i18n.t("snackbar.cycle.error") }
				)
			}
			if (resolveClose) resolveClose(haveSaved)
			resolveClose = null
		},
		/** 
		 * salvo in DRAFT un nuovo CYCLE
		 */
		save: async (_: void, store?: CycleNewDialogStore) => {
			const growUnit = growUnitSo.getByID(store.state.growUnitId)
			const crop = cropSo.getById(store.state.cropId)
			if (!growUnit || !crop) return
			const isBatch = isBatchDesired(growUnit)
			const layers = isBatch ? getLayers(growUnit, crop) : getUngroupSelectedLayers(growUnit, store.state.layersSelected)
			const cycleDef:DefineDataDraft = {
				growUnitId: growUnit.id,
				cropCycleId: store.state.cropId,
				seedingDate: dateToString(store.state.seedingDate),
				phaseLayers: layers
			}
			try {
				const newCycle = await draftSo.createCycle(cycleDef)
				querySo.setSearchUrl([URL_PAR.CYCLE_SEL, newCycle.cycleUuid])
				return true
			} catch (e) {
				return false
			}
		},
		/** data la phase e il numero di layer lo memorizza tra i selezionati */
		select(
			{ phase, layerNumber, select }: { phase: PhaseString, layerNumber: number, select: boolean },
			store?: CycleNewDialogStore
		) {
			const layersByPhase = { ...store.state.layersSelected }
			let layers = layersByPhase[phase]
			if (!layers) {
				layers = []
				layersByPhase[phase] = layers
			}
			const index = layers.findIndex(layer => layer == layerNumber)
			if (index == -1 && select) layers.push(layerNumber)
			if (index != -1 && !select) layers.splice(index, 1)
			store.setLayersSelected(layersByPhase)
		},
	},

	mutators: {
		setIsOpen: (isOpen: boolean) => ({ isOpen }),
		setSeedingDate: (seedingDate: DateNumber) => ({ seedingDate }),
		setCropId: (cropId: number) => ({ cropId }),
		setGrowUnitId: (growUnitId: number) => ({ growUnitId }),
		setLayersSelected: (layersSelected: LayersNumberByPhase) => ({ layersSelected }),
	},
}


export type CycleNewDialogState = typeof setup.state
export type CycleNewDialogGetters = typeof setup.getters
export type CycleNewDialogActions = typeof setup.actions
export type CycleNewDialogMutators = typeof setup.mutators
export interface CycleNewDialogStore extends StoreCore<CycleNewDialogState>, CycleNewDialogGetters, CycleNewDialogActions, CycleNewDialogMutators {
	state: CycleNewDialogState
}
const store = createStore(setup) as CycleNewDialogStore
export default store

