import { Box } from "@mui/material";
import dayjs from "dayjs";
import React, { FunctionComponent } from "react";
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import { dateToHuman, dateToString } from "utils";



interface PHProps {
	prevDate?: number
	currDate?: number
	desc?: boolean
}

const DatePlaceholder: FunctionComponent<PHProps> = ({
	prevDate,
	currDate,
	desc,
}) => {
	const dateSel = dayjs(querySo.getSearchUrl(URL_PAR.DATE_SEL))

	// RENDER
	if (!dateSel.isValid()) return null
	if (!prevDate && !currDate) return null
	if ((currDate && dateSel.isSame(currDate, "day")) || (prevDate && dateSel.isSame(prevDate, "day"))) return null
	if (currDate && prevDate && !dateSel.isBetween(prevDate, currDate, "day", "()")) return null
	if (desc) {
		if (!prevDate && dateSel.isBefore(currDate, "day")) return null
		if (!currDate && dateSel.isAfter(prevDate, "day")) return null
	} else {
		if (!currDate && dateSel.isBefore(prevDate, "day")) return null
		if (!prevDate && dateSel.isAfter(currDate, "day")) return null
	}
	const dateFrm = dateToHuman(dateSel)
	const dateId = dateToString(dateSel)

	return <Box id={dateId} sx={cssDatePlaceholder}>
		{dateFrm}
	</Box>
}

export default DatePlaceholder

const cssDatePlaceholder = {
	borderRadius: '5px',
	backgroundColor: 'secondary.main',
	color: 'common.black',
	marginBottom: '15px',
	fontSize: '14px',
	fontWeight: '600',
	padding: '5px 20px'
}