import dayjs from 'dayjs';
import productSo from "stores/library/product";
import querySo from 'stores/route/query';
import { URL_PAR } from 'stores/route/utils/types';
import { PRODUCTION_STATUS, Production, REQUEST_STATUS, Request, Solution } from 'types/Production';
import { Uuid } from 'types/global';



export enum PROD_SHOW {
	PRODUCTIONS,
	FAVORITES,
	COMPARISION,
}

export function buildRequest(production?: Production): Request {
	const startingFrom = production?.startDate ?? dayjs().format("YYYY-MM-DD")
	const timeFrame = production?.endDate ? dayjs(production.endDate).diff(dayjs(production.startDate), "days") : 30
	const request: Request = {
		uuid: null,
		name: "",
		status: REQUEST_STATUS.PENDING,
		constraints: {
			startingFrom,
			harvestPeriod: 7,
			numberHarvestPerPeriod: [],
			timeFrame,
			maxDailyWorkload: 50 * 60,
			harvestOnlyOn: [],
			layersPerHarvest: { min: 1, max: 50 },
			enableElasticPhases: true,
			enableMultiGroupsTries: false,
			timeoutPerIteration: 30,
		},
		products: [],
		productionUuid: production.uuid,
		solutions: [],
	}
	return { ...request, constraints: { ...request.constraints } }
}

export function getProductionsView(prods: Production[]): Production[] {
	if (!prods) return []
	let [state] = querySo.getSearchUrl([URL_PAR.PRODUCTION_STATE])
	if (!state) state = PRODUCTION_STATUS.ACTIVE
	return prods
		.filter(p => p.status == state)
		.sort((a, b) => a.name.localeCompare(b.name))
}

/** REQUEST filtrate secondo la "text" */
export function getRequestsView(requests: Request[], text?: string): Request[] {
	let res = requests ?? []
	const txt = text?.trim().toLowerCase() ?? ""
	if (txt.length > 0) {
		res = res.filter(request => {
			if (!request.name?.toLowerCase().includes(txt)
				//&& !request.solutions?.some( s => s.solutionCrops.some( sc => cropSo.getByUuid(sc.cropUuid)?.name?.toLowerCase().includes(txt)))
				&& !request.products?.some(p => productSo.getByUuid(p.productUuid)?.name?.toLowerCase().includes(txt))
			) return false
			return true
		})
	}
	res.sort((r1, r2) => {
		if (!!r1.createdAt && !!r2.createdAt) return dayjs(r2.createdAt).valueOf() - dayjs(r1.createdAt).valueOf()
		return r1.name.localeCompare(r2.name)
	})
	return res
}

export function getSolutionsView(solutions: Solution[]): Solution[] {
	if (!solutions) return []
	return solutions.sort((s1, s2) => s2.metrics?.score - s1.metrics?.score)
}

export function getProductPercLabel(productsPerc: { productUuid: Uuid, percentage: number }[]): string {
	if (!productsPerc || productsPerc.length == 0) return null
	return productsPerc?.map(pp => {
		const product = productSo.getByUuid(pp.productUuid)
		return product ? `${pp.percentage}% ${product.name}` : ""
	}).join(", ")
}