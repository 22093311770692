import { Entity } from "./Entity"
import { DateString, TimeString, TimestampString, Uuid } from "./global"



/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-97/TASKs
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-160/Drafts
 */
export interface Task extends Entity<Task> {
	id: number
	taskUuid: Uuid

	//#region DRAFT
	/** preso dal /api/tasks_drafts/from_template */
	taskDraftUuid: Uuid
	/** points to the production task (if exist) or a new uuid that will match the task when created */
	refToTaskUuid: Uuid
	/** if the tasks is linked to a cycle */
	cycleUuid: Uuid
	//#endregion

	relatedTo: RELATED_TO
	farmId: number

	/** only if if the task is linked to a grow unit */
	growUnitId: number

	subject: string
	scope: TASK_SCOPE
	description: string

	isCancelled: boolean
	isClosed: boolean
	firstClosedAt: DateString
	lastClosedAt: DateString
	/** la data del TASK YYYY-MM-DD */
	dueDate: DateString
	/** l'ora del TASK HH:MM */
	dueTime: TimeString

	/** soomato a "trolleysPoints" permette di calcolare "totalPoints"  */
	points: number 			// float 
	/** soomato a "points" permette di calcolare "totalPoints"  */
	trolleysPoints: number 	// float  
	/** è praticamente la somma di "points" + "trolleysPoints" */
	totalPoints: number 	// float 
	/** (true) cancella il TASKs se il CYCLE abbinato è stato REJECTED */
	cancelIfCycleRejected: boolean
	/** (true) updates the rendering of the TASKs */
	updatesYield: boolean
	taskTemplateId: number
	/** if this property is null then existing metadata remain  */
	metadata: any,
	/** if this property is null then existing subtasks remain  */
	subtasks: SubTask[]
	/** used in resync with template.index the subtasks resynced from template */
	resyncedSubtasks: SubTask[]

	createdAt: TimestampString
}

export enum RELATED_TO {
	FARM = "farm",
	GROW_UNIT = "grow_unit",
	CYCLE = "cycle",
}

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-97/TASKs#subtask
 */
export interface SubTask {
	id?: number
	taskId?: number
	subtaskTemplateId?: number
	subtaskUuid?: Uuid
	subject?: string
	description?: string
	layerNumber?: number
	input?: boolean
	inputValue?: any
	createdAt?: TimestampString
	updatedAt?: TimestampString
}

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-97/TASKs#scope
 * gli scope disponibili per un TASK
 */
export enum TASK_SCOPE {
	PREVIEW = "preview",
	AGRO = "agro",
	MACHINERY = "machinery",
	MAINTENANCE = "maintenance",
	PRODUCTION = "production",
}