import ajax from "plugins/AjaxService"

/**
 * Returns the data of CURRENT-USER
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-204/get
 */
function getCurrent() {
	return ajax.get("user")
}

/**
 * Start the session with a user via email and password (and opt-code)
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-181/sign_in
 */
function login(email: string, password: string, otpCode: string) {
	if (otpCode == "") otpCode = undefined
	return ajax.post(
		"users/sign_in",
		{ api_user: { email, password, otpAttempt: otpCode } },
		{ isLogin: true }
	)
}

/**
 * Logs out the CURRENT-USER
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-205/sign_out
 */
function logout() {
	return ajax.delete("users/sign_out")
}

const authApi = {
	getCurrent,
	login,
	logout,
}

export default authApi