import { createStore, StoreCore } from "@priolo/jon";
import farmSo from "stores/farm";



/**
 * Gestione EVENTS cioe' gli i messaggio di ROOT
 */
const setup = {
	state: {
		code: <string>null,
		title: <string>null,
		open: false,
		url: <string>null,
		heigth: 400,
	},
	getters: {
		getByCode: (code: string, store?: GrafanaStore) => farmSo.state.componentSelect?.options?.grafana?.find(opt => opt.code == code) ?? { title:null, url: null}
	},
	actions: {
		toggle: (code: string, store?: GrafanaStore) => {
			const isSelect = store.state.code == code && store.state.open
			if ( isSelect ) store.close(); else store.openCode(code)
		},
		openCode: (code: string, store?: GrafanaStore) => {
			const { title, url } = store.getByCode(code)
			if (!url) return
			store.setDrawer({ open: true, code, title, url })

		},
		open: ({ title, url }: { title: string, url: string }, store?: GrafanaStore) => {
			if (!url) return
			store.setDrawer({ open: true, title, url, code: null })
		},
		close: (_: void, store?: GrafanaStore) => {
			store.setDrawer({ open: false })
		}
	},
	mutators: {
		setDrawer: (props) => props,
		setHeigth: (heigth:number)=> ({heigth}),
	},
}

export type GrafanaState = typeof setup.state
export type GrafanaGetters = typeof setup.getters
export type GrafanaActions = typeof setup.actions
export type GrafanaMutators = typeof setup.mutators
export interface GrafanaStore extends StoreCore<GrafanaState>, GrafanaGetters, GrafanaActions, GrafanaMutators {
	state: GrafanaState
}

const grafanaSo = createStore(setup) as GrafanaStore
export default grafanaSo
