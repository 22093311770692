import { Close } from "@mui/icons-material";
import { Box, Chip, SxProps } from "@mui/material";
import React, { ForwardRefRenderFunction, forwardRef } from "react";
import LabelTag from "../LabelTag";
import WrapControl, { ICON_VARIANT } from "./WrapControl";



export interface SelectorChipBaseProps {
	values?: any[]
	valuesOnlyRead?: string[]
	placeholder?: string
	disabled?: boolean
	helperText?: string
	refIcon?: any
	variantIcon?:ICON_VARIANT,

	onChange?: (newValues: any[]) => void
	onClick?: (e: React.MouseEvent) => void

	fnTextFromItem?: (item: any) => string
	fnSxFromItem?: (item: any) => SxProps
	sx?: any
}

const SelectorChipsBase: ForwardRefRenderFunction<any, SelectorChipBaseProps> = ({
	values = [],
	valuesOnlyRead = [],
	placeholder,
	disabled,
	helperText,
	refIcon,
	variantIcon = ICON_VARIANT.PAGE,

	onChange,
	onClick,

	fnTextFromItem = (item) => item,
	fnSxFromItem = (item) => null,
	sx,
}, ref) => {

	// HOOKs

	// HANDLE
	const handleClickDelete = (index: number) => {
		if (disabled) return
		const newValues = values.filter((_, i) => i != index)
		onChange(newValues)
	}
	const handleClick = (e: React.MouseEvent) => {
		if (disabled) return
		onClick?.(e)
	}

	// RENDER
	const notHaveValues = ((values?.length ?? 0) + (valuesOnlyRead?.length ?? 0)) == 0

	return (
		<WrapControl 
			sx={sx} disabled={disabled}
			variantIcon={variantIcon}
			onClick={handleClick}
			ref={ref}
			refIcon={refIcon}
			helperText={helperText}
		>
			<Box sx={{ display: "flex", flexWrap: "wrap", rowGap: "4px", gap: 1, m: "4px 0px" }}
				onClick={handleClick}
			>
				{notHaveValues ? <Box sx={sxPlaceholder}>{placeholder}</Box> : valuesOnlyRead.map((value, index) => (

					<Chip key={index}
						label={<LabelTag label={fnTextFromItem(value)} />}
					/>

				)).concat(values.map((value, index) => (

					<Chip key={index + valuesOnlyRead.length}
						label={<LabelTag sx={fnSxFromItem(value)} label={fnTextFromItem(value)} />}
						onDelete={() => handleClickDelete(index)}
						deleteIcon={<Close sx={{ width: "18px" }} />}
					/>

				)))}
			</Box>
		</WrapControl>
	)
}

export default forwardRef(SelectorChipsBase)

const sxPlaceholder: SxProps = {
	fontSize: 14,
	fontWeight: 300,
	opacity: .5,
}