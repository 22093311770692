import { Box, Typography } from '@mui/material';
import { useStore } from '@priolo/jon';
import Paragraph from 'components/controls/form/Paragraph';
import { FunctionComponent } from 'react';
import dialogSo from 'stores/cycle/editDialog';
import farmSo from 'stores/farm';
import { getGrowUnitLayerName, getLayerByNumber } from 'stores/growUnit/utils/layer';
import { groupLayersByPhases } from "stores/phase/layers";
import { PHASE_SLUG, getPhaseLabel } from 'stores/phase/utils';
import { Layer } from 'types/Layer';



/**
 * Visualizza i LAYER raggruppati per PHASEs di un CYCLE
 */
const CycleLayersShow: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)

	// HOOKs

	// RENDER

	// i LAYERs raggruppati per PHASE
	const phasesGroups = groupLayersByPhases<Layer>(dialogSa.cycle.layers)
	// i nomi dei layer utilizzati da questo CYCLE
	const getNameLayer = (layer: Layer) => {
		const growUnit = farmSo.getGrowUnit(dialogSa.cycle.growUnitId ?? layer.growUnitId)
		return getGrowUnitLayerName(growUnit, getLayerByNumber(growUnit, layer.layerNumber))
	}
	const phases = Object.keys(phasesGroups)

	return <>{
		phases.map(phase => (
			<Paragraph key={phase} sx={{ alignItems: "start" }}
				title={phase != PHASE_SLUG.BATCH ? getPhaseLabel(phase)?.toUpperCase() : ""}
			>
				<Box sx={cssRow}>
					{phasesGroups[phase].map((layer) => (
						<ChipLayer key={layer.layerNumber}
							label={getNameLayer(layer)}
						/>
					))}
				</Box>
			</Paragraph>
		))
	}</>

}

export default CycleLayersShow


const cssRow = {
	display: "flex",
	margin: "5px 0px",
	flexWrap: "wrap",
	gap: 1,
}

interface ChipLayerProps {
	label?: string
	disabled?: boolean
}
export const ChipLayer: FunctionComponent<ChipLayerProps> = ({
	label,
	disabled
}) => {
	return <Box sx={[cssChip, { bgcolor: disabled ? "text.disabled" : "secondary.main" }]}>
		<Typography variant="caption" color="secondary.contrastText">
			{label}
		</Typography>
	</Box>
}

const cssChip = {
	borderRadius: "4px",
	padding: "2px 7px 0px 7px",
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
}