import { Add as AddIcon } from "@mui/icons-material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const AlarmTableFooter: FunctionComponent = () => {

	const { t } = useTranslation()
	return <ButtonCmp  sx={{ ml: "26px" }}
		shape={BTT_SHAPE.MAIN}
		href="/alarms/new"
		startIcon={<AddIcon />}
	>
		{t("footer.alarm.new")}
	</ButtonCmp>
}

export default AlarmTableFooter