import { FilterAlt } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { useStore } from "@priolo/jon";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import querySo from 'stores/route/query';
import SelectorDialogBase from "../base/SelectorDialogBase";
import Chip from "./Chip";



interface Props {
	sx?: SxProps
	title?: string
	states: any[]
	state: any
	stateDefault?: string
	fnGetLabel?: (state: any) => string
	onClose: (state: any) => void
}

const SelectorStateH: FunctionComponent<Props> = ({
	sx,
	title = "SELECT A STATE",
	states,
	state,
	stateDefault,
	fnGetLabel = (state) => state.toString(),
	onClose,
}) => {

	// STORE
	const querySa = useStore(querySo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => setIsOpen(true)
	const handleClose = (newState: any) => {
		setIsOpen(false)
		if ( !newState ) return
		onClose(newState)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		handleClose?.(stateDefault)
	}

	// RENDER
	if (!states || states.length == 0) return null
	if (!state) state = stateDefault
	const label = fnGetLabel(state)
	const isActive = state != stateDefault

	return (<>
		<Chip sx={sx}
			tooltip={"DISPLAYS/HIDES ARCHIVED ITEMS"}
			active={isActive}
			icon={<FilterAlt />}
			label={label}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			icon={<FilterAlt />}
			width={"auto"}
			height={null}
			title={title}

			items={states}
			idSelect={state}

			isOpen={isOpen}
			fnTextFromItem={fnGetLabel}
			onClose={handleClose}
		/>
	</>)
}

export default SelectorStateH
