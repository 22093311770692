import ajax from "plugins/AjaxService"
import { Crop } from "types/Crop"
import humps from "humps"



// url builder
const urlBuilder = (cropId?: number) => `crops${cropId ? `/${cropId}` : ""}`

/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28875
 */
const index = (): Promise<{ data: Crop[] }> => ajax.get(urlBuilder())

/**
 * GET
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28915
 */
async function get(cropId: number): Promise<{ data: Crop }> {
	const { data: crop_snake } = await ajax.get(urlBuilder(cropId), null, { noCamelize: true })
	//const customFields = crop_snake.custom_fields
	const crop: Crop = humps.camelizeKeys(crop_snake)
	crop.customFields = crop_snake.custom_fields
	return { data: crop }
}

/**
 * SAVE AND NEW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28935
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28955
 */
const save = (cropId: number, crop: Partial<Crop>): Promise<{ data: Crop }> => {
	// const customFields = crop.customFields
	// const cost1kg = crop.cost1kg
	const crop_snake = humps.decamelizeKeys(crop)
	crop_snake.custom_fields = crop.customFields
	crop_snake.cost_1kg = crop.cost1kg
	delete crop["cost1kg"]

	if (cropId) {
		return ajax.put(urlBuilder(cropId), { crop: crop_snake }, { noDecamelize: true })
	} else {
		return ajax.post(`${urlBuilder()}/define`, { crop: crop_snake }, { noDecamelize: true })
	}
}

/**
 * DELETE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29015
 */
const destroy = (cropId: number): Promise<void> => ajax.delete(urlBuilder(cropId))

/**
 * ARCHIVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28975
 */
const archive = (cropId: number): Promise<{ data: Crop }> => ajax.post(`${urlBuilder(cropId)}/archive`)

/**
 * BULK ARCHIVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-47215
 */
const bulkArchive = (cropsIds: number[]): Promise<void> => ajax.post(`crops/bulk_archive`, { cropsIds })

/**
 * RESTORE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28995
*/
const restore = (cropId: number): Promise<{ data: Crop }> => ajax.post(`${urlBuilder(cropId)}/restore`)


const cropsApi = {
	index,
	get,
	save,
	destroy,
	archive,
	bulkArchive,
	restore,
}
export default cropsApi