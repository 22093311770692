import { SolutionTaskGroup } from "types/Production"
import { RELATED_TO, TASK_SCOPE, Task } from "types/Task"



/**
 * da un array i SOLUTION-TASKS restituisce un array di TASKS
 */
export function tasksFromSolutionTasks(
	solutionsTasks: SolutionTaskGroup[],
	farmId: number,
): Task[] {
	const tasks = solutionsTasks.map<Task>(solutionTask => {
		const relatedTo = !!solutionTask.cycleUuid ? RELATED_TO.CYCLE : RELATED_TO.FARM
		return {
			id: null,
			taskUuid: solutionTask.uuid,
			taskDraftUuid: solutionTask.uuid,
			refToTaskUuid: solutionTask.uuid,
			cycleUuid: solutionTask.cycleUuid,
			relatedTo,
			farmId,
			growUnitId: null,

			subject: solutionTask.description,
			scope: TASK_SCOPE.PREVIEW,
			description: "",

			isCancelled: false,
			isClosed: false,
			firstClosedAt: null,
			lastClosedAt: null,
			dueDate: solutionTask.dueDate,
			dueTime: null,

			points: 0,
			trolleysPoints: 0,
			totalPoints: solutionTask.occupationWeight,
			cancelIfCycleRejected: true,
			updatesYield: false,
			taskTemplateId: null,
			metadata: null,
			subtasks: [],
			resyncedSubtasks: [],
			createdAt: null,
		}
	})
	return tasks
}

