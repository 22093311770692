import ajax from "plugins/AjaxService"
import { Draft } from "types/Draft"
import { Production, Solution } from "types/Production"



/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-34095
 */
async function index(production: Production): Promise<{ data: Solution[] }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.get(
		`farms/${farmId}/productions/${prodUuid}/solutions`
	)
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-34115
 */
async function show(production: Production, solUuid: string): Promise<{ data: Solution }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.get(
		`farms/${farmId}/productions/${prodUuid}/solutions/${solUuid}`
	)
}

/**
 * PROMOTE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-35035
 */
async function promote(production: Production, solUuid: string, description: string = "..."): Promise<{ data: Draft }> {
	const farmId = production.farmId
	const prodUuid = production.uuid
	return await ajax.post(
		`farms/${farmId}/productions/${prodUuid}/solutions/${solUuid}/promote`,
		{ productionSolution: { draftDescription: description } }
	)
}

const api = {
	index,
	show,
	promote,
}
export default api