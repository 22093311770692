import ajax from "plugins/AjaxService"
import { Event } from "types/Root"


/**
 * get all EVENTS in dashboard 
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-203/DASHBOARD-EVENTS
 */
function index(): Promise<{ data: Event[] }> {
	return ajax.get("dashboard_events")
}


const api = {
	index,
}
export default api

