import { getGrowUnitName } from "stores/growUnit/utils";
import { Farm } from "types/Farm";
import { COMPONENT_TYPE, GrowUnitComponent } from "types/Service";



export function normalizeFarm(farm: Farm) {
	const config = farm.edgeServicesConfig ?? { components: [] }
	farm.growUnit.forEach(growUnit => {
		if (config.components.some(component =>
			component.type == COMPONENT_TYPE.GROWUNIT &&
			(<GrowUnitComponent>component).options.code == growUnit.growUnitCode
		)) return
		const growUnitCmp: GrowUnitComponent = {
			name: getGrowUnitName(growUnit),
			code: growUnit.growUnitCode,
			type: COMPONENT_TYPE.GROWUNIT,
			options: {
				code: growUnit.growUnitCode,
				fans: [],
			}
		}
		config.components.push(growUnitCmp)
	})
	farm.edgeServicesConfig = config
	return farm
}