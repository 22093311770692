import ajax from "plugins/AjaxService"
import { GROW_UNIT_ACTION } from "types/GrowUnitEnum"


// url builder
const urlBuilder = (growUnitId?: number) => `grow_units${growUnitId ? `/${growUnitId}` : ""}`

/**
 * Recupera tutte le GROW-UNITs
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27935
 */
const index = () => ajax.get(urlBuilder())

/**
 * Recupera tutti i dati di una specifica GROW-UNIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27955
 */
const get = (growUnitId: number) => ajax.get(urlBuilder(growUnitId))

/**
 * Invia un RECIPE da applicare ad una specifica GROW-UNIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27975
 * { "recipe_id":23, "layers_state": { "layers":[{"layer_id":2919,"layer_number":1,"disabled":false,"phase":"_batch"}, ...]},"layers_matrix_from_cycles":true}
 */
const deployRecipe = (growUnitId: number, data: DeployRecipeData) => {
	return ajax.post(`${urlBuilder(growUnitId)}/set_desired_recipe`, data)
}
export type DeployRecipeData = {
	growUnitId: number,
	recipeId: number,
	layersMatrixFromCycles?: boolean,
	layersState: {
		layers: DeployLayerData[]
	}
}
export type DeployLayerData = {
	layerId: number,
	layerNumber: number,
	disabled: boolean,
	virtual: boolean,
	phase: string,
}

/**
 * Invia un ACTION ad una GROW-UNIT 
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27995
 */
const runAction = (growUnitId: number, type: GROW_UNIT_ACTION, time: string, data: any = {}) => {
	return ajax.post(
		`${urlBuilder(growUnitId)}/action`,
		{
			grow_unit_action: {
				type,
				time,
				...data
			}
		}
	)
}

const api = {
	index,
	get,
	deployRecipe,
	runAction,
}
export default api