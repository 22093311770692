import { Clear } from "@mui/icons-material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import { NATSPublish } from "plugins/nats";
import { FunctionComponent } from "react";
import metaSo from "stores/metadata";



const AlertsFooter: FunctionComponent = () => {

	// STORE

	// HOOKs

	// HANDLERs
	const handleResetAll = () => {
		const resetMetas = metaSo.state.metadata
			.filter(meta => meta.alias.includes("fAlarm_ACK"))
		resetMetas.forEach(meta => NATSPublish(meta.serviceCode, meta.alias, 1))
	}

	// RENDER
	return <>
		<ButtonCmp sx={{ ml: "26px", mr: "20px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<Clear />}
			onClick={handleResetAll}
		>RESET ALL</ButtonCmp>
	</>
}

export default AlertsFooter