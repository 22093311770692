import { Box } from '@mui/material'
import { useStore } from '@priolo/jon'
import DateRangePickerH from 'components/controls/select/DateRangePickerH'
import SelectorCycleH from 'components/controls/select/SelectorCycleH'
import SelectorGrowUnitH from 'components/controls/select/SelectorGrowUnitH'
import SelectorSessionH from 'components/controls/select/SelectorSessionH'
import React, { FunctionComponent, useMemo } from 'react'
import cycleSo from "stores/cycle"
import querySo from 'stores/route/query'
import { URL_PAR } from 'stores/route/utils/types'
import { Cycle } from 'types/Cycle'
import { GrowUnit } from 'types/GrowUnit'
import { DateRange } from 'types/global'
import { dateRangeToUrl, urlToDateRange } from 'utils'



const PlannerFilterHeader: FunctionComponent = () => {

	// STORE
	const querySa = useStore(querySo)
	const cycleSa = useStore(cycleSo)

	// HOOKs

	// HANDLERs
	const handleChangeDateRange = (dateRange: DateRange) => {
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateRangeToUrl(dateRange)])
	}
	const handleChangeGrowUnit = (growUnit: GrowUnit) => {
		querySo.setSearchUrl([URL_PAR.GROWUNIT_FIL, growUnit?.id?.toString()])
	}
	const handleChangeCycle = (cycle:Cycle) => {
		querySo.setSearchUrl([URL_PAR.CYCLE_SEL, cycle?.cycleUuid])
	}

	// RENDER
	const [growUnitId, cycle, dateRangeSel] = useMemo(() => {
		const [growUnitId, cycleUuid, dateRangeSelUrl, scopeUrl] = querySo.getSearchUrl([URL_PAR.GROWUNIT_FIL, URL_PAR.CYCLE_SEL, URL_PAR.DATE_SEL])
		let dateRangeSel = urlToDateRange(dateRangeSelUrl)
		const cycle = cycleSo.getCycleByUuid(cycleUuid)
		return [growUnitId, cycle, dateRangeSel]
	}, [querySa.queryUrl, cycleSa.all])


	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>

			<DateRangePickerH sx={{ mr: 1 }}
				dateRange={dateRangeSel}
				onChange={handleChangeDateRange}
			/>

			<SelectorGrowUnitH sx={{ mr: 1 }}
				growUnitId={+growUnitId}
				onChange={handleChangeGrowUnit}
			/>

			<SelectorCycleH sx={{ mr: 1 }}
				cycle={cycle}
				onChange={handleChangeCycle}
			/>

			<SelectorSessionH sx={{ mr: 1 }} />

		</Box >
	)
}

export default PlannerFilterHeader

