import { Button, ButtonProps, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";
import { useHistory } from "react-router";

export enum BTT_SHAPE {
	DEFAULT = 1,
	MAIN = 2,
	OPTION = 3,
	OPTION_DELETE = 4,
}
interface Props extends ButtonProps {
	tooltip?: string
	shape?: BTT_SHAPE
	href?: string
	newTab?: boolean
}

const ButtonCmp: FunctionComponent<Props> = ({
	tooltip,
	shape = BTT_SHAPE.DEFAULT,
	children,
	href,
	newTab,
	...props
}) => {

	// HOOKs
	const history = useHistory()

	// HANDLES
	const handleClick = (e: React.MouseEvent<any>) => {
		if (newTab) {
			window.open(href)
		} else if (href) {
			history.push(href)
		}
		props.onClick?.(e)
	}

	// RENDER
	let color = shape == BTT_SHAPE.MAIN ? "secondary" : "inherit"
	if (shape == BTT_SHAPE.OPTION_DELETE) color = "error"
	const variant = shape == BTT_SHAPE.MAIN || shape == BTT_SHAPE.DEFAULT ? "button" : "caption"

	return <Tooltip title={tooltip}><div>
		<Button color={color as any}
			onClick={handleClick}
			{...props}
		>
			<Typography variant={variant} sx={cssTxt}>
				{children}
			</Typography>
		</Button>
	</div></Tooltip>

}

export default ButtonCmp

const cssTxt = {
	mt: "2px",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}