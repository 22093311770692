import { CheckCircle, Error as FaultIcon, PauseCircleFilled, PlayCircleFilled } from "@mui/icons-material";
import { FunctionComponent } from "react";
import { EVENT_SUBTYPE, Event } from "types/Root";



interface Props {
	event: Event
}

const SubIcon: FunctionComponent<Props> = ({
	event
}) => {
	return {
		[EVENT_SUBTYPE.PAUSE_NOW]: <PauseCircleFilled />,
		[EVENT_SUBTYPE.PLAY_NOW]: <PlayCircleFilled />,
		[EVENT_SUBTYPE.STATUS_NOW]: <CheckCircle />,
		[EVENT_SUBTYPE.FAULT]: <FaultIcon />,

	}[event.subtype] ?? null
}


export default SubIcon