import { Timestamp, TimestampString } from "./global"

/**
 * POCO per la gestione dei DRAFTs
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-310/DRAFTS
 */
export interface Draft {
	id?: number
	uuid?: string
	farmId?: number
	description?: string
	status?: DRAFT_STATUS
	active?: boolean
	createdAt?: TimestampString
	appliedAt?: TimestampString
}

export enum DRAFT_STATUS {
	/** the DRAFT has been created */
	INIT = "init",
	/** the draft is being promoted from a solution (cycles and task generation in progress */
	PROMOTING_FROM_SOLUTION = "promoting_from_solution",
	/** draft is succesfully promoted from a solution (related cycles and tasks created **/
	PROMOTED_FROM_SOLUTION   = "promoted_from_solution",
	/** Merging into PROD is in progress **/
	APPLYING = "applying",
	/** DRAFT has been merged into PROD **/
	APPLIED = "applied",
}