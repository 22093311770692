import { StoreCore, resetAll, validateAll } from "@priolo/jon"
import foldersApi from "api/folders"
import farmSo from "stores/farm"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import { Folder, FOLDER_SPECIAL_ID } from "types/Recipe"
import { clone } from "utils/func"



const folderSetup = {

	state: {
		/** tutti i folder caricati dal BE */
		allFolder: <Folder[]>null,
		/** il folder attualmente aperto che mosta i RECIPE */
		selectedFolderId: <number>null,
		/** il folder attualmente in editazione */
		folderInEdit: <Folder>null,

		/** se è aperta la dialog di editazione del folder selezionato */
		folderEditorOpen: false,
		/** se è aperta la dialog per selezionare un FOLDER */
		folderSelectorOpen: false,

		repo: <string>null,

				// per la selezione multipla
				selectedIds: <number[]>[]

	},

	getters: {
		getFolderById(id: number, store?: FolderStore): Folder {
			return store.state.allFolder.find(f => f.id == id)
		}
	},

	actions: {

		/** recupero tutti i RECIPEs */
		fetchAllFolder: async (_: void, store?: FolderStore) => {
			const { data } = await foldersApi.index(store.state.repo)
			store.setAllFolder(data)
			store.setSelectedFolderId(FOLDER_SPECIAL_ID.ALL)
		},
		fetchAllFolderIfVoid: async (_: void, store?: FolderStore) => {
			if (!!store.state.allFolder) return
			await store.fetchAllFolder()
		},


		//#region EDIT

		openFolderInEdit: (folder: Folder, store?: FolderStore) => {
			const newFolder: Folder = !!folder
				? clone(folder)
				: { title: "", subtitle: "", farmIds: [] }
			store.setFolderInEdit(newFolder)
			resetAll()
			store.setFolderEditorOpen(true)
		},

		closeFolderInEdit: (_: void, store?: FolderStore) => {
			store.setFolderInEdit(null)
			store.setFolderEditorOpen(false)
		},

		saveFolderInEdit: async (_: void, store?: FolderStore) => {

			if (validateAll().length > 0) return

			// controllo che non ci siano conflitti
			const err = store.state.allFolder.some(folder => {
				// folders da non controllare
				if (store.state.folderInEdit.id == null || folder.id == null || store.state.folderInEdit.id == folder.id) return false
				if (folder.title != store.state.folderInEdit.title) return false
				if (folder.farmIds?.some(id => store.state.folderInEdit?.farmIds.includes(id))) return true
				return false
			})
			if (err) {
				console.log("error")
				return
			}

			// API
			const { data: folder } = await foldersApi.save(store.state.repo, store.state.folderInEdit, store.state.selectedIds)
			

			// create/modify FOLDER
			const index = store.state.allFolder.findIndex(f => f.id == folder.id)
			if (index == -1) {
				store.setAllFolder([...store.state.allFolder, folder])
			} else {
				const folders = [...store.state.allFolder]
				folders[index] = { ...folders[index], ...folder }
				store.setAllFolder(folders)
			}

			// aggiorno l'entities
			store.fetchAll()

			// graph
			store.setSelectedIds([])
			store.setSelectedFolderId(folder.id)
			store.setFolderEditorOpen(false)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: "The folder was updated successfully.",
			})
		},

		deleteFolder: async (id: number, store?: FolderStore) => {

			const folder = store.getFolderById(id)
			if (!folder) return
			if (folder.farmIds?.some(id => farmSo.state.all.find(f => f.id == id) == null)) {
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.WARNING, modal: false,
					text: "You do not have permission to delete this folder.\nContact the administrator",
				})
				return
			}

			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: "Are you sure you want to delete the FOLDER?",
			})
			if (!res) return

			await foldersApi.destroy(store.state.repo, id)

			store.setAllFolder(store.state.allFolder.filter(f => f.id != id))
			
			//store.onFolderDelete(id)
			// aggiorno l'entities
			store.fetchAll()

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: "The folder has been deleted.\nThe content has been moved to the UNASSIGNED folder",
			})
			store.setSelectedFolderId(FOLDER_SPECIAL_ID.ALL)
		},

		/** su chiusura della dialog per la selezione di un folder di destinazione */
		onFolderSelect: async (folderId: number, store?: FolderStore) => { 
			await foldersApi.bulkMove(store.state.repo, folderId, store.state.selectedIds)
			// aggiorno l'entities
			store.fetchAll()
			store.setSelectedIds([])
		},

		/** ABSTRACT */
		fetchAll: async (_: void, store?: FolderStore) => {}

	},

	mutators: {
		setAllFolder: (allFolder: Folder[]) => ({ allFolder }),
		setSelectedFolderId: (selectedFolderId: number) => ({ selectedFolderId }),
		setFolderInEdit: (folderInEdit: Folder) => ({ folderInEdit }),
		setFolderEditorOpen: (folderEditorOpen: boolean) => ({ folderEditorOpen }),
		setFolderSelectorOpen: (folderSelectorOpen: boolean) => ({ folderSelectorOpen }),

		toggleSelectedIds: (id: number, store?: FolderStore) => {
			if (!store.state.selectedIds.includes(id)) {
				return { selectedIds: store.state.selectedIds.concat(id) }
			} else {
				return { selectedIds: store.state.selectedIds.filter(i => i != id) }
			}
		},
		setSelectedIds: (selectedIds: number[]) => ({ selectedIds }),
	},
}

export default folderSetup

export type FolderState = typeof folderSetup.state
export type FolderGetters = typeof folderSetup.getters
export type FolderActions = typeof folderSetup.actions
export type FolderMutators = typeof folderSetup.mutators
export interface FolderStore extends StoreCore<FolderState>, FolderGetters, FolderActions, FolderMutators {
	state: FolderState
}