import { SxProps, TableSortLabel } from '@mui/material';
import { useStore } from '@priolo/jon';
import React from 'react';
import { FunctionComponent } from 'react';
import querySo from "stores/route/query"


interface Props {
	name: string
	children: React.ReactNode
	sx?: SxProps
}

const TableSortProp:FunctionComponent<Props> = ({
	name,
	children,
	sx,
}) => {

	// STORE
	useStore(querySo)

	// HOOKs
	const active = querySo.getSearchUrl("sortName") == name
	const direction = querySo.getSearchUrl("isDes") == "true" ? "desc" : "asc"

	// HANDLERs
	const handleClickSort = () => querySo.setSort(name)

	// RENDER
	return (
		<TableSortLabel sx={sx}
			active={active}
			direction={direction}
			onClick={handleClickSort}
		>{children}
		</TableSortLabel>
	)
}

export default TableSortProp