import ajax from "plugins/AjaxService"

/**
 * retrieves all TAGs
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29175
 */
const index = () => ajax.get("taggable/list")

const tagApi = {
	index,
}
export default tagApi