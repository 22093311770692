import { diffDay } from "utils/time"
import { Activity, TAGS } from "../types"


/**
 * Restituisce TRUE se c'e' almeno un ACTIVITY tra gli "actToTest" 
 * che va in overlap sulle "activities"
 * @param actToTest 
 * @param activities tutte le ACTIVITIES da controllare
 * @param ignore le ACTIVITIES presenti in "activities" da ignorare
 */
export function activitiesOverlap(actToTest: Activity[], activities: Activity[], ignore?: Activity[]): boolean {
	return activities.some(activity => {
		if (ignore?.includes(activity)) {
			return false
		}
		return actToTest.some(act => activityOverlap(act, activity))
	})
}

/**
 * setta nelle ACTIVITY passate le ACTIVITY che hanno degli overlaps
 * @param activities 
 * @returns 
 */
export function activitiesSetOverlap(activities: Activity[]): Activity[] {
	for (const activity1 of activities) {
		activity1.overlaps = [];
		for (const activity2 of activities) {
			if (activity1.tags?.[TAGS.LAYER] != activity2.tags?.[TAGS.LAYER]) continue
			if (!activityOverlap(activity1, activity2)) continue
			activity1.overlaps.push(activity2);
		}
	}
	return activities
}

/** restituisce tutte le ACTIVITY che sono in OVERLAP */
export function activitiesGetOverlap(activities: Activity[]): Activity[] {
	return activities?.filter(a => a.overlaps?.length > 0) ?? []
}

/**
 * Restituiesce TRUE se due ACTIVITY sono in ovelap
 */
export function activityOverlap(act1: Activity, act2: Activity, noRowCheck: boolean = false): boolean {
	// se l'activity è la stessa 
	// una delle due activity non ha lunghezza allora non c'e overlap
	// non sono nella stessa "row" (dipende da parametro)
	if (act1 == act2
		|| !act1 || !act1.length || !act2 || !act2.length
		|| (!noRowCheck && act1.row != act2.row)
	) return false

	const deltaDays = diffDay(act1.start, act2.start)
	return deltaDays > 0 ? deltaDays < act2.length : -deltaDays < act1.length
}
