import { SxProps } from "@mui/material";
import SelectorChipsValues from "components/controls/base/SelectorChipsValues";
import { FunctionComponent, useRef, useState } from "react";
import { Cycle } from "types/Cycle";
import { PositionTrolley } from "types/Incident";
import LayerPositionDialog from "./LayerPositionDialog";
import PositionChip from "./PositionChip";



interface Props {
    layersSelect: PositionTrolley[]
    cycle: Cycle
    onChangeSelect?: (pos: PositionTrolley[]) => void
    sx?: SxProps

}
const LayersPositionSelector: FunctionComponent<Props> = ({
    layersSelect = [],
    cycle,
    onChangeSelect,
    sx,
}) => {

    // STORE

    // HOOKs
    const positionSelect = useRef<PositionTrolley>(null)
    const [dialogOpen, setDialogOpen] = useState(false)

    // HANDLEs
    const handleChipSelect = (pos: PositionTrolley) => {
        positionSelect.current = pos
        setDialogOpen(true)
    }
    const handleChipDelete = (pos: PositionTrolley) => {
        const newLayersSelect = layersSelect.filter(l => l.layerId != pos.layerId)
        onChangeSelect?.(newLayersSelect)
    }
    const handleChipAdd = () => {
        positionSelect.current = null
        setDialogOpen(true)
    }

    const handleLayerSelectClose = (layerSelect: PositionTrolley) => {
        setDialogOpen(false)
        if (!layerSelect) return
        const index = layersSelect.findIndex(l => l.layerId == layerSelect.layerId)
        const newLayersSelect = [...layersSelect]
        if (index == -1) {
            newLayersSelect.push(layerSelect)
        } else {
            newLayersSelect[index] = layerSelect
        }
        onChangeSelect?.(newLayersSelect)
    }

    // RENDER
    if (!cycle) return null
    const readOnly = onChangeSelect == null

    return <>
        <SelectorChipsValues sx={sx}
            readOnly={readOnly}
            items={layersSelect}
            placeholder={"No layer selected"}
            onAdd={() => handleChipAdd()}
            renderItem={(position: PositionTrolley) =>
                <PositionChip
                    cycle={cycle}
                    position={position}
                    readOnly={readOnly}
                    onSelect={handleChipSelect}
                    onDelete={handleChipDelete}
                />
            }
        />
        {!readOnly && (
            <LayerPositionDialog
                open={dialogOpen}
                ignoreLayerId={layersSelect?.map(l => l.layerId) ?? []}
                onClose={handleLayerSelectClose}
                positionTrolley={positionSelect.current}
                cycle={cycle}
            />
        )}
    </>
}

export default LayersPositionSelector
