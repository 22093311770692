import { Star } from '@mui/icons-material'
import { Box } from '@mui/material'
import Chip from 'components/controls/select/Chip'
import SelectorProductionStateH from 'components/controls/select/SelectorProductionStateH'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import solSo from "stores/productions/solution"
import reqSo from "stores/productions/request"
import { useStore } from '@priolo/jon'



const PlannerFilterHeader: FunctionComponent = () => {

	// STORE
	const solSa = useStore(solSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleRefClick = () => reqSo.select(solSo.state.reference?.requestUuid)

	// RENDER
	const labelSolRef = `SCORE: ${solSo.state.reference?.metrics?.score.toFixed(0) ?? "--"}`

	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<SelectorProductionStateH sx={{ mr: 1 }} />
			{!!solSo.state.reference && (
				<Chip
					tooltip={t("selector.production.ref.tooltip")}
					icon={<Star />}
					label={labelSolRef}
					onClick={handleRefClick}
				/>
			)}
		</Box >
	)
}

export default PlannerFilterHeader

