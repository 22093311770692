import farmSo from "stores/farm";
import { GrowUnit, GrowUnitLayer } from "types/GrowUnit";
import { getLetters } from "utils/humanize";
import { getLayersOfColumn } from "./layer";



/** se tutti i LAYER della COLUMN sono "virtual" allora la COLUMN non è visibile */
export function isColumnVisible(column: number, growUnit: GrowUnit) {
	const layers = getLayersOfColumn(growUnit, column + 1)
	return isLayersVisible(layers)
}

/** restituisce true se c'e' almeno un LAYER non "virtual" */
export function isLayersVisible(layers: GrowUnitLayer[]) {
	return layers.some(layer => !layer.virtual);
}

/** il numero dei LAYERs presenti nella GROW-UNIT eliminando i LAYERs "virtual" */
export function getLayersSize(growUnit?: GrowUnit) {
	if (!growUnit) growUnit = farmSo.getGrowUnitByComponentSelect()
	return growUnit?.layers?.reduce((acc, layer) => acc + (!layer.virtual ? 1 : 0), 0)
}

/** 
 * tutte le colonne prensenti nella GROW-UNIT 
 * index start: 0
*/
export function getColumns(growUnit?: GrowUnit): number[] {
	if (!growUnit) growUnit = farmSo.getGrowUnitByComponentSelect()
	const length = growUnit?.columnsNumber ?? 0
	return Array.from({ length }, (_, index) => index)
}

/** 
 * tutte le colonne prensenti nella GROW-UNIT VISIBILI
 * index start: 0
*/
export function getColumnsVisible(growUnit?: GrowUnit): number[] {
	if (!growUnit) growUnit = farmSo.getGrowUnitByComponentSelect()
	const columns = getColumns(growUnit)
	return columns.filter((column: number) => isColumnVisible(column, growUnit))
}

/**
 * il nome di una colonna in base al suo numero
 */
export function getColumnName(columns: number): string {
	const step = 1; // per il momento non si controlla piu' "growUnit.connectedLayers"
	return getLetters(columns * step, step).join("-");
}

/**
 * un array di "nome di una colonna" in base ad un array di "numero colonna"
 */
export function getColumnsName(column: number[], growUnit?: GrowUnit): string {
	if (!growUnit) growUnit = farmSo.getGrowUnitByComponentSelect()
	return column.map(col => getColumnName(col)).join("-")
}

/** un array di "numero row" dei layer prensenti in una singola COLONNA della GROW-UNIT */
export function getRowsNumber(growUnit?: GrowUnit) {
	if (!growUnit) growUnit = farmSo.getGrowUnitByComponentSelect()
	return Array.from({ length: growUnit.layersPerColumn ?? 0 }, (_, index) => index + 1)
}