import { Box, Checkbox, Typography } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";
import cycleSo from "stores/cycle";
import farmSo from "stores/farm";
import taskSo from "stores/task";
import theme from "styles/theme";
import { Cycle } from "types/Cycle";
import { Uuid } from "types/global";
import { RELATED_TO, Task } from "types/Task";
import { getSxRow } from "utils/entity";
import GrowUnitCycleChip from "./GrowUnitCycleChip";
import ScopeIcon from "./ScopeIcon";
import TaskIcon from "./TaskIcon";



interface Props {
	task: Task
	hideCycle?: boolean
	hideGrowUnit?: boolean
	editMode?: boolean
	cycleUuidHighlight?: Uuid
	onClick?: (e: React.MouseEvent) => void
	onCycleMouseOver?: (cycle: Cycle, e: React.MouseEvent) => void
	sx?: any
}

const TaskItem: FunctionComponent<Props> = ({
	task,
	hideCycle,
	hideGrowUnit,
	editMode,
	cycleUuidHighlight,
	onClick = () => { },
	onCycleMouseOver,
	sx
}) => {

	// STORE

	// HOOKs
	const cycle = useMemo(() => {
		if (hideCycle) return null
		return task.relatedTo == RELATED_TO.GROW_UNIT || task.relatedTo == RELATED_TO.CYCLE ? cycleSo.getCycleByUuid(task.cycleUuid) : null
	}, [hideCycle, task.cycleUuid, task.relatedTo])
	
	const growUnit = useMemo(() => {
		if (hideGrowUnit) return null
		return farmSo.getGrowUnit(task.growUnitId)
	}, [hideGrowUnit, task.growUnitId, task.relatedTo])

	// HANDLE
	const handleSelect = (e: React.MouseEvent) => {
		e.stopPropagation()
		taskSo.toggleSelected(task.taskUuid)
	}

	// RENDER
	const isSelect = taskSo.isSelected(task.taskUuid)
	const isHighlight = task.cycleUuid && cycleUuidHighlight == task.cycleUuid
	const date = task.dueTime ?? "-- : --"
	return (
		<Box
			sx={[cssContainer(isSelect, isHighlight), sx]}
			onClick={onClick}
		>

			{editMode && (
				<Checkbox tabIndex={-1} sx={{ p: 0, mr: 1 }}
					checked={isSelect}
					onClick={handleSelect}
				/>
			)}
			<TaskIcon task={task} />

			<Typography variant="caption" sx={{ minWidth: "38px", ml: "5px", mr: "3px" }}>
				{date}
			</Typography>

			<Typography variant="body1" sx={[{ flex: "1 0" }, cssLabel(task)]}>
				{task.subject}
			</Typography>

			<GrowUnitCycleChip sx={{ mr: 1 }}
				task={task}
				growUnit={growUnit}
				cycle={cycle}
				onMouseOver={onCycleMouseOver}
			/>

			<ScopeIcon scope={task?.scope} />

			<Typography variant="subtitle2" align="right" sx={{ width: "38px" }}>
				{task.totalPoints}
			</Typography>

		</Box >
	)
}

export default TaskItem

const cssContainer = (select: boolean, highlight: boolean) => ({
	display: "flex",
	alignItems: "center",
	cursor: "pointer",
	bgcolor: highlight ? "#ffffff20" : select ? "#00bcd440" : "unset"

})

const cssLabel = (task: Task) => {

	const cssSync = getSxRow(task._edit?.type)

	let cssTask = {
		width: 0,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	}

	if (task.isCancelled) {
		cssTask["textDecoration"] = "line-through"
		cssTask["color"] = theme.palette.text.disabled
	} else if (task.isClosed) {
		cssTask["color"] = theme.palette.text.disabled
	}

	return { ...cssTask, ...cssSync }
}


