import { TAB_TYPE } from "components/tabs/components/Tab"
import dayjs from "dayjs"
import i18n from "i18next"
import { CycleState } from "stores/cycle"
import draftSo from "stores/draft"
import { TaskState } from "stores/task"
import { DRAFT_STATUS, Draft } from "types/Draft"
import { Solution } from "types/Production"



export interface PlannerSession {
	type?: SESSION_TYPE
	draftId?: number
	cycle?: CycleState
	task?: TaskState
	/** valorizzato nel caso sia di type PREVIEW */
	solution?: Solution
}

export enum SESSION_TYPE {
	PROD,
	DRAFT,
	PREVIEW,
}

export enum DRAFT_MODE {
	EDIT,
	READ,
}


export enum DRAFT_STATUS_FILTER {
	//ALL = "ALL",
	CURRENTS = "currents",
	MERGED = "merged",
}


//#region SESSION

export function sessionTitle(session: PlannerSession) {
	if (session.type == SESSION_TYPE.PROD) return "PROD"
	if (session.type == SESSION_TYPE.PREVIEW) {
		const score = session.solution?.metrics?.score
		return `SCORE ${score?.toFixed(1) ?? "--"}%`
	}
	return draftSo.getDraftById(session.draftId)?.description ?? "DRAFT"
}

export function sessionIconType(session: PlannerSession) {
	if (session.type == SESSION_TYPE.PROD) return TAB_TYPE.PRODUCTION
	if (session.type == SESSION_TYPE.PREVIEW) return TAB_TYPE.PREVIEW
	if (session.type == SESSION_TYPE.DRAFT) {
		const draft = draftSo.getDraftById(session.draftId)
		if (draft?.status == DRAFT_STATUS.INIT || draft?.status == DRAFT_STATUS.PROMOTED_FROM_SOLUTION) return TAB_TYPE.DRAFT
		if (draft?.status == DRAFT_STATUS.PROMOTING_FROM_SOLUTION) return TAB_TYPE.LOADING
		return TAB_TYPE.DRAFT_APPLY
	}
	return TAB_TYPE.NONE
}

export function sessionCanClose(session: PlannerSession) {
	if (session.type == SESSION_TYPE.PROD) return false
	if (session.type == SESSION_TYPE.DRAFT) return true
	if (session.type == SESSION_TYPE.PREVIEW) return true
}

export function sessionVoid(session: PlannerSession) {
	return !session || session.cycle == null && session.task == null
}

/** dato un array di SESSIONS memorizza i draftId in localStorage */
export function sessionsDraftsStorageWrite(farmId: number, sessions: PlannerSession[]) {
	if (farmId == null || isNaN(farmId) || !sessions) return
	const draftIds = sessions.filter(s => s.draftId).map(s => s.draftId)
	localStorage.setItem(`farm:id:${farmId}`, JSON.stringify(draftIds));
}

/** restituisce un array di draftIds dal localStorage */
export function sessionsDraftsStorageRead(farmId: number): number[] {
	if (isNaN(farmId)) return []
	try {
		return JSON.parse(localStorage.getItem(`farm:id:${farmId}`));
	} catch (e) {
		return []
	}
}

/** creo una SESSION da un id DRAFT * */
export function sessionFromDraftId(draftId: number): PlannerSession {
	if (!draftId) return null
	const session: PlannerSession = {
		type: SESSION_TYPE.DRAFT,
		draftId,
	}
	return session
}

/** creo una SESION tramite una SOLUTION */
export function sessionFromSolution(solution: Solution): PlannerSession {
	if (!solution) return null
	const session: PlannerSession = {
		type: SESSION_TYPE.PREVIEW,
		solution,
	}
	return session
}

//#endregion


//#region DRAFT

export function draftStatusLabel(status: DRAFT_STATUS): string {
	return i18n.t(`app.draft.status.${status}`)
}

export function draftFilter(drafts: Draft[], text: string, status: DRAFT_STATUS_FILTER): Draft[] {
	if (!drafts) return []
	text = text?.trim()?.toLowerCase() ?? ""
	if (!status) status = DRAFT_STATUS_FILTER.CURRENTS
	return drafts
		.filter(draft => (
			!status
			|| (status == DRAFT_STATUS_FILTER.CURRENTS && (draft.status == DRAFT_STATUS.INIT || draft.status == DRAFT_STATUS.PROMOTED_FROM_SOLUTION || draft.status == DRAFT_STATUS.PROMOTING_FROM_SOLUTION))
			|| (status == DRAFT_STATUS_FILTER.MERGED && (draft.status == DRAFT_STATUS.APPLIED || draft.status == DRAFT_STATUS.APPLYING))
		)).sort((d1, d2) => {
			if (!!d1.appliedAt && !!d2.appliedAt) return dayjs(d2.appliedAt).valueOf() - dayjs(d1.appliedAt).valueOf()
			if (!!d1.createdAt && !!d2.createdAt) return dayjs(d2.createdAt).valueOf() - dayjs(d1.createdAt).valueOf()
			return (d1.description ?? "").localeCompare(d2.description ?? "")
		})
}

//#endregion

