import { BoxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useStore } from "@priolo/jon";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import eventSo from "stores/event";
import { isRoot } from "stores/event/utils";
import { dateToHuman } from "utils";
import RootRow from "./RootRow";
import UserRow from "./UserRow";



interface Props extends BoxProps {
	scroll?: boolean
}

const ChatCard: FunctionComponent<Props> = ({
	scroll,
	...props
}) => {

	// STORE
	const eventSa = useStore(eventSo)

	// HOOKS
	const refLast = useRef<Element>()

	useEffect(() => {
		eventSo.fetchIfVoid()
	}, [])

	const [smooth, setSmooth] = useState(false)

	useEffect(() => {
		if (!scroll || !refLast.current || !eventSa.all || eventSa.all.length == 0) return
		refLast.current?.scrollIntoView({ behavior: smooth ? "smooth" : undefined, block: "start" })
		eventSo.setUnread(0)
		setSmooth(true)
	}, [refLast.current, scroll, eventSa.all])


	// HANDLERs

	// RENDER
	const groups = eventSa.groups

	return <Box sx={[cssContainer, props]} >

		<Box sx={{ bgcolor: "#2F2F2F", position: "absolute", width: "calc( 100% - 40px*2 )", height: "100%", left: "40px", zIndex: -1 }} />

		{groups.map(group => (
			
			<Box id={group.id} key={group.id} sx={{ display: "flex", flexDirection: "column" }}>

				{group.dayChanged && (
					<Typography
						variant="caption"
						sx={{
							mb: 3, mx: "40px",
							textAlign: "center", color: "white.main",
							borderBottom: "1px dashed rgba(255, 255, 255, 0.2)",
						}}>
						{dateToHuman(group.timestamp)}
					</Typography>
				)}

				{isRoot(group) ? (
					<RootRow groupEvents={group} />
				) : (
					<UserRow groupEvents={group} />
				)}

			</Box>
		))}

		<Box ref={refLast} />

	</Box>
}

export default ChatCard

const cssContainer = {
	display: "flex",
	flexDirection: "column",
	bgcolor: "#242424",
	position: "relative",
	zIndex: -2
}