import { createTheme } from "@mui/material"
import palette from "./palette"

const theme = createTheme({
	palette,
	components: {

		MuiTabs: {
			defaultProps: {
				textColor: "secondary",
				indicatorColor: "secondary"
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					//minWidth: "108px",
					minHeight: "0px"
				},
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid #3a3a3a",
				}
			}
		},
		MuiSvgIcon: {
			defaultProps: {
				fontSize: "small",
			}
		},
		PrivateValueLabel: {
			styleOverrides: {
				label: {
					color: "#000000",
					fontWeight: "900",
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: "#515151"
				}
			}
		},


		// BUTTON
		MuiButton: {
			defaultProps: {
				variant: "text",
				color: "text",
			},
			styleOverrides: {
				root: {
					borderRadius: "20px"
				},
			}
		},
		MuiButtonGroup: {
			defaultProps: {
				color: "text",
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					height: "28px"
				},
			}
		},


		
		// BORDER
		// MuiPaper: {
		// 	styleOverrides: {
		// 		root: {
		// 			borderRadius: "10px",
		// 			padding: "20px"
		// 		}
		// 	}
		// },
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: palette.background.card,
					backgroundImage: "none",
					boxShadow: "none"
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					backgroundImage: "none",
					padding: "20px",
					borderRadius: "10px",
				}
			}
		},
		// MuiMenu: {
		// 	styleOverrides: {
		// 		paper: {
		// 			padding: "0px",
		// 		}
		// 	}
		// },
		MuiPopover: {
			styleOverrides: {
				paper: {
					backgroundColor: palette.background.card,
					backgroundImage: "none",
					borderRadius: "10px",
					//padding: "20px"
				}
			}
		},
		MuiTooltip: {
			defaultProps: {
				placement: "top",
				arrow: true,
				disableInteractive: true,
			},
			styleOverrides: {
				arrow: { 
					color: "#e7e7e7",
				},
				tooltipArrow: { 
					backgroundColor: "#e7e7e7", color: "black", 
					boxShadow: "2px 2px 1px 0 rgb(0 0 0)"
				},
			}
		},


		// INPUT
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					backgroundColor: palette.background.card,
				}
			}
		},
		MuiCheckbox: {
			defaultProps: {
				color: "secondary"
			}
		},
		MuiSwitch: {
			defaultProps: {
				color: "secondary",
			}
		},
		MuiSlider: {
			defaultProps: {
				color: "secondary",
				size: "small",
				valueLabelDisplay: "auto",
			}
		},
		// MuiInputBase: {
		// 	styleOverrides: {
		// 		root: {
		// 			padding: "0px",
		// 			//borderRadius: "10px",
		// 			//backgroundColor: palette.background.default,
		// 			height: "36px",
		// 		}
		// 	}
		// },
		MuiSelect: {
			defaultProps: {
				variant: "outlined",
			},
			styleOverrides: {
				// root: {
				// 	//padding: "3px 14px 1px 14px",
				// 	//borderRadius: "10px",
				// 	//backgroundColor: palette.background.default,
				// 	height: "36px",
				// },
				select: {
					padding: "0px",
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				size: "small",
				variant: "outlined",
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					display: "none",
				},
			},
			input: {
				padding: 0,
			}
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					paddingTop: "1px",
					"&&.Mui-selected": {
						backgroundColor: palette.secondary.main,
						color: palette.background.paper,
					}
				},
				dayWithMargin: {
					//color: palette.secondary.main,
					backgroundColor: "transparent",
				}
			}
		},

		// LIST
		MuiListItemText: {
			styleOverrides: {
				secondary: {
					fontWeight: 500,
					fontSize: "12px",
					color: palette.text.sub,
				},
			},
		},
	},
	app: {
		offline: {
			zIndex: 1201,
		},
		header: {
			height: 75,
			zIndex: 10,
		},
		footer: {
			height: 75,
			zIndex: 1,
		},
		menu: {
			width: 150,
			background: "#121212",
			zIndex: 5,
			parent: {
				height: 40,
			},
			section: {
				height: 7 + 24 + 7,
			}
		},
		body: {
			width: 1300,
		},
		table: {
			action: {
				width: 185,
			},
			switch: {
				width: 94,
			}
		},
		cards: {
			farm: {
				width: "380px",
			}
		}
	},
	typography: {
		h5: {
			fontWeight: 500,
			fontSize: "18px",
			color: palette.text.primary
		},
		h6: {
			fontWeight: 500,
			fontSize: "17px",
			color: palette.text.primary
		},

		subtitle1: {
			fontWeight: 400,
			fontSize: "16px",
			color: palette.text.primary
		},
		subtitle2: {
			fontWeight: 500,
			fontSize: "14px",
			color: palette.text.secondary
		},

		body1: {
			fontWeight: 300,
			fontSize: "16px",
			color: palette.text.secondary
		},
		body2: {
			fontWeight: 300,
			fontSize: "14px",
			color: palette.text.secondary,
			lineHeight: "unset",
		},
		caption: {
			fontWeight: 500,
			fontSize: "12px",
			color: palette.text.sub,
			lineHeight: "unset",
			lineSpacing: "unset",
		},
		// per definire nuovi stili segnare qua:
		micro: {
			fontWeight: 500,
			fontSize: "10px",
			color: palette.text.sub,
		}

	}
} as any)

export default theme as any
