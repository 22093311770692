import { Add as AddIcon } from "@mui/icons-material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";


const ProductTableFooter: FunctionComponent = () => {
	
	const { t } = useTranslation()

	return <ButtonCmp data-test="product-btt-new" sx={{ ml: "26px" }}
		shape={BTT_SHAPE.MAIN}
		href="/products/new"
		startIcon={<AddIcon />}
	>
		{t("footer.product.new")}
	</ButtonCmp>
}

export default ProductTableFooter