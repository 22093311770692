import { Activity, GraphGroup, TAGS } from "components/planner/types"
import { groupBy, roundValue } from "components/planner/utils"
import { Cycle } from "types/Cycle"
import { dateWithoutTime } from "utils/time"



/**
 * Inserendo i CYCLE restituisco il GRAPH-GROUP delle somme di tutti i yield
 */
export function buildYeldGroup(cycles?: Cycle[]): GraphGroup {

	// ottengo le somme per "plannedYield"
	const cyclesPlanned = cycles.filter(cycle => cycle.plannedYield)
	const planned = getActionFromCycles(
		cyclesPlanned,
		cycle => cycle.plannedYield,
		0
	)

	// ottengo le somme per "yield"
	const cyclesActual = cycles.filter(cycle => cycle.yieldDate)
	for (const cycle of cyclesActual) {
		if (!(cycle.cropLots?.length > 0)) continue
		if (cycle.yield == null) cycle.yield = cycle.cropLots.reduce((acc, cl) => acc + cl.yield, 0)
		if (cycle.unitsYield == null) cycle.unitsYield = cycle.cropLots.reduce((acc, cl) => acc + cl.unitsYield, 0)
	}
	const actual = getActionFromCycles(
		cyclesActual,
		cycle => cycle.yield,
		1
	)
	const actualUnits = getActionFromCycles(
		cyclesActual,
		cycle => cycle.unitsYield,
		2
	)

	// creo il GROUP
	const yieldGroup: GraphGroup = {
		name: "yield",
		title: "YIELD",
		collapsed: false,
		labels: ["EXPECTED [kg]", "ACTUAL [kg]", "UNITS"],
		activities: [...planned, ...actual, ...actualUnits],
		unselectable: true,
		noCompact: true,
		activitiesHistogram: planned,
	}
	return yieldGroup
}





/** 
 * array di dati yield "effective" 
 * @param cycles i CYCLES da analizzare
 * @param fnGetValue la funzione che estrapola il valore da sommare (tipicamente: plannedYield e yield)
 * @param row la row da assegnare a queste ACTIVITY
 * */
function getActionFromCycles(
	cycles: Cycle[],
	fnGetValue: (cycle: Cycle) => number,
	row: number = 0,
): Activity[] {

	// Raggruppo i CYCLES in base alla data di raccolta "harvestingDate"
	const cyclesGroups = groupBy<Cycle>(
		cycles,
		(c1, c2) => c1.harvestingDate == c2.harvestingDate
	)

	// la ACTIVITY dello stesso giorno con la somma dei valori e i riferimenti in TAGS
	const activities = cyclesGroups.reduce((acc, cyclesGroup) => {
		// la somma dei valori
		const sum = cyclesGroup.reduce((sum, cycle) => sum + (fnGetValue(cycle) ?? 0), 0)
		if (isNaN(sum) || sum == 0) return acc
		const dateString = cyclesGroup[0].harvestingDate

		// creo l'ACTIVITY
		const activity = {
			tags: { [TAGS.CYCLES]: cyclesGroup.map(c => c.cycleUuid) },
			start: dateWithoutTime(dateString),
			row,
			label: roundValue(sum),
			value: sum,
		} as Activity
		return [...acc, activity]
	}, [] as Activity[])

	return activities
}


