import dialogSo from "stores/layout/dialogStore"
import { ListPhases, getNextPhase, PHASE_SLUG } from "../../phase/utils"
import { getNewGroupPhase } from "./utils"
import i18n from "i18next"
import { createStore, StoreCore } from "@priolo/jon"
import { PhaseString } from "types/global"
import { moveTo } from "utils/array"



/** 
 * Si occupa della finestra dettaglio del CROP 
 * */
const setup = {

	state: {
		daysGroups: <DayGroups[]>[],
		startingDay: 0,
	},

	getters: {
		havePreCycle: (_: void, store?: CropDayStore) => {
			return store.state.daysGroups[0]?.phase == PHASE_SLUG.PRE_CYCLE
		},
		havePostCycle: (_: void, store?: CropDayStore) => {
			return store.state.daysGroups[store.state.daysGroups.length - 1]?.phase == PHASE_SLUG.POST_CYCLE
		},
		checkRatio: (index: number, store?: CropDayStore) => {
			const group = store.state.daysGroups[index]
			if (!group) return false
			if (!store.isRealPhase(group)) return true
			const groupRatio = +group.ratio
			if (index == 0 && groupRatio == 1) return true
			const groupPrev = store.state.daysGroups[index - 1]
			const groupPrevRatio = +groupPrev.ratio
			if (!store.isRealPhase(groupPrev) && groupRatio == 1) return true
			if ((groupRatio % groupPrevRatio) == 0) return true
			return false
		},
		checkElasticPhase: (value: number, store?: CropDayStore) => {
			if (value < 0 || value > 7) {
				return false
			}
			return true
		},
		checkAllRatio: (_: void, store?: CropDayStore) => {
			const check = store.state.daysGroups.every((group, index) => store.checkRatio(index))
			return check
		},
		// il "groupDay" del è del tipo che necessita del parametro "ratio"
		isRealPhase: (groupDay: DayGroups, store?: CropDayStore) => {
			return groupDay.phase != PHASE_SLUG.PRE_CYCLE && groupDay.phase != PHASE_SLUG.POST_CYCLE && groupDay.phase != PHASE_SLUG.BATCH
		},
	},

	actions: {
		addPhase: (groupIndex: number, store?: CropDayStore) => {
			const daysGroups = [...store.state.daysGroups]
			const newPhase = getNextPhase({ used: daysGroups.map(g => g.phase) })
			const newGroup = getNewGroupPhase(newPhase)
			daysGroups.splice(groupIndex + 1, 0, newGroup)
			store.setDaysGroups(daysGroups)
		},
		movePhase: ({ from, to }: { from: number, to: number }, store?: CropDayStore) => {
			const daysGroups: DayGroups[] = [...store.state.daysGroups]
			store.setDaysGroups(moveTo(daysGroups, from, to))
		},
		addPrePhase: (_: void, store?: CropDayStore) => {
			const daysGroups = [...store.state.daysGroups]
			const newGroup = getNewGroupPhase(PHASE_SLUG.PRE_CYCLE)
			daysGroups.splice(0, 0, newGroup)
			store.setDaysGroups(daysGroups)
		},
		addPostPhase: (_: void, store?: CropDayStore) => {
			const daysGroups = [...store.state.daysGroups]
			const newGroup = getNewGroupPhase(PHASE_SLUG.POST_CYCLE)
			daysGroups.push(newGroup)
			store.setDaysGroups(daysGroups)
		},

		//#region DAY
		addDay: (groupIndex: number, store?: CropDayStore) => {
			const groups = [...store.state.daysGroups]
			groups[groupIndex].days.push({ tasks: [] })
			store.setDaysGroups(groups)
		},
		deleteDay: (
			{ groupIndex, dayIndex }: { groupIndex: number, dayIndex: number },
			store?: CropDayStore
		) => {
			const groups = [...store.state.daysGroups]
			const group = groups[groupIndex]
			if (group.days.length == 1) {
				dialogSo.dialogOpen({
					title: i18n.t(`dialog.crop.delete-last-day.title`),
					text: i18n.t(`dialog.crop.delete-last-day.text`),
				})
				return
			}
			group.days.splice(dayIndex, 1)
			store.setDaysGroups(groups)
		},
		changeDay: (
			{ newDay, groupIndex, dayIndex }: { newDay, groupIndex: number, dayIndex: number },
			store?: CropDayStore
		) => {
			const groups = [...store.state.daysGroups]
			groups[groupIndex].days[dayIndex] = newDay
			store.setDaysGroups(groups)
		},
		cloneDay: (
			{ groupIndex, dayIndex }: { groupIndex: number, dayIndex: number },
			store?: CropDayStore
		) => {
			const groups = [...store.state.daysGroups]
			const dayClone = { ...groups[groupIndex].days[dayIndex] }
			groups[groupIndex].days.splice(dayIndex, 0, dayClone)
			store.setDaysGroups(groups)
		},
		dragEndDay: (result, store?: CropDayStore) => {
			if (!result.destination) return
			const groups = [...store.state.daysGroups]
			const groupIndex = +result.destination.droppableId
			const group = groups[groupIndex]

			const [del] = group.days.splice(result.source.index, 1)
			group.days.splice(result.destination.index, 0, del)
			store.setDaysGroups(groups)
		},
		//#endregion

		//#region GROUP PHASE
		changeGroup: (
			{ newGroup, groupIndex }: { newGroup, groupIndex: number },
			store?: CropDayStore
		) => {
			const groups = [...store.state.daysGroups]
			groups[groupIndex] = newGroup
			store.setDaysGroups(groups)
		},
		deleteGroup: (groupIndex: number, store?: CropDayStore) => {
			const groups = store.state.daysGroups.filter((g, i) => i != groupIndex)
			if (groups.length == 0) {
				dialogSo.dialogOpen({
					title: i18n.t(`dialog.crop.delete-last-phase.title`),
					text: i18n.t(`dialog.crop.delete-last-phase.text`),
				})
				return
			}
			store.setDaysGroups(groups)
		},
		cloneGroup: (groupIndex: number, store?: CropDayStore) => {
			const groups = [...store.state.daysGroups]
			const cloneGroup = JSON.parse(JSON.stringify(groups[groupIndex]))
			groups.splice(groupIndex, 0, cloneGroup)
			store.setDaysGroups(groups)
		},
		//#endregion
	},

	mutators: {
		setDaysGroups: (daysGroups) => ({ daysGroups }),
		setNewDaysGroups: () => ({
			daysGroups: [
				{
					phase: ListPhases[0],
					ratio: 1,
					collapsed: false,
					days: [{ tasks: [] }]
				}
			]
		}),
		setStartingDay: (startingDay: number) => {
			if (startingDay < 0) startingDay = 7 + startingDay
			return { startingDay: startingDay % 7 }
		}
	},
}

export type CropDayState = typeof setup.state
export type CropDayGetters = typeof setup.getters
export type CropDayActions = typeof setup.actions
export type CropDayMutators = typeof setup.mutators
export interface CropDayStore extends StoreCore<CropDayState>, CropDayGetters, CropDayActions, CropDayMutators {
	state: CropDayState
}
const store = createStore(setup) as CropDayStore
export default store


export type DayGroups = {
	phase: PhaseString,
	ratio: number,
	collapsed: boolean,
	days: { tasks: number[] }[]
	shrinkDays: number,
	expandDays: number,
}
