import { ActivityStyle, GraphStyle, HeaderStyle, HistogramType, ScrollStyle, TimelineStyle, TooltipStyle } from "../types"


export function buildGraphStyle(
	style: GraphStyle = {},
	styleBase: GraphStyle = graphStyleBase
): GraphStyle {

	const s: GraphStyle = {
		...styleBase,
		...style,
	}

	s.space.x2 = s.space.x / 2
	s.space.y2 = s.space.y / 2
	s.activity.compact.top = ((s.compact.y - s.activity.compact.height) / 2) + .5
	s.histogram.height = s.space.y * 2
	s.columns = (s.width - s.stageMarginLeft) / s.space.x

	// altezza del rettangolo ACTIVITY
	s.activity.padding.height = s.space.y - (s.activity.padding.y * 2)
	// triangolino CAP del rettangolo ACTIVITY
	s.activity.capPoint = [
		s.activity.padding.x + 1, s.activity.padding.y,
		(s.activity.padding.x / 2) - 1, s.activity.padding.y + (s.activity.padding.height / 2),
		s.activity.padding.x + 1, s.activity.padding.y + s.activity.padding.height
	]

	return s
}


const histogramStyle: HistogramType = {
	factor: .045,
	color: "#ffc107",
	highValueColor: "#f44336",
	bgColor: "#000000",
	lineColor: "#363636",
	height: null,
}

const activityStyle: ActivityStyle = {
	defaultColor: "#23DA4C",
	rects:{
		marginX: 12.5,
		marginY: 9.5,
	},
	padding: {
		x: 5,
		y: 4,
	},
	border: {
		color: "#000000",
		highlight: { color: "#FFFFFF" },
		warning: { color: "#ffc107" },
	},
	font: {
		size: 11,
		color: {
			default: "#FFFFFF",
			disabled: "#A2A2A2",
			highlight: "#FFFFFF",
		}
	},
	selection: {
		margin: { top: 6, },
		defaultColor: "#FFFFFF",
		drag: { color: "#C0C0C0", }
	},
	compact: {
		height: 2,
		margin: 6,
	},
	overlap: {
		color: "#FF0000",
		thickness: 3,
	}
}

const headerStyle: HeaderStyle = {
	height: 20,
	label: {
		margin: {
			right: 3,
			left: 4,
			top: 4,
		}
	},
	collaps: {
		line: {
			color: "#667667",
			thickness: 1,
		}
	},


	root: {
		color: {
			bg: "#ffc107",
			fg: "#000000",
			icon: "#ffc107",
		},
		font: {
			size: 14,
			style: "bold",
			top: 4,
		}
	},
	container: {
		color: {
			bg: "#FFFFFF",
			fg: "#000000",
			icon: "#FFFFFF",
		},
		font: {
			size: 12,
			style: "normal",
			top: 5,
		}
	},
	leaf: {
		color: {
			bg: "#000000",
			fg: "#FFFFFF",
			icon: "#FFFFFF",
		},
		font: {
			size: 12,
			style: "normal",
			top: 5,
		}
	},


	margin: {
		left: 7,
		top: 6,
		innerLeft: 11.5,
		innerTop: 8.5,
	}
}

const toolTipStyle: TooltipStyle = {
	width: 60,
	padding: { top: 5, bottom: 5, left: 5, right: 15 },
	margin: { left: 2, top: -2 },
	bg: { color: '#3A3A3A' },
	line: { color: "#515151" },
	title: { color: "#E3E3E3", size: 12, },
	label: { color: "#E3E3E3", size: 12, },
}

const scrollStyle: ScrollStyle = {
	bg: {
		color: "#585858",
	},
	cursor: {
		color: "#ffc107",
	},
	label: {
		color: "#141414",
		size: 12,
	},
	width: 15,
	height: 22,
}

const timelineStyle: TimelineStyle = {
	bg: {
		color: "#222222",
	},
	mount: {
		size: 14,
		color: "#E3E3E3"
	},
	day: {
		size: 12,
		color: "#E3E3E3",
		weekend: "#FFC107"
	},
	year: {
		size: 12,
		color: "#A3A3A3"
	}
}

const graphStyleBase: GraphStyle = {
	height: 500,
	width: 700,
	groupMargin: 5,
	stageMarginLeft: 110,
	space: { x: 28, y: 22 },
	compact: { y: 4 },
	primaryColor: "#ffc107",
	stageColor: "#000000",
	bgColor: "#141414",
	bg: {
		row: { color: "#3A3A3A" },
		column0: { color: "#222222" },
		font: {
			color: "#bfbfbf",
			size: 10,
		},
	},
	timeline: timelineStyle,
	histogram: histogramStyle,
	scroll: scrollStyle,
	activity: activityStyle,
	header: headerStyle,
	tooltip: toolTipStyle,
	day: {
		current: {
			border: "#aaaaaa",
			thickness: 2,
			opacity: 1,
		},
		highlight: {
			color: "#FFFFFF",
			thickness: 0,
			opacity: .2,
		}
	}
}