import { Box, SxProps } from "@mui/material"
import React, { FunctionComponent } from "react"
import theme from "styles/theme"



interface ChipLayerProps {
	label?: string
	disabled?: boolean
	selected?: boolean
	overlap?: boolean
	onClick?: (e: React.MouseEvent) => void
	onDClick?: (e: React.MouseEvent) => void
}

export const ChipLayer: FunctionComponent<ChipLayerProps> = ({
	label,
	disabled,
	selected,
	overlap,
	onClick,
	onDClick
}) => {

	// RENDER
	const interactive = !disabled && !!onClick
	const sxRoot: SxProps = [sxChip, disabled && sxDisabled, selected && sxSelected, overlap && sxOverlap, interactive && sxInteractive]

	return <Box draggable={false}
		sx={sxRoot}
		onMouseDown={interactive && onClick}
		onMouseEnter={interactive && onClick}
		onDoubleClick={interactive && onDClick}
	>
		<Box sx={sxLabel} draggable={false}>
			{label}
		</Box>
	</Box>
}

const sxChip: SxProps = {
	userSelect: "none",
	padding: "2px 7px 0px 7px",
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
	borderRadius: "4px",
	border: "2px solid",
}

const sxSelected: SxProps = {
	bgcolor: theme.palette.secondary.main,
	color: theme.palette.secondary.contrastText,
	borderColor: theme.palette.secondary.main,
}

const sxDisabled: SxProps = {
	bgcolor: "text.disabled"
}

const sxInteractive: SxProps = {
	cursor: "pointer"
}

const sxOverlap: SxProps = {
	color: theme.palette.error.main,
	borderColor: theme.palette.error.main,
}

const sxLabel: SxProps = {
	fontSize: "14px",
	fontWeight: 500,
}
