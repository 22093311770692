import { StoreCore, createStore } from "@priolo/jon";
import solApi from "api/productions_solution";
import draftSo from "stores/draft";
import { sessionFromSolution } from "stores/draft/utils";
import prodSo from "stores/productions";
import reqSo from "stores/productions/request";
import { Production, Solution, SolutionCrop } from "types/Production";
import { Uuid } from "types/global";



/**
 * Gestione SOLUTIONS
 */
const setup = {

	state: {
		all: <Solution[]>null,
		/** le SOLUTION selezionate... cioe' CHECKED */
		selects: <Solution[]>[],
		/** riferimento da usare per visualizzare gli offset sulle altre SOLUTION */
		reference: <Solution>null,
	},

	getters: {
		//getByUuid: (uuid: Uuid, store?: SolutionStore) => store.state.all.find( s => s.uuid == uuid),
		getByReqUuid: (reqUuid: string, store?: SolutionStore) => store.state.all?.filter(s => s.requestUuid == reqUuid) ?? [],
		/** indica che la SOLUTION rislta CHECKED */
		isSelect: (uuid: Uuid, store?: SolutionStore) => store.state.selects.some(s => s.uuid == uuid),
		getCropOffset: (
			{ cropSol, prop }: { cropSol: SolutionCrop, prop: string },
			store?: SolutionStore
		) => {
			if (!store.state.reference) return null
			const cropSolRef = store.state.reference?.solutionCrops?.find(cs => cs.cropUuid == cropSol.cropUuid)
			if (!cropSolRef || cropSol == cropSolRef) return null
			return cropSol.metrics[prop] - cropSolRef.metrics[prop]
		},
		getOffset: (
			{ solution: sol, prop }: { solution: Solution, prop: string },
			store?: SolutionStore
		) => {
			const solRef = store.state.reference
			if (!solRef || sol == solRef) return null
			return sol.metrics[prop] - solRef.metrics[prop]
		},
	},

	actions: {
		fetchByProd: async (production: Production, store?: SolutionStore) => {
			if (!production) return
			const { data } = await solApi.index(production)
			store.setAll(data)
		},
		/**
		 * carica e mostra la SOLUTION in planner
		 */
		showInPlanner: async (solution: Solution, store?: SolutionStore) => {
			const production = prodSo.getByReqUuid(solution.requestUuid)
			if (!production) return
			const { data: detail } = await solApi.show(production, solution.uuid)
			draftSo.addSession(sessionFromSolution(detail))
			draftSo.setSelect(draftSo.getSessionIndex(draftSo.getSessionBySolutionUuid(solution.uuid)))
		},

		toggleSel: (solution: Solution, store?: SolutionStore) => {
			const index = store.state.selects.findIndex(sol => sol.uuid == solution.uuid)
			let selects = null
			if (index == -1) {
				solution._parent = reqSo.getByUuid(solution.requestUuid)
				selects = [...store.state.selects, solution]
			} else {
				solution._parent = null
				selects = store.state.selects.filter(s => s.uuid != solution.uuid)
			}
			store.setSelects(selects)
		},

		toggleReference: (solution: Solution, store?: SolutionStore) => {
			if (solution?.uuid == store.state.reference?.uuid) solution = null
			store.setReference(solution)
		},
		// toggleSolutionFavorite: async (solution: Solution, store?: ProductionStore) => {
		// 	solution.favorite = !solution.favorite
		// 	await reqApi.solutionSave(solution)
		// 	store.setAll([...store.state.all])
		// },
		// removeRequest: async (uuid: string, store?: ProductionStore) => {

		// },
	},

	mutators: {
		setAll: (all: Solution[]) => ({ all }),
		setSelects: (selects: Solution[]) => ({ selects }),
		setReference: (reference: Solution) => ({ reference }),
	},
}



export type SolutionState = typeof setup.state
export type SolutionGetters = typeof setup.getters
export type SolutionActions = typeof setup.actions
export type SolutionMutators = typeof setup.mutators
export interface SolutionStore extends StoreCore<SolutionState>, SolutionGetters, SolutionActions, SolutionMutators {
	state: SolutionState
}
const solutionSo = createStore(setup) as SolutionStore
export default solutionSo
