import { DateNumber } from "types/global"

/** restituisce una funzione con una tabella di risposta ottimizzata per proprietà uguali */
export function memoize(func) {
	const results = {}
	return (...args) => {
		const argsKey = JSON.stringify(args)
		let res = results[argsKey]
		if (!res) {
			res = func(...args)
			results[argsKey] = res
		}
		return res
	}
}

/** 
 * Restituisce un "iterator" con le date comprese tra "start" ed "end" 
 * incrementate dello "step" 
 * */
export function* forDates(start:DateNumber, end:DateNumber, step = 1)/*: Generator<number, number[], number>*/ {
	if ( typeof start !== "number" || typeof end !== "number" ) return []
	const dateCount = new Date(start < end ? start : end)
	const dateEnd = new Date(start > end ? start : end)

	while (dateCount <= dateEnd) {
		yield dateCount.getTime()
		dateCount.setUTCDate(dateCount.getUTCDate() + step)
	}
}

/**
 * Restituisce un oggetto che ha le proprietà comuni a tutti gli elementi dell'array
 * i params tutti uguali sono valorizzati
 * i params diversi sono settati a null
 * @param {*} objects 
 * @returns 
 */
export function minCommonProps(objects: any[]) {
	return objects.reduce((objRef, obj) => {
		const keysRef = new Set(Object.keys(objRef));
		for ( const key in obj ) {
			if (!(key in objRef)) {
				objRef[key] = obj[key]
			} else if (objRef[key] !== obj[key]) {
				objRef[key] = null
			}
			keysRef.delete(key)
		}
		keysRef.forEach(key => objRef[key] = null)
		return objRef
	}, {})
}

export function clone<T>( obj:T ): T {
	return JSON.parse(JSON.stringify(obj))
}
