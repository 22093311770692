import { Box, SxProps, Typography } from "@mui/material";
import { FunctionComponent, useMemo } from "react";



interface Props {
	label: string
	sx?: SxProps
}
const LabelTag: FunctionComponent<Props> = ({
	label,
	sx,
}) => {

	// HOOKs
	const [key, value] = useMemo(() => label?.split(":") ?? ["unknow",], [label])

	return <Box component="span">
		<Typography variant="caption" sx={sx}>
			{key}
		</Typography>
		{value && (
			<Typography variant="caption" >
				<Box component="span" sx={{ fontWeight: "700" }}>:{value}</Box>
			</Typography>
		)}
	</Box>
}

export default LabelTag
