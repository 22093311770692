import { Divider, Grid } from '@mui/material';
import { useStore } from '@priolo/jon';
import CycleLayer from 'components/controls/CycleLayer';
import Paragraph from 'components/controls/form/Paragraph';
import { FunctionComponent } from 'react';
import { useTranslation } from "react-i18next";
import cycleSo from 'stores/cycle';
import { getLayers } from 'stores/cycle/utils/layer';
import { Crop } from 'types/Crop';
import { GrowUnit } from 'types/GrowUnit';



interface Props {
	growUnit?: GrowUnit,
	crop?: Crop,
	flex?: number
}
/**
 * Visualizza i LAYER presenti nella PHASE "" (la phase BATCH) della FARM
 */
const CycleBatchLayersShow: FunctionComponent<Props> = ({
	growUnit,
	crop,
	flex = 20,
}) => {

	// STORE
	const cycleSa = useStore(cycleSo)
	//const { getLayers, getOverlapError } = cycleSo

	// HOOKs
	const { t } = useTranslation()

	// RENDER
	// const haveError = (layerNumber) => getOverlapError(
	// 	dayjs(cycleSa.select.seedingDate).format("YYYYMMDD"),
	// 	layerNumber,
	// 	verifyOverlap
	// )

	// RENDER
	if (!growUnit || !crop) return null
	const layers = getLayers(growUnit, crop)
	if (!layers || layers.length == 0) return null

	return (<>
		<Divider sx={{ my: 1 }} />
		<Paragraph title={t("pag.cycle.selector.layers")} flex={flex}>
			<Grid container sx={cssRow}>

				{layers.map((layer) => (
					<CycleLayer key={layer.layerNumber}
						layer={layer}
					//error={haveError(layer.layerNumber)}
					/>
				))}

			</Grid>
		</Paragraph>
		<Divider />
	</>)
}

export default CycleBatchLayersShow

const cssRow = {
	margin: "5px 0px",
}
