import { Box } from '@mui/material'
import { useStore } from '@priolo/jon'
import DateRangePickerH from 'components/controls/select/DateRangePickerH'
import SelectorAlertStatusH from 'components/controls/select/SelectorAlertStatusH'
import SelectorGrowUnitH from 'components/controls/select/SelectorGrowUnitH'
import SelectorSeverityH from 'components/controls/select/SelectorSeverityH'
import { FunctionComponent, useMemo } from 'react'
import cycleSo from "stores/cycle"
import querySo from 'stores/route/query'
import { URL_PAR } from 'stores/route/utils/types'
import { ALERT_FILTER } from 'types/Alert'
import { ALARM_SEVERITY } from 'types/AlertRule'
import { GrowUnit } from 'types/GrowUnit'
import { DateRange } from 'types/global'
import { dateRangeToUrl, urlToDateRange } from 'utils'


const AlertFilterHeader: FunctionComponent = () => {

	// STORE
	const querySa = useStore(querySo)
	const cycleSa = useStore(cycleSo)

	// HOOKs

	// HANDLERs
	const handleChangeDateRange = (dateRange: DateRange) => {
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateRangeToUrl(dateRange)])
	}
	const handleChangeGrowUnit = (growUnit: GrowUnit) => {
		querySo.setSearchUrl([URL_PAR.GROWUNIT_FIL, growUnit?.id?.toString()])
	}
	const handleChangeSeverity = (severity:ALARM_SEVERITY) => {
		querySo.setSearchUrl([URL_PAR.ALARM_SEVERITY, severity?.toString()])
	}
	const handleChangeStatus = (status:ALERT_FILTER) => {
		querySo.setSearchUrl([URL_PAR.ALERT_STATUS, status])
	}

	// RENDER
	const [growUnitId, dateRangeSel, severityId, status] = useMemo(() => {
		const [growUnitUrl, dateRangeSelUrl, severityUrl, statusUrl] = querySo.getSearchUrl([URL_PAR.GROWUNIT_FIL, URL_PAR.DATE_SEL, URL_PAR.ALARM_SEVERITY, URL_PAR.ALERT_STATUS])
		const dateRangeSel = urlToDateRange(dateRangeSelUrl)
		const severityId = +severityUrl
		const growUnitId = +growUnitUrl
		return [growUnitId, dateRangeSel, severityId, statusUrl]
	}, [querySa.queryUrl])


	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>

			<DateRangePickerH sx={{ mr: 1 }}
				dateRange={dateRangeSel}
				onChange={handleChangeDateRange}
			/>

			<SelectorGrowUnitH sx={{ mr: 1 }}
				growUnitId={growUnitId}
				onChange={handleChangeGrowUnit}
			/>

			<SelectorSeverityH sx={{ mr: 1 }}
				severityId={severityId}
				onChange={handleChangeSeverity}
			/>

			<SelectorAlertStatusH sx={{ mr: 1 }}
				filter={status as ALERT_FILTER}
				onChange={handleChangeStatus}
			/>

		</Box >
	)
}

export default AlertFilterHeader

