import ajax from "plugins/AjaxService"
import { DateRange, Uuid } from "types/global"
import { Task } from "types/Task"
import { TaskData } from "types/TaskTemplate"
import { dateRangeToDateRangeString } from "utils"


// url builder
const urlFarmBuilder = (farmId: number, taskId?: number) => `farms/${farmId}/tasks${taskId ? `/${taskId}` : ""}`

// url builder
const urlGrowUnitBuilder = (growUnitId: number, taskId?: number) => `grow_units/${growUnitId}/tasks${taskId ? `/${taskId}` : ""}`


/**
 * Recupera tutti i TASKs di una specifica FARM da una data di "start" ad una data di "end"
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28355
 */
function indexByFarm(farmId: number, range?: DateRange): Promise<{ data: Task[] }> {
	const url = urlFarmBuilder(farmId)
	const r = dateRangeToDateRangeString(range)
	const query = `start_due_date=${r.start}&end_due_date=${r.end}`
	return ajax.get(`${url}?${query}`)
}
/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-244/GET#show--api-farms-:farm_id-tasks-:id
 */
function getByFarm(farmId: number, taskId: number): Promise<{ data: Task }> {
	return ajax.get(`${urlFarmBuilder(farmId, taskId)}`)
}
/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-198/sync-cycle
 */
function sync(farmId: number, cycleUuid: Uuid) {
	return ajax.post(`${urlFarmBuilder(farmId)}/sync_cycle`, { cycle_uuid: cycleUuid })
}


//#region LEGACY

/**
 * Recupera tutti i TASKs di una specifica GROW-UNIT da una data di "start" ad una data di "end"
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-246/GETindex-api-growunits-growunitid-tasks
 */
// function indexByGrowUnit(growUnitId: number, start: DateString, end: DateString): Promise<{ data: Task[] }> {
// 	return ajax.get(`${urlGrowUnitBuilder(growUnitId)}?start_due_date=${start}&end_due_date=${end}`)
// }

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-247/GET#show--api-grow_units-:grow_unit_id-tasks-:id
 */
// function getByGrowUnit(farmId: number, taskId: number): Promise<{ data: Task }> {
// 	return ajax.get(`${urlGrowUnitBuilder(farmId, taskId)}`)
// }

/**
 * retrieves the TASKs of a specific GROW-UNIT included in a time interval
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-199/bulk-schedule
 */
// function bulkSchedule(growUnitId: number, diff: Diff) {
// 	const data = {
// 		scheduled: [
// 			...diff.newTasks,
// 			...diff.modTasks,
// 		],
// 		cancelled: diff.delTasks.map(task => ({ taskUuid: task.taskUuid }))
// 	}
// 	return ajax.post(`${urlGrowUnitBuilder(growUnitId)}/bulk_schedule`, data)
// }
// function bulkScheduleByGrowUnit(growUnitId: number, data: { scheduled: Task[], cancelled: Uuid[] }) {
// 	return ajax.post(`${urlGrowUnitBuilder(growUnitId)}/bulk_schedule`, data)
// }
// function bulkScheduleByFarm(farmId: number, data: { scheduled: Task[], cancelled: { taskUuid: Uuid }[] }) {
// 	return ajax.post(`${urlFarmBuilder(farmId)}/bulk_schedule`, data)
// }
// type Diff = {
// 	newTasks: Task[],
// 	modTasks: Task[],
// 	delTasks: Task[],
// }

/**
 * retrieves the TASKs of a specific CYCLE for GROW-UNIT
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-197/cycle
 */
// function byGrowUnitAndCycle(growUnit: number, cycleUuid: Uuid): Promise<{ data: Task[] }> {
// 	return ajax.get(`${urlGrowUnitBuilder(growUnit)}/cycle?cycle_uuid=${cycleUuid}`)
// }

/**
 * retrieves the TASKs of a specific CYCLE for FARM
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-305/cycle
 */
// function byFarmAndCycle(farmId: number, cycleUuid: Uuid): Promise<{ data: Task[] }> {
// 	return ajax.get(`${urlFarmBuilder(farmId)}/cycle?cycle_uuid=${cycleUuid}`)
// }

/**
 * generarte a set of tasks starting from templates id and due dates. tasks are generated without saving them in DB. To save the generated tasks, use the bulk schedule api
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-161/From-Template
 */
// function draftsTemplate(data: { templates: TaskData[] }) {
// 	return ajax.post(`tasks_drafts/from_template`, data) as Promise<{ data: Task[] }>
// }

/**
 * takes a list of task uuids (of existing tasks). regenerate the tasks and subtasks updating fields from the task template and return a list of tasks drafts
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-264/Resync-with-template
 */
// function draftsResyncTemplate(taskUuids: string[]): Promise<{ data: Task[] }> {
// 	return ajax.post(
// 		`tasks_drafts/resync_with_template`,
// 		{ taskUuids }
// 	)
// }

//#endregion


//#region DRAFT

/**
 * INDEX BY DRAFT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28535
 */
async function indexDraft(draftId: number, range?: DateRange): Promise<{ data: Task[] }> {
	const url = `tasks/tasks_drafts/by_draft/${draftId}`
	const r = dateRangeToDateRangeString(range)
	const query = `start_date=${r.start}&end_date=${r.end}`
	return await ajax.get(`${url}?${query}`)
}

/**
 * BULK SCHEDULE
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-313/Bulk-schedule
 */
function bulkScheduleDraft(draftId: number, data: TaskDraftData): Promise<{ data: Task[] }> {
	return ajax.post(`tasks/tasks_drafts/${draftId}/bulk_schedule`, data)
}
export type TaskDraftData = {
	scheduled?: Task[],
	cancelled?: { refToTaskUuid: Uuid }[],
	undo?: { refToTaskUuid: Uuid }[],
}

/**
 * CREATE FORM TEMPLATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28575
 */
function fromTemplateDraft(templates: TaskData[], draftId?: number) {
	const data = { templates }
	return ajax.post(`tasks/tasks_drafts/${draftId}/from_template`, data) as Promise<{ data: Task[] }>
}

/**
 * RESYNC WITH TEMPLATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28595
 */
function resyncDraft(taskUuids: Uuid[]) {
	const data = { taskUuids }
	return ajax.post(`tasks/tasks_drafts/resync_with_template`, data) as Promise<{ data: Task[] }>
}

//#endregion


const api = {
	//indexByFarm_legacy,
	//indexByGrowUnit,
	//getByGrowUnit,
	// bulkSchedule,
	// bulkScheduleByGrowUnit,
	// bulkScheduleByFarm,
	//byGrowUnitAndCycle,
	//byFarmAndCycle,
	//draftsTemplate,
	//draftsResyncTemplate,

	indexByFarm,
	getByFarm,
	sync,

	//#region DRAFT
	indexDraft,
	bulkScheduleDraft,
	fromTemplateDraft,
	resyncDraft,
	//#endregion
}
export default api