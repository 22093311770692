import { Crop } from "./Crop"
import { PhaseString } from "./global"

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-27575
 */
export interface Recipe {
	id?: number,
	name: string,
	isBatch: boolean,
	totalDays?: number,
	farmIds: number[],
	days: Day[],
	tagList?: string[],
	autotagList?: string[],
	baseRecipe?: Recipe,
	targetSolutionId?: number,
	archived?: boolean,

	groupingFolderId?: number,
}


export interface Day {
	co2?: number,
	dayPhotoPeriod?: number,
	ec?: number,
	humDay?: number,
	humNight?: number,
	irrigationDuration?: number,
	irrigationPause?: number,
	ledBars?: number,
	nightPhotoPeriod?: number,
	nutrientSolutionId?: number,
	ph?: number,
	phase?: PhaseString,
	position?: number,
	spectrum?: number,
	tempDay?: number,
	tempNight?: number
}

/** gli schema disponibili per una PHASE */
export enum RECIPE_SCHEMA {
	CONTINUOUS = "continuous",
	BATCH = "batch",
}


export interface Folder {
	id?: number
	title: string
	subtitle?: string
	farmIds?: number[]
	recipes?: Recipe[]
	crops?: Crop[]
}

export enum FOLDER_SPECIAL_ID {
	ALL = -100
}