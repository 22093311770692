import ajax from "plugins/AjaxService"

/**
 * invia un messaggio al BOT
 */
const postMessage = (message: { text: string }, predefined = false) => {
	return ajax.post(
		"user_messages",
		{
			message,
			predefined
		}
	)
}

const botApi = {
	postMessage,
}

export default botApi