import { useStore } from "@priolo/jon";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Check as SaveIcon, Close as CancelIcon } from "@mui/icons-material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import productSo from "stores/library/product";
import { getUrlByPageId } from "stores/route/utils/pagesId";
import { PAGE_ID } from "stores/route/utils/types";
import { Box } from "@mui/material";


const ProductDetailFooter: FunctionComponent = () => {

	// STORE
	const productSa = useStore(productSo)

	// HOOKs
	const history = useHistory()
	const { t } = useTranslation()

	// HANDLERs
	const handleClickSave = async () => {
		const saved = await productSo.saveSelect()
		if (saved) history.push(getUrlByPageId(PAGE_ID.PRODUCTS))
	}

	// RENDER
	const canSave = productSo.getTotal() > 0
	const disabled = productSa.select?.archived == true

	return <Box sx={{ display: "flex", ml: "26px" }}>

		{!disabled && (
			<ButtonCmp data-test="product-btt-save" sx={{ mr: "20px" }}
				disabled={!canSave}
				shape={BTT_SHAPE.MAIN}
				startIcon={<SaveIcon />}
				onClick={handleClickSave}
			>
				{t("footer.product.save")}
			</ButtonCmp>
		)}

		<ButtonCmp data-test="btt-product-cancel"
			href="/products"
			startIcon={<CancelIcon />}
		>
			{t("footer.product.cancel")}
		</ButtonCmp>

	</Box>
}

export default ProductDetailFooter