import dayjs from 'dayjs'
import ajax from "plugins/AjaxService"
import { Cycle } from "types/Cycle"
import { Layer } from "types/Layer"
import { DateRange, DateString, Uuid } from "types/global"
import { dateRangeToDateRangeString } from 'utils'


// url builder
const urlFarmBuilder = (farmId: number, cycleId?: number): string => `farms/${farmId}/cycles${cycleId ? `/${cycleId}` : ""}`
const urlGrowUnitBuilder = (growUnitId: number, cycleId?: number): string => `grow_units/${growUnitId}/cycles${cycleId ? `/${cycleId}` : ""}`
// function dateQueryBuilder(start: string, end: string) {
//     if (!start && !end) return ""
//     if (start == "auto") start = dayjs().subtract(6, 'month').format("YYYY-MM-DD")
//     if (end == "auto") end = dayjs().add(6, 'month').format("YYYY-MM-DD")
//     let query = start ? `start_date=${start}` : ""
//     query += start && end ? "&" : ""
//     query += end ? `end_date=${end}` : ""
//     return `?${query}`
// }




/**
 * INDEX (FARM)
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28095
 */
function indexByFarm(farmId: number, range?: DateRange): Promise<{ data: Cycle[] }> {
    const url = urlFarmBuilder(farmId)
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return ajax.get(`${url}?${query}`)
}

/**
 * INDEX (GROW UNIT)
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-222/index
 */
function indexByGrowUnit(growUnitId: number, range?: DateRange) {
    const url = urlGrowUnitBuilder(growUnitId)
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return ajax.get(`${url}?${query}`)
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28115
 */
function get(farmId: number, cycleId: number): Promise<{ data: Cycle }> {
    const url = urlFarmBuilder(farmId, cycleId)
    return ajax.get(url)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-25595
 */
function harvestCropLot(lotCode: string, yieldAmount: number, unitsYield: number) {
    return ajax.post(`cycles/yield_by_lot_code`, { lotCode, yieldAmount, unitsYield })
}

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-303/delay
 */
async function modifyByFarm(farmId: number, data: { cycles: Partial<Cycle>[] }): Promise<{ data: Cycle[] }> {
    const result = await ajax.post(`${urlFarmBuilder(farmId)}/delay`, data)
    return result
}


//#region LEGACY

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-189/yield
 */
// function harvest(growUnitId: number, cycleId: number, data: HarvestData) {
//     return ajax.post(`${urlGrowUnitBuilder(growUnitId, cycleId)}/yield`, data)
// }
// type HarvestData = {
//     yieldAmount: number,        // float
//     yieldDate: DateString,      // "YYYY-MM-DD" # normaly set as now()
// }

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-190/reject
 */
// function reject(growUnitId: number, cycleId: number) {
//     if (!growUnitId || !cycleId) return
//     const data = {
//         rejectDate: dateToString(nowWithoutTime())
//     }
//     return ajax.post(`${urlGrowUnitBuilder(growUnitId, cycleId)}/reject`, data)
// }

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-188/delay
 */
// const delay = async (growUnitId, cycleId, data) => {
//     const result = await ajax.post(`${urlGrowUnitBuilder(growUnitId, cycleId)}/delay`, data)
//     return result
// }

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-188/delay
 */
// async function modify(growUnitId: number, data: { cycles: Partial<Cycle>[] }) {
//     const result = await ajax.post(`${urlGrowUnitBuilder(growUnitId)}/delay`, data)
//     return result
// }

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-187/define
 */
// async function define(growUnitId: number, data: { cycle: DefineData }): Promise<DefineResponse> {
//     const result = await ajax.post(`${urlGrowUnitBuilder(growUnitId)}/define`, data)
//     return result
// }
// type DefineData = {
//     cropCycleId: number,
//     seedingDate: DateString,
//     phaseLayers: Partial<Layer>[]
// }
// type DefineResponse = {
//     data?: Cycle,
//     errors?: {
//         code: string // "cycle_overlap"
//         data: any[]
//     }[]
// }

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-191/validateoverlaps
 */
// function validateOverlaps(growUnitId: number, data: VerifyData): Promise<{ data: { dates: OverlapErrorsByDate } }> {
//     return ajax.post(`${urlGrowUnitBuilder(growUnitId)}/validate_overlaps`, data)
// }
// type VerifyData = {
//     cycle: {
//         cropCycleId: number,
//         seedingDates: DateString[],
//         phaseLayers: Partial<GrowUnitLayer>[]
//     }
// }

//#endregion



//#region DRAFT

/**
 * CREATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28275
 */
async function defineDraft(cycle: DefineDataDraft, draftId?: number): Promise<{ data: Cycle }> {
    const data = { cycle }
    const result = await ajax.post(`cycles/cycles_drafts/${draftId}/define`, data)
    return result
}
export interface DefineDataDraft {
    growUnitId: number
    cropCycleId: number
    seedingDate: DateString
    phaseLayers: Partial<Layer>[]
}

/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28315
 */
async function indexDraft(draftId: number, range?: DateRange): Promise<{ data: Cycle[] }> {
    const url = `cycles/cycles_drafts/by_draft/${draftId}`
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return await ajax.get(`${url}?${query}`)
}

/**
 * BULK EDIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29275
 */
async function bulkScheduleDraft(draftId: number, data: CycleDraftData): Promise<{ data: Cycle[] }> {
    const result = await ajax.post(`cycles/cycles_drafts/${draftId}/bulk_schedule`, data)
    return result
}
export type CycleDraftData = {
    scheduled?: Cycle[],
    rejected?: { refToCycleUuid: Uuid }[],
    undo?: { refToCycleUuid: Uuid }[],
}
//#endregion








const api = {
    //index,
    indexByFarm,
    indexByGrowUnit, // USATO IN LAYERS/TOWERS
    get,
    //current,
    //harvest,
    //reject,
    //delay,
    //modify,
    modifyByFarm, // DA ELIMINARE
    //define,
    //validateOverlaps,
    harvestCropLot,


    //#region DRAFT
    defineDraft,
    indexDraft,
    bulkScheduleDraft,
    //#endregion
}
export default api
