import { Star as MainIcon, Share, AssignmentReturned as SubTaskIcon, Assignment as TaskIcon } from "@mui/icons-material";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useStore } from "@priolo/jon";
import Modal from "components/controls/Modal";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import draftSo from "stores/draft";
import detailSo from "stores/task/taskDetail";
import { TASK_SCOPE } from "types/Task";
import TaskDetailTab from "./TaskDetailTab";
import TaskPreviewForm from "./TaskPreviewDialog";
import TaskSubTab from "./TaskSubTab";
import ButtonShare from "components/controls/buttons/ButtonShare";
import { URL_PAR } from "stores/route/utils/types";
import querySo from "stores/route/query";
import taskSo from "stores/task";



const TaskDialog: FunctionComponent = () => {

	// STORE
	const detailSa = useStore(detailSo)
	useStore(draftSo)
	useStore(querySo)

	// HOOKs
	const { t } = useTranslation()
	useEffect(() => {
		if ( detailSa.isOpen ) querySo.setSearchUrl([URL_PAR.TASK_SEL, detailSa.select?.taskUuid])
	}, [detailSa.isOpen])

	// HANDLERs
	const handleClose = () => {
		querySo.setSearchUrl([URL_PAR.TASK_SEL, null])
		detailSo.close()
	}
	const handleSaveAndClose = () => {
		querySo.setSearchUrl([URL_PAR.TASK_SEL, null])
		detailSo.close(true)
	}

	// RENDER 
	if (!detailSa.select) return null
	const readOnly = !draftSo.isEditable()
	const isPreview = detailSa.select?.scope == TASK_SCOPE.PREVIEW

	return (
		<Modal
			icon={<TaskIcon />}
			maxWidth="sm"
			title={t("dialog.task.title")}
			titleEndRender={<ButtonShare />}
			isOpen={detailSa.isOpen}
			onClose={handleClose}
			actionsRender={<>
				<Box style={{ flex: 1 }} />
				{readOnly ? (
					<Button
						onClick={handleClose}
					>{t("dialog.task.btt-close")}</Button>
				) : (<>
					<Button
						onClick={handleClose}
					>{t("dialog.task.btt-cancel")}</Button>
					<Button color="secondary"
						onClick={handleSaveAndClose}
					>{t("dialog.task.btt-confirm")}</Button>
				</>)}
			</>}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				{isPreview
					? <TaskPreviewForm />
					: <>
						<Tabs sx={{ flex: "0 1 auto", marginBottom: "20px" }}
							value={detailSa.tab}
							onChange={(e, tab) => detailSo.setTab(tab)}
						>
							<Tab icon={<MainIcon />} iconPosition="start" label={t("dialog.task.details.title")} />
							<Tab icon={<SubTaskIcon />} iconPosition="start" label={t("dialog.task.subtasks.title")} />
						</Tabs>

						<Box sx={{ flex: "1 0 590px", overflowY: "auto", paddingRight: "15px", marginRight: "-15px" }}>
							{[
								<TaskDetailTab readOnly={readOnly} />,
								<TaskSubTab />,
							][detailSa.tab]}
						</Box>
					</>
				}
			</Box>
		</Modal>
	)
}

export default TaskDialog
