import { InputBase, InputBaseProps, SxProps } from "@mui/material";
import { FunctionComponent } from "react";
import WrapControl from "../base/WrapControl";



interface Props extends InputBaseProps {
	helperText?: string
	sx?: SxProps
}

const TextField: FunctionComponent<Props> = ({
	helperText,
	sx,
	...props
}) => {

	return (
		<WrapControl sx={sx}
			helperText={helperText}
		>
			<InputBase fullWidth
				autoComplete='off'
				type="text"
				{...props}
			/>
		</WrapControl>
	)
}

export default TextField
