import ajax from "plugins/AjaxService"
import { Farm } from "types/Farm"
import { GrowUnit } from "types/GrowUnit"
import { SharedServices } from "types/Service"
import humps from "humps"



// url builder
const urlBuilder = (farmId?: number): string => `farms${farmId ? `/${farmId}` : ""}`

/**
 * retrieves all FARMS
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-107/index
 */
function index(): Promise<{ data: Farm[] }> {
	return ajax.get(urlBuilder())
}

/**
 * Recupera tutte le GROW-UNITs di una specifica FARM
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-178/index
 */
function getGrowUnits(idFarm: number): Promise<{ data: GrowUnit[] }> {
	return ajax.get(`${urlBuilder(idFarm)}/grow_units`)
}

/**
 * Recupera tutti i dati di una specifica FARM
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-108/get
 * @param {*} id identificativo della FARM
 */
async function get(id: number): Promise<{ data: Farm }> {
	const response = await ajax.get(urlBuilder(id), null, { noCamelize: true })
	const mapConfig = response.data.map_config
	delete response.data.map_config
	response.data = humps.camelizeKeys(response.data)
	response.data.mapConfig = mapConfig
	return response
}


/**
 * Recupera tutti i dati della farm CURRENT (per PLC)
  */
function current(): Promise<{ data: Farm }> {
	return ajax.get("zero/farms/current")
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-38955
 */
function sharedServices(): Promise<{data: SharedServices }> {
	return ajax.get("zero/farms/shared_services")
}


export default {
	get,
	index,
	getGrowUnits,

	sharedServices,
	current,
}
