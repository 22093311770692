import { CalendarPicker, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import theme from "styles/theme";
import { DateRange } from "types/global";
import { daysDuration, isSingleDate, normalizeDateRange } from "utils";



interface Props {
	dateRange?: DateRange
	onChange?: (range: DateRange) => void
	sx?: any
}

const MultiDaysCalendar: FunctionComponent<Props> = ({
	dateRange,
	onChange,
	sx
}) => {

	// STORE

	// HOOKs
	const [isFirstClick, setIsFirstClick] = useState<boolean>(true)
	const shift = useRef(false)
	useEffect(() => {
		const handlekeyDown = (e: KeyboardEvent) => shift.current = e.shiftKey
		const handlekeyUp = (e: KeyboardEvent) => shift.current = false
		document.addEventListener('keydown', handlekeyDown)
		document.addEventListener('keyup', handlekeyUp)
		setIsFirstClick(true)
		return () => {
			document.removeEventListener("keydown", handlekeyDown)
			document.removeEventListener("keyup", handlekeyUp)
		}
	}, [])

	// HANDLE
	const handleDateChange = (date: Dayjs) => {
		const dateNum = date.valueOf()
		let range = { ...dateRange }
			//range = { start: dateNum, end: dateNum}
			if (shift.current) {
				range.end = dateNum
			} else if (isFirstClick) {
				range = { start: dateNum, end: null }
			} else {
				range.end = dateNum
			}
			range = normalizeDateRange(range)
			if (isSingleDate(range)) {
			 	setIsFirstClick(false)
			} else {
			 	setIsFirstClick(!isFirstClick)
			}

		onChange(range)
	}

	// RENDER
	const singleDay = daysDuration(dateRange) == 0
	const dateSel = dayjs(dateRange.start)

	const renderDay = (day: Dayjs, selectedDay: any, pickersDayProps: PickersDayProps<Dayjs>) => {
		return <PickersDay {...pickersDayProps} disableMargin sx={cnDay(day/*, group*/)} />
	}
	const cnDay = (day: Dayjs/*, group: GroupTasksByDate*/) => {
		let cssMultiselect = {}
		if (!singleDay && day.isBetween(dateRange.start, dateRange.end, "day", "[]")) {
			cssMultiselect = {
				borderRadius: 0,
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
				'&:hover, &:focus': {
					backgroundColor: theme.palette.secondary.main,
				},
			}
			if (day.isSame(dateRange.start, "day")) {
				cssMultiselect = {
					...cssMultiselect,
					borderTopLeftRadius: '50%',
					borderBottomLeftRadius: '50%',
				}
			} else if (day.isSame(dateRange.end, "day")) {
				cssMultiselect = {
					...cssMultiselect,
					borderTopRightRadius: '50%',
					borderBottomRightRadius: '50%',
				}
			}
		} else {
			cssMultiselect = {}
		}
		return cssMultiselect
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div onClick={(e) => e.stopPropagation()}>
				<CalendarPicker
					//showDaysOutsideCurrentMonth
					//displayStaticWrapperAs="desktop"
					date={dateSel}
					onChange={handleDateChange}
					renderDay={renderDay}
				/>
			</div>
		</LocalizationProvider>
	)
}

export default MultiDaysCalendar

const cssBadge = {
	'& .MuiBadge-badge': {
		right: 6,
		//top: 9,
		border: `2px solid ${theme.palette.background.header}`,
	},
}