import dayjs, { Dayjs } from "dayjs"
import { DateNumber, DateRange, DateRangeString, DateString, isDateRange } from "types/global"
import { dateWithoutTime, nowWithoutTime } from "./time"
import { dateToString } from "./timeFormat"

const dateFormat = "YYYY-MM-DD"
const dateDiv = "_"


//#region RATE RANGE

/**
 * Controlla se effettivamente lo start è minore di end eventualmente li inverte
 * e se non ci sono valori provo a usare il parametro "def"
 */
export function normalizeDateRange(range: DateRange, def?: DateRange): DateRange {
	if (!range) range = def
	if (!range) return {}
	if (range.start == null) range.start = def?.start
	if (range.end == null) range.end = def?.end
	if (!range.start && range.end) range.start = range.end
	if (range.start && !range.end) range.end = range.start
	if (range.start && range.end) {
		range = { start: Math.min(range.start, range.end), end: Math.max(range.start, range.end) }
	}
	return range
}
export function normalize(ranges:DateRange[]): DateRange {
	if ( !ranges ) ranges = []
	let rangeRet:DateRange = {}
	for ( let range of ranges ) {
		if ( !range ) range = {}
		if (rangeRet.start == null) rangeRet.start = range.start
		if (rangeRet.end == null) rangeRet.end = range.end
		if ( !!rangeRet.start && !!rangeRet.end ) {
			rangeRet = { start: Math.min(rangeRet.start, rangeRet.end), end: Math.max(rangeRet.start, rangeRet.end) }
			break
		}
	}
	if (!rangeRet.start && rangeRet.end) rangeRet.start = rangeRet.end
	if (rangeRet.start && !rangeRet.end) rangeRet.end = rangeRet.start
	return rangeRet
}

/** 
 * Restituisce un RANGE da una data di inizio e un numero di giorni 
 * */
export function buildDateRange(start: any, days: number = 0): DateRange {
	const dj = dayjs(start)
	if (!dj.isValid()) return {}
	return {
		start: dj.valueOf(),
		end: dj.add(days, "days").valueOf()
	}
}


export function dateRangeFromStrings(start: DateString, end: DateString): DateRange {
	return {
		start: dayjs(start).valueOf(),
		end: dayjs(end).valueOf()
	}
}

/**
 * restituisce i giorni compresi
 */
export function daysDuration(range: DateRange): number {
	if (!haveValues(range)) return 0
	return dayjs(range.end).diff(range.start, "day")
}



/**
 * Trasforma un DateRange numerico in string (senza time)
 */
export function dateRangeToDateRangeString(range: DateRange): DateRangeString {
	return {
		start: dateToString(range.start),
		end: dateToString(range.end)
	}
}
/**
 * Trasforma un DateRange in una stringa adatta all'URL
 */
export function dateRangeToUrl(range: DateRange): string {
	if (!haveValues(range)) return ""
	const start = dateToString(range.start)
	const end = dateToString(range.end)
	const value = (start != end) ? `${start ?? ""}${dateDiv}${end ?? ""}` : start
	return value
}
/** 
 * trasforma una data in un parametro adatto a essere inserito nell'url 
 * */
export function dateToUrl(value: string | Dayjs | number | DateRange): string {
	let valueUrl = null
	if (isDateRange(value)) {
		valueUrl = dateRangeToUrl(value)
	} else {
		valueUrl = dateToString(value)
	}
	return valueUrl
}
/**
 * Trasforma una stringa URL in un  DateRange
 */
export function urlToDateRange(url: string): DateRange {
	if (!url || url.trim().length == 0) return {}
	const [startStr, endStr] = url.split(dateDiv)
	const start = dateWithoutTime(startStr)
	const end = dateWithoutTime(endStr)
	const value = normalizeDateRange({ start, end })
	return value
}

/** restituisce una stringa rappresentativa del DATE-RANGE */
export function getDateRangeForHuman(dateRange: DateRange): string {
	if (!haveValues(dateRange)) return null
	if (isSingleDate(dateRange)) {
		return dayjs(dateRange.start).format("DD/MM/YYYY")
	}
	return `${dayjs(dateRange.start).format("DD/MM")} - ${dayjs(dateRange.end).format("DD/MM")}`
}



/**
 * Verifico che un "dataRange" sia dentro l'intervallo di un altro DATE-RANGE "filter"
 * da `true` se i due RANGE si "sovrappongono"
 */
export function includes(dateRange: DateRange, filter: DateRange): boolean {
	const outStart = dateRange.start && filter.end && dateRange.start > filter.end
	const outEnd = dateRange.end && filter.start && dateRange.end < filter.start
	return !outStart && !outEnd
}

/** controlla che in un DATE-RANGE contenga un altro DATE RANGE  */
export function includeDateRange(container: DateRange, test: DateRange): boolean {
	if (!haveValues(container)) return false
	if (!haveValues(test)) return true
	return container.start <= test.start && container.end >= test.end
}

/**
 * Restituisce true se il range è ristretto ad una singola date
 */
export function isSingleDate(range: DateRange): boolean {
	return range?.start && (!range.end || range.start == range.end)
}

/**
 * Restituisce true se la data è nel range. se i parametri del range sono null quel limite non esiste
 */
export function isBetween(date: DateNumber, range: DateRange): boolean {
	if (!haveValues(range)) return true
	const start = range.start ? dateWithoutTime(range.start) : null
	const end = range.end ? dateWithoutTime(range.end) : null
	date = dateWithoutTime(date)
	return (start == null || start <= date) && (end == null || end >= date)
}

/** restituisce `true` se due DATE-RANGE sono uguali come contenuti */
export function isEqualRange(range1: DateRange, range2: DateRange): boolean {
	return (range1 == range2) || (range1.start == range2.start && range1.end == range2.end)
}

/**
 * restituisce true se il range ha sia uno "start" che un "end"
 */
export function haveValues(range: DateRange): boolean {
	return !!range && !!range.start && !!range.end
}

/** 
 * dato un DATE-RANGE 
 * restituisce i giorni da "sfalsare" 
 * per poter centrare il numero di giorni "days"
 * (utilizzato per centrare i giorni selezionati e il cycles selezionato)
 * */
export function dateRangeCenter(rangeToCenter: DateRange, days: number): number {
	const rangeDays = daysDuration(rangeToCenter)
	return Math.round((days - rangeDays) / 2)
}

/** Restituisce un DATE_RANGE settato al giorno di oggi */
export function getDateRangeToday(): DateRange {
	const today = nowWithoutTime()
	return { start: today, end: today }
}

/** dati due DATE-RANGE restituisce un DATE-RANGE che li include entrambi 
 * CLONE
*/
export function addDateRange(d1: DateRange, d2: DateRange): DateRange {
	return {
		start: !d1.start ? d2.start : !d2.start ? d1.start : Math.min(d1.start, d2.start),
		end: !d1.end ? d2.end : !d2.end ? d1.end : Math.max(d1.end, d2.end)
	}
}

//#endregion



//#region DATE RANGE STRING

/**
 * Trasforma un DateRangeString in DateRange numerico (senza time)
 */
export function dateRangeStringToDateRange(range: DateRangeString): DateRange {
	return {
		start: dateWithoutTime(range.start),
		end: dateWithoutTime(range.end)
	}
}

/**
 * trasforma una stringa in un oggetto DateRangeString
 */
export function urlToDateRangeString(range: string): DateRangeString {
	const [start, end] = range?.split(dateDiv) ?? []
	return { start, end }
}

/**
 * Trasforma un oggetto DateRangeString in una stringa
 */
export function dateRangeStringToUrl(range: DateRangeString): string {
	const value = `${range?.start ?? ""}${dateDiv}${range?.end ?? ""}`
	return value
}

//#endregion