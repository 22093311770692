import { colors } from "@mui/material"
import dayjs from "dayjs"
import i18n from "i18next"
import { Cycle } from "types/Cycle"
import { GrowUnit } from "types/GrowUnit"
import { GROW_UNIT_STATUS, GROW_UNIT_TYPOLOGY } from "types/GrowUnitEnum"
import { Day } from "types/Recipe"
import { Color } from "types/global"
import { groupBy } from "utils/array"
import { getLetters } from "utils/humanize"
import { getLayerDesiredByNumber } from "./layer"



//export * from "./layer"

/**
 * Un dictionary che traduce una typology con un label
 */
export const GROW_UNIT_TYPOLOGY_LABEL = Object.values(GROW_UNIT_TYPOLOGY)
	.map(type => i18n.t(`app.farm.typology.${type}`))

export function getTypologyLabel(typology: string): string {
	return i18n.t(`app.farm.typology.${typology}`)
}

/**
 * Il colore dello STATUS
 */
export function getColorStatus(growUnit: GrowUnit): Color {
	return {
		[GROW_UNIT_STATUS.IDLE]: colors.blue[500],
		[GROW_UNIT_STATUS.RUNNING]: colors.green[500],
		[GROW_UNIT_STATUS.PAUSED]: colors.yellow[800],
		[GROW_UNIT_STATUS.UNKNOW]: colors.grey[500],
		[GROW_UNIT_STATUS.WAITING]: colors.grey[500],
		[GROW_UNIT_STATUS.OFFLINE]: colors.red[500],
	}[getLabelStatus(growUnit)?.toLowerCase()] ?? "#616161"
}

/**
 * Ricavo lo status in formato stringa
 */
export function getLabelStatus(growUnit: GrowUnit): GROW_UNIT_STATUS {
	if (!growUnit) return GROW_UNIT_STATUS.UNKNOW
	if (growUnit.isOffline) return GROW_UNIT_STATUS.OFFLINE
	if (growUnit.isWaitingForEdge) return GROW_UNIT_STATUS.WAITING
	return growUnit.status
}

/**
 * Ricavo lo stato tradotto
 */
export function getLabelStatusT(growUnit: GrowUnit): string {
	return i18n.t(`app.farm.status.${getLabelStatus(growUnit)}`, GROW_UNIT_STATUS.UNKNOW)
}

/**
 * restituisce il label modello della farm costruito su alcune sue caratteristiche
 */
export function getLabelTypologyByGrowUnit(growUnit: GrowUnit): string {
	if (!growUnit?.typology) return ""
	let typology = growUnit.typology.toUpperCase()
	if (typology == "X") return "LAB"
	return `${typology}_${growUnit.layersPerColumn}_${growUnit.columnsNumber}`
}

/**
 * Restituisce se una GROW-UNIT è un LAB oppure no
 */
export function isFarmLab(growUnit: GrowUnit): boolean {
	if (!growUnit || !growUnit.typology) return false
	return growUnit.typology.toUpperCase() == "X"
		&& growUnit.connectedLayers == 1
		&& growUnit.columnsNumber == 1
}

/**
 * Restituisce il colore di una TYPOLOGY di GROW-UNIT
 */
export function getColorTypology(growUnit: GrowUnit): Color {
	return {
		[GROW_UNIT_TYPOLOGY.MANUAL]: colors.purple[500],
		[GROW_UNIT_TYPOLOGY.SMALL]: colors.teal[500],
		[GROW_UNIT_TYPOLOGY.LARGE]: colors.lightBlue[500],
		[GROW_UNIT_TYPOLOGY.CANNABIS]: colors.orange[500],
		[GROW_UNIT_TYPOLOGY.LAB]: colors.green[500],
		[GROW_UNIT_TYPOLOGY.CONTAINER]: colors.red[500],
		[GROW_UNIT_TYPOLOGY.GERMINATION]: colors.indigo[500],
		[GROW_UNIT_TYPOLOGY.ZR11_VBS]: colors.yellow[300],
		[GROW_UNIT_TYPOLOGY.ZR13_RUH]: colors.yellow[500],
		[GROW_UNIT_TYPOLOGY.ZR14_AUH]: colors.yellow[700],
	}[growUnit.typology?.toUpperCase()] ?? "#616161"
}

/**
 * Inserisco all'interno del CYCLE un parametro dinamico "error" 
 * che indica eventuali problemi relativi ai LAYERS presenti in GROW-UNIT
 */
export function updateCyclesWarning(cycles: Cycle[], growUnit: GrowUnit): void {
	if (!growUnit?.desiredRecipe?.days || !cycles) return

	// raggruppo i days del recipe desired per PHASE
	const groupsDay: { [phase: string]: Day[] } = groupBy<Day>(growUnit.desiredRecipe.days, day => day.phase)

	// ciclo tutti i CYCLE e i loro LAYER
	cycles.forEach(cycle => {
		// se è rejected o harvested allora non ci sono warn
		if (cycle.rejected || cycle.yieldDate != null || !cycle.layers) return
		cycle.layers.forEach(layerCycle => {
			const num = layerCycle.layerNumber
			const layerRecipe = getLayerDesiredByNumber(growUnit, num)
			layerCycle["errors"] = []

			// non c'e' il layer ... poco probabile ma tant'e'
			if (!layerRecipe) {
				layerCycle["errors"].push({ cycleId: cycle.id, code: "layer:null" })
				return
			}

			// il CYCLE LAYER non ha la stessa PHASEs del LAYER del RECIPE
			if (layerCycle.phase != layerRecipe.phase) {
				layerCycle["errors"].push({ cycleId: cycle.id, code: "phase" })
				return
			}

			// il CYCLE LAYER non ha la stessa durata del LAYER del RECIPE
			const cycleDuration = dayjs(layerCycle.busyTo).diff(layerCycle.busyFrom, "day") + 1
			const recipeDuration = groupsDay[layerRecipe.phase]?.length ?? 0
			if (cycleDuration != recipeDuration) {
				layerCycle["errors"].push({ cycleId: cycle.id, code: "duration" })
			}
		})
	})
}

/**
 * restituisco una stringa che rappresenta il numero di giorni attuali
 * @param growUnit 
 * @returns 
 */
export function getCurrentDay(growUnit: GrowUnit): string {
	if (!growUnit) return "--"
	if (growUnit.status == GROW_UNIT_STATUS.IDLE || growUnit.recipe == null || !growUnit.recipe.isBatch) return "--"
	return i18n.t("pag.grow-unit.detail.tab-main.value-current-day", {
		current: growUnit.currentDeployDay,
		total: growUnit.recipe.totalDays ?? "-"
	})
}

/**
 * restituisco il nome di una GROW-UNIT tramite il suo codice
 */
export function getGrowUnitName(growUnit: GrowUnit): string {
	if (!growUnit) return "--"
	if (growUnit.name) return growUnit.name
	const code = growUnit.growUnitCode
	const parts = code.split('_');
	if (parts.length != 3) return code;
	return parts[1]
}

/**
 * Restituisco le GROW-UNIT filtered/sort secondo le impostazioni route
 */
export function getFiltered(growUnits: GrowUnit[], txt?:string, farmId?: number): GrowUnit[] {
	if (!growUnits) return []
	growUnits = growUnits.filter(growUnit =>
		(farmId == null || growUnit.farmId == farmId)
		&&
		(!txt || txt.length == 0
			|| growUnit.growUnitCode.toLowerCase().indexOf(txt) != -1
			|| getLabelTypologyByGrowUnit(growUnit).toLowerCase() == txt
			|| getLabelStatusT(growUnit).toLowerCase() == txt
		)
	)
	// sort
	// growUnits = querySo.getSorted({
	// 	items: growUnits,
	// 	map: {
	// 		"type": f => getLabelTypologyByGrowUnit(f),
	// 		"status": f => getLabelStatusT(f),
	// 		"mode": f => f.mode,
	// 	}
	// })
	return growUnits.sort((g1, g2) => getGrowUnitName(g1).localeCompare(getGrowUnitName(g2)))
}

/**
 * Restituisco i nomi di colonne di una GROW-UNIT
 */
export function getColumnsName(growUnit: GrowUnit): string[] {
	if (!growUnit) return []
	const length = growUnit.columnsNumber
	return Array.from({ length }, (_, index) => getColumnName(growUnit, index))
}
/**
 * Restituisco il nome di una colonna in base al suo numero
 */
export function getColumnName(growUnit: GrowUnit, number: number): string {
	if (!growUnit || isNaN(number)) return ""
	const step = 1 // per il momento non si controlla piu' "growUnit.connectedLayers"
	return getLetters(number * step, step).join("-")
}

/** restituisce se la GROWUNIT è in BATCH */
export function isBatch(growUnit: GrowUnit): boolean {
	return growUnit.recipe?.isBatch
}

/** restituisce se la DESIDERED GROWUNIT è in BATCH */
export function isBatchDesired(growUnit: GrowUnit): boolean {
	if ( !growUnit ) return false
	return growUnit.desiredRecipe?.isBatch
}