import { GrowUnit } from "types/GrowUnit"
import { Component, COMPONENT_TYPE, GrowUnitComponent } from "types/Service"
import { getGrowUnitName } from "."



/**
 * filtra i COMPONENTS
 */
export function getFiltered(components: Component[], txt?: string): Component[] {
	if (!components) return []
	txt = txt?.trim()?.toLowerCase()
	const component = components.filter(component => {
		if ( component.hidden == true ) return false
		if (!(txt?.length > 0)) return true
		if (component.code?.toLowerCase().includes(txt)) return true
		if ((component as GrowUnitComponent)?.options?.code?.toLowerCase()?.includes(txt)) return true
		return false
	})
	return component
}

/** restituisce un component con i dati di una gu offline */
export function getComponentFromGrowUnit( gu: GrowUnit): GrowUnitComponent {
	return {
		code: gu.growUnitCode,
		name: getGrowUnitName(gu),
		type: COMPONENT_TYPE.GROWUNIT,
		subjects: null,
		options: { code: gu.growUnitCode }
	}
}