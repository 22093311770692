import { FilterList as FilterIcon } from "@mui/icons-material";
import { Badge, Box, SxProps } from "@mui/material";
import { useStore } from "@priolo/jon";
import FindTagsField from "components/controls/fields/FindTagsField";
import React, { FunctionComponent } from "react";
import layoutSo from "stores/layout";
import querySo from "stores/route/query";
import { URL_PAR } from "stores/route/utils/types";
import ButtonIcon from "../controls/buttons/ButtonIcon";



interface Props {
	paramUrl?: URL_PAR
	showFilterButton?: boolean
	hideFilterText?: boolean
	placeholder?: string
	sx?: SxProps
}

const SearchFiltersUrl: FunctionComponent<Props> = ({
	paramUrl = URL_PAR.TEXT,
	showFilterButton = false,
	hideFilterText = false,
	placeholder,
	sx,
}) => {

	// STORE
	useStore(querySo)
	const layoutSa = useStore(layoutSo)

	// HOOKs

	// HANDLERs
	const handleChange = (txt: string) => {
		querySo.setSearchUrl([paramUrl, txt])
	}
	const handleClickFiltersExtra = (e: React.MouseEvent) => {
		layoutSo.setDrawerIsOpen(!layoutSa.drawerIsOpen)
	}

	// RENDER
	const search = querySo.getSearchUrl(paramUrl)
	return (
		<Box sx={[{ display: "flex", alignItems: "center" }, sx as any]}>

			{!hideFilterText && (
				<FindTagsField sx={{ mx: 2, flex: 1 }}
					placeholder={placeholder}
					value={search}
					onChange={handleChange}
				/>
			)}

			{showFilterButton && (
				<ButtonIcon sx={{ mx: 1 }}
					onClick={handleClickFiltersExtra}
				>
					<Badge color="secondary" variant="dot"
						invisible={!layoutSa.filterActive}
					>
						<FilterIcon />
					</Badge>
				</ButtonIcon>
			)}
		</Box>
	)
}

export default SearchFiltersUrl
