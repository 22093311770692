const Icon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24" height="24" viewBox="0 0 24 24"
		fill="currentColor"
		stroke="none"
		{...props}
	>
		<path d="M1 11V13H7V11H1Z" fill="#C7C7C7" />
		<path d="M5.65 7.05L7.75 9.15L9.15 7.75L7.05 5.65L5.65 7.05Z" fill="#C7C7C7" />
		<path d="M11 1V7H13V1H11Z" fill="#C7C7C7" />
		<path d="M14.85 7.75L16.25 9.15L18.35 7.05L16.95 5.65L14.85 7.75Z" fill="#C7C7C7" />
		<path d="M17 11V13H23V11H17Z" fill="#C7C7C7" />
		<path d="M14.85 16.25L16.95 18.35L18.35 16.95L16.25 14.85L14.85 16.25Z" fill="#C7C7C7" />
		<path d="M5.65 16.95L7.05 18.35L9.15 16.25L7.75 14.85L5.65 16.95Z" fill="#C7C7C7" />
		<path d="M11 17V23H13V17H11Z" fill="#C7C7C7" />
	</svg>
)

export default Icon