import { Box, List, ListItem, ListItemText, Typography } from "@mui/material"
import { useStore } from "@priolo/jon"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import detailSo from "stores/task/taskDetail"


const TaskSubTab:FunctionComponent = () => {

	// STORE
	const detailSa = useStore(detailSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs

	// RENDER 
	const subtasks = detailSa.select?.subtasks
	if (!subtasks || subtasks.length == 0) return (
		<Typography align="center" style={{ marginTop: "60px" }}>
			{t("dialog.task.subtasks.void")}
		</Typography>
	)

	return (
		<Box sx={{ display: "flex", flexDirection: "column"}}>
			<List>
				{subtasks.map((subtask, index) => (
					<ListItem key={subtask.id ?? index}>
						<ListItemText
							primary={subtask.subject}
							secondary={<span style={{ whiteSpace: 'pre-line' }}>
								{subtask.description}
							</span>}
						/>
					</ListItem>
				))}
			</List>
		</Box>
	)
}

export default TaskSubTab
