import { StoreCore, createStore } from "@priolo/jon"
import theme from "styles/theme"



const setup = {

	state: () => ({
		title: "",
		/** id del componente che necessita di focus */
		focus: "",
		/** indica che il DROWER secondario dei FILTER (per esempio) è aperto o chiuso */
		drawerIsOpen: false,
		/** indica se ci sono filtri abilitati per la corrente pagina
		  questo viene settato da route/queryUrl */
		filterActive: false,
		/** theme material-ui */
		theme: theme,
		/** comandato direttamente dal servizio AJAX indica un loading */
		busy: false,
		/** se settato visualizza una rotella di caricamento e blocca l'interfaccia */
		loadingMsg: <string>null,
	}),

	getters: {
	},

	actions: {
	},

	mutators: {
		setTitle: (title: string) => ({ title }),
		setFocus: (focus: string) => ({ focus }),

		setDrawerIsOpen: (drawerIsOpen: boolean) => ({ drawerIsOpen }),
		setFilterActive: (filterActive: boolean) => ({ filterActive }),

		setTheme: (theme: any) => ({ theme }),
		setBusy: (busy: boolean) => ({ busy }),
		setLoadingMsg: (loadingMsg?: string) => ({ loadingMsg }),
	},
}

export type LayoutState = ReturnType<typeof setup.state>
export type LayoutGetters = typeof setup.getters
export type LayoutActions = typeof setup.actions
export type LayoutMutators = typeof setup.mutators
export interface LayoutStore extends StoreCore<LayoutState>, LayoutGetters, LayoutActions, LayoutMutators {
	state: LayoutState
}
const layoutSo = createStore(setup) as LayoutStore
export default layoutSo
