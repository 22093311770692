import ajax from "plugins/AjaxService"
import { Product } from "types/Product"


// url builder
const urlBuilder = (uuid?: string) => `products${uuid ? `/${uuid}` : ""}`

/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29035
 */
function index(): Promise<{ data: Product[] }> {
	return ajax.get(urlBuilder())
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29055
 */
function get(uuid: string): Promise<{ data: Product }> {
	return ajax.get(urlBuilder(uuid))
}

/**
 * UPDATE / CREATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29075
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29095
 */
function save(uuid: string, product: Product): Promise<{ data: Product }> {
	// CREATE
	if (!!uuid) {
		return ajax.put(urlBuilder(uuid), { product })

		// UPDATE
	} else {
		return ajax.post(`${urlBuilder()}/define`, { product })
	}
}

/**
 * ARCHIVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29115
 */
function archive(uuid: string): Promise<{ data: Product }> {
	return ajax.post(`${urlBuilder(uuid)}/archive`)
}

/**
 * RESTORE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29135
 */
function restore(uuid: string): Promise<{ data: Product }> {
	return ajax.post(`${urlBuilder(uuid)}/restore`)
}


const api = {
	index,
	get,
	save,
	archive,
	restore,
}
export default api