
/** definisce il tipo di modifica all'ELEMENT */
export enum EDIT_TYPE {
	MODIFIED,
	DELETED,
	NEW,
	//UNDO,
}

export interface Entity<T> {
	id?: number,
	/** mi serve internamente per l'editazione */	
	_edit?: {
		type?: EDIT_TYPE
		original?: T
	},
	/** lo so è triste ma è la migliore opzione per immagazzinare dati. Mi serve per le performance */
	_extra?: any
}