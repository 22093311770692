
import { createStore, StoreCore } from "@priolo/jon";
import draftSo from "stores/draft";
import querySo from 'stores/route/query';
import { URL_PAR } from 'stores/route/utils/types';
import taskSo from "stores/task";
import { Uuid } from "types/global";
import { RELATED_TO } from "types/Task";
import { TaskTemplate } from "types/TaskTemplate";
import { haveValues, urlToDateRange } from "utils";



// used when dialog closed
let resolveClose = null

/**
 * Gestisce la dialog per creazione di piu' tasks
 */
const setup = {

	state: {
		/**  se la dialog è aperta */
		isOpen: false,
		/** il task-template-group selezionato */
		taskTemplateGroupId: <number>null,
		/** l'intervallo di tempo tra un task e l'altro */
		dateStep: 1,
		/** I TASK-TEMPLATE selezionati */
		taskTemplates: <TaskTemplate[]>[],
		/** tipo di relazione dei nuovi TASKS */
		relatedTo: RELATED_TO.FARM,
		/** Il CYCLE al quale è associato */
		cycleUuid: <Uuid>null,
		/** Il CYCLE-DRAFT al quale è associato */
		cycleDraftUuid: <Uuid>null,
		/** La GROW-UNIT al quale è associato */
		growUnitId: <number>null,
	},

	getters: {
		canCreate: (_: void, store?: TaskNewDialogStore) => {
			if (store.state.relatedTo == RELATED_TO.CYCLE && !store.state.cycleUuid) return false
			if (store.state.relatedTo == RELATED_TO.GROW_UNIT && !store.state.growUnitId) return false
			return store.state.taskTemplates?.length > 0
				&& haveValues(urlToDateRange(querySo.getSearchUrl(URL_PAR.DATE_SEL) as string))
		}
	},

	actions: {
		/** apre la dialog con delle opzioni */
		open(_: void, store?: TaskNewDialogStore) {
			let [dateRange, cycleUuid, growUnitId] = querySo.getSearchUrl([URL_PAR.DATE_SEL, URL_PAR.CYCLE_SEL, URL_PAR.GROWUNIT_FIL])
			let growUnitIdNum = growUnitId ? +growUnitId : null
			let relatedTo = null
			const taskUuid = taskSo.state.selecteds?.[0]
			if (taskUuid) {
				const task = taskSo.getSelected()?.[0]
				if (task.cycleUuid) cycleUuid = task.cycleUuid
				if (task.relatedTo) relatedTo = task.relatedTo
				if (task.growUnitId) growUnitIdNum = task.growUnitId
			}

			// imposto i valori iniziali
			store.setTasks([])
			store.setTaskTemplateGroupId(null)
			store.setDateStep(1)
			store.setCycleUuid(cycleUuid)
			store.setGrowUnitId(growUnitIdNum)
			if (!relatedTo) relatedTo = cycleUuid ? RELATED_TO.CYCLE : growUnitId ? RELATED_TO.GROW_UNIT : RELATED_TO.FARM
			store.setRelatedTo(relatedTo)

			// apro la dialog
			store.setIsOpen(true)
			return new Promise((resolve, reject) => {
				resolveClose = resolve
			})
		},
		/** si occupa di chiudere la dialog */
		async close(save: boolean, store?: TaskNewDialogStore) {
			store.setIsOpen(false)
			if (save) await store.create()
			if (resolveClose) resolveClose(save)
			resolveClose = null
		},
		/** richiede i draft dei TASK scelti e li memorizza in questo STORE (drafts) 
		 * tieni presente che non sono TASKs memorizzati in DB 
		 * ma sono solo istanziati temporaneamente dai template passati
		*/
		async create(_: void, store?: TaskNewDialogStore) {
			const dateRange = urlToDateRange(querySo.getSearchUrl(URL_PAR.DATE_SEL) as string)
			// creo i TASK-DRAFT
			await draftSo.createTask({
				taskTemplates: store.state.taskTemplates,
				dateRange,
				dateStep: store.state.dateStep,
				growUnitId: store.state.growUnitId,
				relatedTo: store.state.relatedTo,
				cycleUuid: store.state.cycleUuid,
			})
		},
	},

	mutators: {
		setIsOpen: (isOpen: boolean) => ({ isOpen }),
		setTaskTemplateGroupId: (taskTemplateGroupId: number) => ({ taskTemplateGroupId }),
		setDateStep: (dateStep: number) => ({ dateStep }),
		setTasks: (tasks: TaskTemplate[]) => ({ taskTemplates: tasks }),
		setRelatedTo: (relatedTo: RELATED_TO) => ({ relatedTo }),
		setCycleUuid: (cycleUuid: Uuid) => ({ cycleUuid }),
		//setCycleDraftUuid: (cycleDraftUuid: Uuid) => ({ cycleDraftUuid }),
		setGrowUnitId: (growUnitId: number) => ({ growUnitId }),
	},
}

export type TaskNewDialogState = typeof setup.state
export type TaskNewDialogGetters = typeof setup.getters
export type TaskNewDialogActions = typeof setup.actions
export type TaskNewDialogMutators = typeof setup.mutators
export interface TaskNewDialogStore extends StoreCore<TaskNewDialogState>, TaskNewDialogGetters, TaskNewDialogActions, TaskNewDialogMutators {
	state: TaskNewDialogState
}
const store = createStore(setup) as TaskNewDialogStore
export default store
