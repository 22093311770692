import { getLayerByNumber, getLayersDesired } from "stores/growUnit/utils/layer"
import { groupLayersByPhases } from "stores/phase/layers"
import { PHASE_SLUG } from "stores/phase/utils"
import { Crop } from "types/Crop"
import { GrowUnit } from "types/GrowUnit"
import { Layer } from "types/Layer"
import { DateString } from "types/global"

/**
 * restituisce tutti i layers della GROWUNIT presenti nel CROP selezionato
 * @param withPreCycle 
 */
export function getLayers(growUnit: GrowUnit, crop: Crop, withPreCycle: boolean = false): Partial<Layer>[] {
	const phases = crop?.phases
	if (!phases) return []
	const layersByPhase = groupLayersByPhases(getLayersDesired(growUnit))

	return phases.reduce((acc, phaseObj) => {
		const { phase } = phaseObj
		const layers = layersByPhase[phase]
		if (!layers) return acc
		return acc.concat(
			layers
				.filter(layer =>
					layer.disabled == false && (!withPreCycle || (layer.phase != PHASE_SLUG.PRE_CYCLE && layer.phase != PHASE_SLUG.POST_CYCLE))
				).map(layer => {
					return {
						layerId: layer.id,
						layerNumber: layer.layerNumber,
						phase: phase,
					}
				})
		)
	}, [])
}

/**
 * Trasforma un DICTIONARY di Layer selected in n array adatto all'invio al server
 */
export function getUngroupSelectedLayers(growUnit: GrowUnit, layersSelected: { [phase: string]: number[] }): Partial<Layer>[] {
	return Object.entries(layersSelected).reduce((acc, [phase, layersNum]) => {
		return acc.concat(layersNum.map(layerNum => {
			const layer = getLayerByNumber(growUnit, layerNum)
			return {
				layerId: layer.id,
				layerNumber: layer.layerNumber,
				phase: phase,
			}
		}))
	}, [])
}

/** setta gli "overlap" di tutti i LAYERs passati */
export function layersSetOverlaps(layers: Layer[]): void {
	for (const layer of layers) {
		layer.overlaps = []
		for ( const layer2 of layers ) {
			if ( 
				layer2.layerId == layer.layerId &&
				layer2 != layer &&
				new Date(layer.busyFrom) <= new Date(layer2.busyTo) &&
				new Date(layer.busyTo) >= new Date(layer2.busyFrom)
			) {
				layer.overlaps.push(layer2)
			}
		}
	}
}

/**
 * verifica che la data passata come argomento non abbia errori di overlap
 * se è CONTINUOUS controllo che non ci siano overlap sui layer selezionati
 * @param date YYYYMMDD
 */
export function getOverlapDateCont(date: string, layersSelected: LayersNumberByPhase, verify: OverlapError[]) {
	if (!verify) return null
	return Object.keys(layersSelected).some(phase => {
		return layersSelected[phase].some(layerNumber => {
			return getOverlapError(date, layerNumber, verify)
		})
	})
}

/**
 *  Restituisce se c'e' un errore per una data e uno specifico layerNumber
 **/
export function getOverlapError(date: string, layerNumber: number, verify: OverlapError[]) {
	if (!date || layerNumber == null || !verify) return null
	if (!verify) return false
	const verifyLayer = verify.find(v => v.checkedLayer.layerNumber == layerNumber)
	return verifyLayer?.overlaps?.length > 0
}

/** se è in BATCH controllo solo se ci sono overlap in quella data */
export function getOverlapDateBatch(verify: OverlapError[]) {
	if (!verify) return false
	return verify.some(over => over.overlaps?.length > 0)
}

// controlla "groupsLayerNumber" e restituisce: true se c'e' un errore di rearrangment altrimenti false
export function getLayersRatioError(crop: Crop, layersSelected: LayersNumberByPhase) {
	if (!crop || !layersSelected) return false
	let commonRatio = null
	const phasesError = Object.keys(layersSelected)
		.filter(phase => {
			const schema = crop.rearrangementSchema.find(r => r.phase == phase)?.ratio
			if (!schema) return false
			const layersNumber = layersSelected[phase].length
			if (layersNumber % schema != 0) return true
			const ratio = layersNumber / schema
			if (commonRatio == null) {
				commonRatio = ratio
				return false
			}
			return commonRatio != ratio
		})
	return phasesError.length > 0
}

// da errore se c'e' almeno una PHASE del CROP che non ha neanche un layer selezionato
export function getLayersAllError(crop: Crop, layersSelected: LayersNumberByPhase) {
	if (!crop || !layersSelected) return false
	// ciclo tutte le PHASES del CROP e verifico che abbiano almeno un layer selezionato
	const phases = crop.phases ?? []
	return phases.some(phaseObj => {
		const { phase } = phaseObj
		if (phase == PHASE_SLUG.PRE_CYCLE || phase == PHASE_SLUG.POST_CYCLE) return false
		const groups = layersSelected[phase]
		if (!groups || groups.length == 0) return true
		return false
	})
}

export type LayersNumberByPhase = { [phase: string]: number[] }

export type OverlapErrorsByDate = { [date: DateString]: OverlapError[] }

export type OverlapError = {
	checkedLayer: {
		layerNumber: number,
		busyFrom: DateString,
		busyTo: DateString,
	},
	//#for each checked layer an array of lyers that overlaps with the checked one
	overlaps: {
		layerNumber: number,
		busyFrom: DateString,
		busyTo: DateString,
		cycleId: number,
	}[]
}
