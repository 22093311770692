import { StoreCore, createStore } from "@priolo/jon"
import alertApi from "api/alerts"
import guSo from "stores/growUnit"
import { LastDateRange } from "stores/planner/utils/fetch"
import { Alert } from "types/Alert"
import { DateRange } from "types/global"
import { haveValues, normalize } from "utils"



type FetchPayload = {
	farmId?: number
	dateRange?: DateRange
	force?: boolean
	add?: boolean
}

export enum SHOW_MODE {
	BY_DATE= "date",
	BY_SEVERITY= "severity",
}

const setup = {

	state: {
		all: <Alert[]>null,
		select: <Alert>null,
		isDetailOpen: false,
	},

	getters: {
	},

	actions: {
		/** recupera dal BE gli ALERTs */
		fetch: async (payload:FetchPayload, store?: AlertStore) => {
			// controllo ci siano tutti i dati
			const farmId = payload?.farmId ?? guSo.state.lastFarmId
			const dateRange = normalize([payload?.dateRange, LastDateRange])
			if (farmId == null || !haveValues(dateRange)) return

			// a sto punto carico di brutto...
			const { data: alertsLoad } = await alertApi.indexByFarm(farmId, dateRange)
			// se devo aggiungere...
			if (payload.add) {
				store.setAll([...store.state.all, ...alertsLoad])
				// ...altrimenti sostituisco tutto
			} else {
				store.setAll(alertsLoad)
			}
		},
		fetchIfVoid: async (farmId:number, store?: AlertStore) => {
			if ( store.state.all != null ) return
			store.fetch({ 
				farmId,
				dateRange: LastDateRange, 
			})
		},
	},

	mutators: {
		setAll: (all:Alert[]) => ({ all }),
		setSelect: (select:Alert) => ({select}),
		setIsDetailOpen: (isDetailOpen:boolean) => ({ isDetailOpen }),
	},
}



export type AlertState = typeof setup.state
export type AlertGetters = typeof setup.getters
export type AlertActions = typeof setup.actions
export type AlertMutators = typeof setup.mutators
export interface AlertStore extends StoreCore<AlertState>, AlertGetters, AlertActions, AlertMutators {
	state: AlertState
}
const store = createStore(setup) as AlertStore
export default store
