import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Popover, SxProps, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import ButtonCmp from "components/controls/buttons/ButtonCmp";
import NumberField from "components/controls/fields/NumberField";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import dialogSo from "stores/cycle/editDialog";
import draftSo from "stores/draft";
import { CropLot } from "types/CropLot";



/**
 * Il bottone che permette di impostare lo YIELD per ogni CROP_LOTS 
 * e quindi eseguire l'HARVEST
 */
const HarvestButton: FunctionComponent = () => {

	// STORE
	const dialogSa = useStore(dialogSo)
	const draftSa = useStore(draftSo)

	// HOOKs
	const [anchorEl, setAnchorEl] = useState(null)
	const { t } = useTranslation()

	// HANDLERs
	const handleOpenMenu = (e: React.MouseEvent) => setAnchorEl(e.currentTarget)
	const handleCloseAndHarvest = () => {
		dialogSo.harvestCropLots()
		setAnchorEl(null)
	}
	const handleYieldChange = (cropLot: Partial<CropLot>) => dialogSo.setYieldCropLot(cropLot)

	// RENDER
	const canHarvest = !draftSo.isEditable()
	const tooltipMsg = canHarvest ? null : t("pag.cycle.edit.no-harvest")
	return <>

		<Popover
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
		>
			<Box sx={sxRoot}>

				<Typography variant="h5" sx={{ mb: 2 }}>CROP LOT YIELD</Typography>

				{/* LIST CROP LOTS */}
				{dialogSa.cycle?.cropLots?.map((cropLot, index) =>
					<Box key={cropLot.id} sx={cssRowLot}>

						<Typography variant="body2" sx={{ flex: 2 }}>
							{cropLot.cropName}
						</Typography>

						<NumberField sx={{ flex: 1 }}
							value={cropLot.yield}
							onChangeNumber={(value) => handleYieldChange({ id: cropLot.id, yield: parseFloat(value) || 0 })}
							endAdornment={<Typography variant="caption">kg</Typography>}
						/>

						<NumberField sx={{ flex: 1 }}
							value={cropLot.unitsYield}
							onChangeNumber={(value) => handleYieldChange({ id: cropLot.id, unitsYield: parseFloat(value) || 0 })}
							endAdornment={<Typography variant="caption">units</Typography>}
						/>

					</Box>
				)}

				<Button sx={{ mt: 2, alignSelf: "end" }}
					color="secondary"
					onClick={handleCloseAndHarvest}
					disabled={!canHarvest}
				>{t("pag.cycle.edit.btt-harvest")}</Button>

			</Box>
		</Popover>

		<ButtonCmp
			tooltip={tooltipMsg}
			onClick={handleOpenMenu}
			color="secondary"
			endIcon={<KeyboardArrowDown />}
			disabled={!canHarvest}
		>{t("pag.cycle.edit.btt-harvest")}</ButtonCmp>
	</>
}

export default HarvestButton

const sxRoot: SxProps = {
	maxWidth: "400px",
	padding: "20px",
	display: "flex",
	flexDirection: "column",
	gap: 1,
}

const cssRowLot: SxProps = {
	display: "flex",
	alignItems: "center",
	gap: 2,
}
