import { CancelOutlined as CancelIcon, /*Done*/ CheckCircle as DoneIcon, Error as PendingIcon, Visibility } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import ScheduleIcon from "images/schedule";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { DUETIME_TYPE, getTaskStatus, getTaskStatusDescription } from "stores/task/utils";
import { TASK_SCOPE, Task } from "types/Task";

interface Props {
	task: Task
	sx?: any
}

const TaskIcon: FunctionComponent<Props> = ({
	task,
	sx
}) => {

	// STORE

	// HOOKs
	const { t } = useTranslation()

	// HANDLE

	// RENDER
	let tooltip = t("app.task.preview")
	if (task.scope == TASK_SCOPE.PREVIEW) {
		return <Tooltip title={tooltip}>
			<Visibility />
		</Tooltip>
	}

	const status = getTaskStatus(task)
	tooltip = getTaskStatusDescription(status)
	return (
		<Tooltip title={tooltip}>
			{{
				[DUETIME_TYPE.CANCELLED]: <CancelIcon color="error" sx={sx} />,
				[DUETIME_TYPE.CLOSED]: <DoneIcon fontSize="small" color="success" sx={sx} />,
				[DUETIME_TYPE.CLOSED_LATE]: <DoneIcon color="secondary" sx={sx} />,
				[DUETIME_TYPE.OPEN_LATE]: <PendingIcon color="error" sx={sx} />,
				[DUETIME_TYPE.OPEN]: <Box sx={{ display: "flex" }}><ScheduleIcon width={20} heigth={20} style={{ display: "flex" }} /></Box>,
			}[status.type]}
		</Tooltip>
	)
}

export default TaskIcon

