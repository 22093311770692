/** 
 * Indica in che posizione è l'ACTIVITY all'interno del CYCLE (che puo' essere un insieme di ACTIVITY) 
 */
export enum ACTIVITY_TYPE {
	START = 0,
	END,
	FULL,
	MIDDLE,
	/** ACTIVITIES tipicamente abbinate ad un valore numerico */
	LABEL,
}

/** 
 * Indica se il PLANNER è in sola lettura o anche in editazione 
 */
export enum PLANNER_MODE {
	READ = "read",
	EDIT = "edit",
}

export enum GROUP_SHOW_TYPE {
	NORMAL = 0,
	COMPACT,
}

/** 
 * indica il tipo di ROW... se è la TIMELINE o tutto il resto 
 */
export enum ROW_TYPE {
	TIMELINE = "timeline",
	ACTIVITY = "activity",
}

