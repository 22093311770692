import { Task } from "types/Task"
import { TaskTemplateGroup } from "types/TaskTemplate"


/**
 * tipo di lista dei TASK
 */
export enum LIST_TYPE {
	NOTHING = 0,
	DATE,
	RANGE,
	CYCLE,
}

/**
 * i tipi a cui puo' appartenere un gruppo DIFF di TASKs
 * @readonly @enum {string}
**/
export enum DIFF_TYPE {
	NEW = "new",
	MODIFY = "mod",
	DELETE = "del",
	EQUAL = "eql",
	MOVE_OUT = "mou",
	MOVE_IN = "min",
}



/** Restituisce true se i task hanno gli stessi dati */
export function taskIsEqual(t1: Task, t2: Task): boolean {
	return t1.farmId == t2.farmId
		&& t1.cycleUuid == t2.cycleUuid
		&& t1.subject == t2.subject
		&& t1.scope == t2.scope
		&& t1.description == t2.description
		&& t1.points == t2.points
		&& t1.dueDate == t2.dueDate
		&& t1.dueTime == t2.dueTime
		&& t1.updatesYield == t2.updatesYield
		&& t1.cancelIfCycleRejected == t2.cancelIfCycleRejected
		&& !t2["_resync"]
}

/**
 * Restituisce dei gruppi di array-tasks che rappresentano 
 * le differenze tra i parametri passati
 */
export function tasksDiff(tasksCurr: Task[], tasksPrev: Task[]): TaskDiff {
	tasksPrev = [...tasksPrev]
	const res = tasksCurr.reduce((acc, task) => {
		// // se non ha id allora è nuovo
		// if (!task.taskUuid) {
		// 	acc.newTasks.push(task)
		// 	return acc
		// }
		// cerca tramite uuid
		const taskFindIndex = tasksPrev.findIndex(taskTmp => taskTmp.taskUuid == task.taskUuid)
		// se non lo trovo vuol dire che l'ho creato
		if (taskFindIndex == -1) {
			acc.newTasks.push(task)
			return acc
		}
		// se lo trovo e verifico che è cambiato lo metto tra i modificati
		const taskFind = tasksPrev[taskFindIndex]
		if (!taskIsEqual(taskFind, task)) {
			acc.modTasks.push(task)
			// e allora non è stato modificato
		} else {
			acc.eqlTasks.push(task)
		}
		// lo elimino dai precedenti in maniera da capire se ci sono cancellature
		tasksPrev.splice(taskFindIndex, 1)
		return acc
	}, { newTasks: [], modTasks: [], eqlTasks: [] })

	return { ...res, delTasks: tasksPrev }
}

/**
 * Rappresenta un oggetto con dentro le informazioni di differenza tra due liste di TASKs
 */
type TaskDiff = {
	/** sono i TASKs inseriti nuovi (sempre in relazione a "tasksCurr) */
	newTasks: Task[],
	/** i TASKs modificati */
	modTasks: Task[],
	/** i TASKs cancellati */
	delTasks: Task[],
	/** i TASKs rimasti uguali */
	eqlTasks: Task[],

	movedOutTasks?: Task[],
	movedInTasks?: Task[],
}

/**
 * dei task cancellati controllo che non ce ne siano di "move_out"
 * */
export function findMovedOutDiff(diff: TaskDiff, tasks: Task[]) {
	const movedOutTasks = tasks.filter(task => {
		const index = diff.delTasks.findIndex(t => t.taskUuid == task.taskUuid)
		if (index == -1) return false
		diff.delTasks.splice(index, 1)
		return true
	})
	return movedOutTasks
}

/** 
 * da un "diff" restituisco 
 * un singolo array con la proprietà "_sync" che indica a che gruppo appartengono 
 */
export function mormalizeDiff(diff: TaskDiff): Task[] {
	const tasks = [
		...diff.newTasks.map(t => ({ ...t, _sync: DIFF_TYPE.NEW })),
		...diff.modTasks.map(t => ({ ...t, _sync: DIFF_TYPE.MODIFY })),
		...diff.delTasks.map(t => ({ ...t, _sync: DIFF_TYPE.DELETE })),
		...diff.eqlTasks.map(t => ({ ...t, _sync: DIFF_TYPE.EQUAL })),
		...(diff.movedOutTasks?.map(t => ({ ...t, _sync: DIFF_TYPE.MOVE_OUT })) ?? []),
		...(diff.movedInTasks?.map(t => ({ ...t, _sync: DIFF_TYPE.MOVE_IN })) ?? []),
	]
	return tasks
}

/** elimino le prop di utilità per l'edit */
export function removeTag(tasks: Task[]) {
	tasks.forEach(t => {
		delete t["_manual"]
		delete t["_sync"]
		delete t["_resync"]
	})
}

export function TTGFiltered(ttgs: TaskTemplateGroup[], text: string, farmIds?: number[]): TaskTemplateGroup[] {
	ttgs = !!ttgs ? [...ttgs] : []
	const txt = text?.trim().toLowerCase()
	let ttgsFiltered: TaskTemplateGroup[] = ttgs
	if (txt && txt.length > 0 || farmIds?.length > 0) {
		ttgsFiltered = ttgs.filter(ttg => {
			const testTxt = !(txt?.length > 0) || ttg.name.toLowerCase().includes(txt)
			const testFarms = !(farmIds?.length > 0) || ttg.farms?.some(farm => farmIds.includes(farm.id))
			return testTxt && testFarms
		})
	}
	return ttgsFiltered.sort((a, b) => a.name.localeCompare(b.name))
}