import ajax from "plugins/AjaxService"
import { Alert } from "types/Alert"
import { DateRange } from "types/global"
import { dateRangeToDateRangeString } from "utils"


/**
 * Recupera tutti gli ALERTS di una specifica FARM da una data di "start" ad una data di "end"
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-307/GETindex
 */
function indexByFarm(farmId: number, range?: DateRange): Promise<{ data: Alert[] }> {
	const url = `farms/${farmId}/alerts`
	const r = dateRangeToDateRangeString(range)
	const query = `start_date=${r.start}&end_date=${r.end}`
	return ajax.get(`${url}?${query}`)
}

const api = {
	indexByFarm,
}
export default api