import { Box, Typography } from '@mui/material';
import { useStore } from '@priolo/jon';
import CycleIcon from "images/cycle";
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import cycleSo from "stores/cycle";
import { Cycle } from 'types/Cycle';
import { Uuid } from 'types/global';
import { dateToHuman, debounce } from 'utils';
import SelectorDialogBase from '../base/SelectorDialogBase';
import WrapControl, { ICON_VARIANT, WrapControlProps } from '../base/WrapControl';



interface Props extends WrapControlProps {
	/** uuid del CYCLE */
	value: Uuid
	/** quando si seleziona una CYCLE  */
	onChange: (cycle: Cycle) => void
}

const SelectorCycle2: FunctionComponent<Partial<Props>> = ({
	value,
	onChange,

	...props
}) => {

	// STORE
	const cycleSa: any = useStore(cycleSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [textFilter, setTextFilter] = useState("")
	const [textFilterAct, setTextFilterAct] = useState("")

	// HANDLER
	const handleClick = () => {
		if (props.disabled) return
		setIsOpen(true)
	}
	const handleClose = (cycle?: Cycle) => {
		setIsOpen(false)
		if (!cycle) return
		onChange?.(cycle)
	}
	const handleTextFilterChange = (txt: string) => {
		debounce("cycles-filter", () => setTextFilterAct(txt), 800)
		setTextFilter(txt)
	}
	const handleClear = () => {
		onChange?.(null)
	}

	// RENDER
	const cycle: Cycle = useMemo(() => cycleSo.getCycleByUuid(value), [value])

	let cycles = useMemo(() => {
		return cycleSa.all.sort((c1: Cycle, c2: Cycle) => c1.seedingDate > c2.seedingDate ? -1 : (c1.seedingDate < c2.seedingDate ? 1 : 0))
	}, [cycleSa.all])

	cycles = useMemo(() => {
		let txt = textFilter?.trim().toLowerCase()
		if (!txt || txt.length < 3) return cycleSa.all

		const dateStr = txt.split("/")
		if (dateStr.length == 2 && !Number.isNaN(dateStr[0])) {
			txt = `${dateStr[1] ?? ""}-${dateStr[0]}`
			return cycleSa.all.filter((cycle: Cycle) => cycle.seedingDate?.includes(txt))
		}

		return cycleSa.all
			.filter((cycle: Cycle) => (
				// se txt c'e' nel nomde del CROP-LOT o nel sui codice
				cycle.cropLots?.some(cl => cl.cropName?.toLowerCase().includes(txt) || cl.lotCode?.includes(txt))
				// se txt c'e' nel uuid del CYCLE
				|| cycle.cycleUuid?.toLowerCase().includes(txt)
			))
	}, [cycles, textFilterAct])

	return (<>

		<WrapControl
			variantIcon={ICON_VARIANT.PAGE}
			placeholder="Click to select"
			onClick={handleClick}
			onClickClear={handleClear}
			{...props}
		>
			{cycle && <CycleRow cycle={cycle} />}
		</WrapControl>

		<SelectorDialogBase
			icon={<CycleIcon />}
			title={t("selector.cycles")}

			items={cycles}
			textValue={textFilter}
			idSelect={value}

			isOpen={isOpen}
			onClose={handleClose}
			onChangeTextValue={handleTextFilterChange}
			fnTextFromItem={(cycle: Cycle) => <CycleRow cycle={cycle} />}
			fnIdFromItem={(cycle: Cycle) => cycle.cycleUuid}
		/>
	</>)
}

export default SelectorCycle2



interface CycleRowProps {
	cycle: Cycle
}
const CycleRow: FunctionComponent<CycleRowProps> = ({
	cycle
}) => {

	if (!cycle) return null
	const name = cycle?.cropLots.map(cl => cl.cropName).join(", ")

	return <Box sx={{ display: "flex", alignItems: "baseline", flex: "1" }}>

		<Box sx={[cssCircle, { bgcolor: cycle.color }]} />

		<Box sx={{
			flex: 1, width: "0px",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap"
		}}>
			{name}
		</Box>

		<Typography component="span" variant="caption">
			{dateToHuman(cycle.seedingDate, true)}
		</Typography>

	</Box>
}

const cssCircle = {
	height: "12px",
	width: "12px",
	marginRight: "10px",
	borderRadius: "25%",
}