

import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";

export enum HELPER_TEXT_TYPE {
	ERROR,
	INFO,
}

interface Props {
	text?: string
	type?: HELPER_TEXT_TYPE
	sx?:any
}

const HelperTextCmp: FunctionComponent<Props> = ({
	text,
	type = HELPER_TEXT_TYPE.ERROR,
	sx,
}) => {

	// HOOKs
	
	// RENDER
	const isVisible = text?.length > 0
	return isVisible && <Typography variant="caption" color="error" sx={[cssError, sx]}>
		{text}
	</Typography>
}

export default HelperTextCmp

const cssError = {
	zIndex: 1,
	color: "common.black",
	position: "absolute",
	backgroundColor: "error.main",
	padding: "0px 8px",
	marginLeft: "0px",
	borderRadius: "6px",
	border: "2px solid black",
	height: "21px",
	width: "max-content",
}
