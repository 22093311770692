import { Box, SxProps } from "@mui/material";
import React, { FunctionComponent } from "react";
import WrapControl, { ICON_VARIANT } from "./WrapControl";
import { Add } from "@mui/icons-material";



interface Props {
	items?: any[]
	readOnly?: boolean
	placeholder?: string
	onSelect?: (item: any, index: number) => void
	onAdd?: () => void
	renderItem?: (item: any, index?: number) => React.ReactNode

	sx?: any
}

const SelectorChipsValues: FunctionComponent<Props> = ({
	items = [],
	readOnly,
	placeholder,
	onSelect,
	onAdd,

	renderItem = (item: any) => item,
	sx,
}, ref) => {

	// HOOKs

	// HANDLE
	const handleClick = (item: any, index: number, e: React.MouseEvent) => {
		e.stopPropagation()
		onSelect?.(item, index)
	}
	const handleAdd = () => {
		onAdd?.()
	}

	// RENDER
	const notValues = !(items?.length > 0)

	return (
		<WrapControl
			readOnly={readOnly}
			sx={{ ...sx, pl: "8px" }}
			//disabled={disabled}
			variantIcon={ICON_VARIANT.PAGE}
			onClick={handleAdd}
		//ref={ref}
		//refIcon={refIcon}
		//helperText={helperText}
		>
			<Box sx={sxRoot}>

				{notValues ? (
					<Box sx={sxPlaceholder}>{placeholder}</Box>
				) : (
					items.map((item, index) =>

						<Box key={index}
							onClick={(e) => handleClick(item, index, e)}
						>
							{renderItem(item, index)}
						</Box>
					)
				)}

				{!readOnly && (
					<Add sx={{ opacity: .5 }} />
				)}

			</Box>
		</WrapControl>
	)
}

export default SelectorChipsValues

const sxRoot: SxProps = {
	display: "flex",
	flexWrap: "wrap",
	rowGap: "4px",
	gap: 1,
	m: "4px 0px",
}

const sxPlaceholder = {
	opacity: .5
}