import { Box, SxProps } from '@mui/material'
import { useStore } from '@priolo/jon'
import DrawerCmp from 'components/controls/drawer/DrawerCmp'
import RootIcon from "images/menu/root2"
import { FunctionComponent } from 'react'
import eventSo from "stores/event"
import palette from 'styles/palette'
import theme from 'styles/theme'
import ChatField from './ChatField'
import ChatCard from './cards/ChatCard'



const ChatDrawer: FunctionComponent = () => {

	// STORE
	const eventSa = useStore(eventSo)

	// HOOKs

	// HANDLERs

	// RENDER
	return (
		<DrawerCmp width={500}
			sx={cssDrawer}
			icon={<RootIcon fill={palette.text.primary} />}
			title={"ROOT"}
			open={eventSa.drawerIsOpen}
			onClose={() => eventSo.setDrawerIsOpen(false)}
		>
			<ChatCard scroll={eventSa.drawerIsOpen}/>

			<Box sx={{mb: "30px"}} />
			
			<ChatField sx={cssField} />
		</DrawerCmp>
	)
}

export default ChatDrawer

const cssDrawer:SxProps = {
	zIndex: theme.app.header.zIndex - 1,
	marginTop: theme.app.header.height,
}

const cssField:SxProps = {
	display: "flex", mt: 2,
	position: "absolute",
    bottom: "0px",
    width: "459px",
	p: "5px",
    bgcolor: "#1d1d1d",
}