import { TaskDraftData, default as taskApi, default as tasksApi } from "api/tasks"
import dayjs from "dayjs"
import cycleSo from "stores/cycle"
import taskSo from "stores/task"
import { EditTask } from "stores/task/utils"
import { EDIT_TYPE } from "types/Entity"
import { RELATED_TO, Task } from "types/Task"
import { TaskData, TaskTemplate } from "types/TaskTemplate"
import { DateRange, Uuid } from "types/global"
import { clone, forDates } from "utils/func"
import { DraftStore } from "."
import { SESSION_TYPE, sessionVoid } from "./utils"



const tasksSetup = {

	state: {
	},

	getters: {
	},

	actions: {

		/**
		 * Carico i TASKS-DRAFT del DRAFT-ID specificato
		 * e li "merge" con i TASK di SESSION corrispondente
		 */
		async fetchTasks({ draftId, range }: { draftId?: number, range?: DateRange }, store?: DraftStore) {
			if (draftId == null) draftId = store.state.sessions[store.state.select]?.draftId
			const session = store.getSessionByDraftId(draftId)
			if (!session || session.type == SESSION_TYPE.PROD) return

			// loading DRAFT
			const drafts = (await taskApi.indexDraft(draftId, range))?.data ?? []

			// se è quella selezionata modifico lo STORE TASKS
			if (session == store.getSessionSelect()) {
				const templates = EditTask.draftsToTemplate(drafts, taskSo.state.all)
				EditTask.merge(taskSo.state.all, templates)
				store.updateSessionWithStores()
			} else if (session.type == SESSION_TYPE.DRAFT && !sessionVoid(session)) {
				const templates = EditTask.draftsToTemplate(drafts, session.task.all)
				EditTask.merge(session.task.all, templates)
			}
		},

		/**
		 * Sincronizza nel DRAFT un array di TASKS
		 * CURRENT SESSION, IN PLACE
		 */
		async saveTasks(tasks: Task[], store?: DraftStore) {
			const draftId = store.state.sessions[store.state.select]?.draftId
			if (!tasks || tasks.length == 0) return
			const data: TaskDraftData = tasks.reduce<TaskDraftData>((acc, t) => {
				// se è MODIFY o NEW con modifica ...
				if (t._edit) {
					const tMod = EditTask.current(t)
					acc.scheduled.push(tMod)
				}
				return acc
			}, { scheduled: [], undo: [], cancelled: [] })

			return (await taskApi.bulkScheduleDraft(draftId, data))?.data ?? []
		},

		/**
		 * Elimina tutti gli UUID di "uuidsDel"
		 * CURRENT SESSION, IN PLACE
		 */
		async deleteTasks(uuidsDel: string[], store?: DraftStore) {
			const session = store.state.sessions[store.state.select]
			const draftId = session?.draftId
			if (!uuidsDel || uuidsDel.length == 0 || !draftId) return
			const data: TaskDraftData = { scheduled: [], undo: [], cancelled: [] }
			const allTasks = [...taskSo.state.all]

			for (const uuid of uuidsDel) {
				const index = allTasks.findIndex(task => task.taskUuid == uuid)
				if (index == -1) continue
				const task = allTasks[index]

				// se si tratta di un NEW allora ...
				if (task._edit?.type == EDIT_TYPE.NEW) {
					data.undo.push({ refToTaskUuid: task.refToTaskUuid })
					allTasks.splice(index, 1)

					// se si tratta di un DELETED non fare nulla
				} else if (task._edit?.type == EDIT_TYPE.DELETED) {
					continue

					// altrimenti ...
				} else {
					data.cancelled.push({ refToTaskUuid: task.refToTaskUuid })
					if (!task._edit) task._edit = { type: EDIT_TYPE.DELETED }
					task._edit.type = EDIT_TYPE.DELETED
				}
			}

			await taskApi.bulkScheduleDraft(draftId, data)
			taskSo.setAll(allTasks)
		},
		/** 
		 * ripristina dallo stato di DELETE tutti i "TASKS" passati 
		 * CURRENT SESSION INPLACE
		 * */
		async restoreTasks(tasks: Task[], store?: DraftStore) {
			const draftId = store.state.sessions[store.state.select]?.draftId
			if (!draftId || !tasks || tasks.length == 0) return

			const undo = tasks.map(t => ({ refToTaskUuid: t.refToTaskUuid }))
			const data: TaskDraftData = { scheduled: [], undo, cancelled: [] }
			await taskApi.bulkScheduleDraft(draftId, data)

			let allTasks = [...taskSo.state.all]
			for (const task of tasks) {
				const index = taskSo.getIndexByUuid(task?.taskUuid)
				if (index != -1) allTasks[index] = EditTask.original(task)
			}
			// elimino i null dovuti a TASK NEW
			allTasks = allTasks.filter(t => !!t)

			taskSo.setAll(allTasks)
		},

		/** 
		 * creo una serie di TASKS nei DRAFT e li restituisco
		 * CURRENT SESSION
		 * */
		async createTask(
			options: {
				taskTemplates: TaskTemplate[]
				dateRange: DateRange,
				dateStep: number,
				relatedTo: RELATED_TO,
				growUnitId: number,
				cycleUuid: Uuid,
			},
			store?: DraftStore
		) {
			const { taskTemplates, dateRange, dateStep, growUnitId, relatedTo, cycleUuid } = options
			const cycle = cycleSo.getCycleByUuid(cycleUuid)
			const draftId = store.state.sessions[store.state.select]?.draftId

			// create request data
			const templates: TaskData[] = [...forDates(dateRange.start, dateRange.end, dateStep)]
				.reduce((acc, time) => {
					const draft: TaskData[] = taskTemplates.map<TaskData>(taskTemplate => ({
						taskTemplateId: taskTemplate.id,
						growUnitId: relatedTo == RELATED_TO.GROW_UNIT ? growUnitId ?? cycle?.growUnitId : undefined,
						dueDate: dayjs(time).format("YYYY-MM-DD"),
						refToCycleUuid: relatedTo == RELATED_TO.CYCLE ? (cycle?.refToCycleUuid ?? cycleUuid) : undefined,
					}))
					return acc.concat(draft)
				}, [])

			// send to BE
			const { data: drafts } = await tasksApi.fromTemplateDraft(templates, draftId)

			// aggiungo i dati mancanti
			drafts.forEach(task => {
				task._edit = { type: EDIT_TYPE.NEW }
				if (!task.taskUuid) task.taskUuid = task.refToTaskUuid
			})

			taskSo.setAll(taskSo.state.all.concat(drafts))
			return drafts
		},

		/**
		 * Sincronizza i TASKS indicati nell'array di uuid
		 * genera una copia dell'array dei tasks modificati
		 */
		async syncTasks(taskIds: Uuid[], store?: DraftStore) {
			const { data: tasksResync } = await tasksApi.resyncDraft(taskIds)
			if (!tasksResync) return
			const toDraft = []
			const allTasks = [...taskSo.state.all]
			// sostituisci i nuovi tasks
			for (const taskResync of tasksResync) {
				const task = allTasks.find(t => t.taskUuid == taskResync.taskUuid)
				if (!task) continue
				taskResync.subtasks = taskResync.resyncedSubtasks
				delete taskResync.resyncedSubtasks
				EditTask.modify(task, taskResync)
				toDraft.push(task)
			}
			// aggiorno il DRAFT
			await store.saveTasks(toDraft)
			taskSo.setAll(allTasks)
		},

		/** + AGGIUNGE a tutte le SESSIONS i TASKS passati */
		addTasksInSessions: (tasks: Task[], store?: DraftStore) => {
			for (const session of store.state.sessions) {
				if (sessionVoid(session)) continue
				session.task.all = clone(tasks).reduce((acc: Task[], task: Task) => {
					if (session.task.all.some(t => t.taskUuid == task.taskUuid)) return acc
					acc.push(task)
					return acc
				}, [...session.task.all])
				if (session == store.getSessionSelect()) taskSo.state.all = session.task.all
			}
		},

		/** SETTA TUTTI i "tasks" */
		setTasksInSessions: (tasks: Task[], store?: DraftStore) => {
			// [II] ATTENZIONE per il momento lo faccio solo per la PROD
			const sessionProd = store.getSessionProd()
			if (!sessionProd || sessionProd == store.getSessionSelect()) {
				taskSo.setAll(tasks)
			} else {
				sessionProd.task.all = tasks
			}
			//[II] TO DO aggiornare tutte le session di conseguenza 
			// for (let index = 0; index < store.state.sessions.length; index++) {
			// 	const session = store.state.sessions[index]
			// }
		},

	},

	mutators: {
	},
}

export default tasksSetup
