import { GROUP_SHOW_TYPE, GraphGroup, GraphStyle } from "../types"

/**
 * all'interno di "groups" cerca il GROUP con la "path" e lo sostituisce con il "group" passato 
 */
export function setSubGroup(groups: GraphGroup[], path: string[], group: GraphGroup) {
	let currents = groups
	for (let index = 0; index < path.length; index++) {
		const name = path[index]
		const groupIndex = currents.findIndex(group => group.name == name)
		if (groupIndex == -1) return false
		if (index == path.length - 1) {
			currents[groupIndex] = group
			return true
		}
		currents = currents[groupIndex].groups
	}
	return false
}

/**
 * Restituisce un group tramite la sua path 
 */
export function getSubGroup(groups: GraphGroup[], path: string[]): GraphGroup {
	let currents = groups
	for (let index = 0; index < path.length; index++) {
		const name = path[index]
		const groupIndex = currents.findIndex(group => group.name == name)
		if (groupIndex == -1) return null
		if (index == path.length - 1) return currents[groupIndex]
		currents = currents[groupIndex].groups
	}
	return null
}

/**
 * Ciclo tutti i gruppi compresi i children
 */
export function groupsForEach(
	groups: GraphGroup[],
	callback: (group: GraphGroup, path: string[]) => boolean | void,
	path: string[] = []
): void {
	if (!groups) return
	for (const group of groups) {
		const p = [...path, group.name]
		if (callback(group, p)) return
		groupsForEach(group.groups, callback, p)
	}
}

// TODO: spostare la props "style" dopo "callback" e renderla opzionale
/**
 * Cicla tutti i GRAPH-GROUP ricorsivamente e chiamo un "callback"
 * a questo "callback" gli passo il "group" preso in considerazione e la sua "y" e "height"
 */
export function groupsHeightForEach(
	groups: GraphGroup[],
	style: GraphStyle,
	callback: (group: GraphGroup, y?: number, height?: number, collapsed?: boolean) => boolean | void,
	y: number = 0,
	parentCollapsed: boolean = false,
): number {
	if (!groups) return y

	for (const group of groups) {
		const collapsed = group.collapsed || parentCollapsed

		// è una leaf
		if (!group.groups) {
			// dimensione del group (compreso header)
			const height = !parentCollapsed ? groupsGetHeight(group, style) : 0
			if (callback(group, y, height, collapsed)) return y
			y += height

			// è un branch
		} else {
			// dimensione dell'header (l'unica cosa che determina l'heght dato che non ha activity)
			const headHeight = !parentCollapsed ? groupsGetVerticalGap(style) : 0
			// y finale in basso di tutti i sub-groups
			const yNew = groupsHeightForEach(
				group.groups,
				style,
				callback,
				y + headHeight,
				collapsed
			)
			const height = yNew - y
			if (callback(group, y, height, collapsed)) return y
			y = yNew
		}
	}
	return y
}

/** 
 * restituisce semplicemente la distanza verticale che c'e' tra un GRAPH-GROUP e l'altro 
 */
export function groupsGetVerticalGap(style: GraphStyle): number {
	if (!style) return 0
	return style.groupMargin + style.header.height
}

/** 
 * Restituisce l'altezza di uno specifico GRAPH-GROUP 
 * tenendo conto del "collapsed" e ricorsivamente dei suoi "child" 
 * */
export function groupsGetHeight(group: GraphGroup, style: GraphStyle): number {
	if (!group || !style) return 0

	let height = groupsGetVerticalGap(style)

	// se non è collassato...
	if (!group.collapsed) {
		// se è una "leaf"...
		if (!group.groups) {
			// è COMPACT...
			if (group.show == GROUP_SHOW_TYPE.COMPACT) {
				const gap = (group.labels.length * style.compact.y) - style.header.height
				height += gap < 0 ? 0 : gap
				// è NORMAL...
			} else {
				height += group.labels.length * style.space.y
			}
			// se c'e' l'istogramma...
			if (group.activitiesHistogram) height += Math.max(style.histogram.height - style.header.height, 0)
			// è un "breanch"
		} else {
			height += group.groups.reduce((acc, group) => acc + groupsGetHeight(group, style), 0)
		}
	}

	return height
}

/**
 * in "groupsTarget setta la stessa situzione "collapsed" che c'e' in "groupsRef"
 */
export function copyShowSetup(groupsRef: GraphGroup[], groupsTarget: GraphGroup[]): void {
	if (!groupsRef || !groupsTarget) return
	groupsForEach(groupsRef, (group, path) => {
		const groupTarget = getSubGroup(groupsTarget, path)
		if ( !groupTarget ) return
		groupTarget.collapsed = group.collapsed
		groupTarget.show = group.show
	})
}