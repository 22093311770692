import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useStore } from '@priolo/jon'
import FilterDrawer from 'components/layout/FilterDrawer'
import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import layoutSo from "stores/layout"
import querySo from "stores/route/query"



const CyclesFilterDrawer: FunctionComponent = () => {

	// STORE
	const querySa = useStore(querySo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleClearDateRange = () => {
		querySo.updateQueryUrl("")
	}
	const handleCheck = (name: string, checked: boolean, negative: boolean) => {
		querySo.setSearchUrl([name, negative ? (!checked ? "false" : null) : (checked ? "true" : null)])
	}

	// RENDER
	const [ planned, running, harvested, rejected] = useMemo(() => {
		const [planned, running, harvested, rejected] = querySo.getSearchUrl(["planned", "running", "harvested", "rejected"])
		layoutSo.setFilterActive( planned == "false" || running == "false" || harvested == "false" || rejected == "true")
		return [ planned, running, harvested, rejected]
	}, [querySa.queryUrl])

	return (
		<FilterDrawer
			title={t("drawer.common.filters")}
			onReset={handleClearDateRange}
		>

			<Typography sx={{ mt: 3, mb: 1 }} variant="subtitle2">
				{t("drawer.cycle.title-type")}
			</Typography>

			<FormControlLabel
				control={<Checkbox
					checked={planned != "false"}
					onChange={(e, value) => handleCheck("planned", value, true)}
				/>}
				label={t("drawer.cycle.planned")}
			/>

			<FormControlLabel
				control={<Checkbox
					checked={running != "false"}
					onChange={(e, value) => handleCheck("running", value, true)}
				/>}
				label={t("drawer.cycle.running")}
			/>

			<FormControlLabel
				control={<Checkbox
					checked={harvested != "false"}
					onChange={(e, value) => handleCheck("harvested", value, true)}
				/>}
				label={t("drawer.cycle.harvested")}
			/>

			<FormControlLabel
				control={<Checkbox
					checked={rejected == "true"}
					onChange={(e, value) => handleCheck("rejected", value, false)}
				/>}
				label={t("drawer.cycle.rejected")}
			/>

		</FilterDrawer>
	)
}

export default CyclesFilterDrawer

