import { TASK_SCOPE, Task } from "types/Task"
import { DateRange, Uuid } from "types/global"
import { dateWithoutTime, haveValues, isBetween } from "utils"
export * from "./edit"
export * from "./group"
export * from "./status"


/** restituisce i task filtrati secondo parametri */
export function getTaskFiltered(tasks: Task[], growUnitId: number = 0, scope?: TASK_SCOPE | string, cycleUuid?: Uuid, range?: DateRange, text?: string): Task[] {
	if (!tasks) return []
	const haveRange = haveValues(range)
	const txt = text?.trim().toLowerCase() ?? ""

	const tasksFiltered = tasks.filter(task => {
		if (haveRange && !isBetween(dateWithoutTime(task.dueDate), range)) return false
		if (growUnitId && task.growUnitId != growUnitId) return false
		if (scope && task.scope != scope) return false
		if (cycleUuid && task.cycleUuid != cycleUuid) return false
		if (txt.length > 0
			&& !task.subject?.toLowerCase().includes(txt)
			&& !task.taskUuid?.includes(txt)
			&& !task.cycleUuid?.includes(txt)
		) return false
		return true
	})
	return tasksFiltered
}

/** mette in ordine un array di TASKs in base al due_time */
export function sortByDueTime(tasks:Task[]): Task[] {
	return tasks.sort ( (t1, t2)=> {
		if ( !t1.dueTime ) return 1
		if ( !t2.dueTime ) return -1
		return t1.dueTime < t2.dueTime ? -1 : 1;
	})
}