import dayjs from "dayjs"
import i18n from "i18next"
import { Color, Uuid } from "types/global"


export const getLetters = (start: number, length = 1) =>
	Array.from({ length }, (_, index) => String.fromCharCode(start + index + 65))

export function dateFormat(date: string | number | Date | dayjs.Dayjs) {
	if (!date) return ""
	return dayjs(date).format("DD/MM/YYYY")
}
export function dateTimeFormat(date: string | number | Date | dayjs.Dayjs) {
	if (!date) return ""
	return dayjs(date).format("DD/MM/YYYY HH:mm")
}

export const monthName = (index: number, short = false) => {
	return i18n.t(`app.months${short ? "_short" : ""}.${index}`)
}
export const monthNameShort = (index: string | number) => {
	return i18n.t(`app.months.${index}`)
}

export function getRef(uuid: Uuid) {
	return uuid?.split("-")?.[0] ?? ""
}

export function insertIndex(name: string): string {
	let index = 1
	const regex = /\((\d+)\)$/
	const match = name.match(regex)
	if (match) {
		index = parseInt(match[1]) + 1
		return name.replace(regex, `(${index})`)
	}
	return `${name}(${index})`
}

export function generateColor(uuid: Uuid = "A"): Color {
	let index = 0
	for (let i = 0; i < uuid.length && i < 10; i++) index += uuid.charCodeAt(i)
	const color = colors_per[index % colors_per.length]
	return color as Color
}

const colors_per = [
	"#FF5733", // Arancione brillante
	"#33FF57", // Verde brillante
	"#5733FF", // Blu brillante
	"#FF33E6", // Rosa brillante
	"#33FFEC", // Ciano brillante
	"#FFD933", // Giallo brillante
	"#33B5FF", // Azzurro brillante
	"#FF336E", // Rosso brillante
	"#33FFA6", // Verde acqua brillante
	"#B533FF", // Viola brillante
	"#FF3333", // Rosso vivo
	"#33FF33", // Verde vivo
	"#3333FF", // Blu vivo
	"#FFFF33", // Giallo vivo
	"#33FFFF", // Ciano vivo
	"#FF33FF", // Magenta vivo
	"#33B5FF", // Azzurro vivo
	"#FF5733", // Arancione vivo
	"#33FF57", // Verde vivo
	"#5733FF"  // Blu vivo
];
