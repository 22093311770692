
/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-16070/wyxbb-16110
 */
export interface Metadata {
	serviceCode?: string 		// nome della CODA
	name?: string				// key del VALUE

	type?: META_TYPE
	format?: string
	alias?: string

	description?: string
	target?: string
	group?: string
	readOnly?:boolean			
	graph?: boolean				// indica se il dato deve essere "graficato"
	tag?: string 				// descrizione
	um?: string					// unità di misura
	min?: number				// valore minimo
	max?: number				// valore massimo
	recipe?: boolean		    // indica se il dato è un parametro gestito sul plan di little root
	_tmp?: boolean				// indica che il metadata è temporaneo
}

export function isEqual(m1: Metadata, m2: Metadata): boolean {
	return m1.serviceCode == m2.serviceCode && m1.name == m2.name
}

export enum META_TYPE {
	BOOLEAN = "boolean",
	INTEGER = "integer",
	REAL = "real",
}
