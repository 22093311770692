import { Activity, TAGS } from "components/planner/types"
import { setType } from "components/planner/utils"
import dayjs from "dayjs"
import { EditCycle } from "stores/cycle/utils/edit"
import { Cycle } from "types/Cycle"
import { Layer } from "types/Layer"
import { DateString } from "types/global"
import { dateToString } from "utils"
import { dateWithoutTime, diffDay } from "utils/time"

/**
 * da un array CYCLEs restituisce un array di ACTIVITY
 */
export function activitiesFromCycles(
	cycles: Cycle[],
	phasesLayer: { [phase: string]: { layerNumber: number }[] },
	growUnitId: number,
): Activity[] {

	// in ordine in base al "layerNumber"
	for (const phase in phasesLayer) phasesLayer[phase]
		?.sort((l1, l2) => l2.layerNumber - l1.layerNumber)

	// restituisce tutte le ACTIVITY raggruppate per CYCLE
	const actCycles = cycles
		.filter(cycle => cycle.growUnitId == null || cycle.growUnitId == growUnitId)
		.map(cycle => {
			const color = cycle.color
			const yieldTime = cycle.yieldDate ? dateWithoutTime(cycle.yieldDate) : null

			// restituisco tutte le ACTIVITY presenti in un CYCLE
			const actLayers = cycle.layers?.map(layer => {
				// per le PREVIEW
				if (layer.growUnitId != null && layer.growUnitId != growUnitId) return null
				const phase = layer.phase
				const layers = phasesLayer[phase]
				if (!phase || !layers || layers.length == 0) return null
				const startTime = dateWithoutTime(layer.busyFrom)
				let endTime = dateWithoutTime(layer.busyTo)
				// se è stato raccolto allora eventualmente taglia il cycle

				if (yieldTime) {
					if (startTime > yieldTime) return null
					if (endTime > yieldTime) endTime = yieldTime
				}
				const delta = diffDay(endTime, startTime) + 1
				// la row è in posizione inversa
				const row = layers.findIndex(l => l.layerNumber == layer.layerNumber)
				if (row == -1) return null
				const activity: Activity = {
					//layer,	// mi serve per abbinarlo alla "phase" e al "cycleId"
					tags: {
						[TAGS.CYCLES]: [cycle.cycleUuid],
						[TAGS.PHASE]: phase,
						[TAGS.LAYER]: layer.layerNumber,
						[TAGS.EDIT]: cycle._edit?.type,
						[TAGS.SESSION]: EditCycle.getExtra(cycle, TAGS.SESSION),
					},
					start: startTime,
					length: delta,
					row,
					color,
				}
				return activity
			}).filter(activity => activity)

			setType(actLayers)

			return actLayers

		})
		.filter(activities => activities && activities.length > 0)

	return actCycles.flat(Infinity) as Activity[]
}

/** 
 * Dato un array di ACTIVITY restituisce un CYCLE (con i suoi "layers" opportunamente settati)
 * */
export function cycleFromActivities(activities: Activity[], yieldDate?: DateString): Cycle {
	const cycleUuid = activities[0]?.tags[TAGS.CYCLES]?.[0]
	if (!cycleUuid) return null

	let minDate: number = null
	let maxDate: number = null
	const layers = activities.map(activity => {
		const dayFrom = dayjs(activity.start)
		const dayTo = dayFrom.add(activity.length - 1, "day")
		if (minDate == null || dayFrom.valueOf() < minDate) minDate = dayFrom.valueOf()
		if (maxDate == null || dayTo.valueOf() > maxDate) maxDate = dayTo.valueOf()
		const layerNumber = activity.tags[TAGS.LAYER]
		return {
			layerNumber,
			busyFrom: dateToString(dayFrom),
			busyTo: dateToString(dayTo),
			phase: activity.tags[TAGS.PHASE]
		} as Layer
	})
	const cycle: Cycle = { cycleUuid, layers }
	if (minDate) cycle.seedingDate = dateToString(minDate)
	if (maxDate) cycle.harvestingDate = dateToString(maxDate)
	if (yieldDate) cycle.yieldDate = dateToString(maxDate)
	return cycle
}

