import React, { FunctionComponent } from 'react';
import WarnLayerIcon from './WarnLayerIcon';

import growUnitStore from 'stores/growUnit';
import { useStore } from '@priolo/jon';
import { Box } from '@mui/material';



interface Props {
	layer?: any
	error?: any
}
const CycleLayer:FunctionComponent<Props> = ({
	layer,
	error,
}) => {

	// STORE
	useStore(growUnitStore)
	const { getLayerByNumber, getLayerName } = growUnitStore

	// HOOKs

	// RENDER
	const labelName = getLayerName(getLayerByNumber(layer.layerNumber))
	return (
		<Box sx={cssLayer} className={error ? "overlap" : ""}>
			<WarnLayerIcon fontSize="small" layer={layer} sx={cssWarn} />
			{labelName}
		</Box>
	)
}

export default CycleLayer



const cssLayer = theme => ({
	backgroundColor: theme.palette.secondary.main,
	padding: "3px 10px",
	margin: "2px 5px",
	textAlign: "center",
	color: theme.palette.black.main,
	display: "flex",
	alignItems: "center",
	"&.overlap": {
		backgroundColor: theme.palette.error.main
	}
})
const cssWarn = {
	marginRight: "5px",
}
