import { Color, DateNumber } from "types/global";
import { ACTIVITY_TYPE, GROUP_SHOW_TYPE, ROW_TYPE } from "./enum";
import { EDIT_TYPE, Entity } from "types/Entity";



/** 
 * E' una riga sul PLANNER oppure un valore 
 * piu' precisamente appare in un GROUP
 */
export interface Activity extends Entity<Activity> {
	/** data di start dell'ACTIVITY */
	start: DateNumber,
	/** lunghezza in giorni dell'ACTIVITY 
	 * questo valore non c'e' per i DATA-ITEM
	*/
	length?: number,
	/** posizione verticale nella griglia del GROUP 
	 * dove è presente questa ACTIVITY
	 */
	row?: number,
	/** stringa visualizzata. 
	 * Usato da DATA-ITEM */
	label?: string,
	/** colore dell'ACTIVITY  */
	color?: Color,

	/** indica la visualizzazione particolare di un activity */
	type?: ACTIVITY_TYPE,

	/** sono informazioni di relazione di questa ACTIVITY:
	 * cycles: Uuid[] - i CYCLEs collegati a questa activity (usato soprattutto per la ACTIVITY TASKS)
	 * phase: string - il group phase in cui risiede questa ACTIVITY
	 * joint: number[] - dall'inizio ACTIVITY, i giorni da contare in cui ci sono dei JIONT (cambio di PHASE quindi ACTIVITY su PHASES diverse)
	 **/
	tags?: { [key: string]: any },
	
	/** Questo è un valore di uso generico  
	 * per esempio in un DATA-ITEM contiene il dato in formato numerico
	 * tieni presente che il dato è visualizzato con "label"
	 */
	value?: any,

	/** indica che l'ACTIVITY ha subito un editazione */
	//_edit?: ActivityEdit,

	/** serve a mantenere le ACTIVITY in overlap */
	overlaps?: Activity[],
}

/** dati aggiuntivi presenti in un ACTIVITY editato */
export interface ActivityEdit {
	type: EDIT_TYPE
	original?: Partial<Activity>
}

export enum TAGS {
	CYCLES = "cycles",
	PHASE = "phase",
	JOINT = "joint",
	LAYER = "layer",
	EDIT = "edit",
	SESSION = "session",
}

/**
 * un gruppo di ACTIVITY che viene visualizzato in un LAYER
 * di fatto puo' essere inteso come un LAYER che si puo' nascondere o visualizzare
 */
export type GraphGroup = {
	/** identificativo del GROUP */
	name?: string,
	/** titolo che appare sul PLANNER */
	title?: string,
	/** il tipo di visualizzazione del contenuto */
	show?: GROUP_SHOW_TYPE,

	/** indica che qua non ci sono ACTIVITY selezionaili col CLICK */
	unselectable?: boolean,
	/** indica se è possibile "collassare" il GROUP */
	noCollap?: boolean,
	/** indica se è possibile "compact" il GROUP */
	noCompact?: boolean,

	/** se è collassato (true) o no (false) */
	collapsed?: boolean,
	/** se non è null allora è un CONTAINER di altri GROUPs  */
	groups?: GraphGroup[]
	/** indica il numero di ROW e la scritta che deve apparire a lato del PLANNER */
	labels?: string[],
	/** le ACTIVITIES da visualizzare per questo PLANNER */
	activities?: Activity[],
	/** le ACTIVITIES che devono essere utilizzate per costruire l'istogramma */
	activitiesHistogram?: Activity[],
}

/**
 * rettangolo segnaposto di un array di ACTIVITIES all'interno di un GROUP
 */
export type GraphRect = {
	p1x: number,
	p1y: number,
	p2x: number,
	p2y: number,
	/** ACTIVITY che il rect delimita */
	activities?: Activity[],
	/** GROUP dove sono presenti quelle ACTIVITY */
	group?: GraphGroup,
	/** indica se il gruppo è collassato (tenendo conto anche dei parent) */
	collapsed?: boolean,
}

/**
 * Un evento avvenuto sul PLANNER
 */
export type EventMouseGantt = {
	event: MouseEvent,
	dateSelect: number,
	row?: ROW_TYPE,
	activity?: Activity,
}

