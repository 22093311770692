import { Box, Checkbox, List, ListItemButton, ListItemIcon, ListItemText, SxProps } from "@mui/material";
import React, { FunctionComponent } from "react";
import PlaceholderCmp, { ICON_TYPE } from "../PlaceholderCmp";



interface Props {
	/** oggetti da visualizzare */
	items: any[]
	/** array di ID degli item selezionati */
	itemIdsSelect: any[]
	sx?: SxProps

	onChangeItemsSelect: (items: any[]) => void

	/** per un "item" restituisce il suo "text" */
	fnTextFromItem: (item: any) => React.ReactNode,
	/** per un "item" restituisce il suo "sottotitolo" */
	fnSecondaryFromItem: (item: any) => React.ReactNode,
	/** a fronte di un item restituisce il suo ID */
	fnIdFromItem: (item: any) => any,
}

const MultiSelectorBase2: FunctionComponent<Partial<Props>> = ({
	items,
	itemIdsSelect,
	sx,

	onChangeItemsSelect,

	fnTextFromItem = (item) => item,
	fnSecondaryFromItem,
	fnIdFromItem = (item) => item,
}) => {

	// HOOKs

	// HANDLER
	const handleClickItem = (item: any) => {
		const itemId = fnIdFromItem(item)
		if (isSelect(itemId)) deselectItem(itemId); else selectItem(itemId)
	}

	// RENDER 
	/** restituisce se un ITEM è selezionato (o no) */
	const isSelect = (itemId: any) => itemIdsSelect?.some(id => id == itemId) ?? false

	const selectItem = (itemId: any) => {
		if (isSelect(itemId)) return
		const newItemsSelect = [...(itemIdsSelect ?? []), itemId]
		onChangeItemsSelect(newItemsSelect)
	}
	const deselectItem = (itemId: any) => {
		if (!isSelect(itemId)) return
		const newItemsSelect = itemIdsSelect?.filter(t => t != itemId) ?? []
		onChangeItemsSelect(newItemsSelect)
	}

	return (
		<Box sx={[sxListCont as any, sx]}>
			{items?.length > 0 ? (
				<List sx={{ width: "100%" }}>
					{items.map((item, index) => (

						<ListItemButton data-test={`dialog-item-${index}`} key={index} dense
							onClick={() => handleClickItem(item)}
						>
							<ListItemIcon>
								<Checkbox
									checked={isSelect(fnIdFromItem(item))}
									tabIndex={-1}
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText
								primary={fnTextFromItem(item)}
								secondary={fnSecondaryFromItem?.(item)}
							/>
						</ListItemButton>

					))}
				</List>
			) : (
				<PlaceholderCmp sx={{ height: "50%" }}
					iconType={ICON_TYPE.VOID}
				/>
			)}
		</Box>
	)
}

export default MultiSelectorBase2

const sxListCont: SxProps = {
	flex: 1,
	overflowY: "auto",
}
