import ajax from "plugins/AjaxService"


/**
 * retrieves all ALARMS
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-260/GET#index
 */
const index = () => ajax.get("alarms")

/**
 * modify or create ALARM
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-261/POST#manage
 */
const manage = alarm => ajax.post("alarms/manage", alarm)

/**
 * delete a specific ALARM
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-263/DELETE#manage
 */
const destroy = alarmCode => ajax.delete("alarms/manage", { alarmCode } )


const api = {
	index,
	manage,
	destroy,
}
export default api