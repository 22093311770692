import ajax from "plugins/AjaxService"
import { Recipe } from "types/Recipe"



// url builder
const urlBuilder = (id?: number) => `recipes${id ? `/${id}` : ""}`

/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28015
 */
function index(): Promise<{ data: Recipe[] }> {
	return ajax.get(urlBuilder())
}

/**
 * GET
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28035
 */
function get(id: number): Promise<{ data: Recipe }> {
	return ajax.get(urlBuilder(id))
}

/**
 * UPDATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28055
 */
function save(id: number, recipe: Recipe): Promise<{ data: Recipe }> {
	if (id) {
		return ajax.patch(urlBuilder(id), { recipe })
	} else {
		return ajax.post(urlBuilder(), { recipe })
	}
}

/**
 * DELETE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28075
 */
function destroy(id: number): Promise<void> {
	return ajax.delete(urlBuilder(id))
}

/**
 * ARCHIVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-46575
 */
const archive = (id: number): Promise<{ data: Recipe }> => ajax.post(`${urlBuilder(id)}/archive`)

/**
 * BULK ARCHIVE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-47235
 */
const bulkArchive = (recipesIds: number[]): Promise<void> => ajax.post(`recipes/bulk_archive`, { recipesIds })


/**
 * RESTORE
 * ???
*/
const restore = (id: number): Promise<{ data: Recipe }> => ajax.post(`${urlBuilder(id)}/restore`)


const recipesApi = {
	index,
	get,
	save,
	destroy,
	archive,
	bulkArchive,
	restore,
}
export default recipesApi