import { Today as TodayIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import ButtonCmp from "components/controls/buttons/ButtonCmp";
import dayjs, { Dayjs } from "dayjs";
import MonthIcon from "images/dateMonth";
import WeekIcon from "images/dateWeek";
import React, { FunctionComponent } from "react";
import querySo from "stores/route/query";
import { URL_PAR } from "stores/route/utils/types";
import theme from "styles/theme";
import { dateRangeToUrl, urlToDateRange } from "utils";


interface Props {
	sx?: any
}

const RangeUrlSelector: FunctionComponent<Props> = ({
	sx
}) => {

	// STORE

	// HOOKs

	// HANDLE
	const handleDateChange = (date: Dayjs) => {
		const dateNum = date.valueOf()
		const rangeUrl = dateRangeToUrl({ start: dateNum, end: dateNum })
		querySo.setSearchUrl([URL_PAR.DATE_SEL, rangeUrl])
	}
	const handleWeek = () => {
		const range = { start: dateSel.startOf("week").valueOf(), end: dateSel.endOf("week").valueOf() }
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateRangeToUrl(range)])
	}
	const handleMonth = () => {
		const range = { start: dateSel.startOf("month").valueOf(), end: dateSel.endOf("month").valueOf() }
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateRangeToUrl(range)])
	}

	// RENDER
	const dateRangeUrl = querySo.getSearchUrl(URL_PAR.DATE_SEL)
	const dateRange = urlToDateRange(dateRangeUrl)
	const dateSel = dayjs(dateRange.start)

	return (
		<Box
			sx={[{ display: "flex" }, sx]}
			onClick={(e) => e.stopPropagation()}
		>
			<ButtonCmp
				startIcon={<TodayIcon />}
				onClick={() => handleDateChange(dayjs())}
			>TODAY</ButtonCmp>
			<ButtonCmp
				startIcon={<WeekIcon width={20} height={20} />}
				onClick={() => handleWeek()}
			>WEEK</ButtonCmp>
			<ButtonCmp
				startIcon={<MonthIcon width={20} height={20} />}
				onClick={() => handleMonth()}
			>MONTH</ButtonCmp>
		</Box>
	)
}

export default RangeUrlSelector

const cssBadge = {
	'& .MuiBadge-badge': {
		right: 6,
		border: `2px solid ${theme.palette.background.header}`,
	},
}