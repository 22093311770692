import { createStore, StoreCore } from "@priolo/jon";
import botApi from "api/bot";
import dashboardApi from "api/events";
import { eventChannel } from "plugins/ChannelService";
import { Event } from "types/Root";
import { GroupedEvents, groupEventsByFarmAndSource, LinkItem } from "./utils";



/**
 * Gestione EVENTS cioe' gli i messaggio di ROOT
 */
const setup = {
	state: {
		all: <Event[]>null,
		drawerIsOpen: false,
		groups: <GroupedEvents[]>[],
		summary: <LinkItem[]>[],
		textSend: "",
		unread: 0,
	},
	getters: {
		/** ricava tutte le farm disponibili (solo i nomi) */
		getFarmNames: (_: void, store?: EventStore): string[] => {
			const allfarms = store.state.all.map(event => event.farmName)
			const farms = [...new Set(allfarms)]
			return farms.filter(n => n).sort()
		},
	},
	actions: {
		fetchIfVoid: async (_: void, store?: EventStore) => {
			if (store.state.all) return
			store.setAll([])
			store.fetch()
		},
		fetch: async (_: void, store?: EventStore) => {
			const { data } = await dashboardApi.index()
			store.setAll(data)
			store.update()
		},
		send: async (_: void, store?: EventStore) => {
			if (!store.state.textSend || store.state.textSend.trim().length == 0) return
			await botApi.postMessage({ text: store.state.textSend })
			store.setTextSend("")
		},
		update: (filter?: { txt: string, farmNames: string[] }, store?: EventStore) => {
			let events = store.state.all.sort((a, b) => a.timestamp - b.timestamp);
			if (filter?.farmNames?.length > 0) {
				events = events.filter(event => filter?.farmNames.includes(event.farmName))
			}
			const txt = filter?.txt?.trim().toLowerCase()
			if (txt && txt.length > 0) {
				events = events.filter(event => event.text.toLowerCase().indexOf(txt) != -1)
			}
			const { groups, summary } = groupEventsByFarmAndSource(events)
			store.setGroups(groups)
			store.setSummary(summary)
		},

	},
	mutators: {
		/** setta tutti gli eventi visibili */
		setAll: (all: Event[]) => ({ all }),
		setDrawerIsOpen: (drawerOpen: boolean) => ({ drawerIsOpen: drawerOpen }),
		toggleDrawer: (_: void, store?: EventStore) => ({ drawerIsOpen: !store.state.drawerIsOpen }),
		/** aggiunge un evento sotto a tutti gli altri */
		addToAll: (event: Event, store?: EventStore) => ({ all: [...(store.state.all ?? []), event] }),
		setTextSend: (textSend: string) => ({ textSend }),
		setGroups: (groups: GroupedEvents[]) => ({ groups }),
		setSummary: (summary: LinkItem[]) => ({ summary }),
		setUnread: (unread: number) => ({ unread }),

		//setFarmNameFilter: (farmNameFilter) => ({ farmNameFilter }),
		//setTextFilter: (textFilter) => ({ textFilter }),
	},
}

export type EventState = typeof setup.state
export type EventGetters = typeof setup.getters
export type EventActions = typeof setup.actions
export type EventMutators = typeof setup.mutators
export interface EventStore extends StoreCore<EventState>, EventGetters, EventActions, EventMutators {
	state: EventState
}
const store = createStore(setup) as EventStore
export default store

// collego lo store al channel
function handleReceive(data: { event: Event }) {
	store.addToAll(data.event)
	store.update()
	store.setUnread(store.state.unread + 1)
}
eventChannel?.subscribe(handleReceive)
//eventChannel?.unsubscribe(handleEvents)
