import { Box, SxProps } from "@mui/material"
import { useStore } from "@priolo/jon"
import { FunctionComponent } from "react"
import parentsSo from "stores/mainMenu/parents"
import natsSo from "stores/nats"
import MenuParents from "./MenuParents"
import MenuSections from "./MenuSections"



interface Props {
	sx?: SxProps
}

const MainMenu: FunctionComponent<Props> = ({
	sx,
}) => {

	// STATE
	const parentsSa = useStore(parentsSo)
	useStore(natsSo)

	// RENDER
	return <Box sx={[cssContainer as any, sx]}>
		<MenuParents sx={{mt: "20px"}}/>
		<MenuSections sx={{mt: "20px"}}/>
	</Box>
}

export default MainMenu

const cssContainer: SxProps = {
	display: "flex",
	flexDirection: "column",
}
